import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getClientCertificateAndPrivateKey } from 'lane-shared/helpers';
import { convertToUUID } from 'uuid-encoding';
import {
  AccessControlServiceDefinitions,
  AccessControlServiceEntity,
} from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import {
  getConfigMetadataByProcessGuid,
  getProviderIntegrationProcesses,
  getProviderMetadata,
  initiateGetProviderMetadata,
} from 'lane-shared/helpers/integrations/AccessManagement/getProviderMetadata';
import {
  HID2,
  HID3,
  IdentityProviderDefinitions,
  IdentityProviderEntity,
  HID3WalletToggleFriendlyName,
  HID3WalletToggleOldFriendlyName,
} from 'lane-shared/helpers/integrations/AccessManagement/identityProvider';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import {
  PropertiesInterface,
  PropertyType,
  PropertyOptionType,
} from 'lane-shared/types/properties/Property';

import { H4 } from 'components/typography';

import { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { RenderInputs } from './RenderInputs';
import { HIDCredTemplateStrategy } from './credentialTemplate';
import { useHIDCredTemplateStrategy } from './hooks/useHIDCredTemplateStrategy';
import { HIDTemplatesMap } from '../CredentialTemplateSelector/types';
import { Validator } from 'lane-shared/types/Validator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Utility function to delete specified fields from an object.
 * @param obj - The object from which fields should be deleted.
 * @param fields - An array of field keys to be deleted from the object.
 */
function deleteFields<T extends object>(
  obj: T,
  fields: (keyof T)[]
): Partial<T> {
  fields?.forEach(field => {
    delete obj[field];
  });

  return obj;
}

type HIDCredMetadata = {
  PartNumbers: Array<{
    PartNumber: string;
    Id: string;
  }>;
};

interface DropdownField {
  _id: string;
  _order: number;
  name: string;
  value: string;
}

interface MultiSelectDropdownField {
  label: string;
  value: string;
}

// Function to validate single-select dropdown fields by checking if the selected value exists in the dropdown options.
const isSelectedFieldExistsInDropdown = (
  dropdownFields: DropdownField[],
  selectedField: DropdownField
): boolean => {
  const exists = dropdownFields.some(
    dropdownField =>
      dropdownField._id === selectedField._id &&
      dropdownField.name === selectedField.name &&
      dropdownField.value === selectedField.value &&
      dropdownField._order === selectedField._order
  );

  return exists;
};

// Function to validate multi-select dropdown fields by checking if the selected values exist in the dropdown options.
const isSelectedFieldsExistsInDropdown = (
  dropdownFields: DropdownField[],
  selectedFields: MultiSelectDropdownField[]
): boolean => {
  // Check that each selected field exists in the dropdown fields
  // Note: For now, we are converting types just to be safe as for some dropdowns selected values are coming as int instead of string, we will see why this is happening when we will refactor this component
  return selectedFields.every(selectedField => {
    const exists = dropdownFields.some(
      dropdownField =>
        String(dropdownField.value) === String(selectedField.value) &&
        String(dropdownField.name) === String(selectedField.label)
    );

    return exists;
  });
};

export function AccessManagement({
  channelIntegration,
  onUpdateChannelIntegration,
  definition,
  channel,
  forCreate,
  integrationIsDeleted,
  isChannelIntegrationUpdated,
}: ChannelIntegrationEditorProps) {
  const [acsMetadataLoading, setAcsMetadataLoading] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [acsFieldsPopulated, setAcsFieldsPopulated] = useState(false);
  const [metadataError, setMetadataError] = useState('');

  const [idpMetadataLoading, setIdpMetadataLoading] = useState(false);
  const [idpFieldsPopulated, setIdpFieldsPopulated] = useState(false);
  const [idpMetadataError, setIdpMetadataError] = useState('');

  type AcsDsxUdfs = {
    name: string;
    udfNum: string;
    locGrp: string;
  };

  const [dsxUdfs, setDsxUdfs] = useState<AcsDsxUdfs[]>([]);
  const [dynamicAcsProperties, setDynamicAcsProperties] = useState<any | null>(
    getDynamicAcsProperties(
      channelIntegration?.settings?.accessControlSettings,
      channelIntegration.settings.accessControlService?.value
    )
  );

  const { t } = useTranslation();
  const genetecCardholderGroupSetting = useFlag(
    FeatureFlag.GenetecCardholderGroupsFilter,
    false
  );
  const brivoFeatureEnabled = useFlag(FeatureFlag.BrivoConfigSetting, false);
  const kastleFeatureEnabled = useFlag(
    FeatureFlag.KastleVisitorManagement,
    false
  );

  const geneaEnableCardFormats = useFlag(FeatureFlag.GeneaCardFormat, false);
  const geneaVisitorCardFormat = useFlag(
    FeatureFlag.GeneaVisitorCardFormat,
    false
  );

  const geneaLocationUdfFeatureEnabled = useFlag(
    FeatureFlag.GeneaLocationUdf,
    false
  );

  const enabledSaltoSvn = useFlag(FeatureFlag.EnableSaltoSvn, false);
  const geneaVisitorFeatureEnabled = useFlag(
    FeatureFlag.GeneaVisitorManagement,
    false
  );

  const ccureVisitorManagement = useFlag(
    FeatureFlag.CCureVisitorManagement,
    false
  );

  const amagVisitorManagement = useFlag(
    FeatureFlag.AmagVisitorManagement,
    false
  );

  const braxosVisitorManagement = useFlag(
    FeatureFlag.BraxosVisitorManagement,
    false
  );

  const sipassVisitorFeatureEnabled = useFlag(
    FeatureFlag.SipassVisitorManagement,
    false
  );

  const genetecLongPollingFF = useFlag(
    FeatureFlag.GenetecLongPollingApi,
    false
  );

  const isGoogleWalletFFEnabled = useFlag<boolean>(
    FeatureFlag.EnableGoogleWallet,
    false
  );

  const isDropdownValidationFFEnabled = useFlag<boolean>(
    FeatureFlag.FixConfigDropdownValidation,
    false
  );

  const hidCredTemplateStrategy = useHIDCredTemplateStrategy(
    channelIntegration?.settings?.identityProviderSettings
  );
  // pulling integration definition
  const idpServiceSelector = {
    identityProviderService: definition.properties.identityProviderService,
  } as PropertiesInterface;
  const acsServiceSelector = {
    accessControlService: definition.properties.accessControlService,
  } as PropertiesInterface;

  // pulling acs and idp properties after it's been selected by the user
  const acsProviderValue =
    channelIntegration.settings.accessControlService?.value;
  const idpProviderValue =
    channelIntegration.settings.identityProviderService?.value;
  const idpProperties =
    IdentityProviderDefinitions[
      channelIntegration.settings.identityProviderService
        ?.value as IdentityProviderEntity
    ];
  const acsProperties =
    AccessControlServiceDefinitions[
      acsProviderValue as AccessControlServiceEntity
    ];

  // Get SFTP User name for configured HID3 integration
  useEffect(() => {
    if (
      channelIntegration?.settings?.identityProviderSettings
        ?.isAppleWalletEnabled &&
      channelIntegration.settings.identityProviderSettings.organizationId
    ) {
      getHid3Metadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelIntegration?.settings?.identityProviderSettings
      ?.isAppleWalletEnabled,
    channelIntegration?.settings?.identityProviderSettings?.organizationId,
  ]);

  // update previous value to current value of sftpUsername when integration is updated
  useEffect(() => {
    if (
      channelIntegration?.settings?.identityProviderSettings
        ?.isAppleWalletEnabled &&
      isChannelIntegrationUpdated
    ) {
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          identityProviderSettings: {
            ...channelIntegration.settings.identityProviderSettings,
            prevSftpUsername:
              channelIntegration.settings.identityProviderSettings.sftpUsername,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelIntegration?.settings?.identityProviderSettings
      ?.isAppleWalletEnabled,
    isChannelIntegrationUpdated,
  ]);

  useEffect(() => {
    const acsValidator =
      acsServiceSelector.accessControlService?.validators?.find(
        validator => validator.name === 'In'
      );

    const idpValidator =
      idpServiceSelector.identityProviderService?.validators?.find(
        validator => validator.name === 'In'
      );

    if (idpValidator && !enabledSaltoSvn) {
      idpValidator.value = idpValidator.value.filter(
        (idp: { value: IdentityProviderEntity }) =>
          idp.value !== IdentityProviderEntity.SaltoSvn
      );
    }

    if (idpValidator && !kastleFeatureEnabled) {
      idpValidator.value = idpValidator.value.filter(
        (idp: { value: IdentityProviderEntity }) =>
          idp.value !== IdentityProviderEntity.Kastle
      );
    }

    if (acsValidator && !brivoFeatureEnabled) {
      acsValidator.value = acsValidator.value.filter(
        (acs: { value: AccessControlServiceEntity }) =>
          acs.value !== AccessControlServiceEntity.Brivo
      );
    }

    if (acsValidator && !kastleFeatureEnabled) {
      acsValidator.value = acsValidator.value.filter(
        (acs: { value: AccessControlServiceEntity }) =>
          acs.value !== AccessControlServiceEntity.Kastle
      );
    }

    if (acsValidator && !enabledSaltoSvn) {
      acsValidator.value = acsValidator.value.filter(
        (acs: { value: AccessControlServiceEntity }) =>
          acs.value !== AccessControlServiceEntity.SaltoSvn
      );
    }
  }, [
    acsServiceSelector.accessControlService?.validators,
    brivoFeatureEnabled,
    kastleFeatureEnabled,
    enabledSaltoSvn,
    idpServiceSelector.identityProviderService?.validators,
  ]);

  if (!geneaEnableCardFormats) {
    const acsValidator =
      acsServiceSelector.accessControlService?.validators?.find(
        validator => validator.name === 'In'
      );

    if (
      acsProviderValue === AccessControlServiceEntity.Genea &&
      acsValidator &&
      acsProperties
    ) {
      delete acsProperties.cardFormat;
      delete acsProperties.facilityCode;
      delete channelIntegration.settings.accessControlSettings.cardFormat;
      delete channelIntegration.settings.accessControlSettings.facilityCode;
    }
  }

  if (!geneaVisitorCardFormat) {
    if (
      acsProviderValue === AccessControlServiceEntity.Genea &&
      acsProperties
    ) {
      delete acsProperties.visitorCardFormat;
      delete channelIntegration.settings.accessControlSettings
        .visitorCardFormat;
    }
  }

  if (!geneaLocationUdfFeatureEnabled) {
    const acsValidator =
      acsServiceSelector.accessControlService?.validators?.find(
        validator => validator.name === 'In'
      );

    if (
      acsProviderValue === AccessControlServiceEntity.Genea &&
      acsValidator &&
      acsProperties
    ) {
      delete acsProperties.locationUdfEnabled;
      delete acsProperties.locationUdf;
      channelIntegration.settings.accessControlSettings.locationUdfEnabled =
        false;
    }
  }

  if (!ccureVisitorManagement) {
    if (
      acsProviderValue === AccessControlServiceEntity.CCure &&
      acsProperties
    ) {
      const acsDefinitionFieldsToRemove: Array<keyof typeof acsProperties> = [
        'heading',
        'visitorManagementEnabled',
        'visitorCardFormats',
        'visitorFacilityCodeEnabled',
        'visitorFacilityCode',
        'visitorCredentialRange',
        'visitorAccessGroupMappings',
        'visitorPersonnelType',
        'userPersonnelTypes',
      ];

      const settingsFieldsToRemove: Array<
        keyof typeof channelIntegration.settings.accessControlSettings
      > = [
        'visitorManagementEnabled',
        'visitorCardFormats',
        'visitorFacilityCodeEnabled',
        'visitorFacilityCode',
        'visitorCredentialRange',
        'visitorAccessGroupMappings',
        'visitorPersonnelType',
        'userPersonnelTypes',
      ];

      // Remove fields from acsDefinition
      deleteFields(acsProperties, acsDefinitionFieldsToRemove);

      // Remove fields from settings
      deleteFields(
        channelIntegration.settings.accessControlSettings,
        settingsFieldsToRemove
      );
    }
  }

  if (!braxosVisitorManagement) {
    if (
      acsProviderValue === AccessControlServiceEntity.Braxos &&
      acsProperties
    ) {
      const acsDefinitionFieldsToRemove: Array<keyof typeof acsProperties> = [
        'heading',
        'visitorManagementEnabled',
        'visitorCardFormats',
        'visitorCredentialRange',
      ];

      const settingsFieldsToRemove: Array<
        keyof typeof channelIntegration.settings.accessControlSettings
      > = [
        'visitorManagementEnabled',
        'visitorCardFormats',
        'visitorCredentialRange',
      ];

      // Remove fields from acsDefinition
      deleteFields(acsProperties, acsDefinitionFieldsToRemove);

      // Remove fields from settings
      deleteFields(
        channelIntegration.settings.accessControlSettings,
        settingsFieldsToRemove
      );
    }
  }

  if (!amagVisitorManagement) {
    if (acsProviderValue === AccessControlServiceEntity.Amag && acsProperties) {
      const acsDefinitionFieldsToRemove: Array<keyof typeof acsProperties> = [
        'heading',
        'visitorManagementEnabled',
        'visitorCredentialRange',
        'visitorEmailTitleNumber',
      ];

      const settingsFieldsToRemove: Array<
        keyof typeof channelIntegration.settings.accessControlSettings
      > = [
        'visitorManagementEnabled',
        'visitorCredentialRange',
        'visitorEmailTitleNumber',
      ];

      // Remove fields from acsDefinition
      deleteFields(acsProperties, acsDefinitionFieldsToRemove);

      // Remove fields from settings
      deleteFields(
        channelIntegration.settings.accessControlSettings,
        settingsFieldsToRemove
      );
    }
  }

  if (!geneaVisitorFeatureEnabled) {
    if (
      acsProviderValue === AccessControlServiceEntity.Genea &&
      acsProperties
    ) {
      delete acsProperties.visitorManagementEnabled;
      delete acsProperties.visitorCredentialRange;
      delete acsProperties.visitorAccessGroupMappings;
      delete acsProperties.tenantMappings;
      delete channelIntegration.settings.accessControlSettings
        .visitorManagementEnabled;
      delete channelIntegration.settings.accessControlSettings
        .visitorCredentialRange;
      delete channelIntegration.settings.accessControlSettings
        .visitorAccessGroupMappings;
      delete channelIntegration.settings.accessControlSettings.tenantMappings;
      delete channelIntegration.settings.accessControlSettings.heading;
    }
  }

  if (!kastleFeatureEnabled) {
    if (
      acsProviderValue === AccessControlServiceEntity.Kastle &&
      acsProperties
    ) {
      delete acsProperties.visitorConnectUsername;
      delete acsProperties.visitorConnectPassword;
      delete acsProperties.visitorConnectOwnerGuid;
      delete acsProperties.loadSettingsButton;
      delete acsProperties.buildingId;
      delete acsProperties.visitorManagementEnabled;
      delete acsProperties.heading;

      delete channelIntegration.settings.accessControlSettings
        .visitorManagementEnabled;
      delete channelIntegration.settings.accessControlSettings.tenantMappings;
      delete channelIntegration.settings.accessControlSettings.heading;
    }
  }

  if (!genetecCardholderGroupSetting) {
    const acsValidator =
      acsServiceSelector.accessControlService?.validators?.find(
        validator => validator.name === 'In'
      );

    if (
      acsProviderValue === AccessControlServiceEntity.Genetec &&
      acsValidator &&
      acsProperties
    ) {
      delete channelIntegration.settings.accessControlSettings.cardholderGroups;
      delete channelIntegration.settings.accessControlSettings.mobAccessUdf;
      channelIntegration.settings.accessControlSettings.cardholderGroupsFilterEnabled =
        null;
    }
  }

  if (!sipassVisitorFeatureEnabled) {
    if (
      acsProviderValue === AccessControlServiceEntity.Sipass &&
      acsProperties
    ) {
      delete acsProperties.visitorManagementEnabled;
      delete acsProperties.visitorCardFormats;
      delete acsProperties.visitorCredentialRange;
      delete acsProperties.visitorAccessGroupMappings;
      delete channelIntegration.settings.accessControlSettings
        .visitorManagementEnabled;
      delete channelIntegration.settings.accessControlSettings
        .visitorCardFormats;
      delete channelIntegration.settings.accessControlSettings
        .visitorCredentialRange;
      delete channelIntegration.settings.accessControlSettings
        .visitorAccessGroupMappings;
      delete channelIntegration.settings.accessControlSettings.heading;
    }
  }

  if (
    !isGoogleWalletFFEnabled &&
    channelIntegration?.settings?.identityProviderSettings
  ) {
    channelIntegration.settings.identityProviderSettings.googleWalletIssuerAppPackageName =
      '';
    channelIntegration.settings.identityProviderSettings.googleWalletSupportDisplayName =
      '';
    channelIntegration.settings.identityProviderSettings.googleWalletLostAndFoundUrl =
      '';
    channelIntegration.settings.identityProviderSettings.googleWalletRegistrationUrl =
      '';
  }

  if (idpProperties?.googleWalletIssuerAppPackageName?.validators?.length) {
    idpProperties.googleWalletIssuerAppPackageName.validators[0].value =
      isGoogleWalletFFEnabled;
  }

  if (idpProperties?.googleWalletSupportDisplayName?.validators?.length) {
    idpProperties.googleWalletSupportDisplayName.validators[0].value =
      isGoogleWalletFFEnabled;
  }

  if (idpProperties?.googleWalletLostAndFoundUrl?.validators?.length) {
    idpProperties.googleWalletLostAndFoundUrl.validators[0].value =
      isGoogleWalletFFEnabled;
  }

  if (idpProperties?.googleWalletRegistrationUrl?.validators?.length) {
    idpProperties.googleWalletRegistrationUrl.validators[0].value =
      isGoogleWalletFFEnabled;
  }

  if (idpProperties?.isAppleWalletEnabled) {
    idpProperties.isAppleWalletEnabled.friendlyName = isGoogleWalletFFEnabled
      ? HID3WalletToggleFriendlyName
      : HID3WalletToggleOldFriendlyName;
  }

  const setupDropdownValidators = (
    property: PropertyType<PropertyOptionType>,
    dataToSet: any,
    key: string,
    isIdpField?: boolean
  ) => {
    if (!property) {
      return;
    }

    const settings = isIdpField
      ? channelIntegration.settings.identityProviderSettings
      : channelIntegration.settings.accessControlSettings;

    if (!settings) {
      return;
    }

    const selectedDropdownField = settings[key];

    property.validators = property.validators || [];

    if (!isDropdownValidationFFEnabled) {
      property.validators.push({
        name: 'In',
        value: dataToSet,
      });

      return;
    }

    const validatorArrayToMap = new Map(
      property.validators.map(v => [v.name, v])
    );

    validatorArrayToMap.set('In', { name: 'In', value: dataToSet });
    property.validators = Array.from(validatorArrayToMap.values());

    if (
      selectedDropdownField &&
      property.packagedType === PackagedTypeEnum.Dropdown &&
      !isSelectedFieldExistsInDropdown(
        dataToSet,
        selectedDropdownField as DropdownField
      )
    ) {
      delete settings[key];
    }

    if (
      selectedDropdownField &&
      property.packagedType === PackagedTypeEnum.MultiSelect &&
      !isSelectedFieldsExistsInDropdown(
        dataToSet,
        selectedDropdownField as MultiSelectDropdownField[]
      )
    ) {
      delete settings[key];
    }
  };

  async function setupCCureFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError(
          t`web.admin.channel.integrations.access.error.metadata`
        );

        return;
      }

      if (data?.PartitionIds && acsProperties?.partitionId?.validators) {
        const partitionData = data.PartitionIds.map(
          (partition: { Id: any; Name: any }) => ({
            _id: partition.Id,
            _order: 0,
            name: `${partition.Name}`,
            value: `${partition.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties?.partitionId,
          partitionData,
          'partitionId'
        );
      }

      if (data?.CardFormats && acsProperties?.chuIdFormat?.validators) {
        const cardData = data.CardFormats.map(
          (card: { Id: any; Name: any }) => ({
            _id: card.Id,
            _order: 0,
            name: `${card.Name}`,
            value: `${card.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties?.chuIdFormat,
          cardData,
          'chuIdFormat'
        );

        if (data?.UDFNames?.length > 0) {
          const udfNames = data.UDFNames.map(
            (udfName: { Id: any; Name: any }) => ({
              _id: udfName.Id,
              _order: 0,
              name: `${udfName.Name}`,
              value: `${udfName.Name}`,
            })
          );

          if (acsProperties?.mobileAccessUdf?.validators) {
            setupDropdownValidators(
              acsProperties?.mobileAccessUdf,
              udfNames,
              'mobileAccessUdf'
            );
          }

          if (acsProperties?.sendInviteEmailUdf?.validators) {
            setupDropdownValidators(
              acsProperties?.sendInviteEmailUdf,
              udfNames,
              'sendInviteEmailUdf'
            );
          }
        }

        if (ccureVisitorManagement) {
          if (
            data?.CardFormats &&
            acsProperties?.visitorCardFormat?.validators
          ) {
            const visitorCardFormats = data.CardFormats?.map(
              (card: { Id: any; Name: any }) => ({
                _id: card.Id,
                _order: 0,
                name: `${card.Name}`,
                value: `${card.Id}`,
              })
            );

            setupDropdownValidators(
              acsProperties?.visitorCardFormat,
              visitorCardFormats,
              'visitorCardFormat'
            );
          }
        }

        if (data?.AccessGroups) {
          const allAccessControlGroups = data?.AccessGroups?.map(
            (acg: { Id: string; Name: string }) => ({
              label: `${acg.Name}`,
              value: `${acg.Id}`,
            })
          );

          acsProperties.visitorAccessGroupMappings.accessGroupIDs =
            allAccessControlGroups;
        }

        if (
          data?.PersonnelTypes &&
          acsProperties?.visitorPersonnelType?.validators
        ) {
          const visitorPersonnelTypeList = data.PersonnelTypes?.reduce(
            (
              acc: {
                _id: string;
                _order: number;
                name: string;
                value: string;
              }[],
              personalType: { IsVisitor: boolean; Id: string; Name: string }
            ) => {
              if (personalType?.IsVisitor) {
                acc.push({
                  _id: personalType.Id,
                  _order: 0,
                  name: `${personalType.Name}`,
                  value: `${personalType.Id}`,
                });
              }

              return acc;
            },
            []
          );

          setupDropdownValidators(
            acsProperties?.visitorPersonnelType,
            visitorPersonnelTypeList,
            'visitorPersonnelType'
          );
        }

        if (
          data?.PersonnelTypes &&
          acsProperties?.userPersonnelTypes?.validators
        ) {
          const personnelTypeList = data.PersonnelTypes?.map(
            (personalType: { Id: string; Name: string }) => ({
              _id: `${personalType.Id}`,
              _order: 0,
              name: `${personalType.Name}`,
              value: `${personalType.Id}`,
            })
          );

          setupDropdownValidators(
            acsProperties?.userPersonnelTypes,
            personnelTypeList,
            'userPersonnelTypes'
          );
        }

        if (
          !channelIntegration.settings.accessControlSettings?.userPersonnelTypes
            ?.length
        ) {
          const defaultVisitorPersonnelTypeId = '4';

          const defaultPersonnelTypeList = data?.PersonnelTypes?.reduce(
            (
              acc: { label: string; value: string }[],
              personalType: { Id: string; Name: string }
            ) => {
              if (
                personalType?.Id?.toString() !== defaultVisitorPersonnelTypeId
              ) {
                acc.push({
                  label: `${personalType.Name}`,
                  value: `${personalType.Id?.toString()}`,
                });
              }

              return acc;
            },
            []
          );

          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              accessControlSettings: {
                ...channelIntegration.settings.accessControlSettings,
                userPersonnelTypes: defaultPersonnelTypeList,
              },
            },
          });
        }
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : t`web.admin.channel.integrations.access.error.metadata.default`;

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupGeneaFields(locationKey = '', locationValue = '') {
    setAcsMetadataLoading(true);

    try {
      const settings = channelIntegration.settings.accessControlSettings;

      // refetch the acgs and tenantList based on location change
      if (locationKey && locationValue) {
        settings[locationKey] = locationValue;
      }

      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        settings
      );

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.CustomerUuid && acsProperties?.customerUuid?.validators) {
        onUpdateChannelIntegration({
          settings: {
            ...channelIntegration.settings,
            accessControlSettings: {
              ...channelIntegration.settings.accessControlSettings,
              customerUuid: data.CustomerUuid,
            },
          },
        });
      }

      if (data?.AllLocations && acsProperties?.locationUuid?.validators) {
        const locationListData = data.AllLocations.map(
          (location: { Uuid: any; Name: any }) => ({
            _id: location.Uuid,
            _order: 0,
            name: `${location.Name}`,
            value: `${location.Uuid}`,
          })
        );

        setupDropdownValidators(
          acsProperties?.locationUuid,
          locationListData,
          'locationUuid'
        );
      }

      if (
        geneaEnableCardFormats &&
        data?.CardFormats &&
        acsProperties?.cardFormat?.validators
      ) {
        const cardFormatList = data.CardFormats.map(
          (cardFormat: { Uuid: any; Name: any }) => ({
            _id: cardFormat.Uuid,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Uuid}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardFormat,
          cardFormatList,
          'cardFormat'
        );
      }

      if (
        geneaVisitorCardFormat &&
        data?.CardFormats &&
        acsProperties?.visitorCardFormat
      ) {
        const visitorCardFormatList = data.CardFormats?.map(
          (cardFormat: { Uuid: string; Name: string }) => ({
            _id: cardFormat.Uuid,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Uuid}`,
          })
        );

        acsProperties.visitorCardFormat?.validators?.push({
          name: 'In',
          value: visitorCardFormatList,
        });
      }

      if (data?.CustomAttributes && acsProperties?.locationUdf?.validators) {
        const locationUdfListData = data.CustomAttributes.map(
          (udf: { Uuid: any; Name: any }) => ({
            _id: udf.Uuid,
            _order: 0,
            name: `${udf.Name}`,
            value: `${udf.Name}`,
          })
        );

        setupDropdownValidators(
          acsProperties.locationUdf,
          locationUdfListData,
          'locationUdf'
        );
      }

      if (channelIntegration.settings.accessControlSettings?.locationUuid) {
        setLocationSelected(true);
      }

      if (geneaVisitorFeatureEnabled) {
        if (data?.AllAccessControlGroups) {
          const allAccessControlGroups = data?.AllAccessControlGroups?.map(
            (acg: { Uuid: string; Name: string }) => ({
              label: `${acg.Name}`,
              value: `${acg.Uuid}`,
            })
          );

          acsProperties.visitorAccessGroupMappings.accessGroupIDs =
            allAccessControlGroups;
        }

        if (data?.AllTenants) {
          const allTenants = data?.AllTenants.map(
            (tenant: { Uuid: string; Name: string }) => ({
              label: `${tenant.Name}`,
              value: `${tenant.Uuid}`,
            })
          );

          acsProperties.visitorAccessGroupMappings.geneaTenantIDs = allTenants;
          acsProperties.tenantMappings.geneaTenantId = allTenants;
        }
      }

      // update the selected location in channel integration setting

      if (locationKey && locationValue) {
        onUpdateChannelIntegration({
          settings: {
            ...channelIntegration.settings,
            accessControlSettings: {
              ...channelIntegration.settings.accessControlSettings,
              [locationKey]: locationValue,
            },
          },
        });
      }

      setAcsMetadataLoading(false);
      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupSaltosvnIdpFields() {
    setIdpMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        idpProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.identityProviderSettings
      );

      setIdpMetadataLoading(false);

      if (!data) {
        setIdpMetadataError(
          t`web.admin.channel.integrations.access.error.metadata`
        );

        return;
      }

      if (data?.Partitions && idpProperties?.partitionId?.validators) {
        const partitionData = data.Partitions.map(
          (partition: { Id: any; Name: any }) => ({
            _id: partition.Id,
            _order: 0,
            name: `${partition.Name}`,
            value: `${partition.Id}`,
          })
        );

        setupDropdownValidators(
          idpProperties.partitionId,
          partitionData,
          'partitionId',
          true
        );
      }

      setIdpMetadataError('');
      setIdpFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : t`web.admin.channel.integrations.access.error.metadata.default`;

      setIdpMetadataError(message);
      setIdpMetadataLoading(false);
    }
  }

  async function setupSaltoSvnFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError(
          t`web.admin.channel.integrations.access.error.metadata`
        );

        return;
      }

      if (data?.Partitions && acsProperties?.partitionId?.validators) {
        const partitionData = data.Partitions.map(
          (partition: { Id: any; Name: any }) => ({
            _id: partition.Id,
            _order: 0,
            name: `${partition.Name}`,
            value: `${partition.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.partitionId,
          partitionData,
          'partitionId'
        );
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : t`web.admin.channel.integrations.access.error.metadata.default`;

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  useEffect(() => {
    // fetch existing save config data when open edit config page
    if (
      geneaVisitorFeatureEnabled &&
      acsProviderValue === AccessControlServiceEntity.Genea &&
      !forCreate
    ) {
      setupGeneaFields();
    } else if (
      acsProviderValue === AccessControlServiceEntity.Genetec &&
      !forCreate
    ) {
      setupGenetecFields();
    } else if (
      kastleFeatureEnabled &&
      acsProviderValue === AccessControlServiceEntity.Kastle &&
      !forCreate &&
      channelIntegration.settings.accessControlSettings
        ?.visitorManagementEnabled // if visitor management is enabled then and only need to fetch config
    ) {
      setupKastleFields();
    } else if (
      sipassVisitorFeatureEnabled &&
      acsProviderValue === AccessControlServiceEntity.Sipass &&
      !forCreate
    ) {
      setupBraxosFields(acsProviderValue);
    } else if (
      ccureVisitorManagement &&
      acsProviderValue === AccessControlServiceEntity.CCure &&
      !forCreate &&
      channelIntegration.settings.accessControlSettings
        ?.visitorManagementEnabled
    ) {
      setupCCureFields();
    }
  }, [forCreate]);

  useEffect(() => {
    // update building GUID when create/save config for kastle
    if (
      kastleFeatureEnabled &&
      acsProviderValue === AccessControlServiceEntity.Kastle &&
      channelIntegration.settings.accessControlSettings
        ?.visitorManagementEnabled &&
      channelIntegration.settings.accessControlSettings?.buildingId &&
      !channelIntegration.settings.accessControlSettings.configurableInputField
    ) {
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          accessControlSettings: {
            ...channelIntegration.settings.accessControlSettings,
            configurableInputField:
              channelIntegration.settings.accessControlSettings?.buildingId
                ?.value,
          },
        },
      });
    }
  }, [
    channelIntegration.settings.accessControlSettings?.configurableInputField,
  ]);

  async function setupBraxosFields(
    provider: AccessControlServiceEntity = acsProviderValue
  ) {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        provider,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      const cardFormatsDropdown =
        acsProperties?.cardFormat?.validators ||
        acsProperties?.cardFormats?.validators;

      const visitorCardFormatDropdown =
        acsProperties?.visitorCardFormat?.validators;

      if (data?.CardFormats) {
        const cardFormatList = data.CardFormats.map(
          (cardFormat: { Name: any }) => ({
            _id: `${cardFormat.Name}`,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Name}`,
          })
        );

        if (cardFormatsDropdown) {
          const cardFormatKey =
            acsProviderValue === AccessControlServiceEntity.Braxos
              ? 'cardFormat'
              : 'cardFormats';

          setupDropdownValidators(
            acsProperties[cardFormatKey],
            cardFormatList,
            cardFormatKey
          );
        }

        if (acsProperties?.visitorCardFormats?.validators) {
          setupDropdownValidators(
            acsProperties.visitorCardFormats,
            cardFormatList,
            'visitorCardFormats'
          );
        }

        if (braxosVisitorManagement) {
          if (visitorCardFormatDropdown) {
            setupDropdownValidators(
              acsProperties.visitorCardFormat,
              cardFormatList,
              'visitorCardFormat'
            );
          }
        }
      }

      if (data?.ServerUrl) {
        onUpdateChannelIntegration({
          settings: {
            ...channelIntegration.settings,
            accessControlSettings: {
              ...channelIntegration.settings.accessControlSettings,
              serverUrl: data.ServerUrl,
            },
          },
        });
      }

      if (sipassVisitorFeatureEnabled) {
        if (data?.AccessGroups) {
          const allAccessControlGroups = data?.AccessGroups?.map(
            (acg: { Name: string }) => ({
              label: `${acg.Name}`,
              value: `${acg.Name}`,
            })
          );

          acsProperties.visitorAccessGroupMappings.accessGroupIDs =
            allAccessControlGroups;
        }
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupOpenpathFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.CardFormats && acsProperties?.cardFormat?.validators) {
        const cardFormatList = data.CardFormats.map(
          (cardFormat: { Id: any; Name: any }) => ({
            _id: cardFormat.Id,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardFormat,
          cardFormatList,
          'cardFormat'
        );
      }

      if (data?.CredentialTypes && acsProperties?.credentialType?.validators) {
        const credentialTypeList = data.CredentialTypes.map(
          (credentialType: { Id: any; Name: any }) => ({
            _id: credentialType.Id,
            _order: 0,
            name: `${credentialType.Name}`,
            value: `${credentialType.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.credentialType,
          credentialTypeList,
          'credentialType'
        );
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupBrivoFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.CardFormats && acsProperties?.cardFormat?.validators) {
        const cardFormatList = data.CardFormats.map(
          (cardFormat: { Id: any; Name: any }) => ({
            _id: cardFormat.Id,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${JSON.stringify(cardFormat)}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardFormat,
          cardFormatList,
          'cardFormat'
        );
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupMaxxessFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.Templates && acsProperties?.templates?.validators) {
        const templateList = data.Templates.map(
          (template: { Id: any; Name: any }) => ({
            _id: template.Id,
            _order: 0,
            name: `${template.Name}`,
            value: `${template.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.templates,
          templateList,
          'templates'
        );
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupGallagherFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getClientCertificateAndPrivateKey(
        channelIntegration.settings.accessControlSettings.certificate,
        channelIntegration.settings.accessControlSettings.passphrase
      );

      if (data.clientCertificate && data.privateKey) {
        channelIntegration.settings.accessControlSettings.clientCertificate =
          data.clientCertificate;
        channelIntegration.settings.accessControlSettings.privateKey =
          data.privateKey;

        const certificateFileObject = {
          name: channelIntegration.settings.accessControlSettings.certificate
            .name,
        };

        channelIntegration.settings.accessControlSettings.certificate =
          JSON.stringify(certificateFileObject);

        onUpdateChannelIntegration({
          settings: {
            ...channelIntegration.settings,
            accessControlSettings: {
              ...channelIntegration.settings.accessControlSettings,
            },
          },
        });
      }
    } catch (error: any) {
      setMetadataError(error.message);
      setAcsMetadataLoading(false);

      return;
    }

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.CardTypes && acsProperties?.cardFormat?.validators) {
        const cardFormatList = data.CardTypes.map(
          (cardFormat: { Id: any; Name: any; Href: string }) => ({
            _id: cardFormat.Id,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Href}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardFormat,
          cardFormatList,
          'cardFormat'
        );
      }

      if (data?.PersonalDataFields && acsProperties?.emailPdf?.validators) {
        const emailPdfList = data.PersonalDataFields.map(
          (emailPdf: { Id: any; Name: any }) => ({
            _id: emailPdf.Id,
            _order: 0,
            name: `${emailPdf.Name}`,
            value: `${emailPdf.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.emailPdf,
          emailPdfList,
          'emailPdf'
        );
      }

      if (data?.Divisions && acsProperties?.division?.validators) {
        const divisionList = data.Divisions.map(
          (division: { Id: any; Name: any; Href: string }) => ({
            _id: division.Id,
            _order: 0,
            name: `${division.Name}`,
            value: `${division.Href}`,
          })
        );

        setupDropdownValidators(
          acsProperties.division,
          divisionList,
          'division'
        );
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupDsxFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.locGrpRes && acsProperties?.locGrp?.validators) {
        const locGrpList = data.locGrpRes.map(
          (locGrp: { locGrp: string; name: string }) => ({
            _id: locGrp.locGrp,
            _order: 0,
            name: `${locGrp.name}`,
            value: `${locGrp.locGrp}`,
          })
        );

        setupDropdownValidators(acsProperties.locGrp, locGrpList, 'locGrp');
      }

      setDsxUdfs(data?.udfsRes || []);
      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  const metaDataErrorHandler = (error: string) => {
    setMetadataError(error);
    setAcsMetadataLoading(false);
  };

  async function setupGenetecFields() {
    setAcsMetadataLoading(true);
    const metaDataError = 'No metadata returned from access control provider';

    let data;
    try {
      if (genetecLongPollingFF) {
        const pollIntegrationProcess = async (processGuid: string) => {
          try {
            const processIntegrationData =
              await getProviderIntegrationProcesses(
                convertToUUID(channel._id),
                acsProviderValue,
                processGuid
              );

            if (processIntegrationData?.length > 0) {
              const process = processIntegrationData[0];

              if (
                process?.failedAt &&
                dayjs.utc(process.failedAt).format('YYYY-MM-DD') !==
                  '0001-01-01'
              ) {
                return {
                  isStopPolling: true,
                  error:
                    'Process failed: The integration process encountered an issue and could not complete successfully',
                };
              }

              if (
                process?.succeededAt &&
                dayjs.utc(process.succeededAt).format('YYYY-MM-DD') !==
                  '0001-01-01'
              ) {
                data = await getConfigMetadataByProcessGuid(
                  acsProviderValue,
                  convertToUUID(channel._id),
                  processGuid
                );

                return {
                  isStopPolling: true,
                  error: !data ? metaDataError : null,
                };
              }
              return { isStopPolling: false, error: null };
            }
            return {
              isStopPolling: true,
              error: 'No metadata found for processGuid',
            };
          } catch (err) {
            return {
              isStopPolling: true,
              error:
                err?.message ||
                'No metadata returned from access control provider',
            };
          }
        };

        const startPolling = async (processGuid: string) => {
          let isPolling = true;
          let retryCount = 0;
          const maxRetries = 180;
          while (isPolling) {
            const integrationProcess =
              await pollIntegrationProcess(processGuid);
            if (integrationProcess.isStopPolling) {
              console.info('Genetec long polling api retry count', retryCount);
              if (integrationProcess.error) {
                setMetadataError(integrationProcess.error);
              }
              setAcsMetadataLoading(false);
              isPolling = false;
              return;
            }
            retryCount++;

            if (retryCount >= maxRetries) {
              metaDataErrorHandler('Polling reached maximum retry limit');
              isPolling = false;
              return;
            }
            await new Promise(resolve => setTimeout(resolve, 5000));
          }
        };

        const processGuidData = await initiateGetProviderMetadata(
          acsProviderValue,
          convertToUUID(channel._id),
          channelIntegration.settings.accessControlSettings
        );

        if (processGuidData?.processGuid) {
          try {
            await startPolling(processGuidData.processGuid);
          } catch (error) {
            metaDataErrorHandler(error?.message || metaDataError);
          }
        } else {
          metaDataErrorHandler('Unable to get processGuid');
          return;
        }
        if (!data) {
          return;
        }
      } else {
        data = await getProviderMetadata(
          acsProviderValue,
          convertToUUID(channel._id),
          channelIntegration.settings.accessControlSettings
        );
        setAcsMetadataLoading(false);

        if (!data) {
          setMetadataError(metaDataError);
          return;
        }
      }

      if (data?.CredentialFormats && acsProperties?.cardFormat?.validators) {
        const cardFormatList = data.CredentialFormats.map(
          (cardFormat: { Id: string; Name: string; Value: string }) => ({
            _id: cardFormat.Id,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Value}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardFormat,
          cardFormatList,
          'cardFormat'
        );
      }

      if (
        data?.CredentialFormats &&
        acsProperties?.visitorCardFormat?.validators
      ) {
        const visitorCardFormatList = data.CredentialFormats.map(
          (cardFormat: { Id: string; Name: string; Value: string }) => ({
            _id: cardFormat.Id,
            _order: 0,
            name: `${cardFormat.Name}`,
            value: `${cardFormat.Value}`,
          })
        );

        setupDropdownValidators(
          acsProperties.visitorCardFormat,
          visitorCardFormatList,
          'visitorCardFormat'
        );
      }

      if (
        genetecCardholderGroupSetting &&
        data?.CustomFields &&
        acsProperties?.mobAccessUdf?.validators
      ) {
        const mobileAccessUdfList = data.CustomFields.map(
          (mobAccessUdf: { Id: string; Name: string }) => ({
            _id: mobAccessUdf.Id,
            _order: 0,
            name: `${mobAccessUdf.Name}`,
            value: `${mobAccessUdf.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.mobAccessUdf,
          mobileAccessUdfList,
          'mobAccessUdf'
        );
      }

      if (
        genetecCardholderGroupSetting &&
        data?.CardholderGroups &&
        acsProperties?.cardholderGroups?.validators
      ) {
        const cardHolderGroupList = data.CardholderGroups.map(
          (cardholderGroup: { Id: string; Name: string }) => ({
            _id: cardholderGroup.Id,
            _order: 1,
            name: `${cardholderGroup.Name}`,
            value: `${cardholderGroup.Id}`,
          })
        );

        setupDropdownValidators(
          acsProperties.cardholderGroups,
          cardHolderGroupList,
          'cardholderGroups'
        );
      }

      if (data?.CardholderGroups) {
        const cardHolderGroupList = data.CardholderGroups?.filter(
          (cardholderGroup: { IsVisitorGroup: boolean }) =>
            cardholderGroup?.IsVisitorGroup
        )?.map((cardholderGroup: { Id: string; Name: string }) => ({
          label: `${cardholderGroup.Name}`,
          value: `${cardholderGroup.Id}`,
        }));

        acsProperties.visitorAccessGroupMappings.accessGroupIDs =
          cardHolderGroupList;
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  async function setupKastleFields() {
    setAcsMetadataLoading(true);

    try {
      const data = await getProviderMetadata(
        acsProviderValue,
        convertToUUID(channel._id),
        channelIntegration.settings.accessControlSettings
      );

      setAcsMetadataLoading(false);

      if (!data) {
        setMetadataError('No metadata returned from access control provider');

        return;
      }

      if (data?.Buildings && acsProperties?.buildingId?.validators) {
        const buildingList = data.Buildings?.map(
          (building: { Name: string; Guid: string }) => ({
            _id: building.Guid,
            _order: 0,
            name: building.Name,
            value: building.Guid,
          })
        );

        setupDropdownValidators(
          acsProperties.buildingId,
          buildingList,
          'buildingId'
        );

        acsProperties.tenantMappings.kastleBuildings = data?.Buildings || [];
      }

      if (channelIntegration.settings.accessControlSettings?.buildingId) {
        const buildingDetail =
          acsProperties.tenantMappings?.kastleBuildings?.find(
            (item: { Guid: string }) =>
              item.Guid ===
              channelIntegration.settings.accessControlSettings?.buildingId?._id
          );
        const buildingTenants =
          buildingDetail?.Tenants?.map(
            (tenant: { Guid: string; Name: string }) => ({
              label: `${tenant.Name}`,
              value: `${tenant.Guid}`,
            })
          ) || [];

        acsProperties.tenantMappings.acsTenantId = buildingTenants;
        setLocationSelected(true);
        onUpdateChannelIntegration({
          settings: {
            ...channelIntegration.settings,
            accessControlSettings: {
              ...channelIntegration.settings.accessControlSettings,
              configurableInputField:
                channelIntegration.settings.accessControlSettings?.buildingId
                  ?.value,
            },
          },
        });
      }

      setMetadataError('');
      setAcsFieldsPopulated(true);
    } catch (error: any) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching the metadata';

      setMetadataError(message);
      setAcsMetadataLoading(false);
    }
  }

  const buttonCallback = () => {
    if (acsProviderValue === AccessControlServiceEntity.CCure) {
      return setupCCureFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Genea) {
      return setupGeneaFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Braxos) {
      return setupBraxosFields(acsProviderValue);
    }

    if (acsProviderValue === AccessControlServiceEntity.Openpath) {
      return setupOpenpathFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Brivo) {
      return setupBrivoFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Maxxess) {
      return setupMaxxessFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Gallagher) {
      return setupGallagherFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Dsx) {
      return setupDsxFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Genetec) {
      return setupGenetecFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Sipass) {
      return setupBraxosFields(acsProviderValue);
    }

    if (acsProviderValue === AccessControlServiceEntity.SaltoSvn) {
      return setupSaltoSvnFields();
    }

    if (acsProviderValue === AccessControlServiceEntity.Kastle) {
      return setupKastleFields();
    }

    return null;
  };

  function getDsxDynamicAcsPropertiesForUdfs(
    value: any,
    dsxUdfs: AcsDsxUdfs[]
  ) {
    const locGrpVal = value?.locGrp?.value || value;
    const emailUdfVal = value?.dynamicAcsProperties?.emailUdf?.value || '';
    const activateUserIdUdfVal =
      value?.dynamicAcsProperties?.activateUserIdUdf?.value || '';

    const objTemplate = {
      packagedType: PackagedTypeEnum.Dropdown,
      type: 'Option',
      friendlyName: '',
      tags: ['metadataFieldResponse'],
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    };

    const emailUdf = { ...objTemplate, friendlyName: 'UDF for email' };
    const activateUserIdUdf = {
      ...objTemplate,
      friendlyName: 'UDF for Activate managed user ID',
    };

    const newDynamicAcsProperties: any = {};

    // check whether emailUdfVal and activateUserIdUdfVal are valid and exist and then make a new udfList
    let udfList;

    if (emailUdfVal || activateUserIdUdfVal) {
      udfList = Object.values(value?.dynamicAcsProperties || {}).map(
        (udf: any) => udf
      );
    } else {
      udfList = dsxUdfs
        .filter((udf: AcsDsxUdfs) => udf.locGrp === locGrpVal)
        .map((udf: { name: string; udfNum: string; locGrp: string }) => ({
          _id: udf.udfNum,
          _order: 0,
          name: `${udf.name}`,
          value: `${udf.udfNum}`,
        }));
    }

    newDynamicAcsProperties.emailUdf = {
      ...emailUdf,
      validators: [...emailUdf.validators, { name: 'In', value: udfList }],
    };
    newDynamicAcsProperties.activateUserIdUdf = {
      ...activateUserIdUdf,
      validators: [
        ...activateUserIdUdf.validators,
        { name: 'In', value: udfList },
      ],
    };

    return newDynamicAcsProperties;
  }

  function getBrivoDynamicAcsPropertiesForCardFormat(value: any) {
    const cardFormatValue = value?.cardFormat?.value || value;

    const objTemplate = {
      type: String.name,
      friendlyName: '',
      tags: ['metadataFieldResponse'],
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    };

    type ParsedCardFormatValue = {
      CredentialFields?: {
        Name: string;
      }[];
    };

    let parsedCardFormatValue: ParsedCardFormatValue = {};

    try {
      parsedCardFormatValue = cardFormatValue
        ? JSON.parse(cardFormatValue)
        : {};
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return null;
    }

    const newDynamicAcsProperties: { [key: string]: typeof objTemplate } = {};

    if (
      parsedCardFormatValue?.CredentialFields &&
      parsedCardFormatValue?.CredentialFields.length > 0
    ) {
      for (const field of parsedCardFormatValue?.CredentialFields) {
        if (field?.Name === 'card_number') {
          continue;
        }

        objTemplate.friendlyName = field?.Name;

        newDynamicAcsProperties[field.Name] = { ...objTemplate };
      }
    }

    return Object.keys(newDynamicAcsProperties).length > 0
      ? newDynamicAcsProperties
      : null;
  }

  const processBrivoDynamicAcsProperties = (value: {
    _id: string;
    _order: number;
    name: string;
    value: string;
  }) => {
    // cardFormat changed, so reset exisitng dynamicAcsProperties
    setDynamicAcsProperties(null);

    if (
      channelIntegration.settings.accessControlSettings?.dynamicAcsProperties
    ) {
      delete channelIntegration.settings.accessControlSettings
        ?.dynamicAcsProperties;
    }

    onUpdateChannelIntegration({
      settings: {
        ...channelIntegration.settings,
        accessControlSettings: {
          ...channelIntegration.settings.accessControlSettings,
        },
      },
    });

    const newDynamicAcsProperties: any =
      getBrivoDynamicAcsPropertiesForCardFormat(value?.value);

    setDynamicAcsProperties(newDynamicAcsProperties);
  };

  function getDynamicAcsProperties(
    value: any,
    acsProviderValue: AccessControlServiceEntity
  ) {
    switch (acsProviderValue) {
      case AccessControlServiceEntity.Brivo:
        return getBrivoDynamicAcsPropertiesForCardFormat(value);
      case AccessControlServiceEntity.Dsx:
        return getDsxDynamicAcsPropertiesForUdfs(value, dsxUdfs);
      default:
        return null;
    }
  }

  function processDsxDynamicAcsProperties(value: {
    _id: string;
    _order: number;
    name: string;
    value: string;
  }) {
    // reset exisitng dynamicAcsProperties
    setDynamicAcsProperties(null);

    if (
      channelIntegration.settings.accessControlSettings?.dynamicAcsProperties
    ) {
      delete channelIntegration.settings.accessControlSettings
        ?.dynamicAcsProperties;
    }

    onUpdateChannelIntegration({
      settings: {
        ...channelIntegration.settings,
        accessControlSettings: {
          ...channelIntegration.settings.accessControlSettings,
        },
      },
    });

    const newDynamicAcsProperties: any = getDsxDynamicAcsPropertiesForUdfs(
      value?.value,
      dsxUdfs
    );

    setDynamicAcsProperties(newDynamicAcsProperties);
  }

  const updateSettingsCallback = ({
    key,
    value,
  }: {
    key: string;
    value: any;
  }) => {
    if (
      acsProviderValue === AccessControlServiceEntity.Brivo &&
      key === 'cardFormat'
    ) {
      processBrivoDynamicAcsProperties(value);
    }

    if (
      acsProviderValue === AccessControlServiceEntity.Dsx &&
      key === 'locGrp'
    ) {
      processDsxDynamicAcsProperties(value);
    }

    if (acsProviderValue === AccessControlServiceEntity.Genea) {
      if (channelIntegration.settings.accessControlSettings?.locationUuid) {
        setLocationSelected(true);
      } else {
        setLocationSelected(false);
      }

      if (geneaVisitorFeatureEnabled && key === 'locationUuid') {
        setupGeneaFields(key, value);

        return;
      }
    }

    if (acsProviderValue === AccessControlServiceEntity.Kastle) {
      if (key === 'buildingId') {
        setLocationSelected(true);
        const buildingDetail =
          acsProperties.tenantMappings?.kastleBuildings?.find(
            (item: { Guid: string }) => item.Guid === value?._id
          );
        const buildingTenants =
          buildingDetail?.Tenants?.map(
            (tenant: { Guid: string; Name: string }) => ({
              label: `${tenant.Name}`,
              value: `${tenant.Guid}`,
            })
          ) || [];

        acsProperties.tenantMappings.acsTenantId = buildingTenants;
      } else if (
        channelIntegration.settings.accessControlSettings?.buildingId
      ) {
        setLocationSelected(true);
      } else {
        setLocationSelected(false);
      }
    }

    if (
      acsProviderValue === AccessControlServiceEntity.Kastle &&
      key === 'buildingId'
    ) {
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          accessControlSettings: {
            ...channelIntegration.settings.accessControlSettings,
            [key]: value,
            configurableInputField: value?.value,
          },
        },
      });
    } else {
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          accessControlSettings: {
            ...channelIntegration.settings.accessControlSettings,
            [key]: value,
          },
        },
      });
    }
  };

  // if the user chooses a new ACS, then the new ACS is set in channelIntegration settings
  // and ACS-specific settings are cleared and set to default to prevent crossing wires
  const handleUpdateAcs = ({ key, value }: { key: string; value: any }) => {
    setAcsMetadataLoading(false);
    setAcsFieldsPopulated(false);
    setMetadataError('');
    onUpdateChannelIntegration({
      settings: {
        ...channelIntegration.settings,
        [key]: value,
        accessControlSettings: {},
      },
    });

    setDynamicAcsProperties(null);
  };

  async function getHid3Metadata() {
    try {
      const data = await getProviderMetadata(
        HID3,
        convertToUUID(channel._id),
        channelIntegration.settings.identityProviderSettings
      );

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          identityProviderSettings: {
            ...channelIntegration.settings.identityProviderSettings,
            sftpUsername: data?.SFTPUsername,
            prevSftpUsername: data?.SFTPUsername,
          },
        },
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // TODO: handle error
    }
  }

  async function fetchHIDCredentialTemplates<
    T extends typeof HID2 | typeof HID3,
  >(version: T, credentialTemplates: HIDTemplatesMap = {}) {
    setIdpMetadataLoading(true);

    try {
      const data: HIDCredMetadata = await getProviderMetadata(
        version,
        convertToUUID(channel._id),
        channelIntegration.settings.identityProviderSettings
      );

      if (!data) {
        setIdpMetadataError('No metadata returned from identity provider');
      }

      if ('PartNumbers' in data) {
        const hidVersion = version === HID2 ? 'hid22' : 'hid30';

        data.PartNumbers.forEach(partNumber => {
          credentialTemplates[partNumber.PartNumber] = {
            ...credentialTemplates[partNumber.PartNumber],
            [hidVersion]: partNumber.Id,
          };
        });
      }

      setIdpMetadataLoading(false);
      setIdpMetadataError('');
      setIdpFieldsPopulated(true);
    } catch (error) {
      const message = error.message
        ? error.message
        : 'Unexpected error while fetching metadata';

      setIdpMetadataError(message);
      setIdpMetadataLoading(false);
    }
  }

  async function setupHIDCredentialTemplates() {
    const credentialTemplates: HIDTemplatesMap = {};

    switch (hidCredTemplateStrategy) {
      case HIDCredTemplateStrategy.Both:
        await fetchHIDCredentialTemplates(HID2, credentialTemplates);
        await fetchHIDCredentialTemplates(HID3, credentialTemplates);
        break;
      case HIDCredTemplateStrategy.HID22:
        await fetchHIDCredentialTemplates(HID2, credentialTemplates);
        break;
      case HIDCredTemplateStrategy.HID30:
        await fetchHIDCredentialTemplates(HID3, credentialTemplates);
        break;
      default:
        break;
    }

    idpProperties.credentialTemplateSelector.validators = [
      {
        name: 'In',
        value: credentialTemplates,
      },
    ];
  }

  const idpCallback = () => {
    if (idpProviderValue === IdentityProviderEntity.SaltoSvn) {
      return setupSaltosvnIdpFields();
    }

    return setupHIDCredentialTemplates();
  };

  function getFilteredAcsValue() {
    const idpProviderValue =
      channelIntegration.settings.identityProviderService?.value;

    const inValidator =
      acsServiceSelector.accessControlService?.validators?.find(
        validator => validator.name === 'In'
      );

    let accessProviders = [];

    if (inValidator && Array.isArray(inValidator.value)) {
      accessProviders = inValidator.value.filter(i => {
        if (idpProviderValue === IdentityProviderEntity.SaltoSvn) {
          return i.value === AccessControlServiceEntity.SaltoSvn;
        }

        if (idpProviderValue === IdentityProviderEntity.Kastle) {
          return i.value === AccessControlServiceEntity.Kastle;
        }

        return (
          i.value !== AccessControlServiceEntity.SaltoSvn &&
          i.value !== AccessControlServiceEntity.Kastle
        );
      });
    }

    return {
      accessControlService: {
        ...acsServiceSelector.accessControlService,
        validators: [
          acsServiceSelector.accessControlService?.validators?.[0] ||
            ({
              name: 'Required',
              value: true,
            } as Validator),
          {
            name: 'In',
            value: accessProviders,
          } as Validator,
        ],
      },
    };
  }

  return (
    <div>
      <H4 mb={4}>
        {t`web.admin.channel.integrations.access.title.identity-provider`}
      </H4>
      <RenderInputs
        disableInput={integrationIsDeleted}
        acsProviderValue={acsProviderValue}
        properties={idpServiceSelector}
        channel={channel}
        settings={channelIntegration.settings}
        forCreate={forCreate}
        locationSelected={locationSelected}
        onUpdateSettings={({ key, value }) =>
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              [key]: value,
              identityProviderSettings: {},
            },
          })
        }
      />
      {idpProperties && (
        <RenderInputs
          disableInput={integrationIsDeleted}
          acsProviderValue={acsProviderValue}
          buttonCallback={idpCallback}
          properties={idpProperties}
          metadataLoading={idpMetadataLoading}
          metadataError={idpMetadataError}
          metadataPopulated={idpFieldsPopulated}
          channel={channel}
          settings={channelIntegration?.settings?.identityProviderSettings}
          forCreate={forCreate}
          locationSelected={locationSelected}
          onUpdateSettings={({ key, value }) =>
            onUpdateChannelIntegration({
              settings: {
                ...channelIntegration.settings,
                identityProviderSettings: {
                  ...channelIntegration.settings.identityProviderSettings,
                  [key]: value,
                },
              },
            })
          }
        />
      )}
      <H4 mb={4}>
        {t`web.admin.channel.integrations.access.title.access-control-system`}
      </H4>
      <RenderInputs
        disableInput={integrationIsDeleted}
        properties={getFilteredAcsValue()}
        acsProviderValue={acsProviderValue}
        channel={channel}
        settings={channelIntegration.settings}
        onUpdateSettings={handleUpdateAcs}
        forCreate={forCreate}
        locationSelected={locationSelected}
      />
      {acsProperties && (
        <div>
          {acsProviderValue !== AccessControlServiceEntity.Kastle && (
            <H4 mb={4}>
              {t`web.admin.channel.integrations.access.title.access-control-settings`}
            </H4>
          )}
          <RenderInputs
            disableInput={integrationIsDeleted}
            acsProviderValue={acsProviderValue}
            locationSelected={locationSelected}
            properties={acsProperties}
            buttonCallback={buttonCallback}
            metadataLoading={acsMetadataLoading}
            metadataError={metadataError}
            metadataPopulated={acsFieldsPopulated}
            channel={channel}
            settings={channelIntegration?.settings?.accessControlSettings}
            onUpdateSettings={updateSettingsCallback}
            forCreate={forCreate}
          />
        </div>
      )}
      {dynamicAcsProperties && (
        <div>
          <RenderInputs
            locationSelected={locationSelected}
            properties={dynamicAcsProperties}
            metadataLoading={acsMetadataLoading}
            metadataError={metadataError}
            metadataPopulated={acsFieldsPopulated}
            channel={channel}
            forCreate={forCreate}
            settings={
              channelIntegration.settings.accessControlSettings
                ?.dynamicAcsProperties
            }
            onUpdateSettings={({ key, value }) =>
              onUpdateChannelIntegration({
                settings: {
                  ...channelIntegration.settings,
                  accessControlSettings: {
                    ...channelIntegration.settings.accessControlSettings,
                    dynamicAcsProperties: {
                      ...channelIntegration.settings.accessControlSettings
                        ?.dynamicAcsProperties,
                      [key]: value,
                    },
                  },
                },
              })
            }
          />
        </div>
      )}
    </div>
  );
}
