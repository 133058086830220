import React from 'react';

import { AdminPage, PageHeader } from 'components/layout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { CreditBundlesTable } from './CreditBundlesTable';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Props = {
  channel: Channel | undefined;
};

export function CreditBundles({ channel }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleCreateCreditBundle = () => {
    const channel_slug = channel?.slug || '';

    history.push(
      routes.channelAdminCreateCreditBundles.replace(':id', channel_slug)
    );
  };

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.creditBundle.pageHeader')}
        description={t('abp.creditBundle.pageHeader.description')}
        headerLevel="h3"
        actionButtons={[
          {
            label: t('abp.creditBundle.pageHeader.actionButtonText'),
            type: 'primary',
            onClick: handleCreateCreditBundle,
          },
        ]}
      />
      <CreditBundlesTable
        channel={channel}
        handleCreateCreditBundle={handleCreateCreditBundle}
      />
    </AdminPage>
  );
}
