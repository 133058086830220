import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    margin: spacing[5],
    position: 'relative',
  },
  line: {
    marginVertical: spacing[2],
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 15,
    marginBottom: spacing[4],
    margin: spacing[2],
  },
  ChargeDetails: {
    marginLeft: spacing[5],
    marginRight: spacing[4],
  },
  ChargeDetailsItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: spacing[2],
    marginTop: spacing[2],
    width: 162,
  },
  ChargeDetailsKey: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: 'heavy',
    flexBasis: '50%',
  },
  ChargeDetailsValue: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: colors.subduedText,
    flexBasis: '50%',
  },
  PageNumber: {
    fontFamily: 'Helvetica',
    position: 'absolute',
    fontSize: 12,
    bottom: spacing[7],
    left: 0,
    right: spacing[7],
    textAlign: 'right',
    color: colors.neutral.grey700,
  },
});
