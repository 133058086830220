import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getLibraryOptions,
  getValidationMessages,
  toSchema,
} from 'lane-shared/helpers';
import {
  SectionTypeEnum,
  PIN_LIST_VIEW,
  ContentTypeEnum,
  FRIENDLY_CONTENT_TYPE_OPTIONS,
  CONTENT_CATEGORIES,
  ContentCategoryEnum,
  ContentGroupByEnum,
} from 'constants-content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { useChannelTheme } from 'lane-shared/hooks';

import { SectionDynamicQueryType } from 'lane-shared/types/sections/SectionDynamicQueryType';
import {
  SectionMetatag,
  SectionEditProps,
} from 'lane-shared/types/sections/SectionType';

import { Tooltip } from 'components';

import { Well } from 'components/general';
import Panel from 'components/layout/Panel';
import SectionContentListBlock from 'components/renderers/v5/primitives/SectionContentListBlock';
import { H3, H5 } from 'components/typography';

import Dropdown from '../../../../../../components/form/Dropdown';
import IconPicker from '../../../../../../components/form/IconPicker';
import Input from '../../../../../../components/form/Input';
import MultiselectField from '../../../../../../components/form/MultiselectField';
import TextArea from '../../../../../../components/form/TextArea';
import Toggle from '../../../../../../components/form/Toggle';
import Button from '../../../../../../components/general/Button';
import ControlMenu from '../../../../../../components/general/ControlMenu';
import IconButton from '../../../../../../components/general/IconButton';
import Loading from '../../../../../../components/general/Loading';
import ModalBackground from '../../../../../../components/general/ModalBackground';
import ResizableWindow from '../../../../../../components/general/ResizableWindow';
import TabStrip, {
  TabItem,
} from '../../../../../../components/general/TabStrip';
import CirclePin from '../../../../../../components/lane/CirclePin';
import MediaPickerButton from '../../../../../../components/lane/MediaPickerButton';
import MetatagLibrary from '../../../../../../components/lane/MetatagLibrary';
import MetatagListItem from '../../../../../../components/lane/MetatagListItem';
import PresetContentFilterListItem from '../../../../../../components/lane/PresetContentFilterListItem';
import PresetContentSortListItem from '../../../../../../components/lane/PresetContentSortListItem';
import SectionListView from '../../../../../../components/lane/SectionListView';
import SquarePin from '../../../../../../components/lane/SquarePin';
import ThemePaletteColorSelectorButton from '../../../../../../components/lane/ThemePaletteColorSelectorButton';

import styles from './SectionEditLegacy.scss';

// cjr: a couple types aren't ready yet.
const availableTypes = FRIENDLY_CONTENT_TYPE_OPTIONS.filter(
  ({ value }) =>
    ![
      ContentTypeEnum.ScheduledContent,
      ContentTypeEnum.ScheduledNotice,
    ].includes(value)
);

const CONTENT_CATEGORIES_WITH_SELECT_ALL = [
  ...CONTENT_CATEGORIES,
  {
    label: 'web.admin.section.contentTypes.selectAll',
    value: 'All',
  },
];

export function SectionEditLegacy({
  className,
  style,
  channel,
  section,
  validation = null,
  onSectionUpdated = () => null,
  forCreate = false,
}: SectionEditProps) {
  const { t } = useTranslation();
  const tabs: TabItem[] = [
    {
      value: 'edit',
      label: t('Edit'),
    },
    {
      value: 'preview',
      label: t('Preview'),
    },
  ];

  const theme = useChannelTheme(channel);
  const [selectedTab, setSelectedTab] = useState<'preview' | 'edit'>('edit');
  const [isMetatagLibraryOpen, setIsMetatagLibraryOpen] = useState(false);
  const [selectedMetatag, setSelectedMetatag] = useState<null | SectionMetatag>(
    null
  );
  const channelLanguagesArr = extractChannelLanguagesArray(channel);

  async function changeSectionType(type: any) {
    if (type === SectionTypeEnum.Dynamic) {
      try {
        const sectionName = section.name;

        await window.Alert.confirm({
          title: t('web.admin.section.changeSectionType.title', {
            sectionName,
            type,
          }),
          message: t('web.admin.section.changeSectionType.message', {
            sectionName,
            type,
          }),
          confirmText: t('web.admin.section.changeSectionType.confirmation'),
        });

        const query: SectionDynamicQueryType = {
          contentCategories: [ContentCategoryEnum.Other],
          contentTypes: [ContentTypeEnum.Static],
          includeChildren: true,
        };

        onSectionUpdated({ type, query });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
      }
    } else {
      onSectionUpdated({ type, query: undefined });
    }
  }

  function backgroundColorChanged(backgroundColor: any) {
    onSectionUpdated({
      backgroundColor,
    });
  }

  function colorChanged(color: any) {
    onSectionUpdated({
      color,
    });
  }

  if (!section) {
    return <Loading />;
  }

  return (
    <div className={cx(styles.SectionEdit, className)} style={style}>
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab }}
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        onSelectTab={tab => setSelectedTab(tab.value)}
        className={styles.tabStrip}
      />
      {selectedTab === 'edit' && (
        <div className={styles.edit}>
          <div className={styles.row}>
            <div className={styles.column}>
              <H3 mt={6} mb={2}>
                {t('web.admin.section.title')}
              </H3>
              <Panel className={styles.panel}>
                <div className={styles.formField}>
                  {channelLanguagesArr.map(
                    (
                      {
                        isPrimary,
                        languageCode,
                        friendlyName: friendlyLangName,
                      },
                      _index,
                      array
                    ) => {
                      const shouldSeeMultiLanguage = array.length > 1;

                      if (isPrimary) {
                        return (
                          <>
                            <H5 className={styles.fieldLabel}>
                              {t('Title')}
                              {shouldSeeMultiLanguage
                                ? ` (${friendlyLangName})`
                                : ''}
                            </H5>
                            <Input
                              error={getValidationMessages(validation, 'name')}
                              value={section.name}
                              minLength={4}
                              maxLength={32}
                              testId="sectionTitle"
                              onChange={name =>
                                onSectionUpdated({
                                  name,
                                  name_l10n: {
                                    ...section.name_l10n,
                                    [languageCode]: name,
                                  },
                                })
                              }
                            />
                          </>
                        );
                      }

                      return (
                        <Well
                          key={languageCode}
                          className={styles.otherLanguagesSection}
                        >
                          <H5 className={styles.translationLabel}>
                            <Icon name="globe" />{' '}
                            {t(
                              'web.admin.content.draftContent.info.generalInfo.translation'
                            )}
                            : {t('Title')}
                          </H5>
                          <H5 className={styles.fieldLabel}>
                            {friendlyLangName}
                          </H5>
                          <Input
                            value={(section.name_l10n || {})[languageCode]}
                            minLength={4}
                            maxLength={32}
                            testId="sectionTitle_l10n"
                            onChange={name =>
                              onSectionUpdated({
                                name_l10n: {
                                  ...section.name_l10n,
                                  [languageCode]: name,
                                },
                              })
                            }
                          />
                        </Well>
                      );
                    }
                  )}
                </div>
                <div className={styles.formField}>
                  <H5 className={styles.fieldLabel}>{t('Description')}</H5>

                  <TextArea
                    minRows={3}
                    className={styles.textArea}
                    errors={getValidationMessages(validation, 'description')}
                    value={section.description}
                    onChange={description =>
                      onSectionUpdated({
                        description,
                      })
                    }
                  />
                </div>
              </Panel>

              <H3 mt={6} mb={2}>
                {t('web.admin.section.settings')}
              </H3>

              <Panel className={styles.panel}>
                <H5 mb={2}>{t('web.admin.section.sectionType')}</H5>
                <Dropdown
                  items={Object.values(SectionTypeEnum).map(toSchema)}
                  value={section.type}
                  onValueChange={changeSectionType}
                />

                {section.type === SectionTypeEnum.Dynamic && (
                  <>
                    <H5 mt={4} mb={2}>
                      {t('web.admin.section.contentCategories')}
                    </H5>
                    <MultiselectField
                      placeholder={t('Select categories')}
                      items={CONTENT_CATEGORIES_WITH_SELECT_ALL}
                      value={section.query!.contentCategories?.map(toSchema)}
                      className={styles.multiselect}
                      onChange={contentCategories => {
                        if (contentCategories.some(c => c.value === 'All')) {
                          contentCategories = CONTENT_CATEGORIES;
                        }

                        onSectionUpdated({
                          query: {
                            ...section.query,
                            contentCategories: contentCategories.map(
                              ({ value }) => value
                            ),
                          },
                        });
                      }}
                    />

                    <H5 mt={4} mb={2}>
                      {t('web.admin.section.contentTypes')}
                    </H5>
                    <MultiselectField
                      errors={getValidationMessages(
                        validation,
                        'query.contentTypes'
                      )}
                      items={availableTypes}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'SchemaType<ContentTypeEnum>[]' is not assign... Remove this comment to see the full error message
                      value={section.query.contentTypes.map(toSchema)}
                      onChange={contentTypes =>
                        onSectionUpdated({
                          query: {
                            ...section.query,
                            contentTypes: contentTypes.map(
                              ({ value }) => value
                            ),
                          },
                        })
                      }
                    />

                    <H5 mt={4} mb={2}>
                      {t('web.admin.section.toggleText')}
                    </H5>
                    <Tooltip
                      TooltipComponent={t(
                        'web.admin.section.toggleTooltipText'
                      )}
                      placement="bottom"
                    >
                      <Toggle
                        testId="includeAllChannelsToggle"
                        className={styles.toggle}
                        value={section?.query?.includeChildren}
                        onChange={includeChildren =>
                          onSectionUpdated({
                            query: { ...section.query, includeChildren },
                          })
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </Panel>
            </div>

            <div className={styles.column}>
              <H3 mt={6} mb={2}>
                {t('web.admin.section.appearance')}
              </H3>
              <Panel className={styles.panel}>
                <H5 mb={2}>{t('web.admin.section.backgroundColor')}</H5>
                <ThemePaletteColorSelectorButton
                  value={section.backgroundColor}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeColorPaletteType | undefined' is not as... Remove this comment to see the full error message
                  palette={theme?.palette}
                  onColorSelected={backgroundColorChanged}
                />

                <H5 mt={4} mb={2}>
                  {t('web.admin.section.iconColor')}
                </H5>
                <ThemePaletteColorSelectorButton
                  value={section.color}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeColorPaletteType | undefined' is not as... Remove this comment to see the full error message
                  palette={theme?.palette}
                  onColorSelected={colorChanged}
                />

                <H5 mt={4} mb={2}>
                  {t('web.admin.section.icon')}
                </H5>
                <IconPicker
                  palette={theme!.palette!}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[] | null' is not assignable to type '... Remove this comment to see the full error message
                  errors={getValidationMessages(validation, 'icon')}
                  icon={section.icon}
                  iconSet={section.iconSet}
                  iconWeight={section.iconWeight}
                  onChange={({ icon, iconSet, iconWeight }: any) =>
                    onSectionUpdated({
                      icon,
                      iconSet,
                      iconWeight,
                    })
                  }
                />

                <H5 mt={4} mb={2}>
                  {t('web.admin.section.logo')}
                </H5>
                <MediaPickerButton
                  showDownload={false}
                  libraries={getLibraryOptions({ channel })}
                  errors={getValidationMessages(validation, 'logo')}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  media={{ _id: section.logo }}
                  onMediaSelected={media =>
                    onSectionUpdated({
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                      logo: (media && media._id) || null,
                    })
                  }
                  storageKey={channel?._id}
                />

                <H5 mt={4} mb={2}>
                  {t('web.admin.section.background')}
                </H5>
                <MediaPickerButton
                  showDownload={false}
                  libraries={getLibraryOptions({ channel })}
                  errors={getValidationMessages(validation, 'backgroundImage')}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  media={{ _id: section.backgroundImage }}
                  onMediaSelected={media =>
                    onSectionUpdated({
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                      backgroundImage: media?._id || null,
                    })
                  }
                  storageKey={channel?._id}
                />
              </Panel>
            </div>
          </div>

          <div className={styles.row}>
            {!forCreate && (
              <>
                <div className={styles.column}>
                  <H3 mt={6} mb={2}>
                    {t('web.admin.section.searchFilterSort')}
                  </H3>
                  <Panel className={styles.panel}>
                    <H5 mb={2}>
                      <span>{t('web.admin.section.standardFilters')}</span>
                      <IconButton
                        icon="plus-circle"
                        iconSet={ICON_SET_FONTAWESOME}
                        testId="buttonStandardFilters"
                        onClick={() =>
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
                          !section.filters.includes('') &&
                          onSectionUpdated({
                            // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | PresetContentFilter' is not assignable ... Remove this comment to see the full error message
                            filters: [...section.filters, ''],
                          })
                        }
                      />
                    </H5>
                    <ul className={styles.filters} data-test="filterList">
                      {section.filters?.map(filter => (
                        <PresetContentFilterListItem
                          key={filter}
                          className={styles.listItem}
                          sectionType={section.type}
                          filter={filter}
                          filters={section.filters}
                          onChange={(filters: any) =>
                            onSectionUpdated({ filters })
                          }
                        />
                      ))}
                    </ul>
                    <H5 mt={4} mb={2}>
                      <span>{t('web.admin.section.sorting')}</span>
                      <IconButton
                        icon="plus-circle"
                        iconSet={ICON_SET_FONTAWESOME}
                        onClick={() =>
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
                          !section.sorts.includes('') &&
                          onSectionUpdated({
                            // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | PresetContentSort' is not assignable to... Remove this comment to see the full error message
                            sorts: [...section.sorts, ''],
                          })
                        }
                      />
                    </H5>
                    <ul className={styles.sorts}>
                      {section.sorts?.map(sort => (
                        <PresetContentSortListItem
                          key={sort}
                          className={styles.listItem}
                          filter={sort}
                          filters={section.sorts}
                          onChange={(sorts: any) => onSectionUpdated({ sorts })}
                        />
                      ))}
                    </ul>

                    <H5 mt={4} mb={2}>
                      {t('web.admin.section.groupBy')}
                    </H5>
                    <Dropdown
                      items={Object.values(ContentGroupByEnum).map(toSchema)}
                      value={section.groupBy}
                      onValueChange={groupBy => onSectionUpdated({ groupBy })}
                    />
                  </Panel>
                </div>
                <div className={styles.column}>
                  <H3 mt={6} mb={2}>
                    {t('web.admin.section.customFilters')}
                  </H3>
                  <Panel className={styles.panel}>
                    <H5 mb={2}>
                      <span>{t('web.admin.section.customFilters')}</span>
                      <IconButton
                        icon="plus-circle"
                        iconSet={ICON_SET_FONTAWESOME}
                        onClick={() => setIsMetatagLibraryOpen(true)}
                      />
                    </H5>
                    <ul className={styles.filters}>
                      {section.sectionMetatags?.reduce<
                        Array<React.ReactElement>
                      >((prev, sectionMetatag, index) => {
                        if (sectionMetatag.metatag.toRemove) {
                          return prev;
                        }

                        prev.push(
                          <li key={sectionMetatag.metatag._id}>
                            <MetatagListItem
                              className={styles.metatagListItem}
                              metatag={sectionMetatag.metatag}
                            />
                            <IconButton
                              className={styles.deleteButton}
                              icon="times"
                              iconSet={ICON_SET_FONTAWESOME}
                              onClick={() => {
                                let deletedSectionMetaTag;

                                if (sectionMetatag._id) {
                                  sectionMetatag.metatag.toRemove = true;
                                } else {
                                  deletedSectionMetaTag =
                                    section.sectionMetatags?.splice(index, 1);
                                }

                                onSectionUpdated({
                                  sectionMetatags: section.sectionMetatags,
                                });

                                return deletedSectionMetaTag;
                              }}
                            />
                          </li>
                        );

                        return prev;
                      }, [])}
                    </ul>
                  </Panel>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {selectedTab === 'preview' && (
        <div className={styles.preview}>
          <Panel className={cx(styles.panel, styles.cards)}>
            <SectionListView section={section} />
            <SquarePin content={section} />
            <CirclePin content={section} />
          </Panel>

          <Panel className={styles.panel}>
            <SectionContentListBlock
              className={styles.sectionContentListBlock}
              isTopLevel={false}
              isTop={false}
              showBackground
              blockId={section._id}
              isValid
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"row"' is not assignable to type 'FlexDirect... Remove this comment to see the full error message
              flexDirection="row"
              hasLink={false}
              hasClick={false}
              listView={PIN_LIST_VIEW}
              showFilters
              section={section}
              header={section.name}
              autoHide={false}
              showViewAll={false}
              perPage={25}
            />
          </Panel>
        </div>
      )}

      <ModalBackground
        onClose={() => setIsMetatagLibraryOpen(false)}
        isOpen={isMetatagLibraryOpen}
        className={styles.background}
      >
        <ResizableWindow
          name="sectionEditMetatag"
          className={styles.wrapper}
          onClose={() => setIsMetatagLibraryOpen(false)}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
          showHeader
        >
          <MetatagLibrary
            className={styles.metatagLibrary}
            libraries={getLibraryOptions({ channel })}
            channelId={channel?._id}
            onMetatagSelected={metatag => setSelectedMetatag(metatag)}
          />
          <ControlMenu>
            <hr />
            <Button onClick={() => setIsMetatagLibraryOpen(false)}>
              {t('Cancel')}
            </Button>
            <Button
              disabled={!selectedMetatag}
              onClick={() => {
                setIsMetatagLibraryOpen(false);

                if (selectedMetatag) {
                  const selectMetaTags = section.sectionMetatags || [];
                  const filteredTags = selectMetaTags.find(
                    item => item.metatag._id === selectedMetatag._id
                  );

                  if (!filteredTags) {
                    onSectionUpdated({
                      sectionMetatags: selectMetaTags.concat({
                        metatag: selectedMetatag,
                      }),
                    });
                  } else if (filteredTags && filteredTags?.metatag?.toRemove) {
                    delete filteredTags.metatag.toRemove;
                  }

                  if (selectMetaTags.length === 0) {
                    onSectionUpdated({
                      sectionMetatags: selectMetaTags.concat({
                        metatag: selectedMetatag,
                      }),
                    });
                  }
                }
              }}
              variant="contained"
            >
              {t('Add')}
            </Button>
          </ControlMenu>
        </ResizableWindow>
      </ModalBackground>
    </div>
  );
}
