import React, { useState, useEffect } from 'react';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProductCategory } from 'graphql-query-contracts';
import { Item } from 'components/form/Dropdown/Dropdown';
import {
  useCategoryListQuery,
  useProductListQuery,
} from 'lane-shared/domains/billingPayments/hooks';
import { M } from 'components/typography';

import {
  RadioGroup,
  ErrorMessage,
  Dropdown,
  MultiselectField,
  Checkbox,
} from 'components';
import { Button } from 'components/general';

import {
  ProductType,
  ExceptionDetails,
  InvoiceeInfo,
} from 'lane-shared/domains/billingPayments/types';
import { InvoiceeSearchBar } from 'pages/portal/admin/channel/charges-invoices/InvoiceeSearchBar';
import { MarkupAndTaxOptions } from 'pages/portal/admin/channel/products-services/products/MarkupAndTaxOptions';
import { createProductExceptionMutation } from '../helpers/createExceptionMutation';
import { isTaxValueValid } from '../helpers/isTaxValueValid';
import { isMarkupValueValid } from '../helpers/isMarkupValueValid';
import { isRateValueValid} from '../helpers/isRateValueValid';

import { routes } from 'lane-shared/config';
import { AdminPage, PageHeader } from 'components/layout';

import styles from './styles.scss';

export function CreateExceptionForm() {
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { t } = useTranslation();
  const channel_slug = channel?.slug || '';
  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    channel_slug
  );

  const defaultBreadcrumbs = [
    {
      label: t('adp.productsServices.breadcrumb'),
      url: productServicesLink,
    },
    { label: t('abp.productsServices.createException.breadcrumb') },
  ];

  const [error, setError] = useState<Error | undefined>(undefined);
  const [exceptionDetails, setExceptionDetails] = useState<ExceptionDetails>({
    productCategoryId: '',
    productType: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
    productIds: [],
    applyToAllProducts: false,
  });

  useEffect(() => {
    setExceptionDetails({
      ...exceptionDetails,
      productCategoryId: '',
      productIds: [],
    });
  }, [exceptionDetails.productType]);

  useEffect(() => {
    setExceptionDetails({
      ...exceptionDetails,
      productIds: [],
    });
  }, [exceptionDetails.productCategoryId]);

  const isSaveButtonDisabled = (): boolean => {
    const {
      productCategoryId,
      productIds,
      applyToAllProducts,
      rate,
      tax,
      markup,
      invoicee,
    } = exceptionDetails;
    const noProductsSelected = !applyToAllProducts && productIds.length === 0;
    const noModifierSelected = !rate && !tax && !markup;

    return (
      !productCategoryId ||
      noProductsSelected ||
      noModifierSelected ||
      !invoicee ||
      (!!tax && !isTaxValueValid(tax)) ||
      (!!markup && !isMarkupValueValid(markup)) ||
      !isRateValueValid(rate)
    );
  };

  const handleSave = async () => {
    try {
      await createProductExceptionMutation(
        {
          ...exceptionDetails,
        },
        channel?._id || ''
      );
      history.goBack();
      window.Toast.show(
        t('abp.productsServices.createException.createSuccessfull')
      );
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setExceptionDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const productTypeOptions = [
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
      label: 'abp.productsServices.createCategory.productServiceType.materials',
    },
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      label: 'abp.productsServices.createCategory.productServiceType.labour',
    },
  ];

  const { data: categoryList } = useCategoryListQuery({
    page: 0,
    pageSize: 100,
    channel,
    productType: exceptionDetails.productType,
  });

  function getCategoryItems(): Item<string>[] | undefined {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[] | undefined;

    if (listOfCategories) {
      const dropdownCategoryItems = listOfCategories.map(productCategory => {
        return {
          label: productCategory.name,
          value: productCategory.productCategoryId,
        };
      });

      return dropdownCategoryItems;
    }

    return undefined;
  }

  const productsList = useProductListQuery({
    page: 0,
    pageSize: 100,
    channel,
    productCategoryId: exceptionDetails.productCategoryId,
  });

  function getProductItems(): Item<string>[] | undefined {
    const listOfProducts = productsList.data?.listProducts.products;

    if (listOfProducts) {
      const dropdownProductItems = listOfProducts.map(product => {
        return {
          label: product.name,
          value: product.id,
        };
      });

      return dropdownProductItems;
    }

    return undefined;
  }

  const onProductSelection = (items: any[]) => {
    handleFieldUpdate(items, 'productIds');
  };

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.productsServices.createException.heading')}
        headerLevel="h3"
        breadcrumbs={defaultBreadcrumbs}
      />
      <div className={styles.createExceptionForm}>
        <InvoiceeSearchBar
          handleUpdateInvoicee={(invoicee: InvoiceeInfo) =>
            handleFieldUpdate(invoicee, 'invoicee')
          }
        />
        <M className={styles.sectionLabel}>
          {t('abp.productsServices.createException.applyExceptionTo')}
        </M>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.createCategory.type')}
          </label>
          <div className={styles.radioGroup}>
            <RadioGroup
              key="type"
              name="productType"
              selected={exceptionDetails.productType}
              className={styles.radioLabel}
              schema={{
                id: 'id',
                text: 'label',
              }}
              items={productTypeOptions}
              onChange={(value: string) => {
                handleFieldUpdate(value, 'productType');
              }}
            />
          </div>
        </fieldset>

        <fieldset className={styles.dropdown}>
          <Dropdown
            testId="categoryDropdown"
            doTranslation={false}
            label={t('abp.productsServices.createProduct.categoryLabel')}
            fixedLabel
            isFullWidth
            isRequired
            value={exceptionDetails.productCategoryId}
            placeholder={t(
              'abp.productsServices.createProduct.category.placeholder'
            )}
            items={getCategoryItems()}
            onChange={item => {
              handleFieldUpdate(item.value, 'productCategoryId');
            }}
          />
        </fieldset>

        <Checkbox
          className={styles.checkbox}
          testId="applyToAllProductsOptionCheckbox"
          value={exceptionDetails.applyToAllProducts}
          text={t(`abp.productsServices.createException.applyToAllProducts`)}
          name="applyToAllProducts"
          selected={exceptionDetails.applyToAllProducts}
          onChange={() =>
            handleFieldUpdate(
              !exceptionDetails.applyToAllProducts,
              'applyToAllProducts'
            )
          }
        />

        {!exceptionDetails.applyToAllProducts && (
          <fieldset className={styles.dropdown}>
            <MultiselectField
              label={t('abp.charges.addProduct.modal.productInputLabel')}
              fixedLabel
              isFullWidth
              testId="productDropdown"
              items={getProductItems()}
              onChange={onProductSelection}
              value={exceptionDetails.productIds}
              disabled={!exceptionDetails.productCategoryId}
              placeholder={t(
                'abp.charges.addProduct.modal.productInputPlaceholder'
              )}
              doTranslation={false}
            />
          </fieldset>
        )}

        <M className={styles.sectionLabel}>
          {t('abp.productsServices.createException.override')}
        </M>

        <MarkupAndTaxOptions
          productDetails={exceptionDetails}
          updateDetails={handleFieldUpdate}
          showRate
        />
      </div>
      <div className={styles.formButtons}>
        <Button
          className={styles.button}
          variant="contained"
          disabled={isSaveButtonDisabled()}
          onClick={handleSave}
        >
          {t('abp.productsServices.createCategory.save')}
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleCancel}
        >
          {t('abp.productsServices.createCategory.cancel')}
        </Button>
      </div>
      {error && <ErrorMessage error={error} />}
    </AdminPage>
  );
}
