import { EscalationRule } from 'lane-shared/domains/workOrder/types';
import { Workflow } from 'packages/lane-shared/types/ContentWorkflowType';
import { convertToUUID } from 'uuid-encoding';

export const saveEscalationRulesFromWorkflows = async (
  escalationRules: EscalationRule[],
  archiveEscalationRule: (options: any) => Promise<any>,
  updateEscalationRule: (options: any) => Promise<any>,
  createEscalationRule: (options: any) => Promise<any>,
  workflows: Workflow[],
  channelId: string
) => {
  for (const workflow of workflows) {
    const escalationRule = escalationRules.find(
      prevEscalationRules =>
        convertToUUID(prevEscalationRules.workflowId) ===
        convertToUUID(workflow._id)
    );

    if (!escalationRule) {
      // A workflow was created that doesn't have an associated escalation rule
      await createEscalationRule({
        variables: {
          escalationRule: {
            entityType: 'SERVICE_REQUEST',
            status: workflow.workflow!.value,
            timeToEscalate: workflow.time,
            extRefId: convertToUUID(channelId),
            workflowId: convertToUUID(workflow._id),
          },
        },
      });
    } else {
      if (
        workflow.workflow!.value === escalationRule.status &&
        workflow.time === escalationRule.timeToEscalate
      ) {
        continue;
      }

      // An escalation rule's workflow was updated
      await updateEscalationRule({
        variables: {
          escalationRule: {
            id: convertToUUID(escalationRule.id),
            status: workflow.workflow!.value,
            timeToEscalate: workflow.time,
          },
        },
      });
    }
  }

  for (const escalationRule of escalationRules) {
    const workflow = workflows.find(
      workflow =>
        convertToUUID(workflow._id) === convertToUUID(escalationRule.workflowId)
    );

    if (!workflow) {
      // A workflow was deleted that had an associated escalation rule
      await archiveEscalationRule({
        variables: {
          escalationRuleId: convertToUUID(escalationRule.id),
        },
      });
    }
  }
};
