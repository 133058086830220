import { routes } from 'lane-shared/config';
import { BLOCK_LINK_TYPES, BLOCK_LINK_PREFIX } from 'constants-content';
import { LinkType } from 'lane-shared/types/baseTypes/LinkType';

import history from './history';
import { ANALYTIC_KEYS, AnalyticKey } from 'constants-analytics';
import { AnalyticsDataType } from 'lane-shared/types/Analytics';
import { convertToUUID } from 'uuid-encoding';

export function getLinkHandler(
  analytics: {
    track: (event: AnalyticKey, data: AnalyticsDataType) => void;
  },
  userId?: string
) {
  return async function linkHandler({
    value,
    switchChannel,
    channelId,
    contentId,
  }: {
    value: LinkType;
    switchChannel?: (inputChannelId: string) => void;
    channelId?: string;
    contentId?: string;
  }) {
    let uri = typeof value.uri === 'string' ? value.uri : value.uri.en;

    const isChannelUri = uri.includes('channel');
    const channelIdFormUri = uri.split('/').pop();

    switch (value.type) {
      case BLOCK_LINK_TYPES.ROUTE:
        // this is an in app route.
        if (isChannelUri && channelIdFormUri) {
          try {
            if (switchChannel) {
              switchChannel(channelIdFormUri);
            }
          } catch (error) {
            window.Alert.alert({
              title: 'Something went wrong',
              error,
            });
          }

          history.push(routes.home);
        } else {
          history.push(uri);
        }

        return;
      case BLOCK_LINK_TYPES.PHONE:
        if (!uri.includes(BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.PHONE])) {
          uri = BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.PHONE] + uri;
        }

        break;
      case BLOCK_LINK_TYPES.EMAIL:
        if (!uri.includes(BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.EMAIL])) {
          uri = BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.EMAIL] + uri;
        }

        break;
      case BLOCK_LINK_TYPES.WEB:
      case BLOCK_LINK_TYPES.WEB_IN_APP:
        analytics.track(ANALYTIC_KEYS.ANALYTIC_PATH_EXTERNAL, {
          channelId,
          contentId,
          me: convertToUUID(userId),
        });
        break;
      default:
        break;
    }

    window.open(uri, '_blank');
  };
}
