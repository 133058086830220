import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import routes from 'lane-shared/config/routes';
import {
  clearDraftInteraction,
  createUserContentInteraction,
} from 'lane-shared/graphql/mutation';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { IntegrationProviderEnum } from 'constants-integrations';
import { ShopifyBurstReviewSectionTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import { Button, ErrorMessage } from 'components/general';
import ReviewInteractionData from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/ReviewInteractionData';
import { shopifyBurstPagesByName } from 'components/integrations/ShopifyBurst/shopifyBurstCreatePages';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import { H5, Text } from 'components/typography';

import { BurstCreatePageProps } from '../../components/BurstCreateFlow';
import BurstCreateHeader from '../../components/BurstCreateHeader';
import Container from '../../components/Container';
import {
  ReviewAttendees,
  ReviewDate,
  ReviewL9,
  ReviewLocation,
  ReviewName,
  ReviewRoom,
} from '../../components/ReviewAndSubmit';

import styles from './BurstReviewAndSubmit.scss';

export default function BurstReviewAndSubmit({
  wizardState,
  content,
  goToStepByName,
}: BurstCreatePageProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const { contentId, newBurstInteraction } = useShopifyBurstContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const {
    name = '',
    portTimeZone,
    dateRange = { startDate: new Date(), endDate: new Date() },
    attendees = [],
    primaryRoomBooking,
    port,
    l9,
    isDiyBurst = false,
    questionnaireContent,
    specialRequestContent,
    diyQuestionsContent,
  } = wizardState.data;

  async function submit() {
    setIsSubmitLoading(true);

    try {
      await getClient().mutate({
        mutation: createUserContentInteraction,
        refetchQueries: ['interactionsQuery'],
        variables: {
          contentId,
          interaction: {
            user: (newBurstInteraction as any).user,
            state: (newBurstInteraction as any).state,
            features: newBurstInteraction.features,
            data: {
              [`_${IntegrationProviderEnum.ShopifyBurst}`]: wizardState.data,
            },
          },
        },
      });

      await getClient().mutate({
        mutation: clearDraftInteraction,
        refetchQueries: ['getDraftInteraction'],
        variables: {
          contentId,
        },
      });

      history.push(routes.home);
      window.Toast.show('Burst Created');
    } catch (e) {
      setError(e);
    }

    setIsModalOpen(false);
    setIsSubmitLoading(false);
  }

  return (
    <>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={content}
        title={t(ShopifyBurstReviewSectionTitle.ReviewAndSubmit)}
      />
      <Container className={styles.reviewAndSubmit}>
        <H5 mb={5}>
          {t('Review all of your Burst details before submission.')}
        </H5>
        <Flex direction="column" mb={9}>
          <ReviewName
            onClick={() => goToStepByName(shopifyBurstPagesByName.name!)}
            burstName={name}
          />

          <hr className={styles.separator} />
          <ReviewInteractionData
            interaction={wizardState.data.questionnaireInteraction}
            content={questionnaireContent!}
            title={t(ShopifyBurstReviewSectionTitle.BurstQuestionnaire)}
            icon={<Icon set="FontAwesome" name="clipboard-check" type="far" />}
            onClick={() =>
              goToStepByName(shopifyBurstPagesByName.questionnaire!)
            }
          />

          <hr className={styles.separator} />

          {port && (
            <>
              <ReviewLocation
                port={port}
                primaryRoomBooking={primaryRoomBooking!}
                onClick={() =>
                  goToStepByName(shopifyBurstPagesByName.defaultView!)
                }
              />
              <hr className={styles.separator} />
            </>
          )}

          <ReviewDate
            starts={dateRange.startDate}
            ends={dateRange.endDate}
            onClick={() =>
              goToStepByName(
                isDiyBurst
                  ? shopifyBurstPagesByName.date!
                  : shopifyBurstPagesByName.defaultView!
              )
            }
            timeZone={portTimeZone}
          />

          <hr className={styles.separator} />

          {isDiyBurst && (
            <>
              <ReviewInteractionData
                interaction={wizardState.data.diyQuestions}
                content={diyQuestionsContent!}
                title={t(ShopifyBurstReviewSectionTitle.BurstDIYQuestions)}
                icon={<Icon set="FontAwesome" name="construction" type="far" />}
                onClick={() => goToStepByName(shopifyBurstPagesByName.diy!)}
              />
              <hr className={styles.separator} />
            </>
          )}

          <ReviewAttendees
            onClick={() => goToStepByName(shopifyBurstPagesByName.guests!)}
            numberOfAttendees={attendees.length}
          />

          <hr className={styles.separator} />

          {primaryRoomBooking && (
            <>
              <ReviewRoom primaryRoomBooking={primaryRoomBooking} />
              <hr className={styles.separator} />
            </>
          )}

          {specialRequestContent?.data ? (
            <ReviewInteractionData
              interaction={wizardState.data.specialRequestInteraction}
              content={specialRequestContent!}
              title={t(ShopifyBurstReviewSectionTitle.BurstSpecialRequest)}
              icon={<Icon set="FontAwesome" name="file-plus" type="far" />}
              onClick={() => goToStepByName(shopifyBurstPagesByName.request!)}
            />
          ) : null}

          {l9 && (
            <ReviewL9
              onClick={() => goToStepByName(shopifyBurstPagesByName.l9!)}
              l9={l9}
            />
          )}
        </Flex>
        <Button
          variant="contained"
          className={styles.submitButton}
          onClick={() => setIsModalOpen(true)}
        >
          Submit
        </Button>
      </Container>
      <Modal
        isOpen={isModalOpen}
        title={t('Submit burst')}
        className={styles.modal}
        onClose={() => setIsModalOpen(false)}
        actions={
          <Button
            className={styles.confirmButton}
            disabled={isSubmitLoading}
            variant="contained"
            onClick={submit}
          >
            {t('Submit')}
          </Button>
        }
      >
        <Text className={styles.modalText}>
          Once you click ‘Submit’, your burst will be sent to your M6+ approver.
          Please give them 5 business days to review your request.
        </Text>
        <Text className={styles.modalText} mb={4}>
          Pro Tip: monitor your email for information on next steps.
        </Text>
      </Modal>
      <Modal
        isOpen={Boolean(error)}
        title={t('An error happened while submitting your burst')}
        className={styles.modal}
        onClose={() => setError(null)}
        actions={
          <Button
            className={styles.confirmButton}
            variant="contained"
            onClick={() => setError(null)}
          >
            Ok
          </Button>
        }
      >
        <ErrorMessage error={error} />
      </Modal>
    </>
  );
}
