import React, { useState } from 'react';
import { PageSizeType, Table } from 'design-system-web';
import { useGetCreditBundleColumns } from './helpers/useGetCreditBundleColumns';
import { Product, ProductType } from 'graphql-query-contracts';
import { useProductListQuery } from 'lane-shared/domains/billingPayments/hooks';
import styles from './styles.scss';
import { NoItemsWithNewItemCta } from 'components/general/NoItemsWithNewItemCta';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export function CreditBundlesTable({
  channel,
  handleCreateCreditBundle,
}: {
  channel: Channel | undefined;
  handleCreateCreditBundle: () => void;
}) {
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [page, setPage] = useState(0);
  const creditBundleColumns = useGetCreditBundleColumns(channel?._id || '');

  const { data: queryResponse, loading } = useProductListQuery({
    page,
    pageSize,
    channel,
    productTypes: [ProductType.ProductTypeCreditBundle],
  });

  // TODO: TM-18619 - Update useProductsListQuery result type to the generated type
  const tableData = (queryResponse?.listProducts.products ||
    []) as unknown as Product[];

  return (
    <div className={styles.tableContainer}>
      {tableData.length ? (
        <Table
          columns={creditBundleColumns}
          data={tableData}
          disableSorting
          isLoading={loading}
          pagination="server"
          totalRows={queryResponse?.listProducts?.pageInfo?.total ?? 200}
          pageSize={pageSize}
          page={page}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        />
      ) : (
        <NoItemsWithNewItemCta
          iconName="coins"
          titleText="abp.creditBundle.noBundles.title"
          subtitleText="abp.creditBundle.noBundles.subtitle"
          buttonCtaText="abp.creditBundle.pageHeader.actionButtonText"
          buttonCtaOnClick={handleCreateCreditBundle}
        />
      )}
    </div>
  );
}
