import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { COMMUNITY_CHANNEL } from 'constants-activate';
import { DeprecatedChannelTypeEnum } from 'constants-channel';

import { convertTo62 } from 'uuid-encoding';
import {
  useContentTargetingAudienceSize,
  useChannelProfileQuery,
} from 'lane-shared/hooks';
import { ContentTypeEnum } from 'constants-content';

import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import ContentPlacementListItem from 'components/lane/ContentPlacementListItem';
import { H4 } from 'components/typography';

import styles from './ContentTargetingPlacements.scss';

const numberFormat = new Intl.NumberFormat().format;
// dumb cache to prevent multiple look ups
const channelCache = {};

function ChannelListView({ channelId }: any) {
  // get the selected channel info to display.
  const { channel } = useChannelProfileQuery({
    channelId,
  });

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  channelCache[channelId] = channel;

  return <ChannelCircleListView channel={channel} />;
}

export default function ContentTargetingPlacements({ channel, content }: any) {
  const [audienceSize] = useContentTargetingAudienceSize(content);

  const isSendingToCommunity = content?.placements?.find(
    (placement: any) =>
      placement.channel?._id === convertTo62(COMMUNITY_CHANNEL)
  );

  // community channel is a special button
  const placements =
    content?.placements?.filter(
      (placement: any) =>
        placement?.channel?._id !== convertTo62(COMMUNITY_CHANNEL)
    ) || [];

  const channelPlacements = placements.filter(({ channel }: any) => channel);

  const groupPlacements = placements.filter(({ groupRole }: any) => groupRole);

  const userPlacements = placements.filter(({ user }: any) => user);

  const isPlaceable = ![ContentTypeEnum.Promotion].includes(content.type);

  const canBePlacedToCommunity = [
    DeprecatedChannelTypeEnum.Meta,
    DeprecatedChannelTypeEnum.Curated,
  ].includes(channel?.type);

  // re-order all the sections this content is placed in by channel
  const channelSections = content.sectionContent?.reduce(
    (channelSections: any, sectionContent: any) => {
      const existingChannel = channelSections.find(
        ({ channel }: any) => channel._id === sectionContent.section.channel._id
      );

      if (!existingChannel) {
        return [
          ...channelSections,
          {
            channel: sectionContent.section.channel,
            sectionContents: [sectionContent],
          },
        ];
      }

      existingChannel.sectionContents = [
        ...existingChannel.sectionContents,
        sectionContent,
      ];

      return channelSections;
    },
    []
  );

  return (
    <section className={styles.ContentTargetingPlacements}>
      {isPlaceable && (
        <>
          {content.placements && (
            <div className={styles.panel}>
              <H4>
                This will reach <strong>{numberFormat(audienceSize)}</strong>{' '}
                people
              </H4>
            </div>
          )}
          <div className={styles.panel}>
            <div className={styles.group}>
              {channelPlacements.length > 0 && (
                <>
                  <H4 mb={2}>Targeted to these channels</H4>
                  <ul>
                    {channelPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        channelId={placement.channel?._id}
                      />
                    ))}
                  </ul>
                </>
              )}

              {groupPlacements.length > 0 && (
                <>
                  <H4 mb={2}>Targeted to these teams</H4>
                  <ul>
                    {groupPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        groupRoleId={placement.groupRole?._id}
                      />
                    ))}
                  </ul>
                </>
              )}

              {userPlacements.length > 0 && (
                <>
                  <H4 mb={2}>Targeted to these users</H4>
                  <ul>
                    {userPlacements.map((placement: any) => (
                      <ContentPlacementListItem
                        key={placement._id}
                        className={styles.placement}
                        userId={placement.user?._id}
                      />
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {canBePlacedToCommunity && (
        <div className={styles.panel}>
          {!!isSendingToCommunity && 'Placed on the Community'}
        </div>
      )}

      {channelSections?.length > 0 && (
        <>
          <H4 mb={2}>In these sections</H4>

          {channelSections.map((channelSection: any) => (
            <div className={styles.panel} key={channelSection.channel._id}>
              <div className={styles.group}>
                <ChannelListView channelId={channelSection.channel._id} />
                <ul>
                  {channelSection.sectionContents?.map(
                    (sectionContent: any) => (
                      <li
                        key={sectionContent._id}
                        className={styles.sectionPlacement}
                      >
                        <Link
                          to={routes.channelAdminSectionsView
                            .replace(
                              ':id',
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              channelCache[sectionContent.section.channel._id]
                                ?.slug
                            )
                            .replace(':sectionId', sectionContent.section._id)}
                        >
                          {sectionContent.section.name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
}
