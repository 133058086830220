import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import {
  useInteractionsOnSectionQuery,
  useFlag,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { SortByEnum, SortPropertyType } from 'lane-shared/types/blocks/Sort';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import EmptyState from '../../../general/EmptyState';
import UserContentInteractionCard from '../../../lane/UserContentInteractionCard';
import styleMap from '../styleMap';
import ListHeader from './components/ListHeader';
import { FeatureFlag } from 'constants-flags';
import { cloneDeep } from 'lodash';

import styles from './InteractionsOnSectionList.scss';
import { SortDirectionEnum } from 'constants-activate';

type BlockProps = WebBlockProps & {
  section: DocumentType;
  flexDirection: 'row' | 'column';
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  perPage: number;
  interactionState: 'Open' | 'Closed' | 'All';
  sort: SortPropertyType;
};

export default function InteractionsOnSectionList({
  style,
  className,
  section,
  flexDirection,
  header,
  autoHide,
  showViewAll,
  perPage,
  interactionState,
  sort,
  theme,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();

  const { translate } = useMultiLanguage();
  const {
    items: initialInteractions,
    loading,
    pageInfo,
    isHidden,
  } = useInteractionsOnSectionQuery({
    sectionId: section?._id,
    pollInterval: POLL_INTERVAL,
    withPagination: false,
    interactionState,
    autoHide,
    perPage,
    sortBy: {
      key: sort?.sortBy || SortByEnum.EndDate,
      dir: sort?.sortDirection || SortDirectionEnum.Asc,
    },
  });
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  const props = useEditModeProps(otherProps);

  if (!props['data-is-edit-mode'] && autoHide && isHidden) {
    return null;
  }

  let items = initialInteractions;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    items = translate({
      model: cloneDeep(items),
      columns: [
        'contentData.name',
        'contentData.description',
        'contentData.subtitle',
      ],
      previewLanguage: props['data-preview-language'],
    });
  }

  return (
    <div
      className={cx(className, styles.InteractionsOnSectionList)}
      style={styleMap(false, style)}
      {...props}
    >
      <ListHeader
        theme={theme}
        header={header}
        totalItems={pageInfo?.total}
        currentItems={items?.length}
        link={routes.interactionsOfSection.replace(':sectionId', section?._id)}
        viewAll={showViewAll}
      />
      <div className={styles.interactionsWrapper}>
        <div className={styles[flexDirection]}>
          {items?.map((interaction: any) => (
            <Link
              className={styles.channelLink}
              to={routes.interaction.replace(':id', interaction._id)}
              key={interaction._id}
            >
              <UserContentInteractionCard
                interaction={interaction}
                className={styles.interactionCard}
              />
            </Link>
          ))}
          {!loading && !(items?.length > 0) && (
            <EmptyState showIcon={false}>
              {t(
                'web.admin.channel.content.layout.editor.components.interactionsList.noResults'
              )}
            </EmptyState>
          )}
        </div>
      </div>
      <div className={styles.wrapper} />
    </div>
  );
}
