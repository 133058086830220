import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import {
  queryChannelsForBlocksAndTargeting,
  deleteChannelMutation,
} from 'lane-shared/graphql/channel';
import { updateChannel } from 'lane-shared/graphql/mutation';
import { castForUpdate } from 'lane-shared/helpers';
import { ActiveChannelTypeEnum } from 'constants-channel';

import { checkIfChannelExistsQuery } from 'graphql-queries';

import {
  QueryString,
  getFiltersFromQueryString,
  FilterType,
} from 'design-system-web';

interface ChannelInfo {
  type?: ActiveChannelTypeEnum | string;
  profile?: any;
  parent?: { _id: LaneType.UUID };
  isLive?: boolean;
}

export default function useChannelData(
  searchParams: QueryString,
  filters: FilterType[]
) {
  const client = getClient();
  const filterValues = getFiltersFromQueryString(searchParams, filters);
  const channelData = useQuery(queryChannelsForBlocksAndTargeting, {
    variables: {
      pagination: {
        start: Number(searchParams.page) * Number(searchParams.pageSize),
        perPage: Number(searchParams.pageSize),
      },
      search: {
        name: {
          type: 'like',
          value: searchParams.keyword,
        },
        type: {
          any: filterValues.find(filter => filter.id === 'type')?.value,
        },
      },
    },
  });

  const deleteChannel = (channelId: string) =>
    client.mutate({
      mutation: deleteChannelMutation,
      variables: {
        channelId,
      },
    });

  const updateChannelInfo = (channelId: string, info: ChannelInfo) =>
    client.mutate({
      mutation: updateChannel,
      variables: {
        channel: castForUpdate({ _id: channelId, ...info }),
      },
    });

  const checkChannelExists = (channelId: string) =>
    client.query({
      query: checkIfChannelExistsQuery,
      variables: {
        id: channelId,
      },
    });

  return { channelData, deleteChannel, updateChannelInfo, checkChannelExists };
}
