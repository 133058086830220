import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { getValidationMessages, toSchema } from 'lane-shared/helpers';

import {
  ContentTypeEnum,
  FRIENDLY_CONTENT_TYPE_OPTIONS,
} from 'constants-content';

import { SectionEditProps } from 'lane-shared/types/sections/SectionType';
import { getTags } from 'lane-shared/graphql/content';

import { Tooltip } from 'components';

import { H4, S } from 'components/typography';

import MultiselectField from 'components/form/MultiselectField';

import Toggle from 'components/form/Toggle';

import type { ContentTag } from 'lane-shared/types/content/ContentTag';
import styles from './styles.scss';
import {
  TagsAndCategoriesMultiselect,
  CategoriesMultiselect,
} from './TagsAndCategoriesMultiselect';

const availableTypes = FRIENDLY_CONTENT_TYPE_OPTIONS.filter(
  ({ value }) =>
    ![
      ContentTypeEnum.ScheduledContent,
      ContentTypeEnum.ScheduledNotice,
    ].includes(value)
);

export function SectionContentsRules({
  channel,
  section,
  validation = null,
  onSectionUpdated = () => null,
}: Pick<
  SectionEditProps,
  'channel' | 'section' | 'onSectionUpdated' | 'validation'
>) {
  const { t } = useTranslation();

  const [isContentTagsSelected, setIsContentTagsSelected] = useState(
    section.tagsOnSection?.length > 0
  );

  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  const { data, loading: tagsLoading } = useQuery(getTags, {
    variables: { channelId: channel?._id },
    skip: !areContentTagsEnabled || !channel,
  });

  const tags: ContentTag[] = data?.tags;

  return (
    <>
      <H4 mt={5}>{t('web.admin.content.sections.edit.dynamic.title')}</H4>
      <S mt={4} mb={2}>
        {t('web.admin.content.sections.edit.dynamic.label')}
        <span className={styles.requiredAstrisk}>*</span>
      </S>
      {areContentTagsEnabled && (
        <TagsAndCategoriesMultiselect
          isContentTagsSelected={isContentTagsSelected}
          setIsContentTagsSelected={setIsContentTagsSelected}
          onSectionUpdated={onSectionUpdated}
          section={section}
          tagsLoading={tagsLoading}
          tags={tags}
        />
      )}

      {!areContentTagsEnabled && (
        <CategoriesMultiselect
          isContentTagsSelected={isContentTagsSelected}
          onSectionUpdated={onSectionUpdated}
          section={section}
        />
      )}

      <S mt={4} mb={2}>
        {t('web.admin.section.contentTypes')}
      </S>
      <MultiselectField
        errors={getValidationMessages(validation, 'query.contentTypes')}
        className={styles.fullWidthMultiselect}
        items={availableTypes}
        testId="contentTypeSelect"
        value={section?.query?.contentTypes?.map(toSchema)}
        onChange={contentTypes =>
          onSectionUpdated({
            query: {
              ...section.query,
              contentTypes: contentTypes.map(({ value }) => value),
            },
          })
        }
      />

      <S mt={4} mb={2}>
        {t('web.admin.section.toggleText')}
      </S>
      <Tooltip
        TooltipComponent={t('web.admin.section.toggleTooltipText')}
        placement="bottom"
      >
        <Toggle
          testId="includeAllChannelsToggle"
          className={styles.toggle}
          value={section?.query?.includeChildren}
          onChange={includeChildren =>
            onSectionUpdated({
              query: { ...section.query, includeChildren },
            })
          }
        />
      </Tooltip>
    </>
  );
}
