import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: spacing[3],
  },
  line: {
    marginLeft: 10,
    marginTop: 4,
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'bold',
    fontSize: 12,
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 8,
  },
  subheader: {
    marginLeft: 10,
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 400,
    color: colors.subduedText,
    marginBottom: 4,
  },
  header2: {
    fontFamily: 'Helvetica',
    fontStyle: 'bold',
    fontSize: 10,
    marginLeft: 10,
  },
  subheadings: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 400,
    color: colors.subduedText,
  },
  subHeadingValues: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    color: '#1D1E20',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 25,
    textAlign: 'right',
    color: 'grey',
  },
  text: {
    margin: 8,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
  },
  fieldContainer: {
    marginTop: 4,
    marginLeft: 10,
  },
  fieldContainer2: {
    marginTop: 4,
    marginLeft: 4,
  },
  twoFieldRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  twoFieldContainer: {
    width: '40%',
    marginLeft: 10,
    marginTop: 4,
  },
  twoFieldContainer2: {
    width: '40%',
    marginLeft: 4,
    marginTop: 4,
  },
  secondFieldAlignment: {
    marginLeft: 210,
  },
  secondFieldDateAlignment: {
    marginLeft: 110,
  },
  meterReadingContainer: {
    width: '550',
    border: 1,
    borderRadius: 8,
    padding: 4,
    borderColor: colors.colorDimGrayStroke,
    marginTop: 2,
    marginBottom: 2,
  },
  MeterReadingWrapper: {
    marginLeft: 10,
    width: '550',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});
