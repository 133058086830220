import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, Loading, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { floorErrorCodeTypes } from 'activate-errors';
import { getFloor, GetFloorQueryResponse } from 'lane-shared/graphql/floors';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';
// import { dates } from 'lane-shared/helpers/constants';
// import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { TagListItem } from 'components/ads';
import { Label } from 'components/general';
import { AdminPage, PageHeader } from 'components/layout';
import { H4, M } from 'components/typography';

import { Tooltip } from '../../../../../../components/general';
import { EditFloor } from '../edit';

import styles from './styles.scss';
import { getSharedTranslationKeys } from '../utils';
import { useFloorDeleteDisabledAlert } from '../hooks/useFloorDeleteDisabledAlert';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Props = {
  channel: Channel;
};

export const FloorDetails = ({ channel }: Props) => {
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const { t } = useTranslation();
  const { detailsPage } = getSharedTranslationKeys(channel.experienceType);
  const [edit, setEdit] = useState(false);
  const { floorId } = useParams<{ floorId: string }>();
  const [isFloorUpdated, setIsFloorUpdated] = useState(false);
  const { handleDeleteAlert } = useFloorDeleteDisabledAlert();

  const {
    data: floorData,
    error,
    loading,
    refetch,
  } = useQuery<GetFloorQueryResponse>(getFloor, {
    fetchPolicy: 'network-only',
    variables: {
      propertyId: channel._id,
      floorId,
    },
  });

  if (isFloorUpdated) {
    refetch();
    setIsFloorUpdated(false);
  }

  if (!unitsFloorsEnabled) {
    return null;
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const floorUnitsTagList: TagListItem[] =
    floorData?.floor.units.map(unit => {
      return { label: unit.name, value: unit.id };
    }) || [];

  const getErrorMessage = (error: Error) => {
    const codes = getErrorCodes(error);
    const errorMessage = getErrorMessagesFromCodes(
      codes,
      floorErrorCodeTypes,
      t('shared.floors.errors.generic')
    );

    return errorMessage;
  };

  // const lastUpdatedBy =
  //   floorData?.floor?.updatedByUserName ||
  //   floorData?.floor?.createdByUserName;

  // const floorCreatedDate = dateFormatter(
  //   floorData?.floor?.createdAtDatetime!,
  //   dates.LONG_MONTH_DATE_YEAR
  // );

  // const floorUpdatedDate = dateFormatter(
  //   floorData?.floor?.updatedAtDatetime! ||
  //     floorData?.floor?.createdAtDatetime!,
  //   dates.LONG_DAY_MONTH_YEAR
  // );

  const renderCustomActions = () => {
    if (!floorData) return null;

    const DeleteButton = (
      <Button
        onClick={() => {
          handleDeleteAlert();
        }}
        disabled={Boolean(floorData.floor.units.length)}
        testId="deleteFloor"
      >
        {t('web.pages.portal.admin.floors.details.deleteFloor')}
      </Button>
    );

    if (floorData.floor.units.length) {
      return (
        <Tooltip TooltipComponent={t(detailsPage.deleteTooltip)}>
          {DeleteButton}
        </Tooltip>
      );
    }

    return DeleteButton;
  };

  const pageHeaderProps = {
    header: floorData?.floor?.name,
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.pages.portal.admin.floors.details.breadCrumbs.back'),
        url: `${routes.channelAdminFloorsListView.replace(
          ':id',
          channel?.slug!
        )}`,
      },
      {
        label: t(
          'web.pages.portal.admin.floors.details.breadCrumbs.currentPage'
        ),
      },
    ],
    // description: error
    //   ? ''
    //   : t('web.pages.portal.admin.floors.details.lastUpdated', {
    //       lastUpdated: floorUpdatedDate,
    //       updatedBy: lastUpdatedBy,
    //     }),
    customActions: renderCustomActions(),
  };

  const nullValueDisplay = () => {
    return <M>{t('punctuation.dash')}</M>;
  };

  return (
    <AdminPage className={styles.adminPage}>
      {error ? (
        <>
          <div className={styles.pageHeader}>
            <PageHeader {...pageHeaderProps} />
          </div>
          <ErrorMessage
            error={getErrorMessage(error)}
            className={styles.errorMessage}
            fullWidth
          />
        </>
      ) : (
        <>
          <div className={styles.pageHeader}>
            <PageHeader {...pageHeaderProps} />
          </div>
          {!edit ? (
            <div className={styles.floorDetails}>
              <div className={styles.header}>
                <H4>{t('web.pages.portal.admin.floors.details.header')}</H4>
                <Button
                  startIcon={<Icon name="pencil" />}
                  size="small"
                  testId="editFloor"
                  onClick={() => setEdit(true)}
                >
                  {t('web.pages.portal.admin.floors.details.edit')}
                </Button>
              </div>
              <div className={styles.body}>
                <div className={styles.leftCard}>
                  <div>
                    <Label className={styles.label}>
                      {t('web.pages.portal.admin.floors.details.floorNumber')}
                    </Label>
                    <M>{floorData?.floor?.index}</M>
                  </div>
                  <div>
                    <Label className={styles.label}>
                      {t('web.pages.portal.admin.floors.details.displayName')}
                    </Label>
                    <M>{floorData?.floor?.name || nullValueDisplay()}</M>
                  </div>
                  <div>
                    <Label className={styles.label}>
                      {t(detailsPage.space.label)}
                    </Label>
                    {floorUnitsTagList.length > 0 ? (
                      <M>
                        {floorUnitsTagList.map(unit => unit.label).join(', ')}
                      </M>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                {/* <div className={styles.rightCard}>
                  <Label className={styles.label}>
                    {t('web.pages.portal.admin.floors.details.dateCreated')}
                  </Label>
                  <M>{floorCreatedDate}</M>
                </div> */}
              </div>
            </div>
          ) : (
            <EditFloor
              channelId={channel._id}
              channelExperienceType={channel.experienceType}
              floor={floorData?.floor}
              setEdit={setEdit}
              setIsFloorUpdated={setIsFloorUpdated}
            />
          )}
          {/* <DeleteFloorModal
            deleteFloorModalOpen={deleteFloorModalOpen}
            setDeleteFloorModalOpen={setDeleteFloorModalOpen}
            floorName={floorData?.floor?.name || ''}
            id={floorId}
          /> */}
        </>
      )}
    </AdminPage>
  );
};
