import React from 'react';

import { IntegrationProviderEnum } from 'constants-integrations';

import Loading from 'components/general/Loading';
import EmailForm from 'components/integrations/AngusServiceRequests/EmailForm';
import BuildingEnginesPrismLogin from 'components/integrations/BuildingEnginesPrism/Login';
import ProxyClickLogin from 'components/integrations/ProxyClick/Login';

function ContentIntegrationLogin({ content }: any) {
  if (!content) {
    return <Loading />;
  }

  const integrationProvider =
    content.integration && content.integration.integration.name;

  switch (integrationProvider) {
    case IntegrationProviderEnum.AngusServiceRequests:
      return <EmailForm content={content} />;
    case IntegrationProviderEnum.ProxyClick:
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ content: any; }' is not assignable to type... Remove this comment to see the full error message
      return <ProxyClickLogin content={content} />;
    case IntegrationProviderEnum.BuildingEnginesPrism:
      return <BuildingEnginesPrismLogin content={content} />;
    default:
      return null;
  }
}

export default ContentIntegrationLogin;
