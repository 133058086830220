import { IntegrationProviderEnum } from 'constants-integrations';
import { useQuery } from '@apollo/client';
import { getChannelIntegration } from 'lane-shared/graphql/query';
import useCurrentChannelIntegrations from 'hooks/useCurrentChannelIntegrations';
import { CurrentChannelIntegration } from '../types';

export function useAccessManagementIntegration() {
  const currentChannelIntegrations = useCurrentChannelIntegrations();
  const accessManagementIntegrationId = currentChannelIntegrations.find(
    channelIntegration => {
      return (
        channelIntegration.integration.name ===
        IntegrationProviderEnum.AccessManagement
      );
    }
  )?._id;

  const { data } = useQuery(getChannelIntegration, {
    skip: !accessManagementIntegrationId,
    variables: { id: accessManagementIntegrationId },
  });

  return data as CurrentChannelIntegration;
}
