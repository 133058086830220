import React, { useState } from 'react';

import { ControlMenu, Button, ErrorMessage } from 'components';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { constructAddress, getDisplayName } from 'lane-shared/helpers';
import {
  Channel,
  CloneChannelOptionsType,
} from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';

import Checkbox from 'components/form/Checkbox';
import SimpleInput from 'components/form/SimpleInput';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import AddressEdit from 'components/lane/AddressEdit';
import { M, H4, H5 } from 'components/typography';

import ChannelSelectorButton from '../../../../../components/lane/ChannelSelectorButton';
import history from 'helpers/history';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  cloneAlertTitle:
    'web.admin.channel.children.cloneChannelModal.cloneAlert.title',
  cloneAlertMessage:
    'web.admin.channel.children.cloneChannelModal.cloneAlert.message',
  cloneAlertConfirmText:
    'web.admin.channel.children.cloneChannelModal.cloneAlert.confirmText',
  cloneBtn: 'web.admin.channel.children.cloneChannelModal.controlMenu.cloneBtn',
  cancelBtn:
    'web.admin.channel.children.cloneChannelModal.controlMenu.cancelBtn',
  copyChannelTabsTitle:
    'web.admin.channel.children.cloneChannelModal.includeChannelPages.title',
  copyChannelTabsDescription:
    'web.admin.channel.children.cloneChannelModal.includeChannelPages.description',
  copyContentCenterTitle:
    'web.admin.channel.children.cloneChannelModal.includeContent.title',
  copyContentCenterDescription:
    'web.admin.channel.children.cloneChannelModal.includeContent.description',
  copyLibrariesTitle:
    'web.admin.channel.children.cloneChannelModal.includeLibraries.title',
  copyLibrariesDescription:
    'web.admin.channel.children.cloneChannelModal.includeLibraries.description',
  copyPageCenterTitle:
    'web.admin.channel.children.cloneChannelModal.includePages.title',
  copyPageCenterDescription:
    'web.admin.channel.children.cloneChannelModal.includePages.description',
  copySectionsContentsTitle:
    'web.admin.channel.children.cloneChannelModal.includeSectionContent.title',
  copySectionsContentsDescription:
    'web.admin.channel.children.cloneChannelModal.includeSectionContent.description',
  copySectionsTitle:
    'web.admin.channel.children.cloneChannelModal.includeSections.title',
  copySectionsDescription:
    'web.admin.channel.children.cloneChannelModal.includeSections.description',
  copyTeamsDescription:
    'web.admin.channel.children.cloneChannelModal.includeGroupRoles.description',
  copyTeamsTitle:
    'web.admin.channel.children.cloneChannelModal.includeGroupRoles.title',
  copyTranslationsTitle:
    'web.admin.channel.children.cloneChannelModal.includeMultiLanguage.title',
  copyTranslationsDescription:
    'web.admin.channel.children.cloneChannelModal.includeMultiLanguage.description',
  newAddressTitle:
    'web.admin.channel.children.cloneChannelModal.newAddress.title',
  newAddressDescription:
    'web.admin.channel.children.cloneChannelModal.newAddress.description',
  newNameTitle: 'web.admin.channel.children.cloneChannelModal.newName.title',
  newParentDescription:
    'web.admin.channel.children.cloneChannelModal.newParent.description',
  newParentTitle:
    'web.admin.channel.children.cloneChannelModal.newParent.title',
  successToastMessage:
    'web.admin.channel.children.cloneChannelModal.successToast.message',
  title: 'web.admin.channel.children.cloneChannelModal.title',
};

const cloneMutation = gql`
  mutation cloneChannel(
    $channelId: UUID!
    $options: CloneChannelOptionsInput!
  ) {
    cloneChannel(channelId: $channelId, options: $options) {
      _id
      name
      slug
    }
  }
`;

export function CloneChannelModal({
  isCloneOpen,
  loading,
  channel,
  cloneOptions,
  toClone,
  user,
  setCloneOptions,
  setIsCloneOpen,
  setLoading,
}: {
  isCloneOpen: boolean;
  loading: boolean;
  channel: Channel;
  cloneOptions: CloneChannelOptionsType;
  toClone: Channel | null;
  user: UserType | null;
  setCloneOptions: (value: any) => void;
  setIsCloneOpen: (state: boolean) => void;
  setLoading: (state: boolean) => void;
}) {
  const { t } = useTranslation();
  const [cloneError, setCloneError] = useState<Error | null>(null);

  async function doCloneChannel() {
    try {
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.cloneAlertTitle),
        message: t(TRANSLATION_KEYS.cloneAlertMessage),
        confirmText: t(TRANSLATION_KEYS.cloneAlertConfirmText),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    setLoading(true);
    setCloneError(null);

    try {
      const { data } = await getClient().mutate({
        mutation: cloneMutation,
        variables: {
          channelId: toClone?._id,
          options: cloneOptions,
        },
      });

      history.push(routes.channelAdmin.replace(':id', data.cloneChannel.slug));
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.successToastMessage, {
            channelName: getDisplayName(toClone),
          })}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t('An error occurred!'),
        error: err,
      });
      setCloneError(err);
    } finally {
      setLoading(false);
    }
  }

  function updateOptions(props: Partial<CloneChannelOptionsType>) {
    setCloneOptions((prevState: any) => ({
      ...prevState,
      ...props,
    }));
  }

  return (
    <ModalBackground
      onClose={() => !loading && setIsCloneOpen(false)}
      isOpen={isCloneOpen}
      className={styles.background}
    >
      <ResizableWindow
        name="cloneChannelWindow"
        className={styles.window}
        onClose={() => !loading && setIsCloneOpen(false)}
        showHeader
      >
        <div className={styles.inner}>
          <H4 mb={6}>
            {t(TRANSLATION_KEYS.title, {
              channelName: getDisplayName(toClone),
            })}
          </H4>

          <ErrorMessage error={cloneError} />

          <H5 mb={2}>{t(TRANSLATION_KEYS.newNameTitle)}</H5>
          <SimpleInput
            onChange={newName => updateOptions({ newName })}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'Re... Remove this comment to see the full error message
            value={cloneOptions.newName}
            mb={4}
          />

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            testId="diffChannel"
            text={t(TRANSLATION_KEYS.newParentTitle)}
            selected={!!cloneOptions.newParentId}
            onChange={() =>
              updateOptions({
                newParentId: !cloneOptions.newParentId ? channel._id : null,
              })
            }
          />
          {cloneOptions.newParentId && (
            <ChannelSelectorButton
              className={styles.selectorButton}
              channelId={cloneOptions.newParentId}
              onChannelSelected={(channel: any) =>
                updateOptions({ newParentId: channel?._id })
              }
              storageKey="cloneChannel"
            />
          )}

          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.newParentDescription)}
          </M>

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.copyTeamsTitle)}
            testId="copyTeams"
            selected={cloneOptions.includeGroupRoles}
            onChange={() =>
              updateOptions({
                includeGroupRoles: !cloneOptions.includeGroupRoles,
              })
            }
          />
          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.copyTeamsDescription)}
          </M>

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.copyLibrariesTitle)}
            testId="copyLibraries"
            selected={cloneOptions.includeLibraries}
            onChange={() =>
              updateOptions({
                includeLibraries: !cloneOptions.includeLibraries,
              })
            }
          />
          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.copyLibrariesDescription)}
          </M>

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.copyContentCenterTitle)}
            testId="copyContentCenter"
            selected={cloneOptions.includeContent}
            onChange={() =>
              updateOptions({ includeContent: !cloneOptions.includeContent })
            }
          />
          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.copyContentCenterDescription)}
          </M>

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.copyPageCenterTitle)}
            testId="copyPageCenter"
            selected={cloneOptions.includePages}
            onChange={() =>
              updateOptions({ includePages: !cloneOptions.includePages })
            }
          />
          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.copyPageCenterDescription)}
          </M>

          {cloneOptions.includePages && (
            <>
              {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
              <Checkbox
                mb={2}
                text={t(TRANSLATION_KEYS.copyChannelTabsTitle)}
                testId="copyChannelTabs"
                selected={cloneOptions.includeChannelPages}
                onChange={() =>
                  updateOptions({
                    includeChannelPages: !cloneOptions.includeChannelPages,
                  })
                }
              />
              <M variant="secondary" mb={6}>
                {t(TRANSLATION_KEYS.copyChannelTabsDescription)}
              </M>
            </>
          )}

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.copySectionsTitle)}
            testId="copySections"
            selected={cloneOptions.includeSections}
            onChange={() =>
              updateOptions({
                includeSections: !cloneOptions.includeSections,
                includeSectionContent: !cloneOptions.includeSections,
              })
            }
          />
          <M variant="secondary" mb={6}>
            {t(TRANSLATION_KEYS.copySectionsDescription)}
          </M>

          {cloneOptions.includeSections && (
            <>
              {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
              <Checkbox
                mb={2}
                text={t(TRANSLATION_KEYS.copySectionsContentsTitle)}
                testId="copySectionsContents"
                selected={cloneOptions.includeSectionContent}
                onChange={() =>
                  updateOptions({
                    includeSectionContent: !cloneOptions.includeSectionContent,
                  })
                }
              />
              <M variant="secondary" mb={6}>
                {t(TRANSLATION_KEYS.copySectionsContentsDescription)}
              </M>
            </>
          )}

          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ mb: number;... Remove this comment to see the full error message */}
          <Checkbox
            mb={2}
            text={t(TRANSLATION_KEYS.newAddressTitle)}
            selected={!!cloneOptions.newAddress}
            onChange={() =>
              updateOptions({
                newAddress: cloneOptions.newAddress
                  ? null
                  : constructAddress({ userId: user!._id }),
              })
            }
          />
          <M variant="secondary" mb={4}>
            {t(TRANSLATION_KEYS.newAddressDescription)}
          </M>
          {cloneOptions.newAddress && (
            <AddressEdit
              mb={4}
              className={styles.input}
              address={cloneOptions.newAddress}
              onAddressUpdated={newAddress => updateOptions({ newAddress })}
            />
          )}

          {toClone?.settings?.multiLanguageEnabled && (
            <>
              <Checkbox
                mb={2}
                value={null}
                text={t(TRANSLATION_KEYS.copyTranslationsTitle)}
                testId="copyTranslations"
                selected={cloneOptions.includeMultiLanguage}
                onChange={() =>
                  updateOptions({
                    includeMultiLanguage: !cloneOptions.includeMultiLanguage,
                  })
                }
              />
              <M variant="secondary" mb={6}>
                {t(TRANSLATION_KEYS.copyTranslationsDescription)}
              </M>
            </>
          )}

          <ControlMenu>
            <hr />
            <Button loading={loading} onClick={() => setIsCloneOpen(false)}>
              {t(TRANSLATION_KEYS.cancelBtn)}
            </Button>
            <Button
              loading={loading}
              disabled={Boolean(
                cloneOptions.newAddress && cloneOptions.newAddress.geo[0] === 0
              )}
              onClick={doCloneChannel}
              variant="contained"
              testId="copyChannelSettings"
            >
              {t(TRANSLATION_KEYS.cloneBtn)}
            </Button>
          </ControlMenu>
        </div>
      </ResizableWindow>
    </ModalBackground>
  );
}
