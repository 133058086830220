import React from 'react';
import {
  useBillingPaymentSettingsForChannel,
  useChargeManager,
} from 'lane-shared/domains/billingPayments/hooks';
import styles from './index.scss';
import {
  ExternalEntityType,
  currencyToPaymentCurrency,
  ExternalPayerType,
  Charge,
} from 'lane-shared/domains/billingPayments/types';
import { Flex, Loading } from 'components';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import useUserLocale from 'hooks/useUserLocale';
import { ChargesList } from './ChargesList/ChargesList';
import { DraftChargeEditor } from './DraftChargeEditor/DraftChargeEditor';
import { InvoiceType } from 'graphql-query-contracts';

type Props = {
  channel: Channel;
  user: UserType;
  externalPayerId: string;
  externalPayerType: ExternalPayerType;
  externalEntityType: ExternalEntityType;
  externalEntityId?: string;
  invoiceType?: InvoiceType;
  chargeName: string;
  chargeDescription?: string;
  onSubmitCharge?: (charge: Charge) => void;
  metadata?: Record<string, any>;
};
export function ChargeManager({
  channel,
  user,
  externalPayerId,
  externalPayerType = ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
  externalEntityType = ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE,
  externalEntityId,
  invoiceType = InvoiceType.InvoiceTypeOneoff,
  chargeName,
  chargeDescription,
  onSubmitCharge,
  metadata,
}: Props) {
  const { currency, invoiceDueInDays } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });
  const locale = useUserLocale();

  const {
    charges,
    draftCharge,
    loading,
    validation,
    submitDraftCharge,
    voidCharge,
    addDraftProductItem,
    updateDraftProductItem,
    deleteDraftProductItem,
  } = useChargeManager({
    channel,
    actingUser: user,
    externalPayerId,
    externalPayerType,
    currency,
    externalEntityId,
    externalEntityType,
    invoiceType,
    locale,
    chargeName,
    chargeDescription,
    invoiceDueInDays: invoiceDueInDays ?? 30,
    onSubmitCharge,
    metadata,
  });

  if (loading) {
    return (
      <Flex p={4} width="full" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  const paymentCurrency = currencyToPaymentCurrency(currency);

  return (
    <Flex gap={4} className={styles.ChargeManager} direction="column">
      <DraftChargeEditor
        items={draftCharge.items}
        showEmpty={charges.length > 0}
        locale={locale}
        currency={paymentCurrency}
        validation={validation}
        payerId={externalPayerId}
        payerType={externalPayerType}
        submitDraftCharge={submitDraftCharge}
        addDraftProductItem={addDraftProductItem}
        updateDraftProductItem={updateDraftProductItem}
        deleteDraftProductItem={deleteDraftProductItem}
      />
      {charges.length > 0 && (
        <ChargesList
          charges={charges}
          currency={paymentCurrency}
          locale={locale}
          voidCharge={voidCharge}
        />
      )}
    </Flex>
  );
}
