import React, { useContext } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { UserType } from 'lane-shared/types/User';
import { Audience } from 'lane-shared/types/audiences/Audience';

import { S } from 'components/typography';

import style from './AudienceActivityHistory.scss';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type AudienceActivityHistoryProps = {
  audience?: Audience;
  channel?: Channel;
  updatedByUser?: Partial<UserType>;
  createdByUser?: Partial<UserType>;
};

export function AudienceActivityHistory({
  audience,
  channel,
  updatedByUser,
  createdByUser,
}: AudienceActivityHistoryProps) {
  function getUserLink(user: Partial<UserType>) {
    return routes.channelAdminTeamMember
      .replace(':id', channel?.slug!)
      .replace(':userId', user?._id!);
  }

  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const createdAtDate = DateTime.fromISO(
    audience?.createdAt! as unknown as string
  );
  const updatedAtDate = DateTime.fromISO(
    audience?.updatedAt! as unknown as string
  );

  if (user?.locale) {
    updatedAtDate.setLocale(user.locale);
    createdAtDate.setLocale(user.locale);
  }

  return (
    <div className={style.activityHistoryContainer}>
      <S className={style.activityHistoryText} data-test="updated-at-text">
        {t('web.pages.portal.admin.audiences.show.updatedAt', {
          date: updatedAtDate.toLocaleString({
            weekday: 'long',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          }),
          time: updatedAtDate.toLocaleString(DateTime.TIME_SIMPLE),
          name: updatedByUser?.name,
        })}
        <Link
          to={getUserLink(updatedByUser!)}
          className={style.activityHistoryLink}
        >
          {updatedByUser?.name}
        </Link>
      </S>

      <S className={style.activityHistoryText} data-test="created-at-text">
        {t('web.pages.portal.admin.audiences.show.createdAt', {
          date: createdAtDate.toLocaleString({
            weekday: 'long',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          }),
          time: createdAtDate.toLocaleString(DateTime.TIME_SIMPLE),
        })}
        <Link
          to={getUserLink(createdByUser!)}
          className={style.activityHistoryLink}
        >
          {createdByUser?.name}
        </Link>
      </S>
    </div>
  );
}
