import React, { useMemo } from 'react';

import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  PresetContentFilter,
  PresetContentFilterDescription,
  PresetContentFilterFriendlyName,
  SectionTypeEnum,
} from 'constants-content';

import Dropdown from '../form/Dropdown';
import IconButton from '../general/IconButton';

import styles from './PresetContentFilterListItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  sectionType: SectionTypeEnum;
  filters: PresetContentFilter[];
  filter: PresetContentFilter;
  onChange: (filters: PresetContentFilter[]) => void;
};

export default function PresetContentFilterListItem({
  className,
  style,
  sectionType,
  filters,
  filter,
  onChange,
}: Props) {
  const items = useMemo(
    () =>
      Object.values(PresetContentFilter)
        // only show filter options that haven't been selected elsewhere.
        .filter(f => f === filter || !filters.includes(f))
        .filter(f => {
          switch (f) {
            case PresetContentFilter.FeatureReservableAvailableWeeks:
            case PresetContentFilter.FeatureReservableAvailableMonths:
              // todo: not implemented yet.
              return false;
            case PresetContentFilter.ByChannel:
            case PresetContentFilter.ByChannelLocations:
              return sectionType === SectionTypeEnum.Dynamic;
            case PresetContentFilter.SearchBox:
            case PresetContentFilter.Location:
            case PresetContentFilter.AvailableNow:
            case PresetContentFilter.FeaturePaymentPrice:
            case PresetContentFilter.FeatureQuantityRemaining:
            case PresetContentFilter.FeatureReservableAvailableMinutes:
            case PresetContentFilter.FeatureReservableAvailableDays:
            default:
              return true;
          }
        })
        // show friendly names for the dropdown options
        .map(value => ({
          label: PresetContentFilterFriendlyName[value],
          value,
        })),
    [filter, filters, sectionType]
  );

  return (
    <div
      className={cx(styles.PresetContentFilterListItem, className)}
      style={style}
    >
      <div className={styles.inner}>
        <Dropdown
          className={styles.dropdown}
          items={items}
          value={filter}
          onValueChange={newValue => {
            filters[filters.findIndex((f: any) => f === filter)] = newValue;
            onChange([...filters]);
          }}
        />
        <p>{PresetContentFilterDescription[filter]}</p>
      </div>
      <IconButton
        className={styles.deleteButton}
        icon="times-circle"
        iconSet={ICON_SET_FONTAWESOME}
        onClick={() => onChange(filters.filter((f: any) => f !== filter))}
      />
    </div>
  );
}
