import { useContext } from 'react';
import { signUpMutation } from 'lane-shared/helpers';
import { UserDataContext } from 'lane-shared/contexts';
import { UserLoginProviderEnum, UserLoginTypeEnum } from 'constants-user';
import { useInviteContext } from 'lane-shared/contexts/InviteContext/useInviteContext';
import { OnboardingData } from './types';

export function useRegisterOnboardedUser(): {
  signUpUser: (accountData: OnboardingData, email?: string) => Promise<void>;
} {
  const { setAuthWarnings } = useContext(UserDataContext);
  const { inviteId, clearInvite } = useInviteContext();

  const signUp = async (
    { fullName, building, company, marketingOptIn }: OnboardingData,
    email?: string
  ) => {
    if (!email) {
      throw new Error('The user email is required');
    }

    const mutationData = {
      name: fullName,
      building,
      company,
      emailOptIn: marketingOptIn,
      loginProvider: UserLoginProviderEnum.Auth0,
      isPublic: true,
      loginType: UserLoginTypeEnum.Auth0,
      email,
      inviteId,
    };

    const mutationResult = await signUpMutation(mutationData);

    const {
      data: { warnings },
    } = mutationResult;

    if (warnings) {
      setAuthWarnings(warnings);
    } else if (inviteId) {
      clearInvite();
    }
  };

  return { signUpUser: signUp };
}
