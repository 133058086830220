import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import {
  AdminPage,
  TabStrip,
  ControlMenu,
  Button,
  ErrorMessage,
} from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { getClient } from 'lane-shared/apollo';
import { updateChannel } from 'lane-shared/graphql/mutation';
import { pause, castForUpdate, getDisplayName } from 'lane-shared/helpers';
import hasModuleEnabled from 'lane-shared/helpers/channelModule/hasModuleEnabled';
import { PROPERTY_TYPES } from 'constants-channel';
import { updateMetaData } from 'lane-shared/helpers/templates';
import { useChannelProfileQuery, useFlag } from 'lane-shared/hooks';
import { useChannelAnalytics } from 'lane-shared/hooks/analytics';
import useThemeEdit from 'lane-shared/hooks/useThemeEdit';
import { FeatureFlag } from 'constants-flags';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';
import { validateCreateChannel } from 'lane-shared/validation';
import {
  validateChannelSettings,
  validateChannelInfo,
  validateChannelBase,
} from 'lane-shared/validation/channel';

import FileInput from 'components/form/FileInput';
import IconButton from 'components/general/IconButton';
import { ChannelSettings, ChannelProfileEdit } from 'components/lane';
import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';
import { H3 } from 'components/typography';

import ChannelAdminContext from '../ChannelAdminContext';
import { UserDataContext } from 'lane-shared/contexts';
import ChannelTheme from '../theme';
import history from 'helpers/history';
import makeFileDownload from 'helpers/makeFileDownload';
import updateWorkOrderChannelModule from 'helpers/updateWorkOrderChannelModule';
import { updateWorkOrderChannelModuleConfiguration } from 'helpers';

import styles from './styles.scss';
import ChannelPublicProfileEdit from 'components/lane/ChannelSettingsEdit/ChannelPublicProfileEdit';
import { Channel } from 'lane-shared/types/ChannelType';

enum ProfileTabsEnum {
  TAB_PROFILE = 'profile',
  TAB_INFO = 'info',
  TAB_SETTINGS = 'settings',
  TAB_THEMES = 'themes',
}

enum AdminProfileTabsEnum {
  TAB_INFO = 'info',
  TAB_CONFIGURATION = 'configuration',
  TAB_PREVIEW = 'preview',
  TAB_PUBLIC_PROFILE = 'public_profile',
  TAB_THEMES = 'themes',
}

const OLD_TABS = [
  {
    label: 'web.admin.channel.settings.profile.profileTab.heading',
    value: ProfileTabsEnum.TAB_PROFILE,
  },
  {
    label: 'web.admin.channel.settings.profile.infoTab.heading',
    value: ProfileTabsEnum.TAB_INFO,
  },
  {
    label: 'web.admin.channel.settings.profile.settingsTab.heading',
    value: ProfileTabsEnum.TAB_SETTINGS,
  },
  {
    label: 'web.admin.channel.settings.profile.themesTab.heading',
    value: ProfileTabsEnum.TAB_THEMES,
  },
];

const TABS = [
  {
    label: 'web.admin.channel.settings.profile.infoTab.heading',
    value: AdminProfileTabsEnum.TAB_INFO,
  },
  {
    label: 'web.admin.channel.settings.profile.configurationTab.heading',
    value: AdminProfileTabsEnum.TAB_CONFIGURATION,
  },
  {
    label: 'web.admin.channel.settings.profile.previewTab.heading',
    value: AdminProfileTabsEnum.TAB_PREVIEW,
  },
  {
    label: 'web.admin.channel.settings.profile.publicProfileTab.heading',
    value: AdminProfileTabsEnum.TAB_PUBLIC_PROFILE,
  },
  {
    label: 'web.admin.channel.settings.profile.themesTab.heading',
    value: AdminProfileTabsEnum.TAB_THEMES,
  },
];

const stepValidation = {
  [ProfileTabsEnum.TAB_PROFILE]: validateChannelBase,
  [ProfileTabsEnum.TAB_INFO]: validateChannelInfo,
  [ProfileTabsEnum.TAB_SETTINGS]: validateChannelSettings,
  [AdminProfileTabsEnum.TAB_PREVIEW]: validateChannelBase,
  [AdminProfileTabsEnum.TAB_PUBLIC_PROFILE]: validateChannelBase,
  // @ts-expect-error this is likely a mistake
  [AdminProfileTabsEnum.TAB_INFO]: validateChannelInfo,
  [AdminProfileTabsEnum.TAB_CONFIGURATION]: validateChannelSettings,
};

function isChannelType(channel: Partial<Channel>): channel is Channel {
  return (
    channel && '_id' in channel && 'name' in channel && 'settings' in channel
  );
}

export default function ChannelProfile() {
  const {
    channel,
    loading: channelLoading,
    refetchChannel,
  } = useContext(ChannelAdminContext);
  const { user } = useContext(UserDataContext);
  const channelModules = channel?.channelModules || [];
  const tempWorkorderToggleValue = hasModuleEnabled(
    channelModules,
    ModuleCategoryEnum.WorkOrders
  );
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [updatedChannel, setUpdatedChannel] = useState<any>(null);
  const [updatedProperties, setUpdatedProperties] = useState<string[]>([]);

  const isMFCreatePropertyFlagEnabled = useFlag(
    FeatureFlag.MultifamilyCreateProperty,
    false
  );

  const isImprovedAdminProfileFlagEnabled = useFlag(
    FeatureFlag.OnboardingImprovementsAdminProfile,
    false
  );

  const isMaintainSettingsConfigurationsEnabled = useFlag(
    FeatureFlag.MaintainSettingsConfigurations,
    false
  );

  const isWorkOrderOotbConfigurationV2Enabled = useFlag(
    FeatureFlag.WorkOrderOotbConfigurationUpdate,
    false
  );

  const tabs = isMFCreatePropertyFlagEnabled
    ? TABS.filter(
        tab =>
          isImprovedAdminProfileFlagEnabled
            ? tab.value !== AdminProfileTabsEnum.TAB_PREVIEW // Remove TAB_PREVIEW if the flag is enabled
            : tab.value !== AdminProfileTabsEnum.TAB_PUBLIC_PROFILE // Remove TAB_PUBLIC_PROFILE if the flag is disabled
      )
    : OLD_TABS;

  const defaultTab = isMFCreatePropertyFlagEnabled
    ? AdminProfileTabsEnum.TAB_INFO
    : ProfileTabsEnum.TAB_PROFILE;
  const [query, goToUrl] = useQueryString({
    tab: defaultTab,
  });

  const infoTab = isMFCreatePropertyFlagEnabled
    ? AdminProfileTabsEnum.TAB_INFO
    : ProfileTabsEnum.TAB_INFO;
  const configurationTab = isMFCreatePropertyFlagEnabled
    ? AdminProfileTabsEnum.TAB_CONFIGURATION
    : ProfileTabsEnum.TAB_SETTINGS;
  let previewTab: ProfileTabsEnum | AdminProfileTabsEnum =
    ProfileTabsEnum.TAB_PROFILE;
  if (isMFCreatePropertyFlagEnabled) {
    if (isImprovedAdminProfileFlagEnabled) {
      previewTab = AdminProfileTabsEnum.TAB_PUBLIC_PROFILE;
    } else {
      previewTab = AdminProfileTabsEnum.TAB_PREVIEW;
    }
  }
  const themesTab = isMFCreatePropertyFlagEnabled
    ? AdminProfileTabsEnum.TAB_THEMES
    : ProfileTabsEnum.TAB_THEMES;

  const { trackReservableManagerEnabled } = useChannelAnalytics();

  const { t } = useTranslation();
  const {
    theme,
    setTheme,
    error: themeError,
    hasChanges: themeHasChanges,
    saveTheme,
  } = useThemeEdit({
    themeId: channel?.profile?.theme?._id,
  });

  const { loading: themeChannelLoading } = useChannelProfileQuery({
    channelId: (theme as any)?.channel?._id,
  });

  const validationSummaryMessage = t(
    'web.admin.channel.generic.validationSummary'
  );
  const onSaveFailedTitle = t(
    'web.admin.channel.profile.save.failure.alert.title'
  );
  const onSaveFailedMessage = t(
    'web.admin.channel.profile.save.failure.alert.message'
  );
  const onSaveSuccessToastMessage = t(
    'web.admin.channel.profile.save.success.toast.message'
  );

  const selectedTab = query.tab || defaultTab;

  const selected = isMFCreatePropertyFlagEnabled
    ? TABS.find(tab => tab.value === selectedTab)
    : OLD_TABS.find(tab => tab.value === selectedTab);

  async function validateStep() {
    try {
      setValidation(null);
      // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
      await stepValidation[selectedTab].validate(updatedChannel, {
        abortEarly: false,
        context: {
          hasCustomOffboarding: updatedChannel.hasCustomOffboarding,
        },
      });

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  function onChannelUpdated(update: any) {
    setUpdatedChannel({
      ...(updatedChannel || channel),
      ...update,
    });

    // track whats changed for smaller save.
    setUpdatedProperties([
      ...updatedProperties,
      ...Object.keys(update).filter(key => !updatedProperties.includes(key)),
    ]);

    if (validation) {
      validateStep();
    }
  }

  const data = updatedChannel || channel;

  function resetData() {
    const updatedChannelLocal = updatedChannel;
    const updatedUrl = history.location.pathname + history.location.search;

    setUpdatedProperties([]);
    setUpdatedChannel(null);
    refetchChannel();

    // update URL if slug has changed
    if (
      updatedChannel?.slug &&
      channel?.slug &&
      updatedChannel?.slug !== channel?.slug
    ) {
      history.replace(
        // @ts-ignore
        updatedUrl.replace(channel.slug, updatedChannelLocal.slug)
      );
    }
  }

  function importJSON(text: any) {
    try {
      const obj = JSON.parse(text);

      if (obj.palette) {
        obj.palette._id = uuid();
      }

      if (obj.layout) {
        obj.layout._id = uuid();
      }

      if (obj.typography) {
        obj.typography._id = uuid();
      }

      setTheme(obj);
      window.Toast.show(`Imported ${(theme as any).name}`);
    } catch (error) {
      window.Alert.alert({
        title: t('There was an error with this file.'),
        error,
      });
    }
  }

  function exportTheme() {
    const clone = updateMetaData(theme);

    // don't need this info on an export
    delete clone.user;
    delete clone.channel;

    makeFileDownload({
      name: `${(theme as any).name || getDisplayName(channel)} Theme.json`,
      contents: JSON.stringify(clone),
      type: 'application/json',
    });
  }

  async function onSave() {
    if (
      isChannelType(updatedChannel) &&
      !!updatedChannel?.settings?.hasReservableManagementEnabled
    ) {
      trackReservableManagerEnabled(updatedChannel);
    }

    if (themeHasChanges) {
      saveTheme();
    }

    if (updatedChannel) {
      try {
        setValidation(null);
        await validateCreateChannel.validate(updatedChannel, {
          abortEarly: false,
          context: {
            hasCustomOffboarding: updatedChannel.hasCustomOffboarding,
          },
        });
      } catch (err) {
        console.error(err);
        setValidation(err);

        return;
      }
    }

    const update = {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      _id: channel._id,
    };

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedProperties.forEach(key => (update[key] = updatedChannel[key]));

    if ((update as any)?.profile?.theme) {
      (update as any).profile.theme = {
        _id: (update as any).profile.theme._id,
      };
    }

    if (
      (update as any)?.settings &&
      'effectiveParentRequirements' in (update as any).settings
    ) {
      delete (update as any).settings.effectiveParentRequirements;
    }

    const didSlugChange = updatedChannel?.slug !== channel?.slug;

    try {
      setLoading(true);
      setError(null);
      await pause();
      await getClient().mutate({
        mutation: updateChannel,
        // we will hard reload if slug changes (url changes)
        refetchQueries: didSlugChange ? [] : ['getChannelForAdmin'],
        variables: {
          channel: castForUpdate(update),
        },
      });

      if (
        updatedChannel?.settings &&
        isMaintainSettingsConfigurationsEnabled &&
        (updatedChannel.settings?.hasWorkOrderServiceRequestsEnabled !==
          channel?.settings?.hasWorkOrderServiceRequestsEnabled ||
          updatedChannel.settings?.hasWorkOrderEquipmentEnabled !==
            channel?.settings?.hasWorkOrderEquipmentEnabled ||
          updatedChannel.settings?.hasWorkOrderPreventiveMaintenanceEnabled !==
            channel?.settings?.hasWorkOrderPreventiveMaintenanceEnabled)
      ) {
        await updateWorkOrderChannelModuleConfiguration(
          channelModules,
          channel,
          channel?._id,
          updatedChannel.settings.hasWorkOrderServiceRequestsEnabled,
          updatedChannel.settings.hasWorkOrderPreventiveMaintenanceEnabled,
          isWorkOrderOotbConfigurationV2Enabled
        );
      }

      if (
        updatedChannel?.settings &&
        !isMaintainSettingsConfigurationsEnabled &&
        (updatedChannel.settings?.hasWorkOrderServiceRequestsEnabled !==
          tempWorkorderToggleValue ||
          updatedChannel.settings?.hasWorkOrderEquipmentEnabled ||
          updatedChannel.settings?.hasWorkOrderPreventiveMaintenanceEnabled)
      ) {
        await updateWorkOrderChannelModule(
          channelModules,
          channel,
          channel?._id,
          updatedChannel.settings.hasWorkOrderServiceRequestsEnabled,
          updatedChannel.settings.hasWorkOrderEquipmentEnabled,
          updatedChannel.settings.hasWorkOrderPreventiveMaintenanceEnabled,
          user,
          isWorkOrderOotbConfigurationV2Enabled
        );
      }

      setLoading(false);
      await window.Toast.show(onSaveSuccessToastMessage);

      resetData();
    } catch (err) {
      setError(err);
      window.Alert.alert({
        title: onSaveFailedTitle,
        message: onSaveFailedMessage,
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  const isAnyLoading = channelLoading || themeChannelLoading || loading;

  // does this theme belong to this channel or not?  If not, you can't edit
  // it from here.  But you can export it, clone it, create a new theme. etc.
  const isChannelTheme = channel?._id === (theme as any)?.channel?._id;

  if (!data) {
    return null;
  }

  // todo: this should be refactored at some point, not sure why this is here.
  //  not good to set properties on a render...
  if (!data.stats) {
    data.stats = {};
  }

  const isSaveDisabled = !updatedChannel && !themeHasChanges;

  const saveButton = (
    <Button
      testId="saveButton"
      loading={loading}
      disabled={isSaveDisabled}
      onClick={onSave}
      variant="contained"
    >
      {t`web.admin.channel.settings.profile.saveButton`}
    </Button>
  );

  const undoButton = (
    <Button loading={loading} onClick={resetData} disabled={!updatedChannel}>
      {t`web.admin.channel.settings.profile.undoButton`}
    </Button>
  );

  function profileButtons() {
    return (
      <>
        {selectedTab === themesTab && (
          <>
            <hr />
            <IconButton
              inverted
              selected={isExpanded}
              testId="moreOptions"
              icon="ellipsis-h"
              onClick={() => setIsExpanded(!isExpanded)}
            />

            {isExpanded && isChannelTheme && (
              <FileInput
                accept="application/json"
                // @ts-expect-error ts-migrate(2322) FIXME: Type '"text"' is not assignable to type 'FileRetur... Remove this comment to see the full error message
                type="text"
                onFileSelected={importJSON}
              >
                <Button
                  testId="fileImport"
                  startIcon={<Icon name="upload" />}
                  loading={isAnyLoading}
                >
                  {t('Import')}
                </Button>
              </FileInput>
            )}

            {isExpanded && (
              <Button
                testId="fileExport"
                startIcon={<Icon name="download" />}
                loading={isAnyLoading}
                onClick={exportTheme}
              >
                {t('Export')}
              </Button>
            )}
          </>
        )}
        {selectedTab !== themesTab && undoButton}
        {saveButton}
      </>
    );
  }

  return (
    <AdminPage className={styles.ChannelProfile}>
      {isMFCreatePropertyFlagEnabled && (
        <div className={styles.header}>
          <H3>
            {channel && PROPERTY_TYPES.includes(channel.type)
              ? t('web.admin.channel.settings.profile.propertyHeader')
              : t('web.admin.channel.settings.profile.header')}
          </H3>
          <div className={styles.buttons}>{profileButtons()}</div>
        </div>
      )}

      <ControlMenu className={styles.controlMenu}>
        <TabStrip
          disabled={loading}
          tabs={tabs}
          selected={selected}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
          className={styles.tabStrip}
        />
        {!isMFCreatePropertyFlagEnabled && profileButtons()}
      </ControlMenu>

      <ErrorMessage error={error} fullWidth />

      {isMFCreatePropertyFlagEnabled && (
        <ErrorMessage
          error={validation && validationSummaryMessage}
          fullWidth
        />
      )}
      {!isMFCreatePropertyFlagEnabled && (
        <ErrorMessage error={validation} fullWidth />
      )}

      {selectedTab === infoTab && (
        <>
          <ChannelInfoEdit
            heading={`${channel?.profile?.name} information`}
            validation={validation}
            channel={data}
            onChannelUpdated={onChannelUpdated}
            shouldShowStats
            isInfoPage
            channelForDataIdentifiers={data}
          />
          {isMFCreatePropertyFlagEnabled && (
            <div className={styles.controlMenuBottom}>
              {saveButton}
              {undoButton}
            </div>
          )}
        </>
      )}

      {selectedTab === previewTab &&
        (previewTab === AdminProfileTabsEnum.TAB_PUBLIC_PROFILE ? (
          <ChannelPublicProfileEdit
            channel={channel}
            setUpdatedChannel={setUpdatedChannel}
            onChannelUpdated={onChannelUpdated}
            isSaveDisabled={isSaveDisabled}
            updatedChannel={updatedChannel}
          />
        ) : (
          <ChannelProfileEdit
            channel={channel}
            onChannelUpdated={onChannelUpdated}
          />
        ))}

      {selectedTab === configurationTab && (
        <ChannelSettings
          validation={validation}
          channel={data}
          isSaveDisabled={isSaveDisabled}
          onChannelUpdated={onChannelUpdated}
          channelForDataIdentifiers={data}
        />
      )}

      {selectedTab === themesTab && (
        <ChannelTheme
          theme={theme}
          onThemeUpdated={setTheme}
          error={themeError}
        />
      )}
    </AdminPage>
  );
}
