import React, { useEffect, useContext, useState } from 'react';

import {
  ErrorPage,
  ContentCard,
  IconButton,
  Loading,
  ContextMenu,
  SearchBarLegacy,
  ContentLink,
  Flex,
} from 'components';
import { match as Match, useHistory } from 'react-router-dom';
import { H3 } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import {
  doObjectTouchAction,
  findAccessRestrictedError,
  getQueryString,
} from 'lane-shared/helpers';
import { useFlag, useSectionQuery } from 'lane-shared/hooks';
import { useSectionAnalytics } from 'lane-shared/hooks/analytics';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { FeatureFlag } from 'constants-flags';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';
import { PresetContentSort } from 'constants-content';
import { TagBar } from './TagBar';

import Button from 'components/general/Button';
import { ChannelSelectorDropdown } from 'components/lane/ChannelSelectorDropdown';
import SectionSearchOptionsMenu from 'components/lane/SectionSearchOptionsMenu';
import Modal from 'components/lds/Modal';
import { SectionSearchBar } from 'components/general/SectionSearchBar';

import styles from './style.scss';

interface SectionProps {
  match?: Match<{
    sectionId?: string;
  }>;
  sectionId?: string;
  showName?: boolean;
  contentComponent?: (
    sectionContent: any[],
    areFiltersApplied?: boolean
  ) => React.ReactNode;
  refresh?: string | null;
  sectionContentFE?: any[];
  setSectionContentFE?: (p: any) => void;
  setSectionRefreshTrigger?: (p: any) => void;
}

export default function WorkSection({
  match,
  sectionId,
  showName = true,
  contentComponent,
  refresh = null,
  sectionContentFE = [],
  setSectionContentFE,
  setSectionRefreshTrigger,
}: SectionProps) {
  const { location, locationHelper } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const sectionUIMemberUpdate = useFlag(
    FeatureFlag.SectionUIMemberUpdate,
    false
  );
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTagFilters, false);

  const { whitelabel } = useContext(AppContext);

  const {
    error,
    loading,
    searchOptions,
    editingSearchOptions,
    section,
    hasFilters,
    hasSearch,
    hasChannelSelector,
    hasEventDateSelector,
    selectedChannel,
    updateSelectedChannel,
    hasChannelLocationsSelector,
    sectionContent,
    channels,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
    fetchSectionContent,
  } = useSectionQuery({
    sectionId: match?.params?.sectionId || sectionId,
    location,
  });

  useEffect(() => {
    async function requestLocation() {
      if (locationHelper.isWatching()) return;

      await locationHelper.startWatching({
        showRequestModal: true,
        availability: LocationAvailabilityEnum.Foreground,
        disableLocationPrecision: whitelabel.disableLocationPrecision,
      });
    }

    requestLocation();

    return () => {
      if (locationHelper.isWatching()) {
        locationHelper.stopWatching();
      }
    };
  }, []);

  useEffect(() => {
    if (
      loading === false &&
      (sectionContent?.length >= sectionContentFE?.length ||
        searchOptions?.areFiltersApplied)
    ) {
      if (setSectionContentFE) setSectionContentFE(sectionContent);
    }
  }, [`${sectionContent.length}-${loading}`]);

  useEffect(() => {
    if (refresh) {
      fetchSectionContent();

      // for preventing unwanted rerender since "refresh" gets set but not unset
      if (setSectionRefreshTrigger) setSectionRefreshTrigger('');
    }
  }, [refresh]);

  const analytics = useSectionAnalytics();

  useEffect(() => {
    if (section?._id) {
      analytics.sectionTracker.View.Details(section);
    }
  }, [section?._id]);

  function openModalHandler() {
    setIsOpen(true);
  }

  function closeModalHandler() {
    setIsOpen(false);
  }

  function resetSearchOptionsHandler() {
    resetSearchOptions();
    applySearchOptions();
    closeModalHandler();
  }

  function searchChangeHandler(search: string) {
    updateSearchOptions({ search, areFiltersApplied: search.length > 0 });
    applySearchOptions();
  }

  function applySearchOptionsHandler() {
    updateSearchOptions({
      areFiltersApplied: true,
    });

    applySearchOptions();

    closeModalHandler();
  }

  const isSortedByDistance = Boolean(
    section?.sorts?.includes(PresetContentSort.Location)
  );

  useEffect(() => {
    if (findAccessRestrictedError(error as Error)) {
      history.push(
        routes.restricted +
          getQueryString({
            id: match?.params?.sectionId || sectionId,
            type: 'section',
          })
      );
    }
  }, [error]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  async function onAction(item: any, action: any) {
    try {
      await doObjectTouchAction(item, action);
      window.Toast.show('Pinned');
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      window.Toast.show('Failed to pin.');
    }
  }

  const legacySectionContents = sectionContent?.map(sectionContent => (
    <div className={styles.wrapper} key={sectionContent._id}>
      <ContentLink content={sectionContent.content}>
        <ContentCard
          className={styles.content}
          content={sectionContent.content}
          showDistance={isSortedByDistance}
          location={location}
        />
        <div className={styles.contextMenu}>
          <ContextMenu
            menuClassName={styles.contextMenuContainer}
            items={[
              <button
                key="button"
                onClick={e => {
                  e.preventDefault();
                  onAction(sectionContent.content, 'pinned');
                }}
              >
                {sectionContent.content.info?.pinned ? t('Un-pin') : t('Pin')}
              </button>,
            ]}
          >
            <IconButton
              icon="ellipsis-v"
              inverted
              className={styles.menuButton}
            />
          </ContextMenu>
        </div>
      </ContentLink>
    </div>
  ));

  const sectionContents = contentComponent ? (
    contentComponent(sectionContentFE, searchOptions?.areFiltersApplied)
  ) : (
    <div className={styles.sectionContentContainer}>
      {sectionContent?.map(sectionContent => (
        <div key={sectionContent._id}>
          <ContentLink content={sectionContent.content}>
            <ContentCard
              className={styles.content}
              content={sectionContent.content}
              showDistance={isSortedByDistance}
              location={location}
            />
          </ContentLink>
        </div>
      ))}
    </div>
  );

  return (
    <div
      className={cx(styles.WorkSection, {
        [styles.sectionWidth]: sectionUIMemberUpdate,
      })}
    >
      {showName && (
        <Flex justify="center">
          <H3>{section?.name}</H3>
        </Flex>
      )}
      {sectionUIMemberUpdate ? (
        <SectionSearchBar
          searchOptions={searchOptions}
          onSearchChange={search => searchChangeHandler(search)}
          hasChannelSelector={hasChannelSelector}
          hasChannelLocationsSelector={hasChannelLocationsSelector}
          hasEventDateSelector={hasEventDateSelector}
          onSearchOptionsUpdated={update => {
            updateSearchOptions(update);
            applySearchOptions();
          }}
          showAllLocations
          channels={channels}
          channelId={selectedChannel?._id || section?.channel?._id}
          selectedChannelId={selectedChannel?._id}
          showFilters={hasFilters}
          showSearch={hasSearch}
          isFiltersEnabled={Boolean(
            searchOptions?.search || searchOptions?.areFiltersApplied
          )}
          onChannelSelected={channel => {
            updateSearchOptions({ areFiltersApplied: true });
            updateSelectedChannel(channel);
          }}
          onOpenFilters={openModalHandler}
          onAllLocationsSelected={() => updateSelectedChannel(null)}
        />
      ) : (
        <>
          {hasChannelSelector && (
            <ChannelSelectorDropdown
              className={styles.channelSelector}
              channelId={selectedChannel?._id || section?.channel?._id}
              channels={channels}
              showAllLocations
              onChannelSelected={channel =>
                updateSelectedChannel(channel as any)
              }
              onAllLocationsSelected={() => updateSelectedChannel(null)}
            />
          )}
          <menu
            className={styles.options}
            style={!hasSearch ? { display: 'none' } : {}}
          >
            <SearchBarLegacy
              searchOptions={searchOptions}
              onSearchChange={search => searchChangeHandler(search)}
              onSearchOptionsUpdated={update => {
                updateSearchOptions(update);
                applySearchOptions();
              }}
              showAllLocations
              showFilters={hasFilters}
              showSearch={hasSearch}
              isFiltersEnabled={Boolean(
                searchOptions?.search || searchOptions?.areFiltersApplied
              )}
              onOpenFilters={openModalHandler}
            />
          </menu>
        </>
      )}

      {areContentTagsEnabled &&
        section?.tagsOnSection &&
        section?.tagsOnSection?.length > 0 && (
          <TagBar
            tagsOnSection={section?.tagsOnSection}
            searchOptions={searchOptions}
            updateSearchOptions={updateSearchOptions}
            applySearchOptions={applySearchOptions}
          />
        )}

      {sectionUIMemberUpdate ? sectionContents : legacySectionContents}

      {sectionContent?.length === 0 && (
        <div className={styles.noResults}>
          <h1>{t('No results')}</h1>
        </div>
      )}

      {loading && <Loading fullcover />}

      <Modal
        size="large"
        className={styles.wrapper}
        onClose={closeModalHandler}
        isOpen={isOpen}
        actions={
          <>
            <Button onClick={resetSearchOptionsHandler}>Clear</Button>
            <Button onClick={applySearchOptionsHandler} variant="contained">
              Apply
            </Button>
          </>
        }
      >
        <SectionSearchOptionsMenu
          className={styles.filters}
          metatags={section?.sectionMetatags?.map(
            sectionMetatag => sectionMetatag.metatag
          )}
          filters={section?.filters}
          searchOptions={editingSearchOptions}
          onSearchOptionsUpdated={props => {
            updateSearchOptions({
              ...props,
            });
          }}
        />
      </Modal>
    </div>
  );
}
