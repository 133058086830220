import React, { useContext, useEffect } from 'react';

import { Route, useHistory } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { UserDataContext } from 'lane-shared/contexts';
import { findAccessRestrictedError, getQueryString } from 'lane-shared/helpers';
import { ContentTypeEnum } from 'constants-content';
import { getIntegrationDefinition } from 'lane-shared/helpers/integrations';
import { useContentQuery } from 'lane-shared/hooks';
import { QRCodeCheckInMode } from 'lane-shared/hooks/features/qrCodeCheckIn/useQRCodeCheckIn';
import { IntegrationProviderEnum } from 'constants-integrations';

import { MemberServiceRequestList } from 'domains/workOrder/serviceRequest/components/member/MemberServiceRequestList';

import ContentCard from 'components/cards/ContentCard';
import { ErrorPage, Loading, NavLinks } from 'components/general';
import ContentPage from 'components/lane/ContentPage';

import ContentFeatureQRCodeCheckInOut from './check-in-out';
import ContentInteractions from './interactions';
import ContentInvoice from './invoice';
import ContentIntegrationLogin from './login';
import ContentNewInteraction from './new';
import VisitEdit from './visits';
import { AttachmentProvider } from 'contexts/AttachmentContext';
import useScrollToTop from 'hooks/useScrollToTop';

import styles from './styles.scss';

export default function ContentLoader({ match, location }: any) {
  const { user } = useContext(UserDataContext);
  const history = useHistory();

  const {
    content,
    interactions,
    loading,
    error,
    getContentInteractions: refetchInteractions,
  } = useContentQuery({
    forPublic: !user,
    contentId: match.params.id,
    includeInteractions: true,
  });

  useScrollToTop([content?._id]);

  const integrationProvider: IntegrationProviderEnum | undefined =
    content?.integration?.integration?.name;

  const hasCustomUI = integrationProvider
    ? getIntegrationDefinition(integrationProvider).hasCustomUI
    : false;

  const hasShopifyIntegration = Boolean(
    content?.integration?.integration?.name ===
      IntegrationProviderEnum.ShopifyMultipass
  );

  useEffect(() => {
    if (content?.externalUrl && !hasShopifyIntegration) {
      // Content with external URLs do not have blocks to be rendered, the only exception is
      // if the shopify multipass integration is enabled.
      // Instead of showing the user a blank screen, let's leave them where they opened
      // the content link
      window.open(content?.externalUrl.url, '_blank');
      history.goBack();
    }
  }, [content?.externalUrl]);

  useEffect(() => {
    if (findAccessRestrictedError(error as Error)) {
      history.push(
        routes.restricted +
          getQueryString({
            id: match.params.id,
            type: 'content',
          })
      );
    }
  }, [error]);

  function renderContent() {
    if (content?.type === ContentTypeEnum.WorkOrder) {
      return <MemberServiceRequestList content={content} />;
    }

    return (
      <div className={styles.contentWrapper}>
        {!hasCustomUI && content?.isInteractive && (
          <NavLinks className={styles.navLinks} routes={contentRoutes} />
        )}
        <AttachmentProvider>
          <ContentPage
            content={content!}
            onInteractionCreated={() => refetchInteractions()}
          />
        </AttachmentProvider>
      </div>
    );
  }

  if (error && !content) {
    return <ErrorPage error={error} />;
  }

  if (!content || loading) {
    return <Loading />;
  }

  const contentRoutes = [
    {
      name: content?.name,
      route: routes.content.replace(':id', content?._id),
    },
    {
      name: 'History',
      route: routes.contentInteractions.replace(':id', content?._id),
    },
  ];

  if (
    [ContentTypeEnum.Notice, ContentTypeEnum.ScheduledNotice].includes(
      content.type
    )
  ) {
    return <ContentCard content={content} className={styles.noticeCard} />;
  }

  return (
    <>
      <Route
        exact
        path={routes.contentIntegrationLogin}
        render={() => <ContentIntegrationLogin content={content} />}
      />

      <Route
        exact
        path={routes.contentInteractionNew}
        render={() => <ContentNewInteraction content={content} />}
      />

      <Route
        exact
        path={routes.contentIntegrationInvoice}
        render={() => <ContentInvoice content={content} location={location} />}
      />

      <Route
        exact
        path={routes.contentInteractions}
        render={() => (
          <ContentInteractions content={content} interactions={interactions} />
        )}
      />

      <Route exact path={routes.content} render={renderContent} />
      <Route
        exact
        path={routes.contentRenderer}
        render={() => (
          <ContentPage
            content={content}
            onInteractionCreated={() => refetchInteractions()}
          />
        )}
      />

      <Route
        exact
        path={routes.contentFeatureQRCodeCheckIn}
        render={() => (
          <>
            {renderContent()}
            <ContentFeatureQRCodeCheckInOut
              content={content}
              mode={QRCodeCheckInMode.CHECK_IN}
            />
          </>
        )}
      />

      <Route
        exact
        path={routes.contentFeatureQRCodeCheckOut}
        render={() => (
          <>
            {renderContent()}
            <ContentFeatureQRCodeCheckInOut
              content={content}
              mode={QRCodeCheckInMode.CHECK_OUT}
            />
          </>
        )}
      />

      <Route
        exact
        path={routes.visitEdit}
        render={({ match }) => (
          <VisitEdit params={match.params} content={content} />
        )}
      />
    </>
  );
}
