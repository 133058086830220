import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { getCurrencyByGeoLocation } from 'lane-shared/helpers';
import { useChannelPaymentAccounts } from 'lane-shared/hooks';
import { PaymentProviderEnum } from 'constants-payments';

import Option from 'components/form/Option';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import { AdminPage } from 'components/layout';
import PlacePayPaymentAccount from 'components/payments/PlacePayPaymentAccount';
import StripePaymentAccount from 'components/payments/StripePaymentAccount';

import styles from './styles.scss';

const createMutation = gql`
  mutation createPaymentAccount($paymentAccount: PaymentAccountInput!) {
    payments {
      createPaymentAccount(paymentAccount: $paymentAccount) {
        _id
      }
    }
  }
`;

export default function ChannelPaymentAccounts({ channel }: any) {
  const [creating, setCreating] = useState(false);
  const [createError, setCreateError] = useState<Error | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [accountType, setAccountType] = useState<PaymentProviderEnum | null>(
    null
  );
  const { t } = useTranslation();

  const {
    paymentAccounts,
    loading,
    updating,
    error,
    updatePaymentAccount,
    deletePaymentAccount,
    createPaymentAccountRule,
    updatePaymentAccountRule,
    deletePaymentAccountRule,
  } = useChannelPaymentAccounts({
    channelId: channel?._id,
  });

  async function addNewAccount() {
    setIsOpen(false);
    setCreating(true);
    setCreateError(null);

    try {
      await getClient().mutate({
        refetchQueries: ['getChannelPaymentAccounts'],
        mutation: createMutation,
        variables: {
          paymentAccount: {
            name: `New ${accountType}`,
            type: accountType,
            channel: {
              _id: channel._id,
            },
          },
        },
      });
    } catch (err) {
      setCreateError(err);
    }

    setCreating(false);
  }

  const currency = getCurrencyByGeoLocation({
    latitude: channel?.address.geo[1],
    longitude: channel?.address.geo[0],
  });

  return (
    <AdminPage className={styles.ChannelPaymentAccounts}>
      <ControlMenu className={styles.menu}>
        <hr />
        <Button
          loading={loading || creating}
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          {t('abp.paymentAccount.addAPaymentAccount')}
        </Button>
      </ControlMenu>

      <ErrorMessage error={error || createError} />

      {/* @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value. */}
      {paymentAccounts.map((paymentAccount: any) => {
        switch (paymentAccount.type) {
          case PaymentProviderEnum.Stripe:
            return (
              <StripePaymentAccount
                key={paymentAccount._id}
                loading={loading || updating}
                className={styles.account}
                channel={channel}
                paymentAccount={paymentAccount}
                currency={currency}
                onPaymentAccountRuleAdd={(rule: any) =>
                  createPaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountRuleDelete={(rule: any) =>
                  deletePaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountRuleSave={(rule: any) =>
                  updatePaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountSave={(paymentAccount: any) =>
                  updatePaymentAccount(paymentAccount)
                }
                onPaymentAccountDelete={deletePaymentAccount}
              />
            );
          case PaymentProviderEnum.PlacePay:
            return (
              <PlacePayPaymentAccount
                key={paymentAccount._id}
                loading={loading || updating}
                className={styles.account}
                channel={channel}
                paymentAccount={paymentAccount}
                currency={currency}
                onPaymentAccountRuleAdd={(rule: any) =>
                  createPaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountRuleDelete={(rule: any) =>
                  deletePaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountRuleSave={(rule: any) =>
                  updatePaymentAccountRule(paymentAccount, rule)
                }
                onPaymentAccountSave={(paymentAccount: any) =>
                  updatePaymentAccount(paymentAccount)
                }
              />
            );
        }
      })}

      <ModalBackground
        className={styles.background}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <ResizableWindow
          className={styles.window}
          onClose={() => setIsOpen(false)}
          name="addPaymentAccount"
          showHeader
          autoHeight
        >
          <div className={styles.picker}>
            <Option
              className={styles.option}
              selected={accountType === PaymentProviderEnum.Stripe}
              onClick={() => setAccountType(PaymentProviderEnum.Stripe)}
              hasIcon
            >
              <h2>{t('abp.paymentAccount.creditCardWithStripe')}</h2>
            </Option>
            <Option
              className={styles.option}
              selected={accountType === PaymentProviderEnum.PlacePay}
              onClick={() => setAccountType(PaymentProviderEnum.PlacePay)}
              hasIcon
            >
              <h2>{t('abp.paymentAccount.bankAccountWithPlacePay')}</h2>
            </Option>
          </div>

          <ControlMenu>
            <hr />
            <Button onClick={() => setIsOpen(false)}>{t('abp.cancel')}</Button>

            <Button
              disabled={!accountType}
              variant="contained"
              onClick={addNewAccount}
            >
              {t('abp.ok')}
            </Button>
          </ControlMenu>
        </ResizableWindow>
      </ModalBackground>
    </AdminPage>
  );
}
