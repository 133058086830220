import React, { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import signInMutation from 'lane-shared/graphql/user/signIn';
import { emitter, isThisError, pause } from 'lane-shared/helpers';
import { UserLoginTypeEnum } from 'constants-user';

import TransitionPage from './Signup/TransitionPage';
import setSharedCookiesForWebView from 'helpers/setSharedCookiesForWebView';

type Props = {
  history: object;
  match: object;
};

function ClaimMagicLink({ history, match }: Props) {
  const [signIn] = useMutation(signInMutation);
  const { verify, guid } = (match as any).params;
  const [, setCookie] = useCookies(['jti', 'token']);
  const { t } = useTranslation();

  useEffect(() => {
    let effectCancelled = false;

    async function claimLink() {
      try {
        await pause(2000); // allows the user to actually see the loading screen
        const { data } = await signIn({
          variables: {
            loginKey: guid,
            password: verify,
            loginType: UserLoginTypeEnum.MagicLink,
          },
        });

        emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
          authToken: data.signIn,
        });

        await setSharedCookiesForWebView(
          data?.signIn?.token,
          data?.signIn?.jti,
          setCookie
        );
      } catch (err) {
        if (!isThisError(err, Error)) {
          throw err;
        }

        await window.Alert.alert({
          subTitle: t('web.login.magicLink.error.subtitle'),
          message: t(err.message),
          confirmText: t('Close'),
        });
      }

      if (!effectCancelled) {
        (history as any).replace(routes.home);
      }
    }

    claimLink();

    return () => {
      effectCancelled = true;
    };
  }, []);

  return <TransitionPage isOpen />;
}

export default ClaimMagicLink;
