import React, { useState, useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import { getClient } from 'lane-shared/apollo';
import { ThemeContext } from 'lane-shared/contexts';
import { errorCodes } from 'activate-errors';
import {
  createUserIntegration,
  discoverUserIntegrations,
} from 'lane-shared/graphql/user';
import { UserLoginTypeEnum } from 'constants-user';

import AngusEmail from 'static/img/angus-email.png';

import { history } from '../../../helpers';
import RadioGroup from '../../form/RadioGroup';
import ValidatedInput from '../../form/ValidatedInput';
import Button from '../../general/Button';
import ErrorMessage from '../../general/ErrorMessage';
import Image from '../../general/Image';
import Loading from '../../general/Loading';

import styles from './EmailForm.scss';

const validator = yup.string().email().nullable().required();

const NOT_SELECTED = 'not-selected';

export default function EmailForm({ content }: any) {
  const channelIntegrationId = content.integration._id;
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const { data, loading: queryLoading } = useQuery(discoverUserIntegrations, {
    client: getClient(),
    variables: {
      channelIntegrationId,
    },
  });

  const discoveredLogins = useMemo(
    () => data?.discoverUserIntegrations || [],
    [data]
  );

  const [selectedLogin, setSelectedLogin] = useState(null);
  const [email, setEmail] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    validate();
  }, [email]);

  function validate() {
    try {
      validator.validateSync(email);
      setValidation(null);
    } catch (err) {
      setValidation(err);
    }
  }

  if (queryLoading) {
    return <Loading />;
  }

  async function setupEmail() {
    setLoading(true);
    setError(null);

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const userKey = selectedLogin ? selectedLogin.key : email;

    try {
      await getClient().mutate({
        mutation: createUserIntegration,
        variables: {
          channelIntegrationId,
          loginType: UserLoginTypeEnum.Email,
          addToLogins: false,
          userKey,
        },
      });

      // success

      history.goBack();
    } catch (err) {
      Sentry.captureException(err, {
        contexts: {
          contentName: content.name,
        },
      });

      if (err.message.includes(errorCodes.notFoundError.code)) {
        window.Alert.alert({
          title: t('Email not found!'),
          message: t(
            '{{- userKey}} does not seem to be valid {{- contentName}} email.  Contact your administrator to set one up.',
            {
              userKey,
              contentName: content.name,
            }
          ),
        });
      } else if (err.message.includes(errorCodes.alreadyExistsError.code)) {
        window.Alert.alert({
          title: t('Email already in use!'),
          message: t(
            '{{- userKey}} is already in use by another user.  If you think this is a mistake, please contact your administrator.',
            { userKey }
          ),
        });
      } else if (err.message) {
        setError(err);
      } else {
        window.Alert.alert({
          title: t('Whoops something went wrong!'),
          message: t('Please try again later.'),
        });
      }
    }

    setLoading(false);
  }

  const isValid = !validation || selectedLogin;
  const { settings } = content.integration;

  return (
    <div className={styles.container}>
      {error && <ErrorMessage error={error} />}

      <h2 style={{ color: theme.primary }}>{settings.name}</h2>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'alt' is missing in type '{ src: any; cla... Remove this comment to see the full error message */}
      <Image src={AngusEmail} className={styles.image} />
      <p>{settings.description}</p>

      {discoveredLogins.length > 0 && (
        <div className={styles.radios}>
          <p>
            {t(
              'We discovered your {{- name}} accounts, choose one below. Or enter a different {{- name}} account below.',
              { name: settings.name }
            )}
          </p>

          <RadioGroup
            name="emails"
            className={styles.radio}
            // @ts-expect-error ts-migrate(2322) FIXME: Object is possibly null
            selected={selectedLogin ? selectedLogin._id : NOT_SELECTED}
            schema={{
              id: '_id',
              text: 'key',
            }}
            items={[
              { _id: NOT_SELECTED, key: t('Use a different email.') },
              ...discoveredLogins,
            ]}
            onChange={(login: any) => {
              setSelectedLogin(
                login === NOT_SELECTED
                  ? null
                  : discoveredLogins.find((o: any) => o._id === login)
              );
              setHasChanged(false);
              setEmail(null);
            }}
          />
        </div>
      )}

      {!selectedLogin && (
        <ValidatedInput
          type="email"
          placeholder={t('Enter your {{- contentName}} email', {
            contentName: content.name,
          })}
          onChange={value => {
            if (selectedLogin) {
              setSelectedLogin(null);
            }

            setHasChanged(true);
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            setEmail(value);
          }}
          validation={validator}
          isPristine={!hasChanged}
          value={email}
          icon="mail"
          className={styles.input}
        />
      )}

      <Button
        disabled={loading || !isValid}
        className={styles.button}
        variant="contained"
        onClick={setupEmail}
        loading={loading}
      >
        {t('Submit')}
      </Button>
      {settings.supportEmail && (
        <p>
          Don&apos;t have a {settings.name} account?{' '}
          <a href={`mailto:${settings.supportEmail}`}>
            {t('Contact your administrator.')}
          </a>
        </p>
      )}
    </div>
  );
}
