import React from 'react';

import { Dropdown, Input, TextArea } from 'components';
import { useTranslation } from 'react-i18next';

import {
  Currency,
  currencyToPaymentCurrency,
  CreditBundle,
  TaxAmountType,
} from 'lane-shared/domains/billingPayments/types';
import { TAX_TYPE_OPTIONS } from 'lane-shared/domains/billingPayments/constants';
import styles from './styles.scss';
import { isNumberTwoDecimalPlaces, isNumberThreeDecimalPlaces } from '../products-services/helpers/isNumberTwoDecimalPlaces';

export function CreateCreditBundleFormInputFields({
  creditBundle,
  updateCreditBundle,
  currency,
}: {
  creditBundle: CreditBundle;
  updateCreditBundle: (creditBundle: CreditBundle) => void;
  currency: Currency;
}) {
  const { t } = useTranslation();
  const paymentCurrency = currencyToPaymentCurrency(currency);

  const zeroOrNull = (v: string) => {
    if (v === '') {
      return null;
    }

    return Number(v);
  };

  const rateInputError = () => {
    if (!isNumberTwoDecimalPlaces(creditBundle.rate)) {
      return [t('abp.productsServices.createProduct.max2Decimal')];
    }

    return null;
  };

  const taxInputError = () => {
    if (creditBundle.taxType ===  TaxAmountType.TaxAmountTypeFixed) {
      if (!isNumberTwoDecimalPlaces(creditBundle.taxValue)) {
        return [t('abp.productsServices.createProduct.max2Decimal')];
      }
    } else if (creditBundle.taxType === TaxAmountType.TaxAmountTypePercentage) {
      if (!isNumberThreeDecimalPlaces(creditBundle.taxValue)) {
        return [t('abp.productsServices.createProduct.max3Decimal')];
      }
    }

    return null;
  };

  return (
    <div className={styles.createCreditBundleInputFields}>
      <Input
        className={styles.inputSection}
        fieldName="name"
        label={t('abp.creditBundle.createCreditBundle.name')}
        fixedLabel
        isRequired
        value={creditBundle.name}
        onChange={v => updateCreditBundle({ ...creditBundle, name: v })}
        testId="creditBundleNameInput"
      />
      <div className={styles.description}>
        <label htmlFor="creditBundleDescription" className={styles.inputLabel}>
          {t('abp.creditBundle.createCreditBundle.description')}
        </label>
        <TextArea
          className={styles.inputSection}
          value={creditBundle.description}
          onChange={v =>
            updateCreditBundle({ ...creditBundle, description: v })
          }
          minRows={3}
          testId="creditBundleDescriptionInput"
        />
      </div>
      <Input
        className={`${styles.inputSection} ${styles.shortInput}`}
        fieldName="numberOfCredits"
        label={t('abp.creditBundle.createCreditBundle.numberOfCredits')}
        fixedLabel
        isRequired
        value={creditBundle.numberOfCredits}
        onClear={() =>
          updateCreditBundle({ ...creditBundle, numberOfCredits: null })
        }
        onChange={v =>
          updateCreditBundle({
            ...creditBundle,
            numberOfCredits: zeroOrNull(v),
          })
        }
        testId="creditBundleNumberOfCreditsInput"
      />
      <div className={styles.inputSection}>
        <div className={styles.lineGroup}>
          <Input
            className={styles.shortInput}
            fieldName="rate"
            label={t('abp.creditBundle.createCreditBundle.rate')}
            fixedLabel
            isRequired
            type="number"
            value={creditBundle.rate}
            onClear={() => updateCreditBundle({ ...creditBundle, rate: null })}
            onChange={v =>
              updateCreditBundle({ ...creditBundle, rate: zeroOrNull(v) })
            }
            testId="creditBundleRateInput"
            input={{ min: 1, step: '0.01' }}
            error={rateInputError()}
          />
          <div className={styles.CurrencyText}>{paymentCurrency}</div>
        </div>
      </div>
      <div className={styles.inputSection}>
        <label htmlFor="creditBundleTax" className={styles.inputLabel}>
          {t('abp.creditBundle.createCreditBundle.tax')}
        </label>
        <div className={styles.lineGroup}>
          <Dropdown
            className={styles.shortInput}
            items={TAX_TYPE_OPTIONS}
            value={creditBundle.taxType}
            onChange={option =>
              updateCreditBundle({ ...creditBundle, taxType: option.value })
            }
            testId="creditBundleTaxTypeDropdown"
          />
          <Input
            className={styles.taxValue}
            fieldName="taxValue"
            type="number"
            value={creditBundle.taxValue || 0}
            disabled={
              creditBundle.taxType === TaxAmountType.TaxAmountTypeUnknown
            }
            icon={
              creditBundle.taxType === TaxAmountType.TaxAmountTypePercentage
                ? 'Percent'
                : ''
            }
            iconRight
            onChange={v => {
              const value = zeroOrNull(v) === null ? 0 : Number(v);

              updateCreditBundle({
                ...creditBundle,
                taxValue: value,
              });
            }}
            testId="creditBundleTaxValueInput"
            error={taxInputError()}
          />
        </div>
      </div>
    </div>
  );
}
