import { UserLoginProviderEnum } from 'constants-user';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import usePersonalizedSSOEnabled from '../usePersonalizedSSOEnabled';
import { UserLoginMethod } from './useExistingOAuthUserLogins';

type Props = {
  whitelabel: WhiteLabelType;
  oAuthUserLoginMethods: UserLoginMethod[];
};

export default function useOAuthConfigForUserLogin({
  whitelabel,
  oAuthUserLoginMethods,
}: Props) {
  const isPersonalizedSSOLoginEnabled = usePersonalizedSSOEnabled();
  const whitelabelOAuthConfig = whitelabel.oAuthConfig || [];

  if (!isPersonalizedSSOLoginEnabled) {
    return whitelabelOAuthConfig;
  }

  const hasUnknownProvider = oAuthUserLoginMethods.find(({ provider }) => {
    const unknownProvider = !provider;

    return unknownProvider;
  });

  if (hasUnknownProvider) {
    return whitelabelOAuthConfig;
  }

  const availableSSOForUser = oAuthUserLoginMethods.map(
    login => login.provider
  );

  return whitelabelOAuthConfig.filter(oAuthConfig =>
    availableSSOForUser.includes(oAuthConfig.provider as UserLoginProviderEnum)
  );
}
