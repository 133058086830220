import { isNumberTwoDecimalPlaces } from "./isNumberTwoDecimalPlaces";

export function isRateValueValid(rate: string | undefined): boolean {
  
  return !rate || 
        ( !!rate &&
          Number(rate) >= 0 &&
          isNumberTwoDecimalPlaces(parseFloat(rate))
        );
}
