import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import { Channel } from 'packages/lane-shared/types/ChannelType';

import MediaEdit from '../../../../home/me/media/edit';
import Media from '../../media';

/**
 * @TODO: bring in the Media components in this folder
 * @TODO: verify all links are working
 */
type Props = {
  channel?: Channel;
};

export default function MediaLibraryIndex({ channel }: Props) {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path} component={Media} />
      <Route
        exact
        path={`${path}/:mediaId/edit`}
        render={({ history, match }) => (
          <MediaEdit match={match} history={history} channel={channel} />
        )}
      />
    </>
  );
}
