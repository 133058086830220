import React from 'react';

import { useTranslation } from 'react-i18next';

import { SectionEditProps } from 'lane-shared/types/sections/SectionType';
import { SectionTypeEnum } from 'constants-content';
import { S } from 'components/typography';
import { GeneralInfo } from './GeneralInfo';
import styles from './styles.scss';
import { SectionContentsRules } from './SectionContentsRules';

export function SectionForm({
  channel,
  section,
  validation = null,
  onSectionUpdated = () => null,
}: SectionEditProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.pageWrapper}>
      <S mt={5} variant="secondary">
        {t('web.admin.content.sections.edit.description')}
      </S>

      <GeneralInfo
        channel={channel}
        section={section}
        validation={validation}
        onSectionUpdated={onSectionUpdated}
      />
      {section.type === SectionTypeEnum.Dynamic && (
        <SectionContentsRules
          channel={channel}
          section={section}
          validation={validation}
          onSectionUpdated={onSectionUpdated}
        />
      )}
    </div>
  );
}
