import React from 'react';

import styles from './Billing.scss';
import { ChargeManager } from 'domains/billingAndPayments/components/ChargeManager';
import { UserType } from 'lane-shared/types/User';
import {
  ChannelServiceRequestFeaturesType,
  Channel,
} from 'lane-shared/types/ChannelType';
import {
  ExternalEntityType,
  ExternalPayerType,
  Charge,
} from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'uuid-encoding';

type Props = {
  channel: Channel;
  user: UserType;
  serviceRequestFeatures: ChannelServiceRequestFeaturesType;
  serviceRequestData: any;
  onSubmitCharge: () => void;
  testId?: string;
};

export function BillingTab({
  serviceRequestFeatures,
  serviceRequestData,
  channel,
  user,
  onSubmitCharge,
  testId,
}: Props) {
  const handleSubmitCharge = (charge: Charge) => {
    if (charge.amount > 0) {
      onSubmitCharge();
    }
  };

  const chargeDescription = `${serviceRequestData.category} - ${serviceRequestData.issue}`;

  const metadata = {
    entity_display_id: convertToUUID(serviceRequestData.serviceRequestId),
    entity_display_type: serviceRequestData.category,
  };

  return (
    <div className={styles.BillingTab} data-test={testId}>
      {serviceRequestFeatures.updateServiceRequest && (
        <ChargeManager
          channel={channel}
          user={user}
          externalEntityType={ExternalEntityType.EXTERNAL_ENTITY_TYPE_WORKORDER}
          externalPayerId={serviceRequestData.company?.id}
          externalPayerType={
            ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY
          }
          chargeName={serviceRequestData.userFriendlyID}
          externalEntityId={convertToUUID(serviceRequestData.serviceRequestId)}
          onSubmitCharge={handleSubmitCharge}
          chargeDescription={chargeDescription}
          metadata={metadata}
        />
      )}
    </div>
  );
}
