import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  PriceException: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: spacing[2],
  },
  PriceExceptionIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    marginRight: spacing[1],
  },
  PriceExceptionTextContainer: {
    color: colors.neutral.grey900,
    fontSize: 11,
  },
  PriceExceptionLabel: {
    marginBottom: spacing[1],
  },
  PriceExceptionText: {
    display: 'flex',
    flexDirection: 'row',
  },
  ExclamationCiricle: {
    paddingTop: spacing[2],
  },
  Arrow: {
    paddingTop: 2,
  },
  OriginalRate: {
    marginRight: spacing[1],
  },
});
