import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import {
  PIN_CIRCLE,
  PIN_IMAGE,
  PIN_SQUARE,
  PIN_STANDARD,
} from 'constants-content';
import {
  useChannelSectionsQuery,
  useFlag,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { SortPropertyType } from 'lane-shared/types/blocks/Sort';
import { FeatureFlag } from 'constants-flags';
import { cloneDeep } from 'lodash';

import ContentImagePin from 'components/lane/ContentImagePin';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import EmptyState from '../../../general/EmptyState';
import Loading from '../../../general/Loading';
import CirclePin from '../../../lane/CirclePin';
import ContentPin from '../../../lane/ContentPin';
import SquarePin from '../../../lane/SquarePin';
import styleMap from '../styleMap';
import ListHeader from './components/ListHeader';

import styles from './SectionsOnChannelBlock.scss';

type BlockProps = WebBlockProps & {
  channel: Channel;
  listView: string;
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  sort: SortPropertyType;
};

export default function SectionsOnChannelBlock({
  style,
  className,
  channel,
  listView,
  header,
  autoHide,
  showViewAll,
  theme,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const props = useEditModeProps(otherProps);
  const { translate } = useMultiLanguage();
  const { sections: initialSections, loading } = useChannelSectionsQuery({
    channelId: channel?._id,
    pollInterval: POLL_INTERVAL,
  });

  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  if (
    !props['data-is-edit-mode'] &&
    autoHide &&
    !loading &&
    initialSections.length === 0
  ) {
    return null;
  }

  let sections = initialSections;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    sections = translate({
      model: cloneDeep(sections),
      columns: ['name'],
      previewLanguage: props['data-preview-language'],
    });
  }

  function renderSections() {
    if (loading && !sections.length) {
      return <Loading />;
    }

    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
    if (!sections?.length > 0) {
      return (
        <EmptyState showIcon={false}>
          {t(
            'web.admin.channel.content.layout.editor.components.sectionsChannelBlock.noResults'
          )}
        </EmptyState>
      );
    }

    return sections?.map((section: any) => {
      const route = routes.section.replace(':sectionId', section._id);

      switch (listView) {
        case PIN_CIRCLE:
          return (
            <Link key={section._id} to={route}>
              <CirclePin content={section} />
            </Link>
          );
        case PIN_SQUARE:
          return (
            <Link key={section._id} to={route}>
              <SquarePin content={section} className={styles.contentPin} />
            </Link>
          );
        case PIN_IMAGE:
          return (
            <Link key={section?._id} to={route}>
              <ContentImagePin
                className={styles.newContentPin}
                content={section}
              />
            </Link>
          );
        case PIN_STANDARD:
        default:
          return (
            <Link key={section._id} to={route}>
              <ContentPin className={styles.contentPin} content={section} />
            </Link>
          );
      }
    });
  }

  return (
    <div
      className={cx(styles.SectionsOnChannelBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <ListHeader
        theme={theme}
        totalItems={sections.length}
        currentItems={sections.length}
        header={header}
        viewAll={showViewAll}
      />
      <div className={styles.content}>{renderSections()}</div>
      <div className={styles.wrapper} />
    </div>
  );
}
