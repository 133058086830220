import { MarkupType } from "lane-shared/domains/billingPayments/types";
import { isNumberTwoDecimalPlaces } from "./isNumberTwoDecimalPlaces";

export function isMarkupValueValid(markup: MarkupType | undefined): boolean {
  
  return !markup ||
      (!!markup.value &&
        Number(markup.value) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(markup.value))
      );
}
