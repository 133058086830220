import React, { useEffect, useState, useContext } from 'react';

import { ErrorMessage, Loading } from 'components';
import gql from 'graphql-tag';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getChannel } from 'lane-shared/graphql/query';
import getAudience from 'lane-shared/graphql/query/getAudience';
import getContentByAudience from 'lane-shared/graphql/query/getContentByAudience';
import getGroupRole from 'lane-shared/graphql/query/getGroupRole';
import {
  useContentTargetingAudienceSize,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { GroupRole } from 'lane-shared/types/GroupRole';
import { UserType } from 'lane-shared/types/User';
import { Audience } from 'lane-shared/types/audiences/Audience';
import { TargetingRule } from 'lane-shared/types/audiences/TargetingRule';
import { ContentType } from 'lane-shared/types/content/Content';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';

import { ChipSelect, ChipStyle } from 'components/ads';
import { Table } from 'design-system-web';
import TabStrip from 'components/general/TabStrip';
import { BreadCrumbs } from 'components/lds';
import { H3 } from 'components/typography';
import { formatDateWithUserLocale } from 'helpers';

import { AudienceActivityHistory } from './components/AudienceActivityHistory';
import { DetailsTab } from './components/DetailsTab';

import style from './ShowAudience.scss';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Props = {
  channel: Channel;
};

const DEBOUNCE_INTERVAL = 500;

type ContentByAudienceResponse = {
  items: Partial<ContentType>[];
  pageInfo: {
    total: number;
  };
};

enum Tabs {
  Details = 'details',
  Content = 'content',
}

export function ShowAudience({ channel }: Props) {
  const { t } = useTranslation();

  const tabs = [
    {
      value: Tabs.Details,
      label: t('web.admin.audience.tabs.details'),
    },

    {
      value: Tabs.Content,
      label: t(`web.admin.audience.tabs.content`),
    },
  ];
  const { user } = useContext(UserDataContext);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Details);
  const [error] = useState('');
  const [audience, setAudience] = useState<Audience | undefined>();
  const [teams, setTeams] = useState<GroupRole[] | undefined>();
  const [channels, setChannels] = useState<Channel[] | undefined>();
  const { audienceTracker } = useAudienceAnalytics({ audience, channels });
  const { translate } = useMultiLanguage();

  const [content, setContent] = useState<
    ContentByAudienceResponse | undefined
  >();
  const [createdByUser, setCreatedByUser] = useState<
    Partial<UserType> | undefined
  >();
  const [updatedByUser, setUpdatedByUser] = useState<
    Partial<UserType> | undefined
  >();

  const audiences = audience ? [{ id: audience.id }] : [];

  const [reachCount] = useContentTargetingAudienceSize({ audiences });

  const [query] = useQueryString({
    page: 0,
    search: '',
    keyword: '',
    sortBy: '_updated',
    sortDirection: 'desc',
    pageSize: 10,
  });

  const { audienceId } = useParams() as Record<string, string>;
  const location = useLocation();

  useEffect(() => {
    if (audience && channels) {
      audienceTracker.View.SingleAudience(audience);
    }
  }, [audience?.id, JSON.stringify(channels?.map(channel => channel._id))]);

  const doFetch = useDebouncedCallback(async () => {
    await fetchAudience();
    fetchTeams();
    fetchChannels();
    fetchContent();
    fetchUsers();
  }, DEBOUNCE_INTERVAL).callback;

  const fetchAudience = async () => {
    if (!channel) return;

    const audience = (
      await getClient().query({
        fetchPolicy: 'network-only',
        query: getAudience,
        variables: {
          audienceId,
          channelId: channel._id,
        },
      })
    ).data.audience;

    setAudience(audience);
  };

  const fetchTeams = async () => {
    if (!audience) return;

    const teams = (
      await Promise.all(
        audience.groupRoleTargetingRules.map(
          async (groupRoleTargetingRule: TargetingRule) => {
            try {
              return (
                await getClient().query({
                  fetchPolicy: 'network-only',
                  query: getGroupRole,
                  variables: {
                    id: groupRoleTargetingRule.groupRoleId,
                  },
                })
              ).data.groupRole;
            } catch {
              return undefined;
            }
          }
        )
      )
    ).filter(team => team);

    setTeams(
      teams.sort((a: GroupRole, b: GroupRole) => a.name!.localeCompare(b.name!))
    );
  };

  const fetchChannels = async () => {
    if (!audience) return;

    const channels = (
      await Promise.all(
        audience.channelTargetingRules.map(
          async (channelTargetingRule: any) => {
            let channelResponse;

            try {
              channelResponse = await getClient().query({
                fetchPolicy: 'network-only',
                query: getChannel,
                variables: {
                  id: channelTargetingRule.channelId,
                },
              });

              return channelResponse.data.channel;
            } catch {
              return undefined;
            }
          }
        )
      )
    ).filter(channel => channel);

    setChannels(
      channels.sort((a: Channel, b: Channel) => a.name!.localeCompare(b.name!))
    );
  };

  const fetchContent = async () => {
    if (!audience) return;

    const variables = {
      audienceId: audience.id,
      pagination: {
        start:
          parseInt(query.page! as string, 10) *
          parseInt(query.pageSize! as string, 10),
        perPage: parseInt(query.pageSize as string, 10),
      },
      search: {} as Record<string, any>,
    };

    if (query.sortBy && query.sortDirection) {
      variables.search.sortBy = { key: query.sortBy, dir: query.sortDirection };
    }

    if (query.keyword) {
      variables.search.name = { value: query.keyword };
    }

    const response = await getClient().query({
      fetchPolicy: 'network-only',
      query: getContentByAudience,
      variables,
    });

    const content = response?.data?.contentByAudience || {};

    if (Object.keys(content).length) {
      const translatedContent = translate({
        model: content?.items || [],
        columns: ['name'],
      });

      setContent({
        items: translatedContent,
        pageInfo: content?.pageInfo,
      });
    } else {
      setContent(content);
    }
  };

  const fetchUsers = async () => {
    if (!audience) return;

    const createdByUserResponse = await getClient().query({
      fetchPolicy: 'network-only',
      query: gql`
        query getCreatedByUser($id: UUID!) {
          user(_id: $id) {
            _id
            name
          }
        }
      `,
      variables: {
        id: audience.createdBy,
      },
    });

    setCreatedByUser(createdByUserResponse.data.user);

    const updatedByUserResponse = await getClient().query({
      fetchPolicy: 'network-only',
      query: gql`
        query getUser($id: UUID!) {
          user(_id: $id) {
            _id
            name
          }
        }
      `,
      variables: {
        id: audience.updatedBy,
      },
    });

    setUpdatedByUser(updatedByUserResponse.data.user);
  };

  useEffect(() => {
    doFetch();
  }, [
    channel?._id,
    audience?.id,
    query?.keyword,
    query?.sortBy,
    query?.sortDirection,
    query?.pageSize,
    query?.page,
  ]);

  if (!channels || !teams) {
    return (
      <div className={style.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const buildLiveDate = ({
    liveDate,
    unpublishDate,
  }: {
    liveDate?: Date | null;
    unpublishDate?: Date | null;
  }) => {
    if (!liveDate || !unpublishDate) {
      return '-';
    }

    const startDate = formatDateWithUserLocale({
      date: liveDate,
      userLocale: user?.locale,
    });

    const endDate = formatDateWithUserLocale({
      date: unpublishDate,
      userLocale: user?.locale,
    });

    return t('web.pages.portal.admin.audiences.show.contentType.liveDate', {
      startDate,
      endDate,
    });
  };

  const columns = [
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.name`,
      key: 'name',
      renderCell: (name: string, contentItem: ContentType) => (
        <Link
          target="_blank"
          className={style.link}
          to={{
            pathname: `/l/channel/${channel.slug}/admin/post/${contentItem._id}`,
            state: { from: location.pathname },
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.type`,
      key: 'type',
      renderCell: (type: string) =>
        t(`web.pages.portal.admin.audiences.show.contentType.${type}`),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.lastUpdated`,
      key: '_updated',
      renderCell: (updated: Date) =>
        formatDateWithUserLocale({
          date: updated,
          userLocale: user?.locale,
          formattingOptions: { year: 'numeric' },
        }),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.schedule`,
      key: 'liveDate',
      renderCell: (_: any, contentRow: ContentType) =>
        buildLiveDate(contentRow),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.status`,
      disableSorting: true,
      key: 'status',
      renderCell: (_: any, contentRow: ContentType) => {
        if (
          new Date().toISOString() < (contentRow.liveDate! as unknown as string)
        ) {
          return (
            <ChipSelect.NonInteractive
              key={contentRow._id}
              value="web.pages.portal.admin.audiences.show.upcoming"
              type={ChipStyle.Yellow}
            />
          );
        }

        if (
          new Date().toISOString() >=
            (contentRow.liveDate! as unknown as string) &&
          new Date().toISOString() <
            (contentRow.unpublishDate! as unknown as string)
        ) {
          return (
            <ChipSelect.NonInteractive
              key={contentRow._id}
              value="web.pages.portal.admin.audiences.show.active"
              type={ChipStyle.Green}
            />
          );
        }

        return (
          <ChipSelect.NonInteractive
            key={contentRow._id}
            value="web.pages.portal.admin.audiences.show.unpublished"
            type={ChipStyle.Grey}
          />
        );
      },
    },
  ];

  return (
    <div className={style.showAudienceContainer}>
      {error && <ErrorMessage error={error} />}
      <div className={style.topContainer}>
        <BreadCrumbs
          links={[
            {
              label: t(
                'web.pages.portal.admin.audiences.create.breadCrumbBack'
              ),
              url: `${routes.channelAdminAudiences.replace(
                ':id',
                channel?.slug!
              )}`,
            },
            {
              label: audience!.name,
            },
          ]}
        />
      </div>
      <H3 className={style.audienceName}>{audience!.name}</H3>
      <AudienceActivityHistory
        audience={audience}
        channel={channel}
        updatedByUser={updatedByUser}
        createdByUser={createdByUser}
      />
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab }}
        onSelectTab={tab => setSelectedTab(tab.value as Tabs)}
        className={style.tabStrip}
        fullWidth
      />
      {selectedTab === Tabs.Details ? (
        <DetailsTab
          audience={audience!}
          channel={channel}
          teams={teams}
          channels={channels}
          reachCount={reachCount}
        />
      ) : null}
      {selectedTab === Tabs.Content ? (
        <div className={style.tableContainer}>
          <Table
            columns={columns}
            hasKeywordFilter
            keywordFilterLabel={t`web.pages.portal.admin.audiences.show.contentTable.contentSearchText`}
            data={content?.items! as ContentType[]}
            totalRows={content?.pageInfo.total}
            pagination="server"
            queryStringsEnabled
          />
        </div>
      ) : null}
    </div>
  );
}
