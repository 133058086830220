import React from 'react';

import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  PresetContentSort,
  PresetContentSortDescription,
  PresetContentSortFriendlyName,
} from 'constants-content';

import Dropdown from '../form/Dropdown';
import IconButton from '../general/IconButton';

import styles from './PresetContentSortListItem.scss';

export default function PresetContentSortListItem({
  className,
  style,
  filters,
  filter,
  onChange,
}: any) {
  const items = Object.values(PresetContentSort)
    // only show sort options that haven't been selected elsewhere.
    .filter(f => f === filter || !filters.includes(f))
    // show friendly names for the dropdown options
    .map(value => ({
      label: PresetContentSortFriendlyName[value],
      value,
    }));

  return (
    <div
      className={cx(styles.PresetContentSortListItem, className)}
      style={style}
    >
      <div className={styles.inner}>
        <Dropdown
          items={items}
          value={filter}
          onValueChange={newValue => {
            filters[filters.findIndex((f: any) => f === filter)] = newValue;
            onChange([...filters]);
          }}
        />
        {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        <p>{PresetContentSortDescription[filter]}</p>
      </div>
      <IconButton
        className={styles.deleteButton}
        icon="times-circle"
        iconSet={ICON_SET_FONTAWESOME}
        onClick={() => onChange(filters.filter((f: any) => f !== filter))}
      />
    </div>
  );
}
