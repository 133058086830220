import React from 'react';
import { SectionEditProps } from 'lane-shared/types/sections/SectionType';
import Input from 'components/form/Input';
import { getValidationMessages } from 'lane-shared/helpers';
import { Text } from 'components/typography';
import { useTranslation } from 'react-i18next';
import { SectionDynamicQueryType } from 'lane-shared/types/sections/SectionDynamicQueryType';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import {
  SectionTypeEnum,
  ContentCategoryEnum,
  ContentTypeEnum,
} from 'constants-content';
import Radio from 'components/form/Radio';
import TextArea from 'components/form/TextArea';
import { Flex } from 'components/layout';

import styles from './styles.scss';

const SectionTypeTranslationsMap = {
  [SectionTypeEnum.Static]: {
    title: 'web.admin.content.sections.edit.type.manual.title',
    description: 'web.admin.content.sections.edit.type.manual.description',
  },
  [SectionTypeEnum.Dynamic]: {
    title: 'web.admin.content.sections.edit.type.dynamic.title',
    description: 'web.admin.content.sections.edit.type.dynamic.description',
  },
};

export function GeneralInfo({
  channel,
  section,
  validation = null,
  onSectionUpdated,
}: Pick<
  SectionEditProps,
  'channel' | 'section' | 'onSectionUpdated' | 'validation'
>) {
  const { t } = useTranslation();

  async function changeSectionType(type: any) {
    if (type === SectionTypeEnum.Dynamic) {
      try {
        const sectionName = section.name;

        await window.Alert.confirm({
          title: t('web.admin.section.changeSectionType.title', {
            sectionName,
            type,
          }),
          message: t('web.admin.section.changeSectionType.message', {
            sectionName,
            type,
          }),
          confirmText: t('web.admin.section.changeSectionType.confirmation'),
        });

        const query: SectionDynamicQueryType = {
          contentTags: [],
          contentCategories: [ContentCategoryEnum.Other],
          contentTypes: [ContentTypeEnum.Static],
          includeChildren: true,
        };

        onSectionUpdated({ type, query });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
      }
    } else {
      onSectionUpdated({ type, query: undefined });
    }
  }

  return (
    <>
      <MultiLanguageWrapper
        channel={channel}
        model={section}
        column="name"
        className={styles.marginTop}
      >
        {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => (
          <>
            <Text mb={1}>
              {labelMaker({
                label: t('web.admin.content.sections.form.nameLabel'),
                required: true,
              })}
            </Text>
            <Input
              error={
                isPrimary
                  ? getValidationMessages(validation, 'name')
                  : undefined
              }
              value={valueGetter()}
              minLength={3}
              maxLength={32}
              testId="sectionTitle"
              onChange={name =>
                onSectionUpdated({
                  ...onChangeUpdates(name),
                })
              }
            />
          </>
        )}
      </MultiLanguageWrapper>

      <MultiLanguageWrapper
        channel={channel}
        model={section}
        column="description"
        className={styles.marginTop}
      >
        {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => (
          <>
            <Text mb={1}>
              {labelMaker({
                label: t('Description'),
              })}
            </Text>
            <TextArea
              minRows={3}
              errors={
                isPrimary
                  ? getValidationMessages(validation, 'description')
                  : undefined
              }
              value={valueGetter()}
              onChange={description =>
                onSectionUpdated({
                  ...onChangeUpdates(description),
                })
              }
            />
          </>
        )}
      </MultiLanguageWrapper>

      <Text mt={5} mb={1}>
        {t('web.admin.content.sections.edit.type')}
        <span className={styles.requiredAstrisk}>*</span>
      </Text>
      <Flex gap={2}>
        {[SectionTypeEnum.Static, SectionTypeEnum.Dynamic].map(key => (
          <Radio
            key={key}
            selected={section.type}
            value={key}
            onChange={changeSectionType}
            showBorder
            text={t(SectionTypeTranslationsMap[key].title)}
            subtext={t(SectionTypeTranslationsMap[key].description)}
            doTranslate={false}
          />
        ))}
      </Flex>
    </>
  );
}
