import { useState, useEffect, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

import { config } from '../config';
import { PlatformEnum } from 'constants-activate';

// New custom attributes should be added to this "LDUserCustom" type
export type LDUserCustom = {
  kind?: string;
  key?: string;
  anonymous?: boolean;
  platform: PlatformEnum;
  appVersion: string;
  whiteLabelName?: string;
  whiteLabelId?: string;
  groupRoleIds?: string[];
  focusedChannelId?: string;
  hardwareId?: string;
  activeContentOriginChannelId?: string;
};

// Define LDUser type as LDUserCustom
export type LDUser = LDUserCustom;

export default function useLaunchDarklyUser({
  platform,
  onChange,
  appVersion,
  whiteLabelName,
  whiteLabelId,
}: {
  platform: PlatformEnum;
  onChange: (lDUser: LDUser) => void;
  appVersion?: string;
  whiteLabelName?: string;
  whiteLabelId?: string;
}) {
  const anonymousLDUser: LDUser = useMemo(
    () => ({
      kind: 'user',
      anonymous: true,
      platform,
      appVersion: appVersion ?? config.laneVersion,
      whiteLabelName,
      whiteLabelId,
    }),
    [appVersion, platform, whiteLabelName, whiteLabelId]
  );

  const [lDUser, setLDUser] = useState<LDUser>(anonymousLDUser);
  const setUser = useCallback(
    (key: string, custom: Partial<LDUserCustom> = {}) => {
      setLDUser(prevState => {
        const updatedUser = { ...prevState, ...custom };

        updatedUser.kind = 'user';
        updatedUser.anonymous = false;
        updatedUser.key = key;

        if (isEqual(updatedUser, prevState)) {
          return prevState;
        }

        return updatedUser;
      });
    },
    []
  );

  const unsetUser = useCallback(() => {
    setLDUser(anonymousLDUser);
  }, [setLDUser, anonymousLDUser]);

  const setAttribute = useCallback((field: Partial<LDUserCustom>) => {
    setLDUser(prevState => {
      const updatedUser = { ...prevState, ...field };

      if (isEqual(updatedUser, prevState)) {
        return prevState;
      }

      return updatedUser;
    });
  }, []);

  useEffect(() => {
    onChange(lDUser);
  }, [onChange, lDUser]);

  return {
    lDUser,
    setUser,
    unsetUser,
    setAttribute,
  };
}
