import React from 'react';

import { Checkbox, Icon } from 'design-system-web';
import cx from 'classnames';
import { IconButton, ContentFilterStatus, Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import { SectionTypeEnum } from 'constants-content';

import styles from './SectionContentTable.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import { SORT_INTERACTIVE } from '../../../constants';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { SectionContentType } from './types';
import type { SectionType } from 'lane-shared/types/sections/SectionType';

const SORT_ASC = 'asc';

type SectionContentTableProps = {
  channel: Channel;
  headers: Array<{ label: string; sort?: string }>;
  makeUrl: (param: Object) => string;
  onPinContent: (contentId: string) => void;
  onRemoveContent: (sectionContent: SectionContentType) => void;
  onUnpinContent: (contentId: string) => void;
  reverseOrder: string;
  section: SectionType;
  sectionContent: Array<SectionContentType>;
  selectedOrder: string;
  selectedSort: string;
  loading?: boolean;
};

function SectionContentTable({
  headers,
  sectionContent,
  channel,
  selectedSort,
  selectedOrder,
  reverseOrder,
  section,
  loading,
  makeUrl,
  onRemoveContent,
  onPinContent,
  onUnpinContent,
}: SectionContentTableProps) {
  const { t } = useTranslation();
  const isPinningEnabled = useFlag(FeatureFlag.PinningSectionContent, false);

  const sortContentForTable = (
    sectionContentProp: Array<SectionContentType>
  ): Array<SectionContentType> => {
    return sectionContentProp?.sort((a, b) => {
      if (selectedSort === SORT_INTERACTIVE) {
        if (a.content.isInteractive && b.content.isInteractive) {
          return 0;
        }

        if (a.content.isInteractive) {
          return -1;
        }

        return 1;
      }

      return (
        // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
        a.content[selectedSort].localeCompare(b.content[selectedSort]) *
        (selectedOrder === SORT_ASC ? 1 : -1)
      );
    });
  };

  const pinnedSectionContent = sectionContent.filter(item => item.isPinned);
  const unpinnedSectionContent = sectionContent.filter(item => !item.isPinned);

  let sectionContentSorted = [];

  if (isPinningEnabled) {
    sectionContentSorted = sortContentForTable(pinnedSectionContent).concat(
      sortContentForTable(unpinnedSectionContent)
    );
  } else {
    sectionContentSorted = sectionContent;
  }

  return (
    <table className={styles.contentSectionTable}>
      <thead>
        <tr>
          {headers.map((header: any) => (
            <th key={header.label}>
              <Flex align="center">
                <span>{header.label}</span>
                {header.sort && (
                  <Link
                    to={makeUrl({
                      sort: header.sort,
                      order: reverseOrder,
                    })}
                  >
                    <IconButton
                      inverted
                      icon={
                        selectedOrder === 'asc'
                          ? 'sort-amount-down-alt'
                          : 'sort-amount-up'
                      }
                      className={cx(styles.sortButton, {
                        [styles.selected]: selectedSort === header.sort,
                      })}
                      selected={selectedSort === header.sort}
                    />
                  </Link>
                )}
              </Flex>
            </th>
          ))}
          {section?.type === SectionTypeEnum.Static && (
            <th>{t`web.admin.content.sections.view.sectionContentTable.remove`}</th>
          )}
          {isPinningEnabled && section?.type === SectionTypeEnum.Dynamic && (
            <th data-test="SectionContentTablePinColumn">{t`Pin`}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {sectionContentSorted.map((sectionContent: any) => (
          <tr key={sectionContent._id}>
            <td>{simpleDate(sectionContent.content._created)}</td>
            <td>{fromNow(sectionContent.content._updated)}</td>
            <td>
              <Link
                to={`/l/channel/${channel.slug}/admin/post/${sectionContent.content._id}`}
              >
                {sectionContent.content.name}
              </Link>
            </td>
            <td style={{ width: '20em' }}>
              <span style={{ fontSize: '0.8em' }}>
                {sectionContent.content.description}
              </span>
            </td>
            <td>
              {sectionContent.content.isInteractive && <Icon name="check" />}
            </td>
            <td>{sectionContent.content.tags.join(', ')}</td>
            <td>
              <ContentFilterStatus
                content={sectionContent.content}
                metatags={section.sectionMetatags?.map(
                  ({ metatag }: any) => metatag
                )}
                filters={section.filters}
                sorts={section.sorts}
              />
            </td>
            {section?.type === SectionTypeEnum.Static && (
              <td>
                <IconButton
                  inverted
                  className={styles.removeButton}
                  icon="times"
                  disabled={loading}
                  onClick={() => onRemoveContent(sectionContent)}
                />
              </td>
            )}
            {isPinningEnabled && section?.type === SectionTypeEnum.Dynamic && (
              <td>
                <Checkbox
                  selected={sectionContent.isPinned}
                  value={1}
                  testId="pinningCheckbox"
                  onChange={() => {
                    if (sectionContent.isPinned) {
                      onUnpinContent(sectionContent.content._id);
                    } else {
                      onPinContent(sectionContent.content._id);
                    }
                  }}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SectionContentTable;
