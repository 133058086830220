import { TaxType } from "lane-shared/domains/billingPayments/types";
import { isNumberThreeDecimalPlaces, isNumberTwoDecimalPlaces } from "./isNumberTwoDecimalPlaces";

export function isTaxValueValid(tax: TaxType | undefined): boolean {
  
  return !tax ||
      (!!tax.value &&
        Number(tax.value) >= 0 &&
        ( 
          (tax.type === "TAX_AMOUNT_TYPE_PERCENTAGE" && isNumberThreeDecimalPlaces(parseFloat(tax.value)))
          ||
          (tax.type === "TAX_AMOUNT_TYPE_FIXED" && isNumberTwoDecimalPlaces(parseFloat(tax.value)))
        )
      );
}
