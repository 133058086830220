import React, { useState, useContext } from 'react';

import {
  ErrorMessage,
  ControlMenu,
  Button,
  Loading,
  ModalBackground,
} from 'components';
import { MemoryHistory } from 'history';
import { ValidationError } from 'yup';
import * as yup from 'yup';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { createChannel } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';

import {
  validateChannelBase,
  validateChannelInfo,
} from 'lane-shared/validation/channel';

import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';
import { AdminPage } from 'components/layout';

import styles from './styles.scss';
import { Channel } from 'lane-shared/types/ChannelType';
import {
  CreateChannelMutation,
  CreateChannelMutationVariables,
} from 'graphql-query-contracts';

import { constructChannel, toChannelInput } from 'lane-shared/helpers/channel';

const validationSchema = yup
  .object()
  .concat(validateChannelBase)
  .concat(validateChannelInfo);

export default function NewChannel({ history }: { history: MemoryHistory }) {
  const { user } = useContext(UserDataContext);

  const item = 'organization';

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState<ValidationError | null>(null);
  const [channel, setChannel] = useState(() =>
    constructChannel({ userId: user?._id, type: undefined })
  );

  async function validate() {
    try {
      await validationSchema.validate(channel, { abortEarly: false });
      setValidation(null);

      return true;
    } catch (error: any) {
      setValidation(error);

      return false;
    }
  }

  function onChannelUpdated(updatedChannel: Partial<Channel>) {
    setChannel({
      ...channel,
      ...updatedChannel,
    });

    if (validation) {
      validate();
    }
  }

  function onCreateChannel() {
    async function validateAndCreate() {
      if (!(await validate())) return;

      setLoading(true);
      setError(null);

      try {
        await pause();

        await getClient().mutate<
          CreateChannelMutation,
          CreateChannelMutationVariables
        >({
          mutation: createChannel,
          variables: { channel: toChannelInput(channel) },
        });

        history.push(`/l/channel/${channel.slug}/admin`);
      } catch (err: any) {
        setError(err);
        window.Alert.alert({
          title: `Error creating ${item}.`,
          message: `This ${item} was not created, please see the error below and try again.`,
          error: err,
        });
      }

      setLoading(false);
    }

    validateAndCreate();
  }

  return (
    <AdminPage className={styles.NewChannel}>
      <ControlMenu className={styles.controlMenu}>
        <Button
          variant="contained"
          onClick={onCreateChannel}
          disabled={loading}
          testId="channelCreationButton"
        >
          Create
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      <section>
        <ChannelInfoEdit
          heading="Create new organization"
          validation={validation}
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          shouldShowType
          channelForDataIdentifiers={channel}
          forCreate
        />
      </section>

      <ModalBackground isOpen={loading} onClose={() => {}}>
        <div className={styles.loadingModal}>
          <h3>Creating {item}</h3>
          <p>This may take a few minutes...</p>
          <Loading />
        </div>
      </ModalBackground>
    </AdminPage>
  );
}
