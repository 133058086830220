import { ApolloError, NetworkStatus } from '@apollo/client';

import useLocation from 'lane-shared/hooks/location/useLocation';

import { CIRCUMFERENCE_OF_EARTH } from '../../helpers/constants';
import useChannelsByRelationshipQuery, {
  ItemType,
} from '../../hooks/useChannelsByRelationshipQuery';
import { Channel } from '../../types/ChannelType';
import { StringSearchTypeEnum } from 'constants-activate';
import { CHANNEL_TYPE_KEYS } from 'constants-channel';

type Props = {
  parentCompany: Pick<Channel, '_id'> | null;
  parentBuilding: Pick<Channel, '_id'> | null;
  search: string;
  oAuth: any;
  skip: boolean;
};

export type CompanyLocationsSearchResult = {
  locations: ItemType[];
  loading: boolean;
  called: boolean;
  error: ApolloError | null | undefined;
  isSignUpBlocked: boolean;
  refetch: () => void;
  fetchNextPage: () => void;
  networkStatus: NetworkStatus;
};

export default function useCompanyLocationsSearch({
  parentCompany,
  parentBuilding,
  search,
  oAuth,
  skip,
}: Props): CompanyLocationsSearchResult {
  const { location } = useLocation();
  let relatedTo: any = {
    // don't want any sub channels included
    isSub: false,
    // we only want locations channels, so they will have something set
    // on the parent channel
    parent: {
      name: {
        type: StringSearchTypeEnum.NotEqual,
        value: null,
      },
    },
    // only show us property channels
    type: { any: [CHANNEL_TYPE_KEYS.Property] },
    // search for what the user entered, the search property on ChannelSearch
    // is a combined key between the address.street1 and name
  };

  const channel: any = {
    parent: { _id: parentCompany?._id },
    search: {
      type: StringSearchTypeEnum.Like,
      value: search,
    },
  };

  // If we have valid location data, add search variable
  if (location?.latitude !== 0 && location?.longitude !== 0) {
    relatedTo.address = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
        distance: CIRCUMFERENCE_OF_EARTH,
      },
    };
  }

  if (parentBuilding && !parentCompany) {
    delete channel.parent; // search by relatedTo instead of parent
    relatedTo = { _id: parentBuilding._id };
  }

  // Get the locations for the selected company for signup
  const {
    items,
    loading,
    called,
    error,
    fetchNextPage,
    refetch,
    networkStatus,
  } = useChannelsByRelationshipQuery({
    perPage: 25,
    skip,
    channel,
    relatedTo,
  });

  // Get the number of private buildings this company is related to
  const numPrivateBuildings =
    items.filter(item => item.relatedTo.inviteOnly).length || 0;

  // We don't want to block signup if user is using OAuth
  const isSignUpBlocked = !oAuth && numPrivateBuildings === items.length;

  return {
    locations: items,
    isSignUpBlocked,
    loading,
    called,
    error,
    refetch,
    fetchNextPage,
    networkStatus,
  };
}
