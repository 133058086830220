import { ThemeProvider as TerraThemeProvider } from '@viewthespace/components';
import {
  AppContext,
  ThemeContext,
  UserDataContext,
} from 'lane-shared/contexts';
import { ThemeContextType } from 'lane-shared/contexts/ThemeContext';
import { getHexColor } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { applyTheme } from './utils/theme/applyTheme';
import { useAuth0 } from '@auth0/auth0-react';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Props = {
  children: React.ReactElement;
  channel: Channel | null;
};

export function ThemeController({ children, channel }: Props) {
  const whitelabel = useWhitelabelByIndexHTML();
  const whitelabelBackgroundColor = whitelabel?.profile?.backgroundColor;
  const whitelabelColor = useRef({
    ...(whitelabel?.profile?.theme.palette || {}),
    primary: whitelabelBackgroundColor,
    secondary: whitelabelBackgroundColor,
    accent: whitelabelBackgroundColor,
    logo: whitelabel?.profile?.logo,
    backgroundImage: imageUrl(whitelabel?.profile?.backgroundImage),
  }).current;
  const [theme, setTheme] = useState<ThemeContextType>(whitelabelColor);
  const { setIsThemeBlockingLoad } = useContext(AppContext);
  const { isLoggedIn } = useContext(UserDataContext);
  const { isAuthenticated } = useAuth0();

  const themeAsPalette = useMemo(
    () => ({
      _id: 'theme-context',
      custom: [],
      ...theme,
    }),
    [theme]
  );

  applyTheme(themeAsPalette);

  useEffect(() => {
    if (isLoggedIn || isAuthenticated) {
      setIsThemeBlockingLoad(true);
    }

    if (!channel) {
      applyTheme(whitelabelColor);

      return;
    }

    if (channel?.profile?.theme?.palette) {
      applyTheme(channel?.profile?.theme?.palette);
      setTheme(prevTheme => {
        return {
          ...prevTheme,
          ...(channel?.profile?.theme?.palette as unknown as ThemeContextType),
        };
      });
      setIsThemeBlockingLoad(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.profile?.theme?.palette?.primary]);

  // Theme colors seemed to be saved in a variety of formats, this ensures we pass hex values to Terra's theme provider
  const { primaryColor, secondaryColor } = useMemo(() => {
    return {
      primaryColor: theme.primary ? getHexColor(theme.primary) : undefined,
      secondaryColor: theme.secondary
        ? getHexColor(theme.secondary)
        : undefined,
    };
  }, [theme.primary, theme.secondary]);

  return (
    <ThemeContext.Provider value={theme}>
      <TerraThemeProvider
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        interactivePaletteId="secondary"
        setCSSVariables
      >
        {children}
      </TerraThemeProvider>
    </ThemeContext.Provider>
  );
}
