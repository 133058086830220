import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import { CHANNEL_DIRECTORY_LIST_VIEW } from 'constants-channel';
import { useUserDirectoryQuery, useChannelSelector } from 'lane-shared/hooks';
import { FlexDirectionEnum } from 'lane-shared/types/blocks/Flex';
import { SortPropertyType } from 'lane-shared/types/blocks/Sort';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import Input from '../../../form/Input';
import ChannelSelectorDropdownLegacy from '../../../lane/ChannelSelectorDropdownLegacy';
import UserListView from '../../../lane/UserListView';
import UserThumbnail from '../../../lane/UserThumbnail';
import styleMap from '../styleMap';
import ListHeader from './components/ListHeader';

import styles from './UserDirectoryBlock.scss';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type BlockProps = WebBlockProps & {
  channel: Channel;
  showSearch: boolean;
  filterByChannel: boolean;
  header: string;
  flexDirection: FlexDirectionEnum;
  listView: CHANNEL_DIRECTORY_LIST_VIEW;
  showViewAll: boolean;
  perPage: number;
  sort: SortPropertyType;
};

export default function UserDirectoryBlock({
  style,
  className,
  channel,
  showSearch,
  filterByChannel,
  header,
  flexDirection,
  listView,
  showViewAll,
  perPage,
  theme,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const channelId = channel?._id;

  const { channels, selectedChannelId, setSelectedChannelId } =
    useChannelSelector({ channelId });

  const { items, search, setSearch, pageInfo } = useUserDirectoryQuery({
    channelId: selectedChannelId || channelId,
    pollInterval: POLL_INTERVAL,
    perPage,
  });

  function renderItem(item: any) {
    switch (listView) {
      case CHANNEL_DIRECTORY_LIST_VIEW.Thumbnail:
        return (
          <UserThumbnail
            user={item}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ user: any; description: any; className: an... Remove this comment to see the full error message
            description={item?.profile?.description}
            className={styles.userThumbnail}
          />
        );
      case CHANNEL_DIRECTORY_LIST_VIEW.List:
      default:
        return (
          <UserListView
            user={item}
            description={item?.profile?.description}
            className={styles.userListView}
          />
        );
    }
  }

  return (
    <div
      className={cx(styles.UserDirectory, className)}
      style={styleMap(false, style)}
      {...props}
    >
      {header && (
        <ListHeader
          theme={theme}
          header={header}
          totalItems={pageInfo?.total}
          currentItems={items?.length}
          link={routes.channelMembers.replace(':id', channelId)}
          viewAll={showViewAll}
        />
      )}
      {filterByChannel && (
        <div className={styles.inputWrapper}>
          <ChannelSelectorDropdownLegacy
            channelId={selectedChannelId}
            channels={channels}
            onChannelSelected={channel => setSelectedChannelId(channel?._id)}
            showAllLocations
            onAllLocationsSelected={() => setSelectedChannelId(channelId)}
          />
        </div>
      )}
      {showSearch && (
        <div className={styles.inputWrapper}>
          <Input
            value={search}
            icon="search"
            className={styles.directoryInput}
            onChange={val => setSearch(val)}
            showClear
          />
        </div>
      )}
      <div className={styles.fullWidth}>
        <div
          className={
            flexDirection === FlexDirectionEnum.Horizontal
              ? styles.row
              : styles.column
          }
        >
          {items.map(user => (
            <Link
              to={routes.user.replace(':id', (user as any)._id)}
              key={(user as any)._id}
              className={styles[listView]}
            >
              {renderItem(user)}
            </Link>
          ))}
        </div>
      </div>
      <div className={styles.wrapper} />
    </div>
  );
}
