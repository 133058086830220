import React, { useState, useEffect, useContext } from 'react';

import { Icon } from 'design-system-web';
import { Button, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';
import { ExternalPayerType } from 'lane-shared/domains/billingPayments/types';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';

import ButtonStrip, {
  Button as ButtonType,
} from 'components/general/ButtonStrip';
import Loading from 'components/general/Loading';
import { H4, H5, M } from 'components/typography';

import { ChargeCard } from './ChargeCard';
import { listRecurringChargesForPayersQuery } from './recurringChargeQueries';
import { RecurringCharge } from 'graphql-query-contracts';

import styles from './styles.scss';

export function RecurringCharges() {
  const { t } = useTranslation();

  const [chargeType, setChargeType] = useState<'user' | 'company'>('user');
  const [loadRecurringCharges, { data, loading, error, refetch }] =
    useLazyQuery(listRecurringChargesForPayersQuery, {
      fetchPolicy: 'network-only',
    });
  const [collapsibleState, setCollapsibleState] = useState({
    active: true,
    expired: true,
  });
  const { user } = useContext(UserDataContext);
  const canAccessCompanyRecurringCharges = hasPermission(user?.roles, [
   PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES,
  ]);

  const toggleButtons = [
    {
      value: 'user',
      name: 'abp.personalButton',
    } as ButtonType<string>,
    {
      value: 'company',
      name: 'abp.companyButton',
    } as ButtonType<string>,
  ];

  const externalPayerType =
    chargeType === 'company' && canAccessCompanyRecurringCharges
      ? ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY
      : ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER;

  useEffect(() => {
    loadRecurringCharges({
      variables: {
        listRecurringChargesForPayersRequest: {
          recurringChargesFilters: {
            externalPayerType,
          },
          pagination: {
            perPage: 100,
            start: 0,
          },
        },
      },
    });
  }, [
    loadRecurringCharges,
    canAccessCompanyRecurringCharges,
    externalPayerType,
  ]);

  const charges =
    data?.accounts?.listRecurringChargesForPayers?.recurringCharges || [];

  const activeCharges = charges.filter((charge: RecurringCharge) => {
    return charge.recurrence?.isActive === true;
  });
  const inactiveCharges = charges.filter((charge: RecurringCharge) => {
    return charge.recurrence?.isActive === false;
  });

  return (
    <>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {charges && (
        <>
          <H4 className={styles.heading}>{t('abp.charges.subscriptions')}</H4>
          <M>{t('abp.subscription.summary')}</M>
          {canAccessCompanyRecurringCharges && (
            <div className={styles.toggleButtonContainer}>
              <ButtonStrip
                buttons={toggleButtons}
                selected={chargeType}
                onClick={buttonValue => setChargeType(buttonValue as any)}
              />
            </div>
          )}
          <div className={styles.listGroup}>
            <Button
              startIcon={
                <Icon
                  name={
                    collapsibleState.active ? 'ChevronDown' : 'ChevronRight'
                  }
                  set="Feather"
                />
              }
              variant="text-icon"
              className={styles.sectionButton}
              onClick={() =>
                setCollapsibleState(previousState => {
                  return {
                    active: !previousState.active,
                    expired: previousState.expired,
                  };
                })
              }
            >
              <H5>{t('abp.subscriptions.active')}</H5>
            </Button>
            {collapsibleState.active &&
              (activeCharges.length > 0 ? (
                activeCharges.map((charge: RecurringCharge) => (
                  <ChargeCard
                    key={charge.recurrence!.recurrenceId}
                    recurringCharge={charge}
                    refetch={refetch}
                  />
                ))
              ) : (
                <span className={styles.emptyState}>
                  {t(`abp.subscription.noSubscriptions.${chargeType}`)}
                </span>
              ))}
          </div>

          <br />

          <div className={styles.listGroup}>
            <Button
              startIcon={
                <Icon
                  name={
                    collapsibleState.expired ? 'ChevronDown' : 'ChevronRight'
                  }
                  set="Feather"
                />
              }
              variant="text-icon"
              className={styles.sectionButton}
              onClick={() =>
                setCollapsibleState(previousState => {
                  return {
                    active: previousState.active,
                    expired: !previousState.expired,
                  };
                })
              }
            >
              <H5>{t('abp.subscription.previous')}</H5>
            </Button>
            {collapsibleState.expired &&
              (inactiveCharges.length > 0 ? (
                inactiveCharges.map((charge: RecurringCharge) => (
                  <ChargeCard
                    key={charge.recurrence!.recurrenceId}
                    recurringCharge={charge}
                    refetch={() => {}}
                  />
                ))
              ) : (
                <span className={styles.emptyState}>
                  {t(`abp.subscription.noSubscriptions.${chargeType}`)}
                </span>
              ))}
          </div>
        </>
      )}
    </>
  );
}
