import React from 'react';

import cx from 'classnames';

import routes from 'lane-shared/config/routes';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import {
  useContentOnChannelQuery,
  useSortFn,
  useMultiLanguage,
  useFlag,
} from 'lane-shared/hooks';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { SortByEnum, SortPropertyType } from 'lane-shared/types/blocks/Sort';
import { ContentTypeEnum } from 'constants-content';

import ContentLink from 'components/lane/ContentLink';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import ContentCard from '../../../cards/ContentCard';
import ListHeader from './components/ListHeader';

import styles from './ContentOnChannelBlock.scss';
import { FeatureFlag } from 'constants-flags';
import { cloneDeep } from 'lodash';
import { SortDirectionEnum } from 'constants-activate';

type BlockProps = WebBlockProps & {
  channel: Channel;
  contentType: ContentTypeEnum;
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  perPage: number;
  sort: SortPropertyType;
};

export default function ContentOnChannelBlock({
  style,
  className,
  channel,
  contentType,
  header,
  autoHide,
  perPage,
  showViewAll,
  sort,
  theme,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { contents: initialContents, isHidden } = useContentOnChannelQuery({
    channelId: channel?._id,
    pollInterval: POLL_INTERVAL,
    contentType,
    autoHide,
  });
  const { translate } = useMultiLanguage();
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  let contents = initialContents;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    contents = translate({
      model: cloneDeep(contents),
      columns: ['name', 'description', 'subtitle'],
      previewLanguage: props['data-preview-language'],
    });
  }

  let sortBy: SortByEnum | SortByEnum[] = sort?.sortBy;

  if (sortBy === SortByEnum.StartDate) {
    sortBy = [SortByEnum.StartDate, SortByEnum.LiveDate];
  } else {
    sortBy = [SortByEnum.EndDate, SortByEnum.UnpublishDate];
  }

  const { sortFn } = useSortFn({
    sortBy: sortBy || SortByEnum.EndDate,
    sortDirection: sort?.sortDirection || SortDirectionEnum.Asc,
  });

  if (!props['data-is-edit-mode'] && autoHide && isHidden) {
    return null;
  }

  const filteredContents = contents.sort(sortFn).slice(0, perPage);

  return (
    <div
      className={cx(styles.ContentOnChannelBlock, className)}
      style={style}
      {...props}
    >
      {header && (
        <ListHeader
          theme={theme}
          header={header}
          totalItems={contents.length}
          currentItems={filteredContents.length}
          link={routes.channelContent.replace(':id', channel?._id)}
          viewAll={showViewAll}
          contentType={contentType}
        />
      )}
      <ul>
        {filteredContents.map((content: any) => (
          <ContentLink content={content} key={content._id}>
            <ContentCard className={styles.ContentCard} content={content} />
          </ContentLink>
        ))}
      </ul>
      <div className={styles.wrapper} />
    </div>
  );
}
