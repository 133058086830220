import cx from 'classnames';
import { Dropdown, Input, Line, RadioGroup, TextArea } from 'components';
import {
  ProductCategory,
  ProductType,
  ChargeCode,
} from 'graphql-query-contracts';
import { M, H4 } from 'components/typography';
import { Item } from 'components/form/Dropdown/Dropdown';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateCategoryFormModal } from './modals/CreateCategoryFormModal';
import {
  isNumberTwoDecimalPlaces,
  useCategoryListQuery,
  useChargeCodeListQuery,
} from '../helpers/index';
import { Checkbox, Sort } from 'design-system-web';
import { PERMISSION_KEYS } from 'constants-permissions';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { hasPermission } from 'lane-shared/helpers';
import {
  NewProductDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { MarkupAndTaxOptions } from './MarkupAndTaxOptions';

import styles from './CreateProductForm.scss';
import { CreateChargeCodeFormModal } from './modals/CreateChargeCodeFormModal';
import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';

const requiredFieldAsteriskSymbol = '*';

export function CreateProductFormInputFields({
  productDetails,
  updateProductDetails,
  currency,
  inclusivePricing = false,
  edit = false,
}: {
  productDetails: NewProductDetails;
  updateProductDetails: (value: any, field: string) => void;
  currency: SupportedPaymentCurrency;
  inclusivePricing?: boolean;
  edit?: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);
  const productsWithCreditsFlag = useFlag(
    FeatureFlag.ProductsWithCredits,
    false
  );

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isChargeCodeModalOpen, setIsChargeCodeModalOpen] = useState(false);
  const [isCreditPriceEnabled, setIsCreditPriceEnabled] = useState(
    productDetails.creditAmount !== undefined
  );

  useEffect(() => {
    setIsCreditPriceEnabled(productDetails.creditAmount !== undefined);
  }, [productDetails.creditAmount]);

  const { data: categoryList, refetch: refetchCategoryList } =
    useCategoryListQuery({
      page: 0,
      pageSize: 100,
      productType: productDetails.productType as unknown as ProductType,
    });

  const rateInputError = (rateToVerify: string) => {
    if (!isNumberTwoDecimalPlaces(parseFloat(rateToVerify))) {
      return [t('abp.productsServices.createProduct.max2Decimal')];
    }

    return null;
  };

  const netPriceInputError = () => {
    if (
      productDetails.netPrice &&
      !isNumberTwoDecimalPlaces(parseFloat(productDetails.netPrice))
    ) {
      return [t('abp.productsServices.createProduct.max2Decimal')];
    }

    return null;
  };

  const canManageChargeCode =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_CHARGE_CODE_MANAGE],
      primaryChannel?._id
    );

  const chargeCodeSort: Sort = { id: 'charge_code', direction: 'asc' };

  const { data: chargeCodeList, refetch: refetchChargeCodeList } =
    useChargeCodeListQuery(0, 100, chargeCodeSort, '');

  function getCategoryItems(): Item<string>[] | undefined {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[] | undefined;

    if (listOfCategories) {
      const dropdownCategoryItems = listOfCategories.map(productCategory => {
        return {
          label: productCategory.name,
          value: productCategory.productCategoryId,
        };
      });

      return dropdownCategoryItems;
    }

    return undefined;
  }

  function getChargeCodeItems(): Item<string>[] | undefined {
    const listOfChargeCodes = chargeCodeList?.accounts?.listChargeCode
      ?.chargeCodes as ChargeCode[] | undefined;

    if (listOfChargeCodes) {
      const dropdownChargeCodeItems = listOfChargeCodes.map(chargeCode => {
        return {
          label: chargeCode.chargeCode,
          value: chargeCode.id,
        };
      });

      return dropdownChargeCodeItems;
    }

    return undefined;
  }

  const label = (labelText: string, required = false) => {
    return (
      <label className={styles.descriptionLabel}>
        {t(labelText)}
        {required && (
          <span className={styles.InputLabelRequiredAstrisk}>
            {requiredFieldAsteriskSymbol}
          </span>
        )}
      </label>
    );
  };

  const toggleCreditPriceInput = () => {
    // section is currently enabled and being closed so disable the credit amount
    if (isCreditPriceEnabled) {
      updateProductDetails(undefined, 'creditAmount');
    }

    // section is currently disabled and being opened so set to empty value
    if (!isCreditPriceEnabled) {
      updateProductDetails('', 'creditAmount');
    }

    setIsCreditPriceEnabled(!isCreditPriceEnabled);
  };

  const InfoSection = ({ title, value }: { title: string; value: string }) => {
    return (
      <span className={styles.group}>
        <label className={styles.descriptionLabel}>{title}</label>
        <M>{value}</M>
      </span>
    );
  };

  return (
    <>
      {isCategoryModalOpen && (
        <CreateCategoryFormModal
          modalVisible={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          onSave={() => refetchCategoryList()}
          testId="createCategoryFormModal"
        />
      )}
      {isChargeCodeModalOpen && (
        <CreateChargeCodeFormModal
          modalVisible={isChargeCodeModalOpen}
          onClose={() => setIsChargeCodeModalOpen(false)}
          onSave={() => refetchChargeCodeList()}
          testId="createChargeCodeFormModal"
        />
      )}
      <div className={styles.createProductInputFields}>
        <div className={styles.typeGroup}>
          {edit ? (
            <InfoSection
              title={t('abp.productsServices.createProduct.typeLabel')}
              value={t(
                `abp.productsServices.productType.${productDetails.productType}`
              )}
            />
          ) : (
            <>
              <label className={styles.descriptionLabel}>
                {t('abp.productsServices.createProduct.typeLabel')}
                <span className={styles.InputLabelRequiredAstrisk}>
                  {requiredFieldAsteriskSymbol}
                </span>
              </label>
              <RadioGroup
                key="type"
                name="productType"
                selected={productDetails.productType}
                schema={{
                  id: 'id',
                  text: 'text',
                  subtext: 'subtext',
                }}
                items={[
                  {
                    id: ProductType.ProductTypeServiceMaterials,
                    text: 'abp.productsServices.createProduct.type.material',
                    subtext:
                      'abp.productsServices.createProduct.type.materialSubText',
                  },
                  {
                    id: ProductType.ProductTypeServiceLabour,
                    text: 'abp.productsServices.createProduct.type.labor',
                    subtext:
                      'abp.productsServices.createProduct.type.laborSubText',
                  },
                ]}
                onChange={(value: string) => {
                  updateProductDetails(value, 'productType');
                }}
              />
            </>
          )}
        </div>
        <Input
          className={styles.inputFields}
          fieldName="name"
          label="Name"
          fixedLabel
          isRequired
          value={productDetails.name}
          onChange={updateProductDetails}
          testId="productNameInput"
        />
        {!edit && (
          <div className={styles.label}>
            {label('abp.productsServices.createProduct.categoryLabel', true)}
            <button
              className={styles.modalButton}
              onClick={() => setIsCategoryModalOpen(true)}
            >
              {t('abp.productsServices.categories.button')}
            </button>
          </div>
        )}

        {edit ? (
          <div className={styles.categoryLabel}>
            <InfoSection
              title={t('abp.productsServices.createProduct.categoryLabel')}
              value={productDetails.productCategoryName}
            />
          </div>
        ) : (
          <Dropdown
            testId="categoryDropdown"
            doTranslation={false}
            className={[styles.inputFields, styles.categoryDropdown]}
            value={productDetails.productCategoryId}
            placeholder={t(
              'abp.productsServices.createProduct.category.placeholder'
            )}
            items={getCategoryItems()}
            onChange={item => {
              updateProductDetails(item.value, 'productCategoryId');
            }}
          />
        )}
        <div className={styles.label}>
          {label('abp.productsServices.createProduct.chargeCodeLabel')}
          {canManageChargeCode && (
            <button
              className={styles.modalButton}
              onClick={() => setIsChargeCodeModalOpen(true)}
            >
              {t('abp.productsServices.chargeCodes.button')}
            </button>
          )}
        </div>
        <Dropdown
          testId="chargeCodeDropdown"
          doTranslation={false}
          className={cx(
            styles.inputFields,
            styles.inputWithCustomLabel,
            styles.categoryDropdown
          )}
          value={productDetails.chargeCodeId}
          placeholder={t(
            'abp.productsServices.createProduct.chargeCode.placeholder'
          )}
          items={getChargeCodeItems()}
          onChange={item => {
            updateProductDetails(item.value, 'chargeCodeId');
          }}
        />
        <div className={styles.label}>
          <label
            htmlFor="productDescription"
            className={styles.descriptionLabel}
          >
            {t('abp.productsServices.createProduct.descriptionLabel')}
          </label>
        </div>
        <TextArea
          className={styles.inputFields}
          value={productDetails.description}
          onChange={value => updateProductDetails(value, 'description')}
          testId="productDescriptionInput"
          minRows={3}
        />

        <Line />

        <div className={styles.pricingSectionTitle}>
          <H4>
            {t('abp.productsServices.createProduct.pricingSection.title')}
          </H4>
          <M>
            {t('abp.productsServices.createProduct.pricingSection.subtitle')}
          </M>
        </div>

        <div className={styles.rateInputGroup}>
          {inclusivePricing ? (
            <Input
              className={styles.rateInput}
              fieldName="netPrice"
              label={t('abp.productsServices.createProduct.totalPrice.label')}
              fixedLabel
              isRequired
              input={{ type: 'number', min: 1, step: '0.01' }}
              value={productDetails.netPrice}
              onChange={(value, field) => {
                return updateProductDetails(value, field);
              }}
              testId="productNetPriceInput"
              error={netPriceInputError()}
            />
          ) : (
            <Input
              className={styles.rateInput}
              fieldName="rate"
              label={t('abp.productsServices.createProduct.rate.label')}
              fixedLabel
              isRequired
              input={{ type: 'number', min: 1, step: '0.01' }}
              value={productDetails.rate}
              onChange={(value, field) => {
                return updateProductDetails(value, field);
              }}
              testId="productRateInput"
              error={rateInputError(productDetails.rate)}
            />
          )}
          <div className={styles.currencyText}>{currency}</div>
        </div>
        <MarkupAndTaxOptions
          productDetails={productDetails}
          updateDetails={updateProductDetails}
        />
        {productsWithCreditsFlag && (
          <Checkbox
            className={styles.checkbox}
            testId="addCreditPriceCheckbox"
            value={isCreditPriceEnabled}
            text={t(
              'abp.productsServices.createProduct.pricingSection.addCreditPriceCheckboxTitle'
            )}
            description={t(
              'abp.productsServices.createProduct.pricingSection.addCreditPriceCheckboxSubtitle'
            )}
            selected={isCreditPriceEnabled}
            onChange={() => toggleCreditPriceInput()}
          />
        )}

        {isCreditPriceEnabled && productsWithCreditsFlag && (
          <div
            className={cx(styles.creditPriceInputGroup, styles.checkboxItem)}
          >
            <Input
              className={styles.creditPriceInput}
              fieldName="creditAmount"
              label={t('abp.productsServices.createProduct.rate.label')}
              fixedLabel
              isRequired
              input={{ type: 'number', min: 1, step: 1 }}
              value={productDetails.creditAmount}
              onChange={updateProductDetails}
              testId="creditPriceInput"
              error={rateInputError(productDetails.creditAmount || '')}
            />
            <M className={styles.creditPricingSuffix}>
              {t(
                'abp.productsServices.createProduct.pricingSection.creditPriceSuffix'
              )}
            </M>
          </div>
        )}
      </div>
    </>
  );
}
