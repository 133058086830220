import React from 'react';

import cx from 'classnames';
import { ContentImagePin } from 'components';

import {
  PIN_STANDARD,
  PIN_CIRCLE,
  PIN_SQUARE,
  PIN_LIST_VIEW,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  CONTENT_CARD_STANDARD,
  PIN_IMAGE,
  CONTENT_TYPES_WITH_PIN_LAYOUT,
 ContentTypeEnum } from 'constants-content';
import parseDate from 'lane-shared/helpers/dates/parseDate';
import {
  useContentForCardQuery,
  useFlag,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { ContentType } from 'lane-shared/types/content/Content';

import ContentLink from 'components/lane/ContentLink';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import { useEditModeProps } from 'components/renderers/v5/primitives/useEditModeProps';

import ContentCard from '../../../cards/ContentCard';
import HeroContentCard from '../../../cards/HeroContentCard';
import HorizontalContentCard from '../../../cards/HorizontalContentCard';
import StandardContentCard from '../../../cards/StandardContentCard';
import Loading from '../../../general/Loading';
import CirclePin from '../../../lane/CirclePin';
import ContentPin from '../../../lane/ContentPin';
import SquarePin from '../../../lane/SquarePin';
import { useRenderTime } from '../../../../hooks';
import { FeatureFlag } from 'constants-flags';

import styles from './ContentBlock.scss';

type BlockProps = WebBlockProps & {
  color: string;
  cardType: string;
  pinType: string;
  content: ContentType;
};

export default function ContentBlock({
  style,
  className,
  cardType,
  pinType,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { translate } = useMultiLanguage();
  const {
    content: initialContent,
    error,
    loading,
  } = useContentForCardQuery({
    contentId: otherProps.content?._id,
  });
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  useRenderTime('ContentBlock');

  let content = initialContent;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    content = translate({
      model: content,
      columns: ['name', 'description'],
      previewLanguage: props['data-preview-language'],
    });
  }

  function getLoadingStyle() {
    if (content?.type !== ContentTypeEnum.Static) {
      return styles.ContentCard;
    }

    switch (pinType) {
      case PIN_LIST_VIEW:
        return styles.ContentCard;
      case PIN_IMAGE:
        return styles.newContentPin;
      default:
        return styles.contentPin;
    }
  }

  function renderContent() {
    if (loading || !content) {
      return (
        <div className={getLoadingStyle()}>
          <Loading />
        </div>
      );
    }

    if (CONTENT_TYPES_WITH_PIN_LAYOUT.includes(content?.type)) {
      switch (pinType) {
        case PIN_SQUARE:
          return <SquarePin content={content} className={styles.contentPin} />;
        case PIN_CIRCLE:
          return <CirclePin content={content} />;
        case PIN_LIST_VIEW:
          return (
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
            <ContentCard content={content} className={styles.ContentCard} />
          );
        case PIN_IMAGE:
          return (
            <ContentImagePin
              className={styles.newContentPin}
              content={content}
            />
          );
        case PIN_STANDARD:
        default:
          return <ContentPin content={content} className={styles.contentPin} />;
      }
    }

    switch (cardType) {
      case CONTENT_CARD_HERO: {
        return (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
          <HeroContentCard content={content} className={styles.ContentCard} />
        );
      }

      case CONTENT_CARD_HORIZONTAL: {
        return (
          <HorizontalContentCard
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
            content={content}
            className={styles.ContentCard}
          />
        );
      }

      case CONTENT_CARD_STANDARD: {
        return (
          <StandardContentCard
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
            content={content}
            className={styles.ContentCard}
          />
        );
      }

      default:
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
        return <ContentCard className={styles.ContentCard} content={content} />;
    }
  }

  if (error && !props['data-is-edit-mode']) {
    return null;
  }

  if (
    !props['data-is-edit-mode'] &&
    content?.unpublishDate &&
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    parseDate(content?.unpublishDate) < new Date()
  ) {
    return null;
  }

  return (
    <div
      className={cx(styles.ContentBlock, className)}
      style={style}
      {...props}
    >
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType | null' is not assignable... Remove this comment to see the full error message */}
      <ContentLink content={content}>{renderContent()}</ContentLink>
      {/* TM-3726 Used to overlay content edit context in content edit mode */}
      {props['data-is-edit-mode'] && (
        <div data-test="contentEditWrapper" className={styles.wrapper} />
      )}
    </div>
  );
}
