import styles from './index.scss';
import { Header } from '../components/Header';
import { Text } from 'components/typography';
import { ErrorMessage, Loading } from 'components';
import { useOnboardingBuildings } from 'lane-shared/contexts/SignUpContext/useOnboardingBuildings';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';
import { OnboardingPreselectedBuilding } from './OnboardingPreselectedBuilding';
import { OnboardingSelectBuilding } from './OnboardingSelectBuilding';

const TRANSLATION_KEYS = {
  serverError: 'web.onboarding.building.server.error',
};

type Props = {
  onboardingState: 'loading' | 'idle' | 'error';
  errorMessage?: string;
  userName: string;
  onComplete: (building: Channel, userName: string) => void;
  onContinue: (building: Channel) => void;
  onClear: () => void;
  invitedChannel?: Channel;
  presumedEmailDomain?: string;
};

export function OnboardingBuilding({
  onboardingState,
  userName,
  errorMessage,
  invitedChannel,
  presumedEmailDomain,
  onComplete,
  onContinue,
  onClear,
}: Props) {
  const { t } = useTranslation();
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const [shouldUsePreselectedBuilding, setShouldUsePreselectedBuilding] =
    useState(true);

  const onboardingBuildingResult = useOnboardingBuildings({
    query: '',
    presumedEmailDomain,
  });

  const preselectedBuildings = onboardingBuildingResult.buildings.filter(
    b => b.node.matchesEmailDomain
  );

  const shouldShowPreselectedBuilding =
    isWorkplaceOnboardingEnabled &&
    shouldUsePreselectedBuilding &&
    preselectedBuildings.length === 1 &&
    !invitedChannel;

  useEffect(() => {
    if (onboardingBuildingResult.error) {
      window.Toast.show(<Text>{t(TRANSLATION_KEYS.serverError)}</Text>);
    }
  }, [onboardingBuildingResult.error]);

  const handleOtherLocationClick = () => {
    setShouldUsePreselectedBuilding(false);
  };

  if (invitedChannel && isWorkplaceOnboardingEnabled) {
    onContinue(invitedChannel);
  }

  return (
    <div className={styles.container}>
      <Header userName={userName} />
      {errorMessage && <ErrorMessage error={errorMessage} />}
      {onboardingBuildingResult.isInitialLoading && (
        <Loading className={styles.loading} />
      )}
      {!onboardingBuildingResult.isInitialLoading &&
        (shouldShowPreselectedBuilding ? (
          <OnboardingPreselectedBuilding
            onboardingState={onboardingState}
            preselectedBuilding={preselectedBuildings[0]}
            onContinue={onContinue}
            handlers={{
              handleOtherLocationClick,
            }}
          />
        ) : (
          <OnboardingSelectBuilding
            onboardingState={onboardingState}
            userName={userName}
            onComplete={onComplete}
            onContinue={onContinue}
            onClear={onClear}
            presumedEmailDomain={presumedEmailDomain}
            invitedChannel={invitedChannel}
          />
        ))}
    </div>
  );
}
