import React, { useEffect, useRef, useState } from 'react';

import { LaneType } from 'common-types';
import { ChannelCreationError, NotAuthorizedError } from 'activate-errors';
import { PERMISSION_KEYS } from 'constants-permissions';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';

import {
  ControlMenu,
  ChannelCircleListView,
  Button,
  IconButton,
  Loading,
  EmptyState,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';

import { getClient } from 'lane-shared/apollo';
import {
  queryChannels,
  deleteChannelMutation,
} from 'lane-shared/graphql/channel';
import { getDisplayName, hasPermission } from 'lane-shared/helpers';
import { shortAddress } from 'lane-shared/helpers/formatters';

import styles from './styles.scss';

// FIXME: COPIED FROM SERVER
function checkCreateChannelPermissions({
  channel,
  me,
  userGroupRoles,
}: {
  channel: {
    parent?: {
      _id: LaneType.UUID;
    };
    groupRoles?: any[];
  };
  me: {
    _id: LaneType.UUID;
    isSuperUser: boolean;
  };
  userGroupRoles: UserGroupRoleType[];
}) {
  // You can't update groupRoles from here. Create the channel first, then add some.
  if (channel.groupRoles) {
    throw new ChannelCreationError(
      'Cannot create a Channel and specify group roles at the same time.'
    );
  }

  if (!me.isSuperUser && !channel.parent && !(channel.parent as any)?._id) {
    // If a non-super user is trying to create a channel without a Parent, this
    // automatically fails.  They can't add new channels into the system (yet).
    // only Lane Super Users can do that for now.
    throw new NotAuthorizedError();
  }

  if (channel.parent) {
    // Is this user allowed to create this channel?
    // If this user has {admin, channel.create} access on the parent channel yes.
    // If they are a SuperUser yes.
    const isAllowed = hasPermission(
      userGroupRoles,
      [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_CHANNEL_CREATE,
      ],
      channel.parent._id
    );

    if (!(me.isSuperUser || isAllowed)) {
      throw new NotAuthorizedError();
    }

    // Make sure the user isn't trying to set additional data on the Parent channel.
    // upsert helper will do recursive updates if we pass in that data.
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '{ _id: st... Remove this comment to see the full error message
    channel.parent = channel.parent._id;
  }
}

function ChannelSubChannels({ channel, user }: any) {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [enableAddChannel, setEnableAddChannel] = useState(false);
  const reFetchRef = useRef(null);

  useEffect(() => {
    try {
      // is this user allowed to create channels
      checkCreateChannelPermissions({
        channel,
        me: { _id: user._id, isSuperUser: user.isSuperUser },
        userGroupRoles: user.roles,
      });

      setEnableAddChannel(true);
    } catch {
      setEnableAddChannel(false);
    }
  }, [channel, user]);

  async function removeChild(child: any) {
    try {
      await window.Alert.confirm({
        title: t('web.admin.channel.subChannel.removeAlert.title', {
          subChannel: getDisplayName(child),
        }),
        message: t('web.admin.channel.subChannel.removeAlert.message', {
          subChannel: getDisplayName(child),
          channel: getDisplayName(channel),
        }),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      await getClient().mutate({
        mutation: deleteChannelMutation,
        variables: {
          channelId: child?._id,
        },
      });
    } catch (err) {
      window.Toast.show(
        t('web.admin.channel.subChannel.removeAlert.error', {
          message: err.message,
        })
      );

      return;
    }

    window.Toast.show(
      t('web.admin.channel.subChannel.removeAlert.success', {
        subChannel: getDisplayName(child),
      })
    );

    if (reFetchRef?.current && typeof reFetchRef?.current === 'function') {
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      return reFetchRef?.current?.();
    }
  }

  const variables = {
    pagination: {
      start: 0,
      perPage: 500,
    },
    search: {
      sortBy: {
        key: 'name',
        dir: 'asc',
      },
      includeChannelsFromHierarchy: true,
      isSub: true,
      parent: {
        _id: channel?._id,
      },
    },
  };

  return (
    <div className={styles.ChannelSubChannels}>
      <ControlMenu>
        <Link
          to="sub-channels/new"
          style={enableAddChannel ? {} : { pointerEvents: 'none' }}
        >
          <Button
            disabled={!enableAddChannel}
            loading={loading}
            variant="contained"
          >
            {t('Add')}
          </Button>
        </Link>
      </ControlMenu>
      <Query
        query={queryChannels}
        variables={variables}
        fetchPolicy="network-only"
      >
        {({ data, refetch }: any) => {
          if (!data || !data.channels) {
            return <Loading />;
          }

          reFetchRef.current = refetch;
          const children = data.channels.items || [];

          if (children.length === 0 && enableAddChannel) {
            return (
              <Link to="sub-channels/new">
                <EmptyState>
                  {t('web.admin.channel.subChannel.emptyState')}
                </EmptyState>
              </Link>
            );
          }

          return children.map((child: any) => (
            <div key={child._id} className={styles.channel}>
              <Link to={`/l/channel/${child.slug}/admin`}>
                <ChannelCircleListView
                  channel={child}
                  description={shortAddress(child.address)}
                />
              </Link>
              <IconButton
                testId="deleteSubChannel"
                icon="times"
                inverted
                disabled={loading}
                className={styles.remove}
                onClick={() => removeChild(child)}
              />
            </div>
          ));
        }}
      </Query>
    </div>
  );
}

export default ChannelSubChannels;
