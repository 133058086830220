/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';

import jwtDecode from 'jwt-decode';

import i18n, { parseSupportedLocaleEnum } from 'localization';

import { getClient } from '../../apollo';
import {
  SignUpFullAccessError,
  SignUpPrivateLocationError,
  SignUpUnsupportedLocationError,
} from 'activate-errors';
import { getLocalizedJoinChannelError } from './getLocalizedJoinChannelError';
import getInvite, { GetInviteResponse } from '../../graphql/query/getInvite';
import { signUpMutation } from '../../helpers';
import { SignUpMutationDataType } from '../../helpers/signUpMutation';
import { UserLoginProviderEnum } from 'constants-user';
import { SignUpContextType } from './SignUpContextType';
import defaultSignUpContext from './defaultSignUpContext';
import SignUpContext from './index';
import useBuildingsSearch from './useBuildingSearch';
import useCompanyLocationsSearch from './useCompanyLocationsSearch';
import useCompanyParentSearch from './useCompanyParentSearch';

// the user will either
//   - search by a building location channel
//      - then choose a company at that location
//   - search by parent company channel
//      - then choose a building location channel that company has
//   - sign up with oAuth?
//   - sign up with an invite.

type DecodedIDToken = {
  iss: string;
  sub: string;
  email?: string;
  name?: string;
  preferred_username?: string;
};

type Props = {
  children: React.ReactNode;
};

export default function SignUpContextProvider({ children }: Props) {
  const [signUpState, setSignUpState] =
    useState<SignUpContextType>(defaultSignUpContext);

  function updateSignUp(updateProps: Partial<SignUpContextType>) {
    setSignUpState(prevSignUpState => ({ ...prevSignUpState, ...updateProps }));
  }

  function clearInvite() {
    updateSignUp({
      inviteId: null,
      inviteError: null,
      building: null,
      company: null,
    });
  }

  function addOAuthSignUpDetails(
    idToken: string,
    provider: UserLoginProviderEnum,
    { inviteId, fullName }: { inviteId?: string | null; fullName?: string } = {}
  ) {
    const {
      iss,
      sub,
      email: jwtEmail,
      name,
      preferred_username,
    } = jwtDecode<DecodedIDToken>(idToken);

    const userName = fullName ?? name ?? preferred_username ?? jwtEmail;
    const email = jwtEmail ?? preferred_username;

    if (!email) {
      throw new Error(
        "Your email could not be shared. Please contact an Administrator to ensure you've been setup correctly."
      );
    }

    const nextSignUpState: Partial<SignUpContextType> = {
      password: idToken,
      name: userName,
      email,
      inviteId,
      emailOptIn: false,
      loginProvider: provider,
      oAuth: {
        code: `${iss}:${sub}`,
        loginProvider: provider,
      },
    };

    setSignUpState(prevSignUpState => ({
      ...prevSignUpState,
      ...nextSignUpState,
    }));
  }

  const buildingsResult = useBuildingsSearch({
    skip: !signUpState.buildingSearch || signUpState.buildingSearch.length < 2,
    search: signUpState.buildingSearch,
  });

  const companyLocationsResult = useCompanyLocationsSearch({
    skip: !signUpState.parentCompany?._id && !signUpState.building?._id,
    parentCompany: signUpState.parentCompany,
    search: signUpState.companySearch,
    oAuth: signUpState.oAuth,
    parentBuilding: signUpState.building,
  });

  const companyParentsResult = useCompanyParentSearch({
    skip:
      !signUpState.parentCompanySearch ||
      signUpState.parentCompanySearch.length < 2,
    search: signUpState.parentCompanySearch,
  });

  function resetSignUp() {
    updateSignUp(defaultSignUpContext);
  }

  async function doSignUp(): Promise<{
    token: string;
    jti: string;
    warnings?: string[];
  }> {
    updateSignUp({ submitting: true, submitError: null });

    try {
      const mutationData: SignUpMutationDataType = {
        loginProvider: null,
        // name will be retrieved on backend - google.ts
        name: signUpState.name || '',
        password: signUpState.password,
        inviteId: signUpState.inviteId,
        emailOptIn: signUpState.emailOptIn,
        isPublic: true,
        locale: parseSupportedLocaleEnum(i18n.language),
        metadata: signUpState.metadata,
      };

      if (signUpState.oAuth) {
        mutationData.loginProvider = signUpState.loginProvider;
        mutationData.oAuth = signUpState.oAuth;
      } else {
        mutationData.email = signUpState.email;
      }

      if (!signUpState.company?.inviteOnly || signUpState.oAuth) {
        mutationData.company = signUpState.company;
      }

      if (!signUpState.building?.inviteOnly) {
        mutationData.building = signUpState.building;
      }

      const { data } = await signUpMutation(mutationData);

      updateSignUp({ submitting: false });

      return data.signUp;
    } catch (err) {
      const error = getLocalizedJoinChannelError(err, signUpState.building);

      updateSignUp({ submitting: false, submitError: error as any });

      throw err;
    }
  }

  useEffect(() => {
    let signupError: Error | null = null;

    if (signUpState.oAuth) {
      // Don't show error on OAuth signup
      signupError = null;
    } else if (
      signUpState.company &&
      companyLocationsResult.called &&
      !companyLocationsResult.loading
    ) {
      const locations = companyLocationsResult.locations;

      // if a company is selected, and the location results are all loaded
      if (locations.length === 0) {
        // if there are no locations, that is not good
        signupError = new SignUpUnsupportedLocationError();
      } else if (locations.every(l => l.relatedTo.inviteOnly)) {
        // if there are only private locations, that is not good either
        signupError = new SignUpPrivateLocationError();
      } else if (signUpState.company.inviteOnly) {
        // We know from the above conditions that we have locations for this
        // company that are not private. If the company is invite only we show
        // a message stating they won't have full access
        signupError = new SignUpFullAccessError();
      }
    }

    updateSignUp({ signupError });
  }, [
    JSON.stringify(signUpState.oAuth),
    signUpState.company,
    companyLocationsResult.locations.length,
  ]);

  useEffect(() => {
    if (!signUpState.inviteId) {
      return;
    }

    async function populateInviteDetails() {
      const { data } = await getClient().query<GetInviteResponse>({
        query: getInvite,
        variables: { id: signUpState.inviteId },
      });

      const {
        invite: { name, email, channel, company },
      } = data;

      const updatedSignUp: Partial<SignUpContextType> = {
        name,
        email,
        building: undefined,
        shouldSkipCompanySelection: false,
      };

      if (channel) {
        updatedSignUp.shouldSkipCompanySelection = true;

        if (company) {
          updatedSignUp.building = channel as SignUpContextType['building'];
          updatedSignUp.company = company as SignUpContextType['company'];
        } else {
          updatedSignUp.building = channel as SignUpContextType['company'];
        }
      }

      updateSignUp(updatedSignUp);
    }

    populateInviteDetails();
  }, [signUpState.inviteId]);

  const isLocked = Boolean(signUpState.inviteId || signUpState.enterprise);

  const providerValue = useMemo(
    () => ({
      ...signUpState,
      isLocked,
      buildingsResult,
      companyLocationsResult,
      companyParentsResult,
      updateSignUp,
      clearInvite,
      doSignUp,
      resetSignUp,
      addOAuthSignUpDetails,
    }),
    [
      signUpState,
      isLocked,
      buildingsResult,
      companyLocationsResult,
      companyParentsResult,
      updateSignUp,
      clearInvite,
      doSignUp,
      resetSignUp,
      addOAuthSignUpDetails,
    ]
  );

  return (
    <SignUpContext.Provider value={providerValue}>
      {children}
    </SignUpContext.Provider>
  );
}
