import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { Query } from '@apollo/client/react/components';

import { getClient } from 'lane-shared/apollo';
import {
  AnalyticsContext,
  ChannelsContext,
  UserDataContext,
} from 'lane-shared/contexts';
import { errorCodes } from 'activate-errors';
import { queryChannels } from 'lane-shared/graphql/channel';
import { joinChannel } from 'lane-shared/graphql/mutation';
import {
  emitter,
  getDisplayName,
  getMessagesFromError,
  pause,
} from 'lane-shared/helpers';
import { emitChannelJoined } from 'lane-shared/helpers/analytics/emitChannel';
import useLocation from 'lane-shared/hooks/location/useLocation';

import { Input } from 'components/form';
import { Label } from 'components/general';
import Pagination from 'components/general/Pagination';
import { ChannelSearchCircleListView } from 'components/lane';

import styles from './AddSubscription.scss';
import { ActiveChannelTypeEnum } from 'constants-channel';

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

const types = [ActiveChannelTypeEnum.Property, ActiveChannelTypeEnum.Company];

type SubscriptionAddProps = {
  className?: string;
  style?: React.CSSProperties;
};

export default function AddSubscription({
  className,
  style,
}: SubscriptionAddProps) {
  const { location } = useLocation();
  const { channels } = useContext(ChannelsContext);
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [search]);

  async function subscribe(channel: any) {
    try {
      const displayName = getDisplayName(channel);

      await window.Alert.confirm({
        // The '-' ensures special characters are not escaped
        title: t('Join {{- displayName}}?', { displayName }),
        message: t('Would you like to join {{- displayName}}?', {
          displayName,
        }),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);
    await pause();

    try {
      await getClient().mutate({
        mutation: joinChannel,
        variables: {
          channelId: channel._id,
        },
      });
      emitter.emit((emitter as any).EVENT_SUBSCRIPTIONS_CHANGED);

      emitChannelJoined({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        userId: user._id,
        channelId: channel._id,
        channelName: channel.name,
        analytics,
      });

      window.Toast.show(
        t('Joined {{- channel}}!', { channel: getDisplayName(channel) })
      );
    } catch (exception) {
      const messages = getMessagesFromError(exception);
      const channelName = getDisplayName(channel);
      let error = exception;

      if (
        messages.some(message =>
          message.includes(errorCodes.unverifiedEmailError.code)
        )
      ) {
        error = t('Please verify your email to join {{channelName}}', {
          channelName,
        });
      }

      await window.Alert.alert({
        title: t('Error joining {{- channel}}?', {
          channel: channelName,
        }),
        message: t(
          "I wasn't able to join {{- channel}} at this time.  Please see the error below and try again.",
          { channel: channelName }
        ),
        error,
      });
    }

    setLoading(false);
  }

  const variables = {
    pagination: {
      start: page * PER_PAGE,
      perPage: PER_PAGE,
    },
    search: {
      type: { any: types },
      isSub: false,
    },
  };

  if (debouncedSearch) {
    (variables.search as any).search = { type: 'like', value: debouncedSearch };
  }

  if (location && location.latitude !== 0) {
    (variables.search as any).address = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
        distance: 1000 * 1000, // 1000 km
      },
    };
  }

  return (
    <div className={cx(styles.SubscriptionAdd, className)} style={style}>
      <Label h1>{t('Join a new channel below.')}</Label>
      <br />
      <Input
        onChange={search => setSearch(search)}
        value={search}
        placeholder={t('Search…')}
        icon="search"
        showClear
        disabled={loading}
      />
      <Query
        query={queryChannels}
        fetchPolicy="network-only"
        variables={variables}
      >
        {({ data }: any) => {
          const items = (data?.channels?.items || []).filter(
            (channel: any) =>
              !!channel.parent &&
              !channels?.some(subscription => subscription._id === channel._id)
          );

          return (
            <>
              {search && (
                <Pagination
                  loading={data?.loading}
                  total={data?.channels?.pageInfo?.total}
                  page={page}
                  perPage={PER_PAGE}
                  onPage={page => setPage(page)}
                />
              )}
              <ul>
                {items.map((channel: any) => (
                  <li key={channel._id} onClick={() => subscribe(channel)}>
                    <ChannelSearchCircleListView
                      channel={channel}
                      className={styles.channel}
                    />
                  </li>
                ))}
              </ul>
            </>
          );
        }}
      </Query>
    </div>
  );
}
