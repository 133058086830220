import React, { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getLookerEmbedURL } from 'lane-shared/graphql/looker';
import { getDisplayName, hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';

import { AdminPage } from 'components/layout';
import { BreadCrumbs } from 'components/lds';

import ChannelAdminContext from '../ChannelAdminContext';
import { useChannelInsightReports } from '../hooks/useChannelInsightReports';

import styles from './styles.scss';

export function ChannelInsightsReport() {
  const { t } = useTranslation();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedReport' does not exist on type '... Remove this comment to see the full error message
  const { selectedReport } = useParams();
  const { user } = useContext(UserDataContext);
  const { channel } = useContext(ChannelAdminContext);
  const [embedURL, setURL] = useState('');

  const availableReports = useChannelInsightReports();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // It's possible the user got here without having this permission
  // rather than draw graphs they can't see, just show the name
  // of the channel.
  const hasAdminPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_ANALYTICS_ADVANCED,
      ],
      channel?._id
    );

  async function getEmbedURL() {
    try {
      const { data } = await getClient().query({
        query: getLookerEmbedURL,
        variables: {
          selectedReport,
          channelId: channel?._id,
          channelName: channel?.name,
          timezone,
        },
        fetchPolicy: 'network-only',
      });

      setURL(data.me.lookerEmbedURL);
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    if (hasAdminPermission && selectedReport && channel) {
      getEmbedURL();
    }
  }, []);

  return (
    <AdminPage className={styles.ChannelInsights}>
      {embedURL && (
        <>
          <BreadCrumbs
            links={[
              {
                label: t('web.admin.channel.insightsReports.title'),
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                url: routes.channelAdminInsights.replace(':id', channel?.slug),
              },
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              { label: t(availableReports[selectedReport]?.label) },
            ]}
          />
          <div className={styles.breakRow} />
          <iframe
            title={t('web.admin.channel.insightsReports.report.title')}
            id="embed-iframe"
            className={styles.LookerEmbed}
            frameBorder="0"
            src={embedURL}
          />
        </>
      )}
      {!hasAdminPermission && <h1>{getDisplayName(channel)}</h1>}
    </AdminPage>
  );
}
