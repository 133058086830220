import { ApolloError, NetworkStatus } from '@apollo/client';

import useLocation from 'lane-shared/hooks/location/useLocation';

import { CIRCUMFERENCE_OF_EARTH } from '../../helpers/constants';
import useChannelsSearchQuery from '../../hooks/useChannelsSearchQuery';
import { Channel } from '../../types/ChannelType';
import { ActiveChannelTypeEnum } from 'constants-channel';

export type CompanyParentSearchResult = {
  companies: Channel[];
  loading: boolean;
  called: boolean;
  error: ApolloError | null | undefined;
  networkStatus: NetworkStatus;
  fetchMore: () => void;
  refetch: () => void;
};

const COMPANY_TYPES = [
  ActiveChannelTypeEnum.Company,
  ActiveChannelTypeEnum.Restaurant,
  ActiveChannelTypeEnum.Retail,
  ActiveChannelTypeEnum.Entertainment,
  ActiveChannelTypeEnum.Service,
];

type Props = {
  search: any;
  skip?: boolean;
};

export default function useCompanyParentSearch({
  search,
  skip,
}: Props): CompanyParentSearchResult {
  const { location } = useLocation();

  const variables: any = {
    pagination: { start: 0, perPage: 25 },
    search: {
      sortBy: { key: 'name', dir: 'asc' },
      type: { any: COMPANY_TYPES },
      isSub: false,
      search: { type: 'like', value: search },
      parent: null,
    },
  };

  // If we have valid location data, add search variable
  if (location.latitude !== 0 && location.longitude !== 0) {
    variables.search.address = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
        distance: CIRCUMFERENCE_OF_EARTH,
      },
    };
  }

  // useChannelsSearchQuery will check to see if variables changed before
  // doing a re-render, so we don't need to ref or set variables in state
  const {
    loading,
    error,
    called,
    channels,
    fetchMore,
    refetch,
    networkStatus,
  } = useChannelsSearchQuery({
    variables,
    skip: !(search?.length > 1) || skip,
  });

  return {
    companies: channels,
    loading,
    error,
    called,
    fetchMore,
    refetch,
    networkStatus,
  };
}
