import * as requestClient from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client';

import {
  INTERACTION_ASSIGNED,
  INTERACTION_CANCELLED,
  INTERACTION_COMPLETE,
  INTERACTION_IN_PROGRESS,
} from 'constants-interactions';

export const STATUS_MAP: {
  [key: string]: { label: string; color: string; laneStatus: string };
} = {
  SCHEDULED: {
    label: 'Scheduled',
    color: 'honey',
    laneStatus: INTERACTION_ASSIGNED,
  },
  CHECKED_IN: {
    label: 'Checked-In',
    color: 'green',
    laneStatus: INTERACTION_IN_PROGRESS,
  },
  CHECKED_OUT: {
    label: 'Checked-Out',
    color: 'gray',
    laneStatus: INTERACTION_COMPLETE,
  },
  CANCELED: {
    label: 'Canceled',
    color: 'red',
    laneStatus: INTERACTION_CANCELLED,
  },
};

export const SORT_KEYS: {
  [key: string]: requestClient.visitors.FetchVisitsOrderingEnum;
} = {
  status: requestClient.visitors.FetchVisitsOrderingEnum.StatusAsc,
  expectedArrival: requestClient.visitors.FetchVisitsOrderingEnum.DateAsc,
  visitor: requestClient.visitors.FetchVisitsOrderingEnum.NameAsc,
};
export const selectedTabFilter = {
  upcoming: requestClient.visitors.FetchVisitorsFilterValues.DateGt,
  past: requestClient.visitors.FetchVisitorsFilterValues.DateLte,
};

export function mapStatusToLaneStatus(
  status: string
): { label: string; color: string; laneStatus: string } | undefined {
  return STATUS_MAP[status];
}

/** Start and End time are validated separately from the rest of the visit.
 * The custom validation logic cannot use values from other fields for
 * validation at the moment
 */
export function validateStartAndEndTime({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}): boolean {
  let isValid = true;

  if (endTime && startTime) {
    const endTimeParsed = parseTimeToDateTime(endTime);
    const startTimeParsed = parseTimeToDateTime(startTime);

    isValid = endTimeParsed > startTimeParsed;
  }

  return isValid;
}

/**
 * Helper function for parsing timestamps.
 * lane-mobile sends dates as ISO datetime strings while lane-mobile
 * sends them as 'HH:TT AM/PM'.
 * Use 'HH:TT AM/PM' as a regular timestamp with the date
 * included and compares entire datetimes instead of just
 * times
 * @see https://stackoverflow.com/a/1353710
 * @returns time since epoch in milliseconds
 */
function parseTimeToDateTime(timeString: string): number {
  let timestamp = Date.parse(timeString);

  if (Number.isNaN(timestamp) === true) {
    timestamp = Date.parse(`2023/01/01 ${timeString}`);
  }

  return timestamp;
}
