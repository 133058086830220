import React, { useState } from 'react';

import { H5 } from 'components/typography';
import { useTranslation } from 'react-i18next';
import { toSchema } from 'lane-shared/helpers';
import MetatagListItem from 'components/lane/MetatagListItem';
import { ContentFilterListItem } from './ContentFilterListItem';

import {
  ContentGroupByEnum,
  PresetContentFilter,
  PresetContentSort,
  PresetContentSortFriendlyName,
} from 'constants-content';
import { Flex } from 'components/layout';
import { FiltersModal } from './FiltersModal';
import { ToggleView } from 'components';
import { Button, Icon, Dropdown } from 'design-system-web';

import type { Channel } from 'lane-shared/types/ChannelType';
import type { SectionType } from 'lane-shared/types/sections/SectionType';
import styles from './SectionFilters.scss';

const emptyFilter = '' as PresetContentFilter;

type SectionFiltersProps = {
  channel: Channel;
  section: SectionType;
  onSectionUpdated: (section: Partial<SectionType>) => void;
};

export const SectionFilters = ({
  channel,
  section,
  onSectionUpdated,
}: SectionFiltersProps) => {
  const { t } = useTranslation();

  const [isMetatagLibraryOpen, setIsMetatagLibraryOpen] = useState(false);

  const onAddFilterClick = () =>
    onSectionUpdated({
      filters: [...section.filters, emptyFilter],
    });

  const sortItems = Object.values(PresetContentSort).map(value => ({
    label: PresetContentSortFriendlyName[value],
    value,
  }));

  const selectedSort = section.sorts[0] || '';

  return (
    <div className={styles.filterContainer}>
      {/* Standard Filters */}
      <div className={styles.filterToggle}>
        <ToggleView
          defaultExpanded={section.filters?.length > 0}
          title={<H5>{t('web.admin.section.standardFilters')}</H5>}
        >
          <Flex m={[4, 0, 0, 0]}>
            <ul data-test="filterList" className={styles.toggleItem}>
              {section.filters?.map((filter: string) => (
                <li key={filter}>
                  <ContentFilterListItem
                    sectionType={section.type}
                    filter={filter}
                    filters={section.filters}
                    onChange={(filters: any) => onSectionUpdated({ filters })}
                  />
                </li>
              ))}
            </ul>
          </Flex>

          <div>
            <Button
              variant="secondary"
              size="large"
              testId="buttonStandardFilters"
              disabled={section.filters.includes(emptyFilter)}
              onClick={onAddFilterClick}
            >
              {t('Add')}
            </Button>
          </div>
        </ToggleView>
      </div>

      {/* Sorting */}
      <div className={styles.filterToggle}>
        <ToggleView
          defaultExpanded={section.sorts?.length > 0}
          title={<H5>{t('web.admin.section.sorting')}</H5>}
        >
          <Flex direction="column" m={[4, 0, 0, 0]}>
            <Dropdown
              items={sortItems}
              value={selectedSort}
              isFullWidth
              onValueChange={newValue => {
                if (newValue === PresetContentSort.None) {
                  onSectionUpdated({ sorts: [] });
                } else {
                  onSectionUpdated({ sorts: [newValue] });
                }
              }}
            />
          </Flex>
        </ToggleView>
      </div>

      {/* GroupBy */}
      <div className={styles.filterToggle}>
        <ToggleView
          defaultExpanded={section.groupBy !== ContentGroupByEnum.None}
          title={<H5>{t('web.admin.section.groupBy')}</H5>}
        >
          <Flex direction="column" m={[4, 0, 0, 0]}>
            <Dropdown
              isFullWidth
              items={Object.values(ContentGroupByEnum).map(toSchema)}
              value={section.groupBy}
              onValueChange={groupBy => onSectionUpdated({ groupBy })}
            />
          </Flex>
        </ToggleView>
      </div>

      {/* Custom filters */}
      <div className={styles.filterToggle}>
        <ToggleView
          defaultExpanded={
            !!(
              section?.sectionMetatags?.length &&
              section?.sectionMetatags?.length > 0
            )
          }
          title={<H5>{t('web.admin.section.customFilters')}</H5>}
        >
          <Flex m={[4, 0, 0, 0]}>
            <ul>
              {section?.sectionMetatags?.reduce<Array<React.ReactNode>>(
                (prev, sectionMetatag, index) => {
                  if (sectionMetatag.metatag.toRemove) {
                    return prev;
                  }

                  prev.push(
                    <li key={sectionMetatag.metatag._id}>
                      <Flex mb={4} align="center" gap={2}>
                        <MetatagListItem metatag={sectionMetatag.metatag} />
                        <div>
                          <Button
                            variant="text"
                            onClick={() => {
                              let deletedSectionMetaTag;

                              if (sectionMetatag._id) {
                                sectionMetatag.metatag.toRemove = true;
                              } else {
                                deletedSectionMetaTag =
                                  section.sectionMetatags?.splice(index, 1);
                              }

                              onSectionUpdated({
                                sectionMetatags: section.sectionMetatags,
                              });

                              return deletedSectionMetaTag;
                            }}
                            startIcon={<Icon name="trash-alt" />}
                          />
                        </div>
                      </Flex>
                    </li>
                  );

                  return prev;
                },
                []
              )}
            </ul>
          </Flex>

          <div>
            <Button
              variant="secondary"
              onClick={() => setIsMetatagLibraryOpen(true)}
              size="large"
            >
              {t('Add')}
            </Button>
          </div>
        </ToggleView>
      </div>

      <FiltersModal
        channel={channel}
        section={section}
        onSectionUpdated={onSectionUpdated}
        isMetatagLibraryOpen={isMetatagLibraryOpen}
        setIsMetatagLibraryOpen={setIsMetatagLibraryOpen}
      />
    </div>
  );
};
