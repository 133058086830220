import React from 'react';
import { ResidentStatusEnum } from 'constants-user';
import { ChipSelect, ChipStyle } from 'components/ads';
import { TFunction } from 'i18next';

const StatusLabelColorMapping: Omit<
  Record<ResidentStatusEnum, { label: string; color: ChipStyle }>,
  | ResidentStatusEnum.NO_PRIOR_STATUS
  | ResidentStatusEnum.RESIDENT_STATUS_INVALID
  | ResidentStatusEnum.UNRECOGNIZED
> = {
  [ResidentStatusEnum.RESIDENT_STATUS_CURRENT]: {
    label: 'web.admin.channel.member.residentProfile.status.current',
    color: ChipStyle.Green,
  },
  [ResidentStatusEnum.RESIDENT_STATUS_FUTURE]: {
    label: 'web.admin.channel.member.residentProfile.status.future',
    color: ChipStyle.Yellow,
  },
  [ResidentStatusEnum.RESIDENT_STATUS_PAST]: {
    label: 'web.admin.channel.member.residentProfile.status.past',
    color: ChipStyle.Grey,
  },
  [ResidentStatusEnum.RESIDENT_STATUS_NOTICE]: {
    label: 'web.admin.channel.member.residentProfile.status.onNotice',
    color: ChipStyle.Green,
  },
  [ResidentStatusEnum.RESIDENT_STATUS_EVICTION]: {
    label: 'web.admin.channel.member.residentProfile.status.underEviction',
    color: ChipStyle.Red,
  },
};

export type ResidentStatus = keyof typeof StatusLabelColorMapping;

type Props = {
  t: TFunction;
  status: ResidentStatus;
};

export default function ResidentStatusChip({ t, status }: Props) {
  let statusLabel = StatusLabelColorMapping[status].label;

  statusLabel = statusLabel ? t(statusLabel) : statusLabel;
  const statusColor = StatusLabelColorMapping[status].color;

  return (
    <ChipSelect.NonInteractive
      size="xs"
      type={statusColor}
      value={statusLabel}
      doTranslate={false}
    />
  );
}
