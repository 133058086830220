import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/browser';

import { ChannelsContext, ThemeContext } from 'lane-shared/contexts';
import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import { validationShape } from 'lane-shared/helpers/integrations/AngusServiceRequests';
import useAngusWorkOrder from 'lane-shared/hooks/integrations/useAngusWorkOrder';
import { IntegrationProviderEnum } from 'constants-integrations';

import Dropdown from 'components/form/Dropdown';
import ValidatedDropdown from 'components/form/ValidatedDropdown';
import ValidatedTextArea from 'components/form/ValidatedTextArea';
import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';

import history from '../../../helpers/history';

import styles from './RequestForm.scss';

function mapItem({ _id, name }: any) {
  return {
    value: _id,
    label: name,
  };
}

export default function RequestForm({ content }: any) {
  const theme = useContext(ThemeContext);
  const { refetchFocus } = useContext(ChannelsContext);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [floor, setFloor] = useState(null);
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [buildingId, setBuildingId] = useState(null);
  const {
    workOrder,
    updateWorkOrder,
    requestTypes,
    buildings,
    floors,
    suites,
    validate,
  } = useAngusWorkOrder({ content, buildingId, floor });

  useEffect(() => {
    if (hasAttemptedSubmit) {
      validate(workOrder);
    }
  }, [workOrder, hasAttemptedSubmit]);

  async function submitWorkOrder() {
    setHasAttemptedSubmit(true);

    if (!validate(workOrder)) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction: {
          data: {
            // create the interaction data using the name from the integration providers.
            [`_${IntegrationProviderEnum.AngusServiceRequests}`]: workOrder,
          },
        },
      });

      refetchFocus();

      setOrderSubmitted(true);

      await window.Alert.alert({
        title: t('Success!'),
        message: t('Your work order was successfully submitted'),
      });

      history.goBack();
    } catch (err) {
      setError(err);
      Sentry.captureException(err);
    }

    setLoading(false);
  }

  return (
    <div className={styles.container}>
      {error && <ErrorMessage error={error} />}
      <h3 style={{ color: theme.primary }}>{content?.name}</h3>
      <div className={styles.form}>
        <h2>{t('Enter Service Request Details')}:</h2>
        <Dropdown
          className={styles.dropdown}
          placeholder={t('Building (Required)')}
          value={buildingId}
          items={buildings.map(mapItem)}
          onValueChange={buildingId => {
            setBuildingId(buildingId);
            setFloor(null);
            updateWorkOrder({ locationRefNum: null });
          }}
        />
        <Dropdown
          className={styles.dropdown}
          placeholder={t('Floor (Required)')}
          value={(floor as any)?._id}
          items={floors.map(mapItem)}
          onValueChange={floorId => {
            setFloor(floors.find((f: any) => f._id === floorId));
            updateWorkOrder({ locationRefNum: null });
          }}
        />
        <ValidatedDropdown
          className={styles.dropdown}
          placeholder={t('Suite')}
          validation={validationShape.locationRefNum}
          isPristine={!hasAttemptedSubmit}
          value={workOrder.locationRefNum}
          items={suites.map(mapItem)}
          onValueChange={locationRefNum => updateWorkOrder({ locationRefNum })}
        />
        <h2>{t('Request')}:</h2>
        <ValidatedDropdown
          className={styles.dropdown}
          validation={validationShape.requestTypeRefNum}
          isPristine={!hasAttemptedSubmit}
          placeholder={t('Service Request Type')}
          value={workOrder.requestTypeRefNum}
          items={requestTypes.map(mapItem)}
          onValueChange={requestTypeRefNum =>
            updateWorkOrder({ requestTypeRefNum })
          }
        />
        <ValidatedTextArea
          validation={validationShape.description}
          isPristine={!hasAttemptedSubmit}
          placeholder={t('Description')}
          onChange={description => updateWorkOrder({ description })}
          minRows={3}
          value={workOrder.description}
          className={styles.textArea}
        />
        <Button
          className={styles.button}
          onClick={submitWorkOrder}
          loading={loading}
          disabled={loading || orderSubmitted}
        >
          {t('Submit')}
        </Button>
      </div>
    </div>
  );
}
