import React from 'react';

import { MediaEdit } from 'components';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { MediaType } from 'lane-shared/types/media';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { LibraryTypeEnum } from 'constants-content';

import DocumentsLibrary from 'components/lane/DocumentsLibrary';
import { AdminPage } from 'components/layout';
import type { Media } from 'graphql-query-contracts';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';

type Props = {
  channel?: Channel;
};

export const DocumentLibraryIndex = ({ channel }: Props) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path} component={ChannelDocuments} />
      <Route
        exact
        path={`${path}/:mediaId/edit`}
        render={({ history, match }) => (
          <MediaEdit match={match} history={history} channel={channel} />
        )}
      />
    </>
  );
};

const ChannelDocuments = () => {
  const { channel } = useChannelAdminContext();
  const history = useHistory();

  if (!channel) {
    return null;
  }

  return (
    <AdminPage className={styles.ChannelDocument}>
      <DocumentsLibrary
        className={styles.documentLibrary}
        storageKey={channel._id}
        library={{
          type: LibraryTypeEnum.Channel,
          _id: channel._id,
          name: getDisplayName(channel),
        }}
        onMediaDoubleClicked={(
          media: MediaType | Media,
          libraryItemId: string
        ) =>
          history.push(
            `${history.location.pathname}/${media._id}/edit?libraryItemId=${libraryItemId}`
          )
        }
      />
    </AdminPage>
  );
};
