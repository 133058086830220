import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import cx from 'classnames';

import { useLazyQuery, useQuery } from '@apollo/client';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdf } from '@react-pdf/renderer';
import makeFileDownload from 'helpers/makeFileDownload';

import { getClient } from 'lane-shared/apollo';
import { routes, AmazonS3Buckets } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { getPublicUser } from 'lane-shared/graphql/query';
import { getDisplayName } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import { convertTo62, convertToUUID, safeConvertToUUID } from 'uuid-encoding';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { PublicProfileType } from 'lane-shared/types/User';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentResponse,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';

import { Button, Flex, Box, AddAttachment, Label, TabStrip } from 'components';
import { TabItem } from 'components/general/TabStrip';
import { BreadCrumbs } from 'components/lds';
import { H3, P, H4 } from 'components/typography';
import { EntityNotesAndHours } from 'domains/workOrder/serviceRequest/components/EntityNotesAndHours';

import {
  getTaskAuditLogsQuery,
  getTaskByIdQuery,
  updatePMTaskMutation,
  updatePMTaskStepsMutation,
} from 'graphql-queries';
import { ClientTask } from 'graphql-query-contracts';
import type { StepExecution } from 'graphql-query-contracts';

import AssignedMemberDropdown from 'pages/portal/admin/channel/service-requests/details/components/AssignedMemberDropdown';
import { StaffTeamsMultiSelectDropdown } from 'pages/portal/admin/channel/service-requests/details/components/StaffTeamsMultiSelectDropdown';

import { StepExecutionForm } from 'domains/workOrder/preventiveMaintenance/schedule/components';
import PMTaskDetailsPDF from '../../components/PMTaskDetailsPDF';
import { PMTaskStatusDropdown } from '../../components/TaskStatusDropDown/PMTaskStatusDropdown';

import styles from './index.scss';
import { ActivityTab } from '../../components/Activity/Activity';
import { debounce } from 'lodash';

enum TaskTabsEnum {
  Details = 'details',
  Steps = 'steps',
  TimeAndNotes = 'timeAndNotes',
  Activity = 'activity',
}

export function TaskDetailsPage({ channel }: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { taskId } = useParams<{ taskId: string }>();
  const newTaskDetailsForm = useFlag(
    FeatureFlag.InteractiveStepsExecute,
    false
  );

  const isAuditLogsEnabled = useFlag(FeatureFlag.WorkOrdersAuditLogs, false);

  const [stepsCollapsed, setStepsCollapsed] = useState(false);
  const [meterReadingsCollapsed, setMeterReadingsCollapsed] = useState(false);

  const [assignedMember, setAssignedMember] = useState<{
    label: string;
    profile: PublicProfileType;
    value: string;
  }>();

  const [assignedTeams, setAssignedTeams] = useState<
    {
      label: string;
      profile: string;
      value: string;
    }[]
  >();

  const [currentSteps, setCurrentSteps] = useState<StepExecution[]>([]);

  const handleStepResponseChange = (step: StepExecution) => {
    setCurrentSteps(prevSteps => {
      const newSteps = prevSteps.map(cs => (cs.id === step.id ? step : cs));

      updateTaskSteps.current(newSteps);

      return newSteps;
    });
  };

  const updateTaskSteps = useRef(
    debounce(async (steps: StepExecution[]) => {
      if (!taskId || !user?._id) {
        console.error('Invalid taskId or user._id');

        return;
      }

      try {
        await getClient().mutate({
          mutation: updatePMTaskStepsMutation,
          variables: {
            task: {
              id: convertToUUID(taskId),
              updatedBy: convertToUUID(user?._id),
              stepExecution: steps.map(step => ({
                id: step.id,
                name: step.name,
                type: step.type,
                order: step.order,
                taskId: step.taskId,
                isComplete: step.isComplete,
                meterReadingId: step.meterReadingId,
                response: step.response,
                options: {
                  subtype: step.options?.subtype,
                  description: step.options?.description,
                  choices: step.options?.choices,
                  unit: step.options?.unit,
                },
              })),
            },
          },
        });
      } catch (err: any) {
        await window.Alert.alert({
          title: t(
            'web.admin.workOrder.preventiveMaintenance.task.status.update.failure'
          ),
          error: err,
        });
      }
    }, 1000)
  );

  useEffect(() => {
    const debouncedUpdateTaskSteps = updateTaskSteps.current;

    return () => {
      debouncedUpdateTaskSteps.cancel();
    };
  }, []);

  const { data, loading, refetch } = useQuery(getTaskByIdQuery, {
    variables: {
      id: convertToUUID(taskId),
    },
    onCompleted: () => {
      setCurrentSteps(data?.task?.stepExecution || []);
    },
  });

  const {
    data: activityLogData,
    loading: auditLogLoading,
    refetch: refetchAuditLogs,
  } = useQuery(getTaskAuditLogsQuery, {
    variables: {
      taskId: convertToUUID(taskId),
    },
    fetchPolicy: 'network-only',
  });

  const refetchData = () => {
    refetch();
    refetchAuditLogs();
  };

  const task: ClientTask = data?.task;

  const isAdmin =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_ADMIN],
      channel?._id
    );

  const taskListPath = isAdmin
    ? routes.channelAdminWorkOrdersPMTasks.replace(':id', channel?.slug)
    : routes.channelStaffWorkOrdersPMTasks.replace(':id', channel?.slug);

  const [publicUser, { data: userData }] = useLazyQuery(getPublicUser);

  const hasUploadAttachmentsPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE],
      channel?._id,
      false
    );

  const userHasEditStatusPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS],
      channel?._id,
      false
    );

  const hasIndividualAssignPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE],
      channel?._id,
      false
    );

  const hasTeamAssignPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM],
      channel?._id,
      false
    );

  const hasEquipmentAccess =
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW],
      channel?._id,
      false
    ) || true;

  const hasScheduleAccess = hasPermission(
    user?.roles,
    [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW],
    channel?._id,
    false
  );

  const hasCanBeAssignedPermission = hasPermission(
    user?.roles,
    [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM],
    channel?._id
  );

  const isEquipmentEnabled = channel?.settings?.hasWorkOrderEquipmentEnabled;

  const tabs: TabItem[] = [
    {
      label: t('web.admin.workOrder.preventiveMaintenance.task.tabs.details'),
      value: TaskTabsEnum.Details,
    },
    ...(newTaskDetailsForm
      ? [
          {
            label: t(
              'web.admin.workOrder.preventiveMaintenance.task.tabs.steps'
            ),
            value: TaskTabsEnum.Steps,
          },
        ]
      : []),
    {
      label: t(
        'web.admin.workOrder.preventiveMaintenance.task.tabs.hoursAndNotes'
      ),
      value: TaskTabsEnum.TimeAndNotes,
    },
  ];

  if (isAuditLogsEnabled) {
    tabs.push({
      label: t('web.admin.workOrder.preventiveMaintenance.task.tabs.activity'),
      value: TaskTabsEnum.Activity,
    });
  }

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]!);

  const handleTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
  };

  const [assignableUsers, _, assignableTeams] = useAssignableMembers(
    channel?._id
  );

  const convertTeamToID = (teams: { _id: string; name: string }[]) => {
    return teams?.map(team => safeConvertToUUID(team?._id));
  };

  const convertTask = (task: ClientTask) => {
    return {
      ...task,
      assignee: task?.assignee?._id,
      assigneeGroup: convertTeamToID(task?.assigneeGroup || []),
      status: task?.status || '',
    };
  };

  useEffect(() => {
    if (task?.createdBy) {
      publicUser({
        variables: {
          id: convertToUUID(task?.createdBy),
        },
      });
    }
  }, [task?.createdBy]);

  useEffect(() => {
    if (
      !assignedMember ||
      convertTo62(assignedMember.value) !== task?.assignee?._id
    ) {
      if (hasIndividualAssignPermission || hasCanBeAssignedPermission) {
        const userToAssign = assignableUsers.find(
          user => convertTo62(user.value) === task?.assignee?._id
        );

        if (userToAssign) setAssignedMember(userToAssign);
      } else if (user && task?.assignee?._id === user?._id) {
        setAssignedMember({
          label: getDisplayName(user),
          value: convertTo62(user._id),
          profile: user.profile,
        });
      }
    }

    if (
      !assignedTeams ||
      assignedTeams.length !== task?.assigneeGroup?.length
    ) {
      const assigneeGroup = task?.assigneeGroup
        ?.filter(group => group !== null)
        .map(group => group?._id);
      const teamToAssign = assignableTeams.filter(team =>
        assigneeGroup?.includes(convertTo62(team.value))
      );

      if (teamToAssign) setAssignedTeams(teamToAssign);
    }
  }, [
    task?.assignee,
    JSON.stringify(task?.assigneeGroup),
    JSON.stringify(assignableTeams),
    JSON.stringify(assignableUsers),
  ]);

  const updateAssignee = async (assignee?: {
    label: string;
    profile: string;
    value: string;
  }) => {
    if (assignee?.value === 'unassign') {
      await unassignTask();
    } else {
      await updateTask(
        assignee?.value ? convertToUUID(assignee?.value) : '',
        convertTeamToID(task.assigneeGroup || []),
        task?.attachments ?? []
      );
    }
  };

  const updateAssigneeGroup = async (
    assigneeTeam: { value: string | null | undefined }[]
  ) => {
    await updateTask(
      task?.assignee?._id || '',
      assigneeTeam
        ? assigneeTeam.map(team => safeConvertToUUID(team.value))
        : convertTeamToID(task.assigneeGroup || []),
      task?.attachments ?? []
    );
  };

  const onTaskUpdated = useCallback(
    async (fields: any) => {
      try {
        await getClient().mutate({
          mutation: updatePMTaskMutation,
          variables: {
            task: {
              id: convertToUUID(task?.id),
              assignee: task.assignee?._id,
              assigneeGroup: task.assigneeGroup
                ? task.assigneeGroup.map(group => group._id)
                : null,
              attachments: task.attachments,
              notes: task.notes,
              hours: task.hours,
              minutes: task.minutes,
              ...fields,
            },
          },
        });
        refetchData();
        await window?.Toast?.show(
          t(
            'web.admin.workOrder.preventiveMaintenance.task.details.toast.success'
          )
        );
      } catch (err: any) {
        throw new Error(err);
      }
    },
    [
      refetch,
      refetchAuditLogs,
      task?.id,
      task?.assignee,
      task?.assigneeGroup,
      task?.attachments,
      task?.notes,
      task?.hours,
      task?.minutes,
    ]
  );

  const updateTask = async (
    assignee: string | undefined,
    assigneeTeam: string[] | undefined,
    attachments: string[]
  ) => {
    const taskData = {
      id: convertToUUID(task?.id),
      assignee,
      assigneeGroup: assigneeTeam,
      attachments,
    };

    try {
      await getClient().mutate({
        mutation: updatePMTaskMutation,
        variables: {
          task: {
            ...taskData,
          },
        },
      });
      await refetch();
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.task.details.toast.success`
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err: any) {
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.task.details.toast.error`
      );
    }
  };

  const handleAttachmentCreated = async (attachments: AttachmentResponse[]) => {
    await updateTask(
      task?.assignee?._id || '',
      convertTeamToID(task?.assigneeGroup || []),
      attachments.map(attachment => attachment.id)
    );
  };

  const handleAttachmentDeleted = async (attachmentId: string) => {
    const attachmentIds =
      task.attachments?.filter(id => id !== attachmentId) ?? [];

    await updateTask(
      task?.assignee?._id || '',
      convertTeamToID(task?.assigneeGroup || []),
      attachmentIds
    );
  };

  const unAssignOption = {
    label: t`web.admin.workOrder.shared.unassign`,
    value: 'unassign',
    profile: {
      name: t`web.admin.workOrder.shared.unassign`,
    },
  };

  const assignMyselfOption = {
    label: t`web.admin.workOrder.preventiveMaintenance.task.details.assignMyself`,
    value: user?._id ? convertTo62(user?._id) : '',
    profile: {
      name: t`web.admin.workOrder.preventiveMaintenance.task.details.assignMyself`,
    },
  };

  const isCurrentUserExist = assignableUsers.some(
    user => user.value === assignMyselfOption.value
  );

  const currentUserOption = {
    label: getDisplayName(user),
    value: user?._id ? convertTo62(user?._id) : '',
    profile: user?.profile,
  };

  const currentlyAssigned =
    task?.assignee && task?.assignee?._id
      ? assignableUsers.filter(
          assignee => assignee.value === convertTo62(task?.assignee?._id)
        )
      : [];

  const assigneeOptions = [
    ...(!hasIndividualAssignPermission && task?.assignee
      ? currentlyAssigned
      : []),
    ...(task?.assignee ? [unAssignOption] : []),
    ...(hasCanBeAssignedPermission &&
    (!task?.assignee?._id || task?.assignee?._id !== user?._id)
      ? [assignMyselfOption]
      : []),
    ...(hasIndividualAssignPermission ? assignableUsers : []),
  ];

  if (hasCanBeAssignedPermission && !isCurrentUserExist) {
    // ensure to available in the options
    assigneeOptions.push(currentUserOption);
    // to set the value in setAssignedMember
    assignableUsers.push(currentUserOption);
  }

  async function unassignTask(
    assigneeTeam?: { value: string | null | undefined }[]
  ) {
    try {
      await window.Alert.confirm({
        title: t('web.staff.task.unassign.modal.title'),
        message: t('web.staff.task.unassign.modal.message'),
        confirmText: t`web.staff.task.unassign.modal.button.text`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    try {
      setAssignedMember(undefined);
      await updateTask(
        '',
        assigneeTeam && assigneeTeam.length > 0
          ? assigneeTeam.map(team => convertToUUID(team.value))
          : convertTeamToID(task?.assigneeGroup || []),
        task?.attachments ?? []
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // do nothing
    }
  }

  const taskRef = useRef(task);

  useEffect(() => {
    taskRef.current = task;
  }, [task]);

  const handleExport = async () => {
    try {
      await Promise.all([refetch(), refetchAuditLogs()]);

      const blob = await pdf(
        <PMTaskDetailsPDF
          taskData={convertTask(taskRef.current)}
          createdUserName={getDisplayName(userData?.user) || ''}
          auditLogs={activityLogData?.taskAuditLogs}
          t={t}
          isAuditLogsEnabled={isAuditLogsEnabled}
        />
      ).toBlob();

      makeFileDownload({
        blob,
        name: `${task?.userFriendlyID}.pdf`,
        type: 'application/pdf',
      });
    } catch (error) {
      await window.Alert.alert({
        title: t('An Error Occurred!'),
        message: t('Unable to download the task. Please try again.'),
      });
      console.error('Error while downloading the PDF:', error);
    }
  };

  return (
    <div className={styles.TaskDetailsPage}>
      <BreadCrumbs
        links={[
          {
            label: t`web.admin.workOrder.preventiveMaintenance.title`,
            url: taskListPath,
          },
          {
            label: t`web.admin.workOrder.preventiveMaintenance.taskTabHeading`,
            url: taskListPath,
          },
          {
            label: `${task?.userFriendlyID}`,
          },
        ]}
      />
      <Flex
        className={styles.TaskDetailsHeader}
        direction="row"
        justify="space-between"
        align="flex-start"
      >
        <Flex direction="column" gap={4}>
          <H3>{task?.userFriendlyID}</H3>
          <span>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.createdBy',
              {
                date: simpleDate(task?.createdAt),
                name: userData?.user?.name,
              }
            )}
          </span>
          <Flex gap={5}>
            <PMTaskStatusDropdown
              taskData={convertTask(task)}
              isTaskList={false}
              refetch={refetchData}
              pillSize="md"
              disableEdit={!userHasEditStatusPermission}
            />
            <StaffTeamsMultiSelectDropdown
              testId="teamAssigneeDropdown"
              items={assignableTeams}
              disabled={!hasTeamAssignPermission}
              isSearchable
              onChange={assigneeTeam => updateAssigneeGroup(assigneeTeam)}
              value={assignedTeams as any}
              placeholder={t(
                'web.admin.workOrder.preventiveMaintenance.task.details.assignedToTeam.select'
              )}
              label={t`web.admin.workOrder.preventiveMaintenance.task.details.assignedToTeam`}
              doTranslate={false}
            />
            <AssignedMemberDropdown
              testId="assigneeDropdown"
              fieldName="assign-to-person"
              users={assigneeOptions}
              setAssignedMember={updateAssignee}
              disabled={
                !(hasIndividualAssignPermission || hasCanBeAssignedPermission)
              }
              assignedMember={assignedMember}
              placeholder={t`web.admin.workOrder.preventiveMaintenance.task.details.assignedTo.select`}
              label={t`web.admin.workOrder.preventiveMaintenance.task.details.assignedTo`}
              className={styles.SelectDropdown}
              doTranslate={false}
            />
          </Flex>
        </Flex>
        <Flex align="center" gap={4}>
          <Flex m={2}>
            <Button
              className={styles.exportButton}
              variant="text"
              onClick={handleExport}
              loading={loading || auditLogLoading}
            >
              <Flex>
                <FontAwesomeIcon icon={faArrowToBottom} />
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.actions.export'
                )}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column">
        <TabStrip
          tabs={tabs}
          selected={selectedTab}
          onSelectTab={handleTabChange}
          className={styles.taskTabStrip}
          fullWidth
          skipLabelTranslation
        />
      </Flex>

      {selectedTab.value === TaskTabsEnum.Details ? (
        <Flex direction="column">
          <Flex direction="row" width="full">
            <Flex
              className={styles.TaskDetailsBlock}
              width="full"
              direction="column"
              gap={4}
            >
              <H4 mt={2}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.heading'
                )}
              </H4>
              <Box className={styles.TaskDetailsScheduleGrid}>
                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.schedule`}
                  </Label>
                  {task?.schedule?.id &&
                    (hasScheduleAccess ? (
                      <Link
                        to={routes.channelAdminWorkOrdersPMScheduleDetails
                          .replace(':id', channel?.slug)
                          .replace(':scheduleId', task?.schedule?.id)}
                      >
                        {task?.schedule?.title}
                      </Link>
                    ) : (
                      <P>{task?.schedule?.title}</P>
                    ))}
                </Flex>
                {task?.equipment?.length === 0 && (
                  <Flex direction="column">
                    <Label className={styles.detailsText}>
                      {t`web.admin.workOrder.preventiveMaintenance.task.details.equipment.location`}
                    </Label>
                    <P mb={2}>{task?.location}</P>
                  </Flex>
                )}
                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.repeats`}
                  </Label>
                  <P>{task?.repeats?.label}</P>
                </Flex>
              </Box>
              <Box className={styles.TaskDetailsScheduleGrid}>
                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.timeToComplete`}
                  </Label>
                  <P>
                    {task?.estimateTimeToComplete.toFixed(2)}{' '}
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.hoursUnit`}
                  </P>
                </Flex>

                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.dueDate`}
                  </Label>
                  <P>{simpleDate(task?.dueDate!)}</P>
                </Flex>
                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.task.details.completedDate`}
                  </Label>
                  <P>
                    {' '}
                    {task?.completedBy ? simpleDate(task?.completedAt!) : '-'}
                  </P>
                </Flex>
              </Box>

              <Flex direction="column">
                <Label className={styles.detailsText}>
                  {t`web.admin.workOrder.preventiveMaintenance.task.details.scheduleNotes`}
                </Label>
                <P className={styles.multiLineText} mb={2}>
                  {task?.scheduleNotes}
                </P>
              </Flex>
            </Flex>
          </Flex>

          {!newTaskDetailsForm && task?.steps && (
            <Flex
              className={styles.TaskDetailsBlock}
              width="full"
              direction="column"
            >
              <Flex direction="row" align="center" justify="space-between">
                <H4 mb={3} mt={2}>
                  {t`web.admin.workOrder.preventiveMaintenance.task.details.steps`}
                </H4>
                <Button
                  variant="text"
                  onClick={() => setStepsCollapsed(!stepsCollapsed)}
                >
                  {stepsCollapsed
                    ? t`web.admin.workOrder.preventiveMaintenance.task.details.expand`
                    : t`web.admin.workOrder.preventiveMaintenance.task.details.collapse`}
                </Button>
              </Flex>
              <p
                className={styles.CollapsableText}
                data-closed={stepsCollapsed}
              >
                {task?.steps}
              </p>
            </Flex>
          )}

          {!newTaskDetailsForm && task?.meterReading && (
            <Flex
              className={styles.TaskDetailsBlock}
              width="full"
              direction="column"
            >
              <Flex direction="row" align="center" justify="space-between">
                <H4 mb={3} mt={2}>
                  {t`web.admin.workOrder.preventiveMaintenance.task.details.meterReading`}
                </H4>
                <Button
                  variant="text"
                  onClick={() =>
                    setMeterReadingsCollapsed(!meterReadingsCollapsed)
                  }
                >
                  {meterReadingsCollapsed
                    ? t`web.admin.workOrder.preventiveMaintenance.task.details.expand`
                    : t`web.admin.workOrder.preventiveMaintenance.task.details.collapse`}
                </Button>
              </Flex>
              <P
                className={styles.CollapsableText}
                data-closed={meterReadingsCollapsed}
              >
                {task?.meterReading}
              </P>
            </Flex>
          )}

          {task?.equipment && task?.equipment.length > 0 && (
            <Flex
              className={styles.TaskDetailsBlock}
              width="full"
              direction="column"
            >
              <H4 mb={3} mt={2}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.task.details.equipment.heading'
                )}
              </H4>
              {task.equipment.map(
                ({ category, name, id, location, floor, suite }, index) => (
                  <Flex
                    key={id}
                    direction="row"
                    className={cx(
                      styles.equipmentRow,
                      task.equipment &&
                        index !== task.equipment.length - 1 &&
                        styles.divider
                    )}
                  >
                    <Flex direction="column" className={styles.equipmentCol}>
                      {hasEquipmentAccess && isEquipmentEnabled ? (
                        <Link
                          to={routes.channelAdminWorkOrdersEquipmentDetails
                            .replace(':id', channel?.slug)
                            .replace(':equipmentId', id)}
                        >
                          {name}
                        </Link>
                      ) : (
                        <P>{name}</P>
                      )}
                      <span className={styles.categorySection}>
                        <P className={styles.categoryText}>
                          {t(
                            'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.category'
                          )}
                        </P>
                        <P>{category}</P>
                      </span>
                    </Flex>
                    <Flex direction="column" className={styles.equipmentCol}>
                      <span>
                        <P className={styles.detailsText}>
                          {t(
                            'web.admin.workOrder.preventiveMaintenance.task.details.equipment.location'
                          )}
                        </P>
                        <P>{location}</P>
                      </span>
                      {floor ? (
                        <span>
                          <P className={styles.detailsText}>
                            {t(
                              'web.admin.workOrder.preventiveMaintenance.task.details.equipment.floor'
                            )}
                          </P>
                          <P>{floor}</P>
                        </span>
                      ) : null}
                      {suite ? (
                        <span>
                          <P className={styles.detailsText}>
                            {t(
                              'web.admin.workOrder.preventiveMaintenance.task.details.equipment.suite'
                            )}
                          </P>
                          <P>{suite}</P>
                        </span>
                      ) : null}
                    </Flex>
                  </Flex>
                )
              )}
            </Flex>
          )}

          <Flex
            className={styles.TaskDetailsBlock}
            width="full"
            direction="column"
          >
            <Flex direction="column">
              <H4 mb={4} mt={2}>
                {t`web.admin.workOrder.preventiveMaintenance.task.attachments.title`}
              </H4>
              <AddAttachment
                entityId={convertToUUID(taskId)}
                entityType="PMTask"
                editMode={false}
                variant={AttachmentVariantEnum.WorkOrder}
                afterAttachmentCreated={handleAttachmentCreated}
                afterAttachmentDeleted={handleAttachmentDeleted}
                canAddAttachments={hasUploadAttachmentsPermission}
                acceptedFileTypes={[
                  ...Object.values(AttachmentImageContentTypeEnum),
                  AttachmentDocumentContentTypeEnum.pdf,
                ]}
                s3Bucket={AmazonS3Buckets.Activate}
              />
            </Flex>
          </Flex>
        </Flex>
      ) : null}

      {selectedTab.value === TaskTabsEnum.Steps ? (
        <StepExecutionForm
          channel={channel}
          steps={currentSteps}
          onStepChange={handleStepResponseChange}
          task={task}
        />
      ) : null}

      {selectedTab.value === TaskTabsEnum.TimeAndNotes ? (
        <EntityNotesAndHours
          entityData={task}
          onEntityUpdated={onTaskUpdated}
        />
      ) : null}

      {selectedTab.value === TaskTabsEnum.Activity && (
        <ActivityTab testId="tabActivity" taskId={taskId} />
      )}
    </div>
  );
}
