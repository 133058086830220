import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  NativeFilterTypes,
  Table,
  CustomFilterType,
  FilterType,
  QueryString,
  convertStringsToDates,
  Column,
} from 'design-system-web';
import { useQueryString } from 'hooks';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  AdminUser,
  useAssignableMembers,
} from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { queryChannelsByPermission } from 'lane-shared/graphql/channel';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import { convertTo62, convertToUUID, safeConvertToUUID } from 'uuid-encoding';
import { getTaskStatusLabel } from 'lane-shared/domains/workOrder/helpers';
import { exportCSV } from 'domains/workOrder/helpers/exportCSV';

import { IdNamePair, ClientTask } from 'graphql-query-contracts';
import { exportCSVTasksQuery, searchTasksQuery } from 'graphql-queries';
import {
  ACTIVE_TASK_STATUSES,
  INACTIVE_TASK_STATUSES,
} from '../../types/taskStatus';
import { WORK_ORDER_STAFF_TASK_TABLE_STATE } from 'helpers/constants/localstorageKey';

import { PMTaskStatusDropdown } from '../TaskStatusDropDown/PMTaskStatusDropdown';

import { StaffPreventiveMaintenanceTabs } from '../../../pages/preventiveMaintenance-home';
import { Channel } from 'packages/lane-shared/types/ChannelType';

const PER_PAGE = 25;

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'created_at',
  sortDirection: 'desc',
  // filters
  assignee: '',
  assignee_groups: '',
  equipment: '',
  keyword: '',
  location: '',
  next_due_date: '',
  recurrence: '',
};

type TaskQueryString = {
  equipment: string;
  schedule: string;
  status: string;
  due_date: string;
  completed_at: string;
  tab: string;
} & QueryString;

function StaffTaskList({ channel, params }: any) {
  const { t } = useTranslation();
  const transitionFilterFlag = useFlag(
    FeatureFlag.TaskStatusChangedBetweenFilter,
    false
  );

  const storedTableState = window.localStorage.getItem(
    WORK_ORDER_STAFF_TASK_TABLE_STATE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;
  const [searchParams, setSearchParams] = useQueryString<TaskQueryString>({
    ...initialTableParams,
    ...params,
  });

  const { user } = useContext(UserDataContext);

  const userHasEditStatusPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS],
      channel?._id,
      false
    );

  const hasScheduleAccess =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW],
      channel?._id,
      false
    );

  const [filterData, setFilterData] = useState<any>();

  const filter: any = {};
  const preFilter: any = { currentUser: convertToUUID(user?._id) };

  const userRole = user?.roles.filter(
    role =>
      role?.groupRole?.channel?._id === channel?._id &&
      role.groupRole.permissions.includes(
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_MODULE_ACCESS
      ) &&
      role.groupRole.permissions.includes(
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM
      )
  );

  const assignableGroupIds =
    userRole?.map(role => safeConvertToUUID(role?.groupRole?._id)) || [];

  const [assignableUsers, _, assignableTeams] = useAssignableMembers(
    channel?._id,
    assignableGroupIds
  );

  const assignableTeamsFilterOptions = assignableTeams?.map((s: AdminUser) => ({
    label: s.label,
    value: convertTo62(s.value),
  }));

  const assignableUsersFilterOptions = assignableUsers?.map((s: AdminUser) => ({
    label: s.label,
    value: convertTo62(s.value),
  }));

  let groupIds: string[] = [];

  if (userRole && userRole.length) {
    groupIds = userRole.map((role: { groupRole: { _id: string } }) =>
      safeConvertToUUID(role?.groupRole?._id)
    );
  }

  if (searchParams.tab === StaffPreventiveMaintenanceTabs.AssignedToMe) {
    preFilter.assignees = [convertToUUID(user?._id)];
    preFilter.statuses = ACTIVE_TASK_STATUSES;
  } else if (searchParams.tab === StaffPreventiveMaintenanceTabs.Unassigned) {
    preFilter.assignees = ['unassigned'];
    preFilter.assigneeGroups = groupIds;
    preFilter.statuses = ACTIVE_TASK_STATUSES;
  } else if (searchParams.tab === StaffPreventiveMaintenanceTabs.Active) {
    preFilter.assigneeGroups = [...groupIds, 'unassigned'];
    preFilter.statuses = ACTIVE_TASK_STATUSES;
  } else if (searchParams.tab === StaffPreventiveMaintenanceTabs.Complete) {
    preFilter.assigneeGroups = [...groupIds, 'unassigned'];
    preFilter.statuses = INACTIVE_TASK_STATUSES;
  } else {
    const userRole = user?.roles.find(
      role =>
        role?.groupRole?.channel?._id === channel?._id &&
        role.groupRole.permissions.includes(
          PERMISSION_KEYS.PERMISSION_WORK_ORDERS_MODULE_ACCESS
        )
    );

    preFilter.assignees = [''];
    preFilter.assigneeGroups = userRole?.groupRole?._id
      ? [convertToUUID(userRole?.groupRole?._id)]
      : [];
  }

  const getTransitionStatusFilter = (transitionParam: string) => {
    const [transitionStatus, transitionDateStart, transitionDateEnd] =
      transitionParam.split('_');

    if (transitionStatus && (transitionDateStart || transitionDateEnd)) {
      return {
        transitionStatus,
        transitionDateStart: transitionDateStart || undefined,
        transitionDateEnd: transitionDateEnd || undefined,
      };
    }

    return {};
  };

  const isCrossProperty = channel?.settings?.hasWorkOrderCrossPropertyEnabled;

  const subChannelsResult = useQuery(queryChannelsByPermission, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        // isSub: true,
        parent: {
          _id: channel?._id,
        },
        permissions: [PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_VIEW],
      },
    },
  });

  let subChannelIds: string[] = [];

  if (isCrossProperty) {
    subChannelIds =
      subChannelsResult?.data?.channels?.items?.map(
        (subChannel: Channel) => subChannel?._id
      ) || [];
  }

  const { data, loading, refetch } = useQuery(searchTasksQuery, {
    skip: !channel?._id,
    variables: {
      groupIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
      filter: {
        ...filter,
        equipments: searchParams?.equipment
          ? searchParams?.equipment?.split(',')
          : [],
        schedules: searchParams?.schedule
          ? searchParams?.schedule?.split(',')
          : [],
        ...(searchParams?.status
          ? {
              statuses: searchParams?.status?.split(','),
            }
          : {}),
        ...(searchParams?.assignee
          ? {
              assignees: searchParams?.assignee
                .split(',')
                .map(safeConvertToUUID),
            }
          : {}),
        ...(searchParams?.assignee_groups
          ? {
              assigneeGroups: searchParams?.assignee_groups
                .split(',')
                .map(safeConvertToUUID),
            }
          : {}),
        ...(searchParams?.due_date
          ? {
              dueDateStart: convertStringsToDates(searchParams?.due_date)
                ?.startDate,
              dueDateEnd: convertStringsToDates(searchParams?.due_date)
                ?.endDate,
            }
          : {}),
        ...(searchParams?.completed_at
          ? {
              completedDateStart: convertStringsToDates(
                searchParams?.completed_at
              )?.startDate,
              completedDateEnd: convertStringsToDates(
                searchParams?.completed_at
              )?.endDate,
            }
          : {}),
        ...(searchParams?.status_changed_between
          ? getTransitionStatusFilter(searchParams?.status_changed_between)
          : {}),
      },
      preFilter,
    },
    fetchPolicy: 'network-only',
  });
  const totalTasks = data?.searchTasks?.pageInfo?.total;

  const handleExportToCSV = async () => {
    const { data: csvData } = await getClient().query({
      query: exportCSVTasksQuery,
      variables: {
        groupIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
        search: {
          ...(searchParams.sortBy
            ? {
                sortBy: {
                  key: searchParams.sortBy,
                  dir: searchParams.sortDirection,
                },
              }
            : {}),
          ...(searchParams.keyword
            ? {
                search: {
                  type: 'like',
                  value: searchParams.keyword,
                },
              }
            : {}),
        },
        filter: {
          ...filter,
          equipments: searchParams?.equipment
            ? searchParams?.equipment?.split(',')
            : [],
          schedules: searchParams?.schedule
            ? searchParams?.schedule?.split(',')
            : [],
          ...(searchParams?.status
            ? {
                statuses: searchParams?.status?.split(','),
              }
            : {}),
          ...(searchParams?.assignee
            ? {
                assignees: searchParams?.assignee
                  .split(',')
                  .map(safeConvertToUUID),
              }
            : {}),
          ...(searchParams?.assignee_groups
            ? {
                assigneeGroups: searchParams?.assignee_groups
                  .split(',')
                  .map(safeConvertToUUID),
              }
            : {}),
          ...(searchParams?.due_date
            ? {
                dueDateStart: convertStringsToDates(searchParams?.due_date)
                  ?.startDate,
                dueDateEnd: convertStringsToDates(searchParams?.due_date)
                  ?.endDate,
              }
            : {}),
          ...(searchParams?.completed_at
            ? {
                completedDateStart: convertStringsToDates(
                  searchParams?.completed_at
                )?.startDate,
                completedDateEnd: convertStringsToDates(
                  searchParams?.completed_at
                )?.endDate,
              }
            : {}),
        },
        preFilter,
      },
      fetchPolicy: 'network-only',
    });
    const tasks = csvData?.exportCSVTasks?.tasks || [];

    exportCSV(
      tasks,
      columns,
      `${t`web.admin.serviceRequest.equipment.csvName`}.csv`
    );
  };

  const exportOptions = [
    {
      label: t`web.admin.serviceRequest.equipment.csvName`,
      onClick: handleExportToCSV,
    },
  ];

  useEffect(() => {
    if (totalTasks) {
      setSearchParams({ total: totalTasks });
    }
  }, [data?.searchTasks?.pageInfo]);

  useEffect(() => {
    setSearchParams({
      ...initialTableParams,
      ...params,
      tab: searchParams?.tab,
    });

    if (!isEqual(searchParams, initialTableParams)) {
      window.localStorage.setItem(
        WORK_ORDER_STAFF_TASK_TABLE_STATE,
        JSON.stringify(searchParams)
      );
    }
  }, [searchParams?.tab]);

  const tableRows = useMemo(() => {
    if (!data?.searchTasks?.tasks) return [];

    const rows = data?.searchTasks?.tasks.map((task: ClientTask) => ({
      ...task,
      assignee_group: task.assigneeGroup,
      due_date: task.dueDate,
      created_at: task.createdAt,
      updated_at: task.updatedAt,
      completed_at: task.completedAt,
      completed_by: task.completedBy,
      created_by: task.createdBy,
      actual_time_to_complete: task.actualTimeToComplete,
      complete_notes: task.completeNotes,
      estimate_time_to_complete: task.estimateTimeToComplete,
      group_by: task.groupBy,
      is_archived: task.isArchived,
      meter_reading: task.meterReading,
      friendly_id: task.friendlyID,
      user_friendly_id: task.userFriendlyID,
      channel: task.groupBy?.name,
    }));

    setFilterData(data?.searchTasks?.filterOptions);

    return rows;
  }, [data?.searchTasks?.task, data?.searchTasks?.filterOptions]);

  let columns: Column<any>[] = [
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.id`,
      key: 'user_friendly_id',
      renderCell: (user_friendly_id: string, row: any) => (
        <Link
          to={routes.channelAdminWorkOrdersPMTaskDetails
            .replace(':id', channel?.slug)
            .replace(':taskId', row.id)}
        >
          {user_friendly_id}
        </Link>
      ),
      renderForCSV: (_: string, row: any) => row.userFriendlyID,
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.status`,
      key: 'status',
      renderCell: (_: any, row: any) => (
        <PMTaskStatusDropdown
          taskData={row}
          isTaskList
          refetch={refetch}
          disableEdit={!userHasEditStatusPermission}
        />
      ),
      renderForCSV: (status: any) => status,
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.schedule`,
      key: 'schedule',
      renderCell: (schedule: any) => {
        if (!schedule) return '';

        return hasScheduleAccess ? (
          <Link
            to={routes.channelAdminWorkOrdersPMScheduleDetails
              .replace(':id', channel?.slug)
              .replace(':scheduleId', schedule.id)}
          >
            {schedule.title}
          </Link>
        ) : (
          schedule.title
        );
      },
      renderForCSV: (schedule: any) => schedule?.title || '',
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.assignee`,
      key: 'assignee',
      renderCell: (assignee: IdNamePair) => {
        return assignee?.name || '';
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.assigneeGroup`,
      key: 'assignee_group',
      renderCell: (assigneeGroup: { _id: string; name: string }[]) => {
        const assigneeGroupNames = assigneeGroup
          ?.filter(group => group !== null)
          .map(group => group.name);

        return assigneeGroupNames?.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.location`,
      key: 'location',
      renderCell: (location: string, row: any) => {
        if (!row.equipment || row.equipment.length === 0) {
          return location;
        }

        return row.equipment.map((eq: any) => eq.location).join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.dueDate`,
      key: 'due_date',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.dueDate),
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.completedAt`,
      key: 'completed_at',
      type: 'date',
      renderCell: (completed_at: Date, row: any) => {
        if (!row.completed_by) return '';

        return new Date(completed_at).toDateString();
      },
      renderForCSV: (_: any, row: any) => {
        if (!row.completedBy) return '';

        return new Date(row.completedAt);
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.recurrence`,
      key: 'repeats',
      renderCell: (repeats: any) => {
        return repeats?.label || '';
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.equipment`,
      key: 'equipment',
      renderCell: (equipments: any[]) => {
        return equipments.map((eq: any) => eq.name).join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.createdAt`,
      key: 'created_at',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.createdAt),
    },
  ];

  if (isCrossProperty) {
    columns.splice(1, 0, {
      key: 'channel',
      header: t('web.admin.serviceRequest.property'),
      type: 'text',
    } as any);
  }

  if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
    columns = columns.filter(column => column.key !== 'equipment');
  }

  const getTableFilters = (): (FilterType | CustomFilterType<any>)[] => {
    let tableFilters: (FilterType | CustomFilterType<any>)[] = [
      {
        key: 'equipment',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.equipment'
        ),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.equipment?.map((c: IdNamePair) => ({
            label: c.name,
            value: c.id,
          })) || [],
      },
      {
        key: 'schedule',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.schedule'
        ),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.schedule?.map((c: IdNamePair) => ({
            label: c.name,
            value: c.id,
          })) || [],
      },
      {
        key: 'status',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.status'
        ),
        type: NativeFilterTypes.Multiselect,
        options:
          filterData?.status?.map((s: string) => ({
            label: getTaskStatusLabel(s),
            value: s,
          })) || [],
      },
      {
        key: 'assignee_groups',
        label: t('web.admin.serviceRequest.assigneeGroups'),
        type: NativeFilterTypes.Multiselect,
        options: assignableTeamsFilterOptions.unshift({
          label: t('web.admin.serviceRequest.unassigned'),
          value: 'unassigned',
        })
          ? assignableTeamsFilterOptions
          : [],
      },
      {
        key: 'assignee',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.assignee'
        ),
        type: NativeFilterTypes.Multiselect,
        options: assignableUsersFilterOptions ?? [],
      },
      {
        key: 'due_date',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.dueDate'
        ),
        type: NativeFilterTypes.DateRange,
      },
      {
        key: 'completed_at',
        label: t(
          'web.admin.workOrder.preventiveMaintenance.task.tableColumn.completedAt'
        ),
        type: NativeFilterTypes.DateRange,
      },
    ];

    if (transitionFilterFlag) {
      tableFilters.push({
        key: 'status_changed_between',
        label: t(
          'web.admin.preventiveMaintenance.task.tableColumn.statusChangedBetween'
        ),
        type: NativeFilterTypes.Transition,
        options: filterData?.transitionStatus?.map((s: string) => ({
          label: getTaskStatusLabel(s),
          value: s,
        })),
      });
    }

    if (
      searchParams.tab === StaffPreventiveMaintenanceTabs.AssignedToMe ||
      searchParams.tab === StaffPreventiveMaintenanceTabs.Unassigned
    ) {
      tableFilters = tableFilters.filter(f => f.key !== 'assignee');
    }

    if (!channel?.settings?.hasWorkOrderEquipmentEnabled) {
      tableFilters = tableFilters.filter(f => f.key !== 'equipment');
    }

    if (searchParams.tab === StaffPreventiveMaintenanceTabs.Complete) {
      const statusTableFilter = tableFilters.find(f => f.key === 'status');

      if (statusTableFilter) {
        statusTableFilter.options = statusTableFilter.options?.filter(
          (s: any) => INACTIVE_TASK_STATUSES.includes(s.value)
        );
      }

      const statusChangedBetweenTableFilter = tableFilters.find(
        f => f.key === 'status_changed_between'
      );

      if (statusChangedBetweenTableFilter) {
        statusChangedBetweenTableFilter.options =
          statusChangedBetweenTableFilter.options?.filter((s: any) =>
            INACTIVE_TASK_STATUSES.includes(s.value)
          );
      }
    } else {
      // for all other tabs, only show active task statuses
      const statusTableFilter = tableFilters.find(f => f.key === 'status');

      if (statusTableFilter) {
        statusTableFilter.options = statusTableFilter.options?.filter(
          (s: any) => ACTIVE_TASK_STATUSES.includes(s.value)
        );
      }
    }

    return tableFilters;
  };

  return (
    <Table
      isLoading={loading}
      columns={columns}
      hasKeywordFilter
      data={tableRows}
      totalRows={Number(searchParams.total)}
      keywordFilterLabel={t`web.admin.workOrder.preventiveMaintenance.search.label`}
      emptyMessage={t('web.admin.serviceRequest.emptyTable.message')}
      showColumnVisibility
      exportOptions={exportOptions}
      tableKey="workOrder.staffTaskListTable"
      filters={getTableFilters()}
      pagination="server"
      queryStringsEnabled
    />
  );
}

export default StaffTaskList;
