import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { FeatureInstance } from '../../../types/FeatureInstance';
import { EntriesFeatureProperties } from '../../../types/features/EntriesFeatureProperties';
import { FeatureNameEnum } from 'constants-content';
import { StatusesFeatureProperties } from '../../../types/features/StatusesFeatureProperties';
import { ShopifyRsvpStatusEnum } from '../../../types/integrations/ShopifyRsvp';

export default function constructShopifyRsvpFeatures({
  creatorId,
}: {
  creatorId: LaneType.UUID;
}): FeatureInstance[] {
  // users should only be able to make 1 RSVP
  const entriesFeature: EntriesFeatureProperties = {
    _id: uuid(),
    allowAnonymous: false,
    entries: 1,
    unlimited: false,
  };

  const statusesFeature: StatusesFeatureProperties = {
    _id: uuid(),
    rules: [
      {
        _id: uuid(),
        status: ShopifyRsvpStatusEnum.Accepted.toString(),
        nextStatuses: [
          ShopifyRsvpStatusEnum.Declined.toString(),
          ShopifyRsvpStatusEnum.Cancelled.toString(),
        ],
        security: [],
      },
      {
        _id: uuid(),
        status: ShopifyRsvpStatusEnum.Declined.toString(),
        nextStatuses: [
          ShopifyRsvpStatusEnum.Cancelled.toString(),
          ShopifyRsvpStatusEnum.Accepted.toString(),
        ],
        security: [],
      },
      {
        _id: uuid(),
        status: ShopifyRsvpStatusEnum.Cancelled.toString(),
        nextStatuses: [],
        security: [],
      },
    ],
  };

  // setup the required content features
  return [
    {
      _id: uuid(),
      _created: new Date(),
      _updated: new Date(),
      _createdBy: creatorId,
      _updatedBy: creatorId,
      type: FeatureNameEnum.Entries,
      feature: entriesFeature,
    },
    {
      _id: uuid(),
      _created: new Date(),
      _updated: new Date(),
      _createdBy: creatorId,
      _updatedBy: creatorId,
      type: FeatureNameEnum.Statuses,
      feature: statusesFeature,
    },
    {
      _id: uuid(),
      _created: new Date(),
      _updated: new Date(),
      _createdBy: creatorId,
      _updatedBy: creatorId,
      type: FeatureNameEnum.SubmitOnBehalfOf,
      // @ts-ignore valid value for feature
      feature: {},
    },
  ];
}
