import { useLazyQuery } from '@apollo/client';

import { getReservableWaitlistByContent } from 'graphql-queries';
import { ReservableWaitlistPagination } from 'graphql-query-contracts';
import { SortDirectionEnum } from 'constants-activate';

type ReservableWaitlistPaginationResponse = {
  reservableWaitlistForAdmin: ReservableWaitlistPagination;
};

const FETCH_POLICY = 'network-only';

export const useGetReservableWaitlist = () => {
  const [fetchWaitlists, { data, loading }] =
    useLazyQuery<ReservableWaitlistPaginationResponse>(
      getReservableWaitlistByContent,
      {
        fetchPolicy: FETCH_POLICY,
      }
    );

  async function fetchReservableWaitlists({
    contentId,
    channelId,
    search,
    page,
    pageSize,
  }: {
    contentId: string;
    channelId: string;
    search: {
      sortBy: {
        key: string;
        dir: 'asc' | 'desc' | SortDirectionEnum;
      };
      afterEndDate: string;
    };
    page: number;
    pageSize: number;
  }) {
    await fetchWaitlists({
      variables: {
        contentId,
        channelId,
        search,
        pagination: {
          start: page * pageSize,
          perPage: pageSize,
        },
      },
    });
  }

  return {
    getReservableWaitlists: fetchReservableWaitlists,
    isLoading: loading,
    waitlists: data?.reservableWaitlistForAdmin,
  };
};
