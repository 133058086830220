import React, { useContext, useState } from 'react';

import { Icon, Input, H3 } from 'design-system-web';
import {
  ControlMenu,
  ChannelCircleListView,
  Button,
  EmptyState,
  ErrorMessage,
} from 'components';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import {
  queryChannels,
  deleteChannelMutation,
} from 'lane-shared/graphql/channel';
import { getDisplayName } from 'lane-shared/helpers';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { CHILDREN_NAMES, getSingularChildrenName } from 'constants-channel';
import { PERMISSION_KEYS } from 'constants-permissions';
import { shortAddress } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import {
  Channel,
  CloneChannelOptionsType,
} from 'lane-shared/types/ChannelType';

import ContextMenu, { ModalPositionEnum } from 'components/general/ContextMenu';
import IconButton from 'components/general/IconButton';
import AdminPage from 'components/layout/AdminPage';
import { Action } from 'components/lds/table/types';

import { CloneChannelModal } from './CloneChannelModal';

import styles from './styles.scss';

const defaultOptions: CloneChannelOptionsType = {
  includeSections: false,
  includeSectionContent: false,
  includePages: false,
  includeContent: false,
  includeGroupRoles: false,
  includeLibraries: false,
  includeChannelPages: false,
  includeMultiLanguage: false,
  newParentId: null,
  newAddress: null,
  newName: '',
};

export default function ChannelChildren({ channel }: any) {
  const { user, hasAnyPermission } = useContext(UserDataContext);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteError, setDeleteError] = useState<Error | null>(null);
  const [isCloneOpen, setIsCloneOpen] = useState(false);
  const [toClone, setToClone] = useState<Channel | null>(null);
  const [cloneOptions, setCloneOptions] = useState<CloneChannelOptionsType>({
    ...defaultOptions,
  });
  const allowChannelArchiving = useFlag<boolean>(
    'allow-channel-archiving',
    false
  );
  const autoSetupEnabled = useFlag<boolean>(
    'activate-insights-migrations.enable-auto-setup-button',
    false
  );

  const createPropertyUIChangesEnabled = useFlag(
    'multifamily.create-property',
    false
  );

  const analytics = useContext(AnalyticsContext);

  const { t } = useTranslation();

  async function deleteChannel(channel: Channel) {
    if (!allowChannelArchiving) {
      return;
    }

    try {
      await window.Alert.confirm({
        title: t`Are you sure you want to delete this channel?`,
        message: t`Are you sure you know what you are doing? Deleting a channel is irreversible. All channel information and data will be deleted, are you sure you want to continue?`,
        confirmText: t`Yes, Delete`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    setLoading(true);
    setDeleteError(null);

    try {
      await getClient().mutate({
        mutation: deleteChannelMutation,
        variables: {
          channelId: channel?._id,
        },
      });

      analytics.track(
        ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETED,
        {
          deletedChannelId: channel?._id,
          deletedChannelName: channel?.name,
        }
      );

      await getClient().refetchQueries({ include: ['Channels'] });

      window.Toast.show(<p>Deleted {getDisplayName(channel)}</p>);
    } catch (err) {
      analytics.track(
        ANALYTIC_KEYS.ANALYTIC_CHANNEL_SETTINGS_CHILD_CHANNEL_DELETE_FAILED,
        {
          attemptedChannelId: channel?._id,
          attemptedChannelName: channel?.name,
          error: err,
        }
      );

      setDeleteError(err);
    } finally {
      setLoading(false);
    }
  }

  function getVariables() {
    const variables = {
      pagination: {
        start: 0,
        perPage: 500,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        includeChannelsFromHierarchy: true,
        isSub: false,
        parent: {
          _id: channel?._id,
        },
      },
    };

    if (search) {
      (variables.search as any).search = {
        type: 'like',
        value: search,
      };
    }

    return variables;
  }

  function startClone(channel: any) {
    setCloneOptions({ ...defaultOptions, newName: `${channel.name} - Copy` });
    setIsCloneOpen(true);
    setToClone(channel);
  }

  const { data, error } = useQuery(queryChannels, {
    skip: !channel?._id,
    fetchPolicy: 'cache-and-network',
    variables: getVariables(),
  });

  function getItemsForContextMenu(channel: Channel) {
    const items: Action[] = [
      {
        iconName: 'copy',
        label: t('Copy'),
        onClick: () => startClone(channel),
      },
    ];

    if (user?.isSuperUser && allowChannelArchiving) {
      items.push({
        iconName: 'trash',
        label: t('Delete'),
        onClick: () => deleteChannel(channel),
        isDelete: true,
      });
    }

    return items;
  }

  const children = data?.channels?.items || [];

  const childrenName = (channel as Channel)?.type;

  return (
    <AdminPage className={styles.ChannelChildren}>
      {createPropertyUIChangesEnabled && (
        <div className={styles.heading}>
          <H3>{CHILDREN_NAMES[childrenName]}</H3>
        </div>
      )}
      <ErrorMessage error={deleteError} />
      <ControlMenu className={styles.noWrap}>
        <Input
          icon="search"
          onChange={search => setSearch(search)}
          value={search}
          showClear
        />
        <hr />
        {hasAnyPermission(
          [PERMISSION_KEYS.PERMISSION_PROPERTY_AUTO_SETUP],
          channel?._id
        ) &&
          autoSetupEnabled && (
            <Link to="children/auto-setup-wizard">
              <Button
                variant="outlined"
                onClick={() => {
                  analytics.track(
                    ANALYTIC_KEYS.HOST_BUILDING_AUTO_SETUP_WIZARD_OPENED,
                    {
                      channelName: channel.name,
                    }
                  );
                }}
              >
                {t`web.admin.channel.autoSetupWizard.autoSetup`}
              </Button>
            </Link>
          )}
        <Link to="children/new">
          <Button variant="contained">
            {createPropertyUIChangesEnabled
              ? t('web.admin.channel.children.index.addButton', {
                  type: lowerFirst(getSingularChildrenName(channel?.type)),
                })
              : t(`Add`)}
          </Button>
        </Link>
      </ControlMenu>

      <ErrorMessage error={error} />

      {children.length === 0 && (
        <Link to="children/new">
          <EmptyState>
            {createPropertyUIChangesEnabled
              ? t('web.admin.channel.children.index.addNew', {
                  type: lowerFirst(getSingularChildrenName(channel?.type)),
                })
              : `Add a new location.`}
          </EmptyState>
        </Link>
      )}

      <ul>
        {children.map((child: any) => (
          <li key={child._id}>
            <Link to={`/l/channel/${child.slug}/admin`}>
              <ChannelCircleListView
                className={styles.channel}
                channel={child}
                description={shortAddress(child.address)}
              />
            </Link>
            <ContextMenu
              align={ModalPositionEnum.Left}
              items={
                <div className={styles.actions}>
                  {getItemsForContextMenu(child).map((action, i) => (
                    <button
                      key={i}
                      className={styles.actionButton}
                      onClick={action.onClick}
                    >
                      {action.iconName && (
                        <Icon name={action.iconName} set="FontAwesome" />
                      )}
                      {action.label}
                    </button>
                  ))}
                </div>
              }
              hasOpaqueBackground={false}
            >
              <IconButton icon="ellipsis-v" iconSet="FontAwesome" />
            </ContextMenu>
          </li>
        ))}
      </ul>
      <CloneChannelModal
        isCloneOpen={isCloneOpen}
        loading={loading}
        channel={channel}
        cloneOptions={cloneOptions}
        toClone={toClone}
        user={user}
        setCloneOptions={setCloneOptions}
        setLoading={setLoading}
        setIsCloneOpen={setIsCloneOpen}
      />
    </AdminPage>
  );
}
