import React, { useMemo } from 'react';

import {
  SectionTypeEnum,
  PresetContentFilter,
  PresetContentFilterFriendlyName,
} from 'constants-content';

import { Dropdown, Button, Icon, Flex } from 'design-system-web';
import styles from './SectionFilters.scss';

export function ContentFilterListItem({
  sectionType,
  filters,
  filter,
  onChange,
}: any) {
  const items = useMemo(
    () =>
      Object.values(PresetContentFilter)
        // only show filter options that haven't been selected elsewhere.
        .filter(f => f === filter || !filters.includes(f))
        .filter(f => {
          switch (f) {
            case PresetContentFilter.FeatureReservableAvailableWeeks:
            case PresetContentFilter.FeatureReservableAvailableMonths:
              // todo: not implemented yet.
              return false;
            case PresetContentFilter.ByChannel:
            case PresetContentFilter.ByChannelLocations:
            case PresetContentFilter.ByEventDate:
              return sectionType === SectionTypeEnum.Dynamic;
            case PresetContentFilter.SearchBox:
            case PresetContentFilter.Location:
            case PresetContentFilter.AvailableNow:
            case PresetContentFilter.FeaturePaymentPrice:
            case PresetContentFilter.FeatureQuantityRemaining:
            case PresetContentFilter.FeatureReservableAvailableMinutes:
            case PresetContentFilter.FeatureReservableAvailableDays:
            default:
              return true;
          }
        })
        // show friendly names for the dropdown options
        .map(value => ({
          label: PresetContentFilterFriendlyName[value],
          value,
        })),
    [filter, filters, sectionType]
  );

  return (
    <Flex direction="row" gap={2} mb={4} align="center">
      <div className={styles.filterDropdown}>
        <Dropdown
          isFullWidth
          items={items}
          value={filter}
          onValueChange={newValue => {
            filters[filters.findIndex((f: any) => f === filter)] = newValue;
            onChange([...filters]);
          }}
        />
      </div>

      <Button
        onClick={() => onChange(filters.filter((f: any) => f !== filter))}
        endIcon={<Icon name="trash-alt" />}
        variant="text"
      />
    </Flex>
  );
}
