import React from 'react';

import { useContentQuery } from 'lane-shared/hooks';
import { IntegrationProviderEnum } from 'constants-integrations';

import RequestForm from 'components/integrations/AngusServiceRequests/RequestForm';
import MeetingForm from 'components/integrations/ProxyClick/components/MeetingForm';

export default function ContentInteractionEdit({ interaction }: any) {
  const { content } = useContentQuery({
    contentId: interaction?.content?._id,
  });

  const integrationProvider = content?.integration?.integration?.name;

  switch (integrationProvider) {
    case IntegrationProviderEnum.ProxyClick:
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ content: any; interaction: any; }' is not ... Remove this comment to see the full error message
      return <MeetingForm content={content} interaction={interaction} />;
    case IntegrationProviderEnum.AngusServiceRequests:
      return <RequestForm content={content} interaction={interaction} />;
    default:
      // todo: non integrations don't use this? maybe redirect them somewhere.
      return null;
  }
}
