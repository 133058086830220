import React, {
  useContext,
  useMemo,
  useCallback,
  FunctionComponent,
  MouseEvent,
} from 'react';

import {
  AppContext,
  ChannelsContext,
  ThemeContext,
  TopLevelComponentContext,
} from 'lane-shared/contexts';
import { v4 as uuidv4 } from 'uuid';
import { getTextColor } from 'lane-shared/helpers';
import Color from 'lane-shared/properties/baseTypes/Color';
import { PropertyType } from 'lane-shared/types/properties/Property';

import ContentRendererContext from '../../contexts/ContentRendererContext';
import RendererContext from '../../contexts/RendererContext';
import mergeThemes from '../../helpers/content/mergeThemes';
import { imageUrl } from '../../helpers/formatters';
import { useMultiLanguage, useCurrentChannel } from '../../hooks';
import { PlatformEnum } from 'constants-activate';
import { ThemeType } from '../../types/Theme';
import {
  BlockInstanceType,
  BlockType,
} from '../../types/blocks/BlockInterface';
import { EventType } from '../../types/blocks/Primitive';
import { PropertiesInterfaceDependencies } from '../../types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';
import getBlockKey from './getBlockKey';
import parseObject from './parseObject';
import parseValue from './parseValue';
import setValue from './setValue';
import { useTranslation } from 'react-i18next';
import TextBlock from 'lane-shared/renderers/v5/primitives/TextBlock';
import { useThemeFromContextForBlockTheme } from 'lane-shared/hooks/useThemeFromContextForBlockTheme';

// todo: none of these prop names or events can be used by a block definition
const _reservedPropNames = [
  'style',
  'isTopLevel',
  'isTop',
  'showBackground',
  'blockId',
  'blockFor',
  'isValid',
  'validationMessage',
  'className',
  'hasLink',
  'hasClick',
  'theme',
  'children',
];

const _reservedPropEvents = [
  'onSubmit',
  'onClick',
  'onInput',
  'onSubscription',
  'onMouseOut',
  'onMouseOver',
  'onClick',
  'onDrag',
  'onDragStart',
  'onDragEnter',
  'onDragOver',
  'onDragLeave',
  'onDrop',
];

export type PrimitiveBlockBaseProps = {
  isTopLevel: boolean;
  isTop: boolean;
  showBackground: boolean;
  blockId: string;
  blockFor: string;
  blockName?: string;
  isValid: boolean;
  validationMessage?: string;
  hasLink: boolean;
  hasClick: boolean;
  children: React.ReactNode;
  theme: ThemeType;
  onSubmit: () => void;
  onClick: (e: Event) => void;
  onInput: (value: any) => void;
  onSubscription: (value: any) => void;
};

type BlockRendererProps = {
  style?: any; // platform dependent.
  className?: string;
  isTop?: boolean;
  isValid?: boolean;
  isPreview?: boolean;
  link?: string;
  top: BlockInstanceType;
  parent?: BlockInstanceType;
  block: BlockInstanceType;
  parentTheme?: ThemeType;
  childrenStyle: any;
  onSubscription?: (value: any) => void;
  $?: number;
};

/**
 * Determines how to render a block from its db blockDefinition using other blocks and primitives,
 * and creates BlockRenderers
 */
export default function createBlockRenderer(
  blockDefinition: BlockType
): FunctionComponent {
  function BlockRenderer({
    style,
    className,
    isTop = false,
    isValid,
    isPreview = false,
    link,
    top,
    parent,
    block = blockDefinition.block,
    parentTheme,
    childrenStyle,
    onSubscription,
    $,
  }: BlockRendererProps) {
    const { primitives, blocks, linkHandler, platform } =
      useContext(RendererContext);
    const { switchChannel, channels } = useContext(ChannelsContext);
    const { navigationRef } = useContext(TopLevelComponentContext);
    const { transition, clearTransition } = useContext(AppContext);
    const { t } = useTranslation();

    // we'll need to feed useMultiLanguage its channels, since it cannot retrieve it from context itself?
    const { translate } = useMultiLanguage({ channels });

    const {
      dataValidation,
      editMode,
      content,
      interaction,
      onSubmit,
      onClick,
      onInteractionUpdated,
      theme,
      previewLanguage,
    } = useContext(ContentRendererContext);
    const contextTheme = useContext(ThemeContext);
    const isContextThemeForBlockEnabled = useThemeFromContextForBlockTheme();
    // for things that required updates when data (i.e. the interaction state) changes
    const dataDependencies = editMode
      ? [content]
      : [interaction.state, interaction.features, interaction.data];

    // setup some re-usable dependencies for the memoization
    const dependencies = editMode
      ? [{}]
      : [
          content?._id,
          content?._updated,
          theme?._updated,
          JSON.stringify(top.properties),
        ];

    const currentChannel = useCurrentChannel();

    const childProps = useMemo(
      () =>
        parseObject({
          obj: block.properties,
          props: top.properties,
          propDefs: blockDefinition.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          editMode,
          dataDefs: content.data,
          $,
        }),
      [...dataDependencies, ...dependencies]
    );

    const propertiesInterfaceDependencies = useMemo(() => {
      return PropertiesInterfaceDependencies.fromJsonData(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'object | null' is not assignable to parameter of type 'PropertiesInterface<PropertyOptionType>'. Type 'null' is not assignable to type 'PropertiesInterface<PropertyOptionType>'
        content.data,
        content.propertiesOptions?.dependencies || []
      );
    }, [content.data, content.propertiesOptions]);

    // check to see if there are any properties being passed in to set colors.
    const colorProps = useMemo(
      () =>
        Object.entries<PropertyType>(blockDefinition.properties).filter(
          ([, property]) => property.type === Color.name
        ),
      []
    );

    // this block may switch themes, so we will track that here.
    const blockTheme = useMemo(() => {
      // if the block does not specify a theme, then it either comes from
      // the parent passing in a theme, or from the content renderer context
      // (i.e. the top level content).
      const paletteOverride: ThemeType = isContextThemeForBlockEnabled
        ? {
            ...theme,
            palette: {
              _id: theme.palette?._id ?? uuidv4(),
              custom: theme.palette?.custom ?? [],
              ...contextTheme,
            },
          }
        : theme;

      let blockTheme: ThemeType = mergeThemes([parentTheme || paletteOverride]);

      if (block.theme) {
        // if there is a theme, merge them together.
        blockTheme = mergeThemes([blockTheme, block.theme]);

        if (theme) {
          blockTheme = mergeThemes([theme, block.theme]);
        }
      }

      // todo: do we need a ticket for this?
      // This is a temporary work around to detect colors passed in as props
      // as map them to the Theme, in the future we will support a fully
      // editable theme input type instead.

      const backgroundColorProperty = colorProps.find(([key]) =>
        ['backgroundColor'].includes(key)
      );

      const backgroundColor = backgroundColorProperty
        ? top.properties?.[backgroundColorProperty[0]]
        : blockTheme.palette!.background;

      const textColorProperty = colorProps.find(([key]) =>
        ['color', 'text'].includes(key)
      );

      const textColor = textColorProperty
        ? top.properties?.[textColorProperty[0]]
        : null;

      // special case for ViewBlock, it is the only primitive that can have
      if (backgroundColor) {
        blockTheme.palette!.background = backgroundColor;
      }

      blockTheme.palette!.text =
        textColor || getTextColor(blockTheme.palette!.background);

      return blockTheme;
    }, [contextTheme, theme, parentTheme, ...dataDependencies]);

    // has the background changed from the parent?
    const showBackground =
      blockTheme.palette!.background !==
      (parentTheme || theme).palette!.background;

    // handle links that have been setup by the blocks
    const handleLinkOnClick = useCallback((e: MouseEvent<HTMLElement>) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      linkHandler({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | LinkType | undefined' is not assign... Remove this comment to see the full error message
        value: link || block.link,
        interaction,
        blockDefinition,
        switchChannel,
        transition,
        clearTransition,
        t,
        navigationRef,
        channelId: currentChannel?._id,
        contentId: content._id,
      });
    }, dependencies);

    // handle published click events
    const handleOnClick = useCallback((event: MouseEvent<HTMLElement>) => {
      if (blockDefinition.publishes && blockDefinition.publishes.click) {
        // Only bubble up events if this block has published them.
        onClick({
          blockDefinition,
          interaction,
          dataValidation,
          event,
        });
      }

      // There are some specific actions that a click can do...
      if (block.subscribe?.click?.action) {
        if (block.subscribe.click.action === 'link') {
          linkHandler({
            value: parseValue({
              value: block.subscribe.click,
              props: top.properties,
              state: interaction.state,
              data: interaction.data,
              features: interaction.features,
              propDefs: blockDefinition.properties,
              dataDefs: content.data,
              $,
              showExamples: false,
            }),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ value: any; interaction: UserC... Remove this comment to see the full error message
            interaction,
            blockDefinition,
          });
        }
      }
    }, dependencies);

    // handle other event subscriptions
    const handleSubscribe = useCallback(
      (input: any, subscription: any) => {
        if (subscription.publish) {
          // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          return onSubscription(subscription.publish, input);
        }

        const features = {};
        const data = {};

        setValue({
          input,
          dest: subscription,
          props: top.properties,
          state: interaction.state,
          features,
          data,
          propDefs: blockDefinition.properties,
          editMode,
          dataDefs: content.data,
          $,
        });

        if (Object.keys(data).length > 0) {
          onInteractionUpdated({
            data,
          });
        } else if (Object.keys(features).length > 0) {
          onInteractionUpdated({
            features,
          });
        }
      },
      [...dataDependencies, ...dependencies]
    );

    const handleOnSubmit = useCallback(() => {
      // Only bubble up events if this block has published them.
      if (blockDefinition.publishes && blockDefinition.publishes.submit) {
        onSubmit();
      }
    }, dependencies);

    // handle input events from the PropertyInputBlocks
    const handleOnInput = useCallback(
      (input: any) => handleSubscribe(input, block.subscribe.primitive),
      [...dataDependencies, ...dependencies]
    );

    const handleOnSubscription = useCallback(
      (defName: EventType, value: any) => {
        const subscription = block.subscribe[defName];

        if (subscription) {
          handleSubscribe(value, subscription);
        }
      },
      dependencies
    );

    // Check the displayIf value to determine if this block should render or not
    const shouldDisplay = useMemo(
      () =>
        parseValue({
          value: block.displayIf || true,
          props: top.properties,
          state: interaction.state,
          features: interaction.features,
          data: interaction.data,
          propDefs: blockDefinition.properties,
          editMode,
          dataDefs: content.data,
          $,
          showExamples: false,
        }),
      dataDependencies
    );

    // setup the style source for this block combining typography styles
    let styleSource = useMemo(() => {
      let _styleSource = {
        ...(block.style || {}),
        ...(!parent && (style || {})),
      };

      if (childrenStyle) {
        _styleSource = { ..._styleSource, ...childrenStyle };
      }

      // Apply a typographyDesignSystem if one applies.
      if (block?.primitive === 'TextBlock' && blockTheme?.typography) {
        if (block.typography) {
          _styleSource = {
            ...blockTheme?.typography[block.typography],
            ..._styleSource,
          };

          if (
            blockTheme?.palette &&
            !block?.properties?.text?.name?.includes('title') &&
            !block?.properties?.text?.name?.includes('header')
          ) {
            if (theme) {
              _styleSource = {
                ..._styleSource,
                ...blockTheme?.typography[block.typography],
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                color: getTextColor(theme?.palette?.background),
              };
            }

            _styleSource = {
              ..._styleSource,
              ...blockTheme?.typography[block.typography],
              color: getTextColor(blockTheme?.palette?.background),
            };
          }
        } else if (blockTheme.typography?.base) {
          _styleSource = {
            ...blockTheme.typography.base,
            ..._styleSource,
          };

          if (blockTheme?.palette) {
            _styleSource = {
              ..._styleSource,
              ...blockTheme.typography.base,
              color: getTextColor(blockTheme?.palette?.background),
            };
          }
        }
      }

      return _styleSource;
    }, dependencies);

    // Pull out any styleStates that block may be in
    const styleStateName = useMemo(() => {
      if (blockDefinition.styleState && block.styleStates) {
        return parseValue({
          value: blockDefinition.styleState,
          props: top.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          propDefs: blockDefinition.properties,
          editMode,
          dataDefs: content.data,
          $,
          showExamples: false,
        });
      }

      return null;
    }, dataDependencies);

    // the style states will override any set styles
    styleSource = useMemo(() => {
      if (styleStateName) {
        const styleState = block.styleStates?.find(
          state => state.name === styleStateName
        )?.style;

        return {
          ...styleSource,
          ...styleState,
        };
      }

      return styleSource;
    }, [styleStateName, styleSource]);

    // parse background image settings, this will point to a Media object
    const mediaId = useMemo(() => {
      if (styleSource.backgroundImage && styleSource.backgroundImage._bind) {
        return parseValue({
          value: styleSource.backgroundImage,
          props: top.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          propDefs: blockDefinition.properties,
          editMode,
          dataDefs: content.data,
          $,
          showExamples: false,
        });
      }
    }, dataDependencies);

    // depending on what platform we are on this source will be different.
    if (mediaId) {
      if (platform === PlatformEnum.Web) {
        styleSource.backgroundImage = `url(${imageUrl(mediaId)})`;
      } else {
        styleSource.backgroundImage = imageUrl(mediaId);
      }
    }

    // for some primitive blocks there will be special cases.
    if (
      block.primitive &&
      platform === PlatformEnum.Web &&
      (link || block.link)
    ) {
      styleSource.cursor = 'pointer';
    }

    if (block?.isDataDependencyBlock && editMode === false) {
      // we don't want left shift for dependency blocks in member view
      styleSource.paddingLeft = '0em';
    }

    const childStyle = useMemo(
      () =>
        parseObject({
          obj: styleSource,
          props: top.properties,
          propDefs: blockDefinition.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          editMode,
          dataDefs: content.data,
          $,
        }),
      dependencies
    );

    childProps.blockFor = (parent && parent.for) || top.for;
    childProps.isValid = isValid;
    childProps.isTop = isTop;
    childProps.showBackground =
      !Array.isArray(block.children) || showBackground;

    if (isTop && !parent) {
      childProps.className = className;
    }

    let children = null;

    const childValues = useMemo(() => {
      if (block.children && (block.children as any)._bind) {
        return parseValue({
          value: block.children,
          props: top.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          propDefs: blockDefinition.properties,
          editMode,
          dataDefs: content.data,
          $,
          showExamples: false,
        });
      }

      return null;
    }, dependencies);

    const _childrenStyle = useMemo(() => {
      if (
        block.children &&
        (block.children as any)._bind &&
        (block.children as any).style
      ) {
        return parseObject({
          obj: (block.children as any).style,
          props: top.properties,
          propDefs: blockDefinition.properties,
          state: interaction.state,
          data: interaction.data,
          features: interaction.features,
          editMode,
          dataDefs: content.data,
          $,
        });
      }

      return null;
    }, dependencies);

    if (block.children && (block.children as any)._bind) {
      if ((block.children as any).block) {
        // this is iterating a block over values that were parsed.
        children =
          childValues &&
          childValues.map((value: any, $: any) => (
            <BlockRenderer
              key={(block.children as any).block._id + $}
              top={top}
              parent={block}
              block={(block.children as any).block}
              parentTheme={blockTheme}
              childrenStyle={_childrenStyle}
              isPreview={isPreview}
              $={$}
            />
          ));
      } else {
        // this returned actual blocks to render.
        children =
          childValues &&
          childValues.map((child: any) => (
            <BlockRenderer
              key={child._id}
              top={top}
              parent={block}
              block={child}
              parentTheme={blockTheme}
              childrenStyle={_childrenStyle}
              isPreview={isPreview}
              $={$}
            />
          ));
      }
    } else if (block.children) {
      // we dont want to render blocks that have dependencies
      const dependencies =
        propertiesInterfaceDependencies.dependencies.values();
      const blocksWithDependencies: string[] = [];

      for (const depend of dependencies) {
        blocksWithDependencies.push(depend.propertyRef);
      }

      if (
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        !blocksWithDependencies.includes(block.for) ||
        Object.keys(interaction).length === 0
      ) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'PropertyBindingType | BlockInstanceType[]'.
        children = block.children.map(child => (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'childrenStyle' is missing in type '{ key: any; top: BlockInstanceType; parent: BlockInstanceType; parentTheme: ThemeType; block: any; $: number | undefined; }' but required in type 'Pick<BlockRendererProps, "parent" | "block" | "$" | "link" | "isValid" | "onSubscription" | "top" | "parentTheme" | "childrenStyle">'.
          <BlockRenderer
            key={child._id}
            top={top}
            parent={block}
            parentTheme={blockTheme}
            block={child}
            isPreview={isPreview}
            $={$}
          />
        ));
      } else {
        const shouldRenderBlock = propertiesInterfaceDependencies.dependencies
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          .get(block.for)
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PropertiesInterface<PropertyOptionType> | null' is not assignable to parameter of type 'JsonObject'.
          ?.isSatisfied(interaction.data);

        if (shouldRenderBlock) {
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'child' implicitly has an 'any' type.
          children = block.children.map(child => (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'childrenStyle' is missing in type '{ key: any; top: BlockInstanceType; parent: BlockInstanceType; parentTheme: ThemeType; block: any; $: number | undefined; }' but required in type 'Pick<BlockRendererProps, "parent" | "block" | "$" | "link" | "isValid" | "onSubscription" | "top" | "parentTheme" | "childrenStyle">'.
            <BlockRenderer
              key={child._id}
              top={top}
              parent={block}
              parentTheme={blockTheme}
              block={child}
              isPreview={isPreview}
              $={$}
            />
          ));
        }
      }
    }

    // TODO: only primitives can do inputs at the moment, could something
    // else cause data to be modified?

    if (blockDefinition.publishes) {
      // submit is a special event. It means that something is trying to
      // submit this interaction for this content.
      // TODO: Do we need to do anything about publishing?
    }

    if (!editMode && block.subscribe) {
      if (block.subscribe.submit) {
        if (dataValidation) {
          childProps.isValid = false;
        }

        // Special case for onSubmit / submit event
        childProps.onSubmit = handleOnSubmit;
      } else if (block.subscribe.click) {
        // Special case for onClick / click event.
        childProps.onClick = handleOnClick;
      } else if (block.subscribe.primitive) {
        childProps.onInput = handleOnInput;
      } else if (block.subscribe) {
        childProps.onSubscription = handleOnSubscription;
      }
    }

    if (!editMode && (link || block.link)) {
      childProps.hasLink = true;
      childProps.hasClick = true;
      childProps.onClick = handleLinkOnClick;
    }

    // If this block has properties that subscribes to data, check validation
    // and pass validation results along, (if that data is invalid).
    // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
    const validationMessage = useMemo(() => {
      if (!editMode && dataValidation && block.properties) {
        const props = Object.values(block.properties).filter(
          prop => prop?._bind
        );

        for (let i = 0; i < props.length; i++) {
          const err = dataValidation.inner?.find(err =>
            err.path.includes(props[i].name)
          );

          if (err) {
            return err.message;
          }
        }
      }
    }, [dataValidation]);

    if (validationMessage) {
      childProps.isValid = false;
      childProps.validationMessage = validationMessage;
    }

    const blockEditable = editMode && (block.editable || !parent);

    if (!shouldDisplay) {
      return null;
    }

    // theme's will now take precedence over background color and color
    // settings in the style.

    if (block.primitive) {
      const Block = primitives[block.primitive];

      if (!Block) {
        return null;
      }

      let isRequired = false;

      if (block.primitive === TextBlock.name && content.data) {
        const requiredKeys = Object.keys(content.data).filter((key: string) => {
          return content.data![key].validators?.find(
            ({ name, value }) => name === 'Required' && value === true
          );
        });

        isRequired = requiredKeys.includes(childProps.blockFor);
      }

      return (
        <Block
          style={childStyle}
          isTopLevel={blockEditable}
          blockId={blockEditable && block.editable ? block._id : top._id}
          primitiveBlockId={blockDefinition._id}
          blockName={blockDefinition.name}
          theme={blockTheme}
          isRequired={isRequired}
          {...{ isPreview, ...childProps }}
        >
          {children}
        </Block>
      );
    }

    const Block = blocks[getBlockKey(block)];

    if (!Block) {
      return null;
    }

    Object.keys(childProps).map(property => {
      if (property.includes('_l10n') && !editMode && !previewLanguage) {
        block = translate({ model: { ...block, channel: currentChannel } });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'channel' does not exist on type 'BlockInstanceType'.
        delete block.channel;
      }
    });

    return (
      <Block
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: BlockInstanceType; isTop: boolean; is... Remove this comment to see the full error message
        top={block}
        isTop={false}
        isValid={isValid}
        link={block.link}
        style={childrenStyle}
        parentTheme={blockTheme}
        onSubscription={childProps.onSubscription}
        className={className}
      />
    );
  }

  BlockRenderer.defaultProps = {
    className: '',
    style: {},
    isTop: false,
  };

  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ ({ style, className, isTop, isValid, link,... Remove this comment to see the full error message
  return BlockRenderer;
}
