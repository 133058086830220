import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  ChargeSummary: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: 12,
    gap: spacing[3],
    marginTop: spacing[1],
  },
  Heading: {
    fontSize: 13,
  },
  ChargeTotalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: colors.neutral.grey800,
  },
  ChargeTotalValue: {
    fontWeight: 'bold',
    color: 'black',
  },
  Line: {
    paddingRight: spacing[8],
  },
});
