import React from 'react';

import cx from 'classnames';

import toSchema from 'lane-shared/helpers/toSchema';
import TimeRange from 'lane-shared/properties/baseTypes/TimeRange';
import { CONTENT_CATEGORIES } from 'constants-content';

import CurrencyInput from 'components/form/CurrencyInput';
import TimePicker from 'components/form/DatePickers/TimePicker';
import MultiselectField from 'components/form/MultiselectField';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';

import styles from './PaymentAccountRuleEdit.scss';

function PaymentAccountRuleEdit({
  className,
  style,
  currency,
  rule,
  channel,
  loading,
  forCreate = false,
  onRuleUpdated = () => null,
  onCancel = () => null,
  onRuleAdd = () => null,
  onRuleSave = () => null,
}: any) {
  return (
    <div className={cx(styles.PaymentAccountRule, className)} style={style}>
      <h1>Rule</h1>
      <fieldset>
        <Toggle
          className={styles.toggle}
          value={rule.allRoles}
          onChange={allRoles => onRuleUpdated({ allRoles })}
          text={rule.allRoles ? 'Applies to everyone' : 'Applies to this team'}
          doTranslate
        />
        {!rule.allRoles && (
          <GroupRoleSelector
            className={styles.groupRoleSelector}
            channelId={channel._id}
            groupRoleId={rule.groupRole?._id}
            onGroupRoleSelected={groupRole => {
              onRuleUpdated({
                groupRole: groupRole
                  ? {
                      _id: groupRole._id,
                    }
                  : null,
              });
            }}
          />
        )}
      </fieldset>
      <fieldset>
        <Toggle
          className={styles.toggle}
          value={rule.noSinglePurchaseLimit}
          onChange={noSinglePurchaseLimit =>
            onRuleUpdated({ noSinglePurchaseLimit })
          }
          text={
            rule.noSinglePurchaseLimit
              ? 'No limit per purchase'
              : 'Limit per purchase'
          }
          doTranslate
        />
        {!rule.noSinglePurchaseLimit && (
          <CurrencyInput
            currency={currency}
            value={rule.singlePurchaseLimit}
            onValueChange={singlePurchaseLimit =>
              onRuleUpdated({ singlePurchaseLimit })
            }
          />
        )}
      </fieldset>
      <fieldset>
        <Toggle
          className={styles.toggle}
          value={rule.noPurchaseLimit}
          onChange={noPurchaseLimit => onRuleUpdated({ noPurchaseLimit })}
          text={
            rule.noPurchaseLimit
              ? 'No total purchase limit per month'
              : 'Total purchase limit per month'
          }
          doTranslate
        />
        {!rule.noPurchaseLimit && (
          <CurrencyInput
            currency={currency}
            value={rule.purchaseLimit}
            onValueChange={purchaseLimit => onRuleUpdated({ purchaseLimit })}
          />
        )}
      </fieldset>
      <fieldset>
        <Toggle
          className={styles.toggle}
          value={rule.anyTimes}
          onChange={anyTimes => {
            onRuleUpdated({
              anyTimes,
              ...(!anyTimes
                ? TimeRange.default
                : { startTime: undefined, endTime: undefined }),
            });
          }}
          text={
            rule.anyTimes
              ? 'No time restriction'
              : 'Limit spending times between hours'
          }
          doTranslate
        />

        {!rule.anyTimes && (
          <div className={styles.timeRange}>
            <TimePicker
              className={styles.timePicker}
              useDates={false}
              value={rule.startTime}
              onChange={(startTime: any) => onRuleUpdated({ startTime })}
            />

            <TimePicker
              className={styles.timePicker}
              useDates={false}
              value={rule.endTime}
              onChange={(endTime: any) => onRuleUpdated({ endTime })}
            />
          </div>
        )}
      </fieldset>
      <fieldset>
        <Toggle
          className={styles.toggle}
          value={rule.allContent}
          onChange={allContent => onRuleUpdated({ allContent })}
          text={
            rule.allContent
              ? 'All categories'
              : 'Limit purchasing to specific categories'
          }
          doTranslate
        />
        {!rule.allContent && (
          <MultiselectField
            className={styles.contentCategories}
            placeholder="Select categories…"
            items={CONTENT_CATEGORIES}
            value={rule.contentCategories.map(toSchema)}
            onChange={contentCategories =>
              onRuleUpdated({
                contentCategories: contentCategories.map(({ value }) => value),
              })
            }
          />
        )}
      </fieldset>
      {forCreate && (
        <ControlMenu>
          <hr />
          <Button loading={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={() => onRuleAdd(rule)} variant="contained">
            Add
          </Button>
        </ControlMenu>
      )}
      {!forCreate && (
        <ControlMenu>
          <hr />
          <Button
            disabled={!rule.__updated}
            loading={loading}
            onClick={() => onRuleSave(rule)}
            variant="contained"
          >
            Save Rule
          </Button>
        </ControlMenu>
      )}
    </div>
  );
}

export default PaymentAccountRuleEdit;

/**
 singlePurchaseLimit: Int
 purchaseLimit: Int
 purchaseLimitTimeUnit: String
 startTime: String
 endTime: String
 groupRole: GroupRole @rel(key: this, pattern: external)
 user: User @rel(key: this, pattern: external)
 */
