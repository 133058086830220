/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';

import { routes , AmazonS3Buckets } from 'lane-shared/config';
import { safeConvertToUUID } from 'uuid-encoding';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { Providers } from 'lane-shared/types/integrations/FileImport';
import {
  saveConfig,
  startImportJob,
  getConfig,
} from 'lane-shared/graphql/fileImport';
import {
  AttachmentResponse,
  EntityTypeEnum,
} from 'lane-shared/types/attachment';
import {
  ImportJobDefinitions,
  ImporterEntityTypes,
  ImporterEntities,
} from 'lane-shared/domains/importer/jobDefinition';

import { AdminPage, Flex, PageHeader } from 'components/layout';
import { Alert } from 'components/lds';
import { H4, M } from 'components/typography';

import { FileUpload, WorkbookUpload } from '../file-upload';
import styles from './styles.scss';

type Props = {
  channel: any;
};

export const Importer = ({ channel }: Props) => {
  const moduleDef = ImportJobDefinitions[0];
  const superConverterFlag = useFlag(
    FeatureFlag.WorkOrdersSuperConverter,
    false
  );
  const [saveImportConfigMutation] = useMutation(saveConfig);
  const [startImportJobMutation] = useMutation(startImportJob);
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState('');
  const [attachments, setAttachments] = useState<any>({});

  const [fetchConfig, { data: configData }] = useLazyQuery(getConfig, {
    variables: {
      config: {
        provider: Providers.IMPORTER,
        vts_organization_id: safeConvertToUUID(channel?._id),
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    moduleDef.entitySections.forEach(section => {
      section.entities.forEach(entity => {
        handleAttachments(entity.type, []);
      });
    });
  }, []);

  const handleAttachments = async (
    type: ImporterEntityTypes,
    attachmentFiles: any[]
  ) => {
    await setAttachments((prev: any) => ({
      ...prev,
      [type]: attachmentFiles,
    }));
  };

  const [loading, setLoading] = useState(false);
  const { uploading: _, filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated: () => null,
    entity: {
      type: moduleDef.attachmentType,
      id: '',
    },
    s3Bucket: AmazonS3Buckets.IntegrationGateway,
  });

  const saveAttachments = async (
    entityID: string,
    entityType: EntityTypeEnum,
    files: AttachmentResponse[]
  ) => {
    let attachmentURLs: string[] = [];

    try {
      const attachmentReturn = await filesSelectedHandler(
        files.map(attachment => attachment.file as File),
        entityID,
        {
          type: entityType,
          id: entityID,
        }
      );

      attachmentURLs = attachmentReturn.map(
        response => `${response?.id}.attachment`
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      setLoading(false);
    }

    return attachmentURLs;
  };

  const handleStartImport = async () => {
    try {
      setLoading(true);
      const importEntiyID = uuid(); // genreate new uuid for import entity
      const settings = [];

      for (const attachment of Object.keys(attachments)) {
        const attachmentFiles = attachments[attachment];
        const attachmentURLs = await saveAttachments(
          importEntiyID,
          EntityTypeEnum.ImporterAttachment,
          attachmentFiles
        );

        if (attachmentURLs.length === 1) {
          settings.push({
            entityType: attachment,
            fileName: attachmentURLs[0],
          });
        }
      }

      await fetchConfig();

      if (settings.length <= 0) {
        setLoading(false);

        return;
      }

      let config;

      if (!configData?.getConfig?.config) {
        config = {
          jobs: [
            {
              jobId: importEntiyID,
              settings,
            },
          ],
        };
      } else {
        config = {
          ...configData.getConfig.config,
          jobs: [
            ...configData.getConfig.config.jobs,
            {
              jobId: importEntiyID,
              settings,
            },
          ],
        };
      }

      await saveImportConfigMutation({
        variables: {
          config: {
            provider: Providers.IMPORTER,
            vts_organization_id: safeConvertToUUID(channel?._id),
            config,
          },
        },
      });

      await startImportJobMutation({
        variables: {
          job: {
            jobId: importEntiyID,
            provider: Providers.IMPORTER,
            vts_organization_id: safeConvertToUUID(channel?._id),
          },
        },
      });
      setLoading(false);
      window.Toast.show(t('web.admin.importer.workorders.runJob.success.wait'));
      setTimeout(() => {
        history.push(
          routes.channelAdminDataImportListView.replace(':id', channel?.slug)
        );
      }, 2000);
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      setLoading(false);
      setError(t('web.admin.importer.workorders.runJob.error'));
    }
  };

  const disableStartJob = useMemo(() => {
    if (!attachments) return true;

    const attachmentValues = Object.values(attachments as string[]).filter(
      attachment =>
        attachment !== null && attachment !== undefined && attachment.length > 0
    );

    if (!attachmentValues || attachmentValues.length === 0) return true;

    for (const key in ImporterEntities) {
      const entity = ImporterEntities[key as keyof typeof ImporterEntities];

      if (
        entity.required &&
        (attachments[entity.type] === null ||
          attachments[entity.type] === undefined ||
          attachments[entity.type].length === 0)
      ) {
        // Check if the key exists in the attachments object
        return true;
      }
    }

    return false;
  }, [attachments]);

  const pageHeaderProps = {
    header: t('web.admin.channel.new.data.import.header'),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.importer.workorders.breadcrumb.data.import'),
        url: routes.channelAdminDataImportListView.replace(
          ':id',
          channel?.slug
        ),
      },
      {
        label: t('web.admin.channel.new.data.import.header'),
      },
    ],
    actionButtons: [
      {
        label: t('web.admin.importer.workorders.start.import'),
        onClick: handleStartImport,
        type: 'primary' as const,
        testId: 'startImportButton',
        disabled: disableStartJob,
        loading,
      },
    ],
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader {...pageHeaderProps} />
      </div>

      <Flex className={styles.bodyWrapper} direction="column" gap={5}>
        {/* Error Message(s) */}
        {error && (
          <Alert type="error" fullWidth>
            {error}
          </Alert>
        )}

        {/* Importer Description */}
        <M variant="secondary" className={styles.description}>
          <Trans i18nKey={moduleDef.description}>
            {
              'Currently we only support importing data to “Work orders” module. To ensure success of data import, please make sure you have appropriately configure '
            }
            <Link
              to={routes.channelAdminWorkOrdersServiceRequestsSettings.replace(
                ':id',
                channel?.slug
              )}
              className={styles.link}
            >
              service request settings
            </Link>
            {' and '}
            <Link
              to={routes.channelAdminWorkOrdersEquipmentSettings.replace(
                ':id',
                channel?.slug
              )}
              className={styles.link}
            >
              equipment settings
            </Link>
            .
          </Trans>
        </M>

        {/* Workbook Uplaoder */}
        {superConverterFlag ? (
          <WorkbookUpload handleAttachments={handleAttachments} />
        ) : null}

        {/* Import Type Uplaoders */}
        {moduleDef.entitySections.map(section => (
          <>
            <H4>{t(section.sectionName)}</H4>
            {section.entities.map(entity => (
              <FileUpload
                title={entity.friendlyName}
                description={entity.description}
                attachmentFiles={attachments[entity.type]}
                setAttachmentFile={attachmentFiles =>
                  handleAttachments(entity.type, attachmentFiles)
                }
                entityType={entity.type}
                key={entity.type}
              />
            ))}
          </>
        ))}
      </Flex>
    </AdminPage>
  );
};
