import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import { useInteractionsOnSectionQuery } from 'lane-shared/hooks';
import { SortByEnum } from 'lane-shared/types/blocks/Sort';
import { SortDirectionEnum } from 'constants-activate';

import { Loading } from 'components';
import UserContentInteractionCard from 'components/lane/UserContentInteractionCard';
import { H3 } from 'components/typography';

import styles from './styles.scss';

const InteractionsOfSection = ({ match }: any) => {
  const { t } = useTranslation();
  const { items, loading } = useInteractionsOnSectionQuery({
    sectionId: match?.params?.sectionId,
    pollInterval: POLL_INTERVAL,
    withPagination: false,
    interactionState: 'All',
    autoHide: false,
    perPage: 100,
    sortBy: {
      key: SortByEnum.EndDate,
      dir: SortDirectionEnum.Asc,
    },
  });

  useEffect(() => {
    window.scrollTo(-100, 0);
  }, []);

  return (
    <div className={styles.ContentInteractionsPage}>
      {!loading && items.length === 0 ? (
        <H3 className={styles.header}>{t(`No interactions`)}</H3>
      ) : (
        <>
          <H3 className={styles.header}>{t(`Interactions`)}</H3>
          {loading ? (
            <Loading fullscreen />
          ) : (
            <div className={styles.interactions}>
              {items.map((interaction: any) => (
                <Link
                  to={routes.interaction.replace(':id', interaction._id)}
                  key={interaction._id}
                >
                  <UserContentInteractionCard
                    interaction={interaction}
                    className={styles.interactionCard}
                  />
                </Link>
              ))}
            </div>
          )}{' '}
        </>
      )}
    </div>
  );
};

export default InteractionsOfSection;
