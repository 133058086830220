import { RecurringCharge } from 'lane-shared/domains/billingPayments/types';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import { UserType } from 'lane-shared/types/User';

export function canAdminCancelMembership(
  isActive: boolean,
  recurringCharge: RecurringCharge,
  user: UserType | null
) {
  if (!isActive) {
    return false;
  }

  const anyoneCanCancel = recurringCharge?.recurrence?.anyoneCanCancel;

  if (anyoneCanCancel) {
    return true;
  }

  if (user) {
    return hasPermission(
      user.roles,
      PERMISSION_KEYS.PERMISSION_CAN_CANCEL_MEMBERSHIPS
    );
  }

  return false;
}
