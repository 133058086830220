import React, { useEffect, useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Loading, Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';
import { ChannelListItem } from '../../../../components/ChannelListItem';
import styles from './index.scss';
import { ChannelListItemWrapper } from './ChannelListItemWrapper';

type OnboardingBuildingInputProps = {
  data: {
    building: Channel | null;
    buildingSearch: string;
    buildings: any[];
    whiteLabelName: string;
  };
  config: {
    isLoading: boolean;
    isFetchingMore: boolean;
    hasNextPage: boolean;
    fetchMore: () => void;
  };
  handlers: {
    onBuildingSelectionChange: (building: Channel | null) => void;
    onBuildingSearchTextChange: (buildingSearch: string) => void;
  };
};

export function OnboardingBuildingInput({
  data: { building, buildingSearch, buildings },
  config: { isLoading, isFetchingMore, hasNextPage, fetchMore },
  handlers: { onBuildingSelectionChange, onBuildingSearchTextChange },
}: OnboardingBuildingInputProps) {
  const { t } = useTranslation();
  const {
    translationKeys: { locationPlaceholder },
  } = useContinueAsGuest();
  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || isFetchingMore) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Check if scrolled to the bottom
      if (scrollHeight - scrollTop <= clientHeight + 50 && hasNextPage) {
        fetchMore();
      }
    };

    const container = containerRef.current;

    container?.addEventListener('scroll', handleScroll);

    return () => container?.removeEventListener('scroll', handleScroll);
  }, [isFetchingMore, hasNextPage, fetchMore]);

  return (
    <div>
      {!isWorkplaceOnboardingEnabled && building ? (
        <div className={styles.selectedChannelContainer}>
          <ChannelListItem
            className={cx([styles.channelResult, styles.selected])}
            // once we have address in api data, it will be updated to false
            showName
            channel={building}
            onClick={() => {
              onBuildingSelectionChange(null);
            }}
          />
          <Icon
            name="edit"
            className={styles.checkIcon}
            set={ICON_SET_FONTAWESOME}
            type={FONT_AWESOME_REGULAR}
          />
        </div>
      ) : (
        <div>
          <Input
            onChange={onBuildingSearchTextChange}
            value={buildingSearch}
            placeholder={t(locationPlaceholder)}
            icon="search"
            testId="buildingLocation"
          />
          <br />
          <div
            ref={containerRef}
            className={styles.results}
            data-test="scroll-container"
          >
            {buildings.map((buildingItem, index) => (
              <ChannelListItemWrapper
                key={buildingItem.cursor || index}
                building={buildingItem}
                onClick={onBuildingSelectionChange}
                className="channelResult"
                isSelected={Boolean(
                  building && buildingItem.cursor === building?._id
                )}
              />
            ))}
            {(isLoading || isFetchingMore) && <Loading />}
          </div>
        </div>
      )}
    </div>
  );
}
