import React, { useState } from 'react';

import { Loading } from 'components';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { Channel } from 'packages/lane-shared/types/ChannelType';

import { ChipSelect, ChipStyle } from 'components/ads';
import { Modal, Button } from 'design-system-web';
import { H3, H5 } from 'components/typography';

import { ChannelIntegrationEditorProps } from '../../ChannelIntegrationEditorProps';

import styles from './CheckPermissionsButton.scss';

const getProviderPermissionsQuery = gql`
  query GetConfigMetadata(
    $provider: String!
    $channelId: UUID!
    $url: String!
    $serverName: String!
    $database: String!
    $propertyId: String!
    $financialEnabled: Boolean!
    $financialUsername: String!
    $financialPassword: String!
  ) {
    getConfigMetadata(
      provider: $provider
      channelId: $channelId
      url: $url
      serverName: $serverName
      database: $database
      propertyId: $propertyId
      financialEnabled: $financialEnabled
      financialUsername: $financialUsername
      financialPassword: $financialPassword
    ) {
      data
    }
  }
`;

interface Permissions {
  CanExportCharges: boolean;
  CanExportPayments: boolean;
}

type CheckPermissionsButtonProps = {
  channel: Channel;
  provider: string;
  disabled: boolean | undefined;
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  requiredPropertiesForPermissions: string[];
  isFinancialsEnabled: boolean;
};

export const CheckPermissionsButton = ({
  channel,
  provider,
  disabled,
  settings,
  requiredPropertiesForPermissions,
  isFinancialsEnabled,
}: CheckPermissionsButtonProps) => {
  const [isCheckPermissionsModalOpen, setIsCheckPermissionsModalOpen] =
    useState(false);
  const [permissions, setPermissions] = useState<Permissions>({
    CanExportCharges: false,
    CanExportPayments: false,
  } as Permissions);
  const [loading, setLoading] = useState(false);
  const [metadataError, setMetadataError] = useState('');

  const { t } = useTranslation();

  const checkVendorPermissions = async () => {
    const variables = getRequiredVariablesValue();

    setMetadataError('');
    setIsCheckPermissionsModalOpen(true);
    setLoading(true);

    try {
      const { data } = await getClient().query({
        query: getProviderPermissionsQuery,
        fetchPolicy: 'network-only',
        variables: {
          provider,
          channelId: channel._id,
          url: variables.url,
          serverName: variables.serverName,
          database: variables.databaseName,
          propertyId: variables.propertyId,
          financialEnabled: isFinancialsEnabled,
          financialUsername: variables.financialUsername,
          financialPassword: variables.financialPassword,
        },
      });

      if (data.getConfigMetadata.data) {
        setPermissions(data.getConfigMetadata.data);
        setLoading(false);
      }
    } catch (err: any) {
      setMetadataError(err.message);
      setIsCheckPermissionsModalOpen(false);
      setLoading(false);
    }
  };

  const getRequiredVariablesValue = () => {
    const variables: { [key: string]: any } = {};

    requiredPropertiesForPermissions.forEach((key: string) => {
      variables[key] = settings?.[key];
    });

    return variables;
  };

  const checkPermissionChip = (permission: boolean) => {
    return (
      <ChipSelect.NonInteractive
        value={
          permission
            ? 'web.admin.integrations.yardi.settings.permission.on.chip'
            : 'web.admin.integrations.yardi.settings.permission.off.chip'
        }
        type={permission ? ChipStyle.Green : ChipStyle.Red}
      />
    );
  };

  return (
    <div>
      <div className={styles.heading}>
        <H5>
          {t('web.admin.integrations.yardi.settings.yardiPaymentPermissions')}
        </H5>
        <p>
          {t(
            'web.admin.integrations.yardi.settings.yardiPaymentPermissions.description'
          )}
        </p>
      </div>
      <Button
        variant="secondary"
        size="large"
        loading={false}
        onClick={() => checkVendorPermissions()}
        testId="buttonCheckPermission"
        disabled={disabled}
      >
        {t('web.admin.integrations.yardi.settings.check.permissions.button')}
      </Button>
      {metadataError ? (
        <div>
          <p
            key={metadataError}
            className={styles.error}
            data-test="errorMessage"
          >
            {metadataError}
          </p>
        </div>
      ) : (
        <Modal
          onClose={() => setIsCheckPermissionsModalOpen(false)}
          isOpen={isCheckPermissionsModalOpen}
          closeButtonSize="small"
        >
          <H3>
            {t('web.admin.integrations.yardi.settings.yardiPaymentPermissions')}
          </H3>
          <div className={styles.modalDescription}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className={styles.permission}>
                  <span className={styles.permissionText}>
                    {t(
                      'web.admin.integrations.yardi.settings.chargeBatches.permission'
                    )}
                  </span>
                  {checkPermissionChip(permissions.CanExportCharges)}
                </div>
                <div className={styles.permission}>
                  <span className={styles.permissionText}>
                    {t(
                      'web.admin.integrations.yardi.settings.receiptBatches.permission'
                    )}
                  </span>
                  {checkPermissionChip(permissions.CanExportPayments)}
                </div>
              </>
            )}
          </div>
          <hr />
          <Button
            variant="primary"
            size="large"
            style={{ width: 'max-content', alignSelf: 'center' }}
            fullWidth={false}
            onClick={() => setIsCheckPermissionsModalOpen(false)}
          >
            {t('web.admin.integrations.yardi.settings.close.button')}
          </Button>
        </Modal>
      )}
    </div>
  );
};
