import styles from './index.scss';
import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input, Loading } from 'components';
import ChannelSearchCircleListView from 'components/lane/ChannelSearchCircleListView';
import { ChannelListItem } from 'components/onboarding/components/ChannelListItem';
import { H5 } from 'components/typography';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingCompanyResult } from 'lane-shared/contexts/SignUpContext/useOnboardingCompanies';
import { ChannelSearchCircleListWrapper } from './ChannelSearchCircleListWrapper';
import { OnboardingBuildingsResult } from 'lane-shared/contexts/SignUpContext/useOnboardingBuildings';
import { ChannelListItemWrapper } from '../../OnboardingBuilding/OnboardingBuildingInput/ChannelListItemWrapper';
import { useWorkplaceOnboardingEnabled } from 'lane-shared/hooks/useWorkplaceOnboardingEnabled';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export type OnboardingCompanyInputProps = {
  data: {
    signupError: Error | null;
    building: Channel | null;
    parentCompanySearch: string;
    onboardingCompaniesResult: OnboardingCompanyResult;
    companyLocationSearch: string;
    parentCompany: Channel | null;
    onboardingBuildingsResult: OnboardingBuildingsResult;
  };
  handlers: {
    resetSelection: () => void;
    onParentCompanySearchTextChange: (parentCompanySearch: string) => void;
    onParentCompanySelectionChange: (parentCompany: Channel) => void;
    onCompanyLocationSearchTextChange: (companyLocationSearch: string) => void;
    onCompanyBuildingSelectionChange: (
      company: Channel | null,
      building: Channel | null
    ) => void;
  };
};

const TRANSLATION_KEYS = {
  multipleLocations: 'shared.onboarding.company.multipleLocations.title',
  location: 'shared.onboarding.company.location.title',
  companySearchPlaceholder: 'web.onboarding.company.name.search.placeholder',
  searchResultsTitle: 'shared.onboarding.company.name.search.results.title',
};

export function OnboardingCompanyInput({
  data: {
    building,
    parentCompanySearch,
    onboardingCompaniesResult: {
      companies,
      hasNextPage: hasCompanyNextPage,
      isInitialLoading: isCompanyInitialLoading,
      isFetchingMore: isCompanyFetchingMore,
      fetchMore: fetMoreCompanies,
    },
    companyLocationSearch,
    parentCompany,
    onboardingBuildingsResult: {
      buildings,
      hasNextPage: hasBuildingNextPage,
      isInitialLoading: isBuildingInitialLoading,
      isFetchingMore: isBuildingFetchingMore,
      fetchMore: fetchMoreBuildings,
    },
  },
  handlers: {
    resetSelection,
    onParentCompanySearchTextChange,
    onParentCompanySelectionChange,
    onCompanyLocationSearchTextChange,
    onCompanyBuildingSelectionChange,
  },
}: OnboardingCompanyInputProps) {
  const { t } = useTranslation();

  const isWorkplaceOnboardingEnabled = useWorkplaceOnboardingEnabled();

  const {
    translationKeys: { locationPlaceholder },
  } = useContinueAsGuest();

  const parentCompanySearchRef = useRef(null);

  useLayoutEffect(() => {
    if (parentCompanySearchRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      parentCompanySearchRef.current.focus();
    }
  }, []);

  const hasCompanyLocations = buildings.length > 0;
  const hasMoreThanOneCompanyLocation = buildings.length > 1;

  const companyLocationSearchHeader = hasMoreThanOneCompanyLocation
    ? TRANSLATION_KEYS.multipleLocations
    : TRANSLATION_KEYS.location;

  const containerRefCompany = useRef<HTMLDivElement>(null);
  const containerRefBuilding = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRefCompany.current || isCompanyFetchingMore) return;

      const { scrollTop, scrollHeight, clientHeight } =
        containerRefCompany.current;

      // Check if scrolled to the bottom
      if (scrollHeight - scrollTop <= clientHeight + 50 && hasCompanyNextPage) {
        fetMoreCompanies();
      }
    };

    const container = containerRefCompany.current;

    container?.addEventListener('scroll', handleScroll);

    return () => container?.removeEventListener('scroll', handleScroll);
  }, [isCompanyFetchingMore, hasCompanyNextPage, fetMoreCompanies]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRefBuilding.current || isBuildingFetchingMore) return;

      const { scrollTop, scrollHeight, clientHeight } =
        containerRefBuilding.current;

      // Check if scrolled to the bottom
      if (
        scrollHeight - scrollTop <= clientHeight + 50 &&
        hasBuildingNextPage
      ) {
        fetchMoreBuildings();
      }
    };

    const container = containerRefBuilding.current;

    container?.addEventListener('scroll', handleScroll);

    return () => container?.removeEventListener('scroll', handleScroll);
  }, [isBuildingFetchingMore, hasBuildingNextPage, fetchMoreBuildings]);

  return (
    <div>
      {!parentCompany ? (
        <div>
          <div className={styles.searchContainer}>
            <Input
              ref={parentCompanySearchRef}
              onChange={onParentCompanySearchTextChange}
              value={parentCompanySearch}
              placeholder={t(TRANSLATION_KEYS.companySearchPlaceholder)}
              testId="companyName"
              icon="search"
            />
          </div>
          <div
            ref={containerRefCompany}
            className={styles.results}
            data-test="scroll-container"
          >
            {companies.map((company, index) => (
              <ChannelSearchCircleListWrapper
                className={styles.companyResult}
                key={index}
                company={company}
                onClick={company => {
                  onParentCompanySelectionChange(company);

                  if (isWorkplaceOnboardingEnabled) {
                    onCompanyBuildingSelectionChange(company, building);
                  }
                }}
                hideDescription
              />
            ))}
            {(isCompanyInitialLoading || isCompanyFetchingMore) && <Loading />}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.selectedCompanyContainer}>
            <ChannelSearchCircleListView
              className={cx([styles.companyResult, styles.selected])}
              key={parentCompany._id}
              channel={parentCompany}
              onClick={() => {
                resetSelection();
              }}
              hideDescription
            />
            <Icon
              name="edit"
              className={styles.checkIcon}
              set={ICON_SET_FONTAWESOME}
              type={FONT_AWESOME_REGULAR}
            />
          </div>
          {!isWorkplaceOnboardingEnabled && !building ? (
            <>
              {hasCompanyLocations && (
                <H5 mb={4}>{t(companyLocationSearchHeader)}</H5>
              )}
              <Input
                className={styles.companyLocationSearchInput}
                onChange={onCompanyLocationSearchTextChange}
                value={companyLocationSearch}
                placeholder={t(locationPlaceholder)}
                testId="buildingLocation"
                icon="search"
                showClear
              />
              <div
                ref={containerRefBuilding}
                className={styles.results}
                data-test="scroll-container"
              >
                {buildings.map((building, index) => (
                  <ChannelListItemWrapper
                    className={cx([styles.companyResult, styles.selected])}
                    key={(building.cursor as any) || index}
                    building={building}
                    onClick={building =>
                      onCompanyBuildingSelectionChange(
                        // location.channel is company
                        parentCompany,
                        // location.relatedTo is building
                        building
                      )
                    }
                    showName
                  />
                ))}
                {(isBuildingInitialLoading || isBuildingFetchingMore) && (
                  <Loading className={styles.loading} />
                )}
              </div>
            </>
          ) : (
            <>
              <H5 className={styles.locationPlaceholder} mb={4}>
                {t(locationPlaceholder)}
              </H5>
              <div className={styles.selectedLocationContainer}>
                <ChannelListItem
                  className={cx([styles.locationResult, styles.selected])}
                  channel={building as Channel}
                  showName
                  onClick={() => {
                    onCompanyBuildingSelectionChange(null, null);
                  }}
                />
                <Icon
                  name="edit"
                  className={styles.checkIcon}
                  set={ICON_SET_FONTAWESOME}
                  type={FONT_AWESOME_REGULAR}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
