import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionMetatag } from 'lane-shared/types/sections/SectionType';
import { getLibraryOptions } from 'lane-shared/helpers';
import { Button } from 'design-system-web';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import MetatagLibrary from 'components/lane/MetatagLibrary';

import type { Channel } from 'lane-shared/types/ChannelType';
import type { SectionType } from 'lane-shared/types/sections/SectionType';

import styles from './FiltersModal.scss';

type FiltersModalProps = {
  channel: Channel;
  section: SectionType;
  onSectionUpdated: (section: Partial<SectionType>) => void;
  isMetatagLibraryOpen: boolean;
  setIsMetatagLibraryOpen: (value: boolean) => void;
};

export const FiltersModal = ({
  channel,
  section,
  onSectionUpdated,
  isMetatagLibraryOpen,
  setIsMetatagLibraryOpen,
}: FiltersModalProps) => {
  const { t } = useTranslation();

  const [selectedMetatag, setSelectedMetatag] = useState<null | SectionMetatag>(
    null
  );

  return (
    <ModalBackground
      onClose={() => setIsMetatagLibraryOpen(false)}
      isOpen={isMetatagLibraryOpen}
      className={styles.background}
    >
      <ResizableWindow
        name="sectionEditMetatag"
        className={styles.wrapper}
        onClose={() => setIsMetatagLibraryOpen(false)}
        defaultPosition={ResizableWindow.mostlyFullScreen()}
        showHeader
      >
        <MetatagLibrary
          className={styles.metatagLibrary}
          libraries={getLibraryOptions({ channel })}
          channelId={channel?._id}
          onMetatagSelected={metatag => setSelectedMetatag(metatag)}
        />
        <ControlMenu>
          <hr />
          <Button onClick={() => setIsMetatagLibraryOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!selectedMetatag}
            onClick={() => {
              setIsMetatagLibraryOpen(false);

              if (selectedMetatag) {
                const selectMetaTags = section.sectionMetatags || [];
                const filteredTags = selectMetaTags.find(
                  item => item.metatag._id === selectedMetatag._id
                );

                if (!filteredTags) {
                  onSectionUpdated({
                    sectionMetatags: selectMetaTags.concat({
                      metatag: selectedMetatag,
                    }),
                  });
                } else if (filteredTags && filteredTags?.metatag?.toRemove) {
                  delete filteredTags.metatag.toRemove;
                }

                if (selectMetaTags.length === 0) {
                  onSectionUpdated({
                    sectionMetatags: selectMetaTags.concat({
                      metatag: selectedMetatag,
                    }),
                  });
                }
              }
            }}
          >
            {t('Add')}
          </Button>
        </ControlMenu>
      </ResizableWindow>
    </ModalBackground>
  );
};
