import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AnalyticsContext,
  ChannelsContext,
  UserDataContext,
} from 'lane-shared/contexts';
import { emitChannelSwitched } from 'lane-shared/helpers/analytics/emitChannel';

import ChannelCircleListView from 'components/lane/ChannelCircleListView';

import styles from './SwitchSubscription.scss';

export default function SwitchSubscription() {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const { primaryChannel, channels, switchChannel } =
    useContext(ChannelsContext);

  function handleSwitchChannel(channel: any) {
    return async () => {
      try {
        await switchChannel(channel._id);

        window.Toast.show(
          t(`Switched to {{channelName}}`, { channelName: channel.name })
        );

        emitChannelSwitched({
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          userId: user._id,
          channelId: channel._id,
          channelName: channel.name,
          analytics,
        });
      } catch (error) {
        window.Alert.alert({
          title: t('Something went wrong'),
          error,
        });
      }
    };
  }

  return (
    <div>
      {channels
        .filter(channel => channel._id !== primaryChannel?._id)
        .map(channel => (
          <button
            className={styles.clickableArea}
            onClick={handleSwitchChannel(channel)}
            key={channel._id}
          >
            <ChannelCircleListView channel={channel} />
          </button>
        ))}
    </div>
  );
}
