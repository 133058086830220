import { ApolloError, useMutation } from '@apollo/client';

import signInMutation from 'lane-shared/graphql/user/signIn';
import { isNotLoggedInError } from 'lane-shared/helpers';
import { UserLoginTypeEnum } from 'constants-user';

export enum SignInError {
  SERVER_ERROR = 'SERVER_ERROR',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
}

export interface SignInData {
  jti: string;
  token: string;
  __typename: string;
}

type QueryData = {
  signIn: SignInData;
};

type Props = {
  onError: (error: SignInError | undefined) => void;
};

function handleSignInError(errorToHandle: ApolloError) {
  const isInvalidPassword = isNotLoggedInError(errorToHandle);

  if (isInvalidPassword) {
    return SignInError.INVALID_PASSWORD;
  }

  return SignInError.SERVER_ERROR;
}

export function useSignIn({ onError }: Props) {
  const [signIn, { loading }] = useMutation<QueryData>(signInMutation, {
    onError: err => {
      const error = handleSignInError(err);

      onError(error);
    },
  });

  async function handleSignIn(
    email: string,
    password: string
  ): Promise<SignInData | undefined> {
    const { data } = await signIn({
      variables: {
        loginKey: email,
        password,
        loginType: UserLoginTypeEnum.Email,
      },
    });

    return data?.signIn;
  }

  return {
    signInUser: handleSignIn,
    isLoading: loading,
  };
}
