import React, { useContext, useState } from 'react';

import { ChannelCircleListView, Checkbox, Label } from 'components';
import { useTranslation } from 'react-i18next';

import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { buildUserChannelHierarchy } from 'lane-shared/helpers/user';
import { useUserEventSubscriptions } from 'lane-shared/hooks';
import { useUserChannelSettings } from 'lane-shared/hooks/useUserChannelSettings';
import { NotificationDeliveryTypeEnum } from 'constants-notifications';

import { RadioGroup } from 'components/form/';

import Button from 'components/general/Button';
import UserGroupRoleEdit from 'components/lane/UserGroupRoleEdit';
import { AdminPage } from 'components/layout';
import { Modal } from 'components/lds';

import styles from './UserChannelSettings.scss';

function ChannelSettings({
  loading,
  channel,
  eventSubscriptions,
  onUpdateEventSubscriptions,
}: any) {
  const { t } = useTranslation();
  const { hasMethod, hasMultipleTeams, updateDelivery } =
    useUserChannelSettings({
      channel,
      eventSubscriptions,
      onUpdateEventSubscriptions,
    });

  return (
    <>
      <div key={channel._id} className={styles.channelCard}>
        <ChannelCircleListView channel={channel} />
        <div className={styles.notifications}>
          <Label h2>{t('Your notification settings')}</Label>

          <ul className={styles.eventSubscriptions}>
            <Checkbox
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
              position="left"
              disabled={loading}
              className={styles.checkbox}
              name={NotificationDeliveryTypeEnum.Email}
              text={t('Email')}
              selected={hasMethod[NotificationDeliveryTypeEnum.Email]}
              onChange={() =>
                updateDelivery(NotificationDeliveryTypeEnum.Email)
              }
              testId="emailNotificationCheckbox"
            />
            <Checkbox
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
              position="left"
              disabled={loading}
              className={styles.checkbox}
              name={NotificationDeliveryTypeEnum.Push}
              text={t('Push')}
              selected={hasMethod[NotificationDeliveryTypeEnum.Push]}
              onChange={() => updateDelivery(NotificationDeliveryTypeEnum.Push)}
              testId="pushNotificationCheckbox"
            />
            <Checkbox
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; disabled: any; className... Remove this comment to see the full error message
              position="left"
              disabled={loading}
              className={styles.checkbox}
              name={NotificationDeliveryTypeEnum.SMS}
              text={t('SMS')}
              selected={hasMethod[NotificationDeliveryTypeEnum.SMS]}
              onChange={() => updateDelivery(NotificationDeliveryTypeEnum.SMS)}
              testId="smsNotificationCheckbox"
            />
          </ul>
        </div>
        <div className={styles.settings}>
          {hasMultipleTeams && <h2>{t('Your teams')}</h2>}
          <ul className={styles.roles}>
            {channel.roles?.map((role: any) => (
              <UserGroupRoleEdit key={role._id} role={role} channel={channel} />
            ))}
          </ul>
        </div>
      </div>

      {channel.subs?.map((channel: any) => (
        <ChannelSettings
          key={channel._id}
          disabled={loading}
          channel={channel}
          eventSubscriptions={eventSubscriptions}
          onUpdateEventSubscriptions={onUpdateEventSubscriptions}
        />
      ))}

      {channel.channelLocations?.map((channel: any) => (
        <ChannelSettings
          key={channel._id}
          disabled={loading}
          channel={channel}
          eventSubscriptions={eventSubscriptions}
          onUpdateEventSubscriptions={onUpdateEventSubscriptions}
        />
      ))}
    </>
  );
}

export default function UserChannelSettings() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { focusOnChannels, channels } = useContext(ChannelsContext);
  const [modal, setModalValue] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number>(1);

  const schema = {
    id: '_id',
    text: 'channel',
  };

  const { updating, eventSubscriptions, updateEventSubscriptionsDelivery } =
    useUserEventSubscriptions({
      userId: user?._id,
    });

  // show the channels in order
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ roles: UserGroupRoleType[] | u... Remove this comment to see the full error message
  const parents = buildUserChannelHierarchy({
    roles: user?.roles,
    channels: focusOnChannels,
  });

  const eventsArr = [] as any;
  const channelsToMap = selectedValue === 1 ? focusOnChannels : channels;
  const channelIds = channelsToMap.map(item => item._id);

  eventSubscriptions.filter((item: any) => {
    if (channelIds.includes(item.channel._id)) {
      eventsArr.push({ ...item, delivery: [] });

      return { ...item, delivery: [] };
    }
  });

  function updateSubscriptionFunction(): void {
    setModalValue(false);
    window.Toast.show(
      `You have unsubscribed from notifications from ${channelIds.length} channels`,
      6000
    );
    updateEventSubscriptionsDelivery(eventsArr);
  }

  async function onUpdateEventSubscriptions(eventSubscriptions: any) {
    await updateEventSubscriptionsDelivery(eventSubscriptions);
  }

  return (
    <AdminPage className={styles.UserChannelSettings}>
      <div className={styles.headerDiv}>
        <Label h1 className={styles.header}>
          {t('web.pages.portal.settings.userChannelsettings.adminTitle')}
        </Label>
        <Button
          testId="Button-UnsubscribeFromAllChannels"
          interfaceStyle="light"
          variant="outlined"
          size="large"
          onClick={() => {
            setModalValue(true);
          }}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"def... Remove this comment to see the full error message
          color="secondary"
        >
          {t('web.pages.portal.settings.userChannelsettings.unsubscribeBtn')}
        </Button>
      </div>
      {parents.map(channel => {
        return (
          <ChannelSettings
            key={channel._id}
            channel={channel}
            loading={updating}
            eventSubscriptions={eventSubscriptions}
            onUpdateEventSubscriptions={onUpdateEventSubscriptions}
          />
        );
      })}

      <Modal
        testId="Modal-UnsubscribeFromAllCannels"
        className={styles.window}
        isOpen={modal}
        title={t('web.pages.portal.settings.userChannelsettings.modalTitle')}
        onClose={() => setModalValue(true)}
        isCloseButtonHidden
        actions={
          <>
            <Button
              variant="outlined"
              loading={updating}
              onClick={() => setModalValue(false)}
            >
              {t('web.pages.portal.settings.userChannelsettings.cancelBtn')}
            </Button>
            <Button
              variant="contained"
              onClick={updateSubscriptionFunction}
              testId="Button-Unsubscribe"
            >
              Unsubscribe
            </Button>
          </>
        }
      >
        <div>
          {t('web.pages.portal.settings.userChannelsettings.modalText')}:{' '}
        </div>

        <RadioGroup
          className={styles.radioDivContainer}
          key="schedule"
          name="schedule"
          selected={selectedValue}
          schema={schema}
          items={[
            { _id: 1, channel: focusOnChannels[0]?.name },
            {
              _id: 2,
              channel: `All ${channels.length} channel${
                channels.length === 1 ? '' : 's'
              }`,
            },
          ]}
          onChange={value => {
            setSelectedValue(Number(value));
          }}
        />
      </Modal>
    </AdminPage>
  );
}
