import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { ChipSelect, ChipStyle } from 'components/ads';
import { UserDataContext } from 'lane-shared/contexts';
import { ContentType } from 'lane-shared/types/content/Content';
import { Link } from 'react-router-dom';
import { Table, PageSizeType } from 'design-system-web';
import { routes } from 'lane-shared/config';

import { Channel } from 'packages/lane-shared/types/ChannelType';
import { formatDateWithUserLocale } from 'helpers';

export const ContentTable = ({
  channel,
  contents,
}: {
  channel: Channel;
  contents: ContentType[];
}) => {
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);

  const buildLiveDate = ({
    liveDate,
    unpublishDate,
  }: {
    liveDate?: Date | null;
    unpublishDate?: Date | null;
  }) => {
    if (!liveDate || !unpublishDate) {
      return '-';
    }

    const startDate = formatDateWithUserLocale({
      date: liveDate,
      userLocale: user?.locale,
    });

    const endDate = formatDateWithUserLocale({
      date: unpublishDate,
      userLocale: user?.locale,
    });

    return t('web.pages.portal.admin.audiences.show.contentType.liveDate', {
      startDate,
      endDate,
    });
  };

  const columns = [
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.name`,
      key: 'name',
      renderCell: (name: string, contentItem: ContentType) => (
        <Link
          target="_blank"
          to={{
            pathname: routes.channelAdminContent
              .replace(':id', channel.slug)
              .replace(':contentId', contentItem._id),
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.type`,
      key: 'type',
      renderCell: (type: string) =>
        t(`web.pages.portal.admin.audiences.show.contentType.${type}`),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.lastUpdated`,
      key: '_updated',
      renderCell: (updated: Date) =>
        formatDateWithUserLocale({
          date: updated,
          userLocale: user?.locale,
          formattingOptions: { year: 'numeric' },
        }),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.schedule`,
      key: 'liveDate',
      renderCell: (_: any, contentRow: ContentType) =>
        buildLiveDate(contentRow),
    },
    {
      header: t`web.pages.portal.admin.audiences.show.contentTable.status`,
      disableSorting: true,
      key: 'status',
      renderCell: (_: any, contentRow: ContentType) => {
        if (
          new Date().toISOString() < (contentRow.liveDate! as unknown as string)
        ) {
          return (
            <ChipSelect.NonInteractive
              key={contentRow._id}
              value="web.pages.portal.admin.audiences.show.upcoming"
              type={ChipStyle.Yellow}
            />
          );
        }

        if (
          new Date().toISOString() >=
            (contentRow.liveDate! as unknown as string) &&
          new Date().toISOString() <
            (contentRow.unpublishDate! as unknown as string)
        ) {
          return (
            <ChipSelect.NonInteractive
              key={contentRow._id}
              value="web.pages.portal.admin.audiences.show.active"
              type={ChipStyle.Green}
            />
          );
        }

        return (
          <ChipSelect.NonInteractive
            key={contentRow._id}
            value="web.pages.portal.admin.audiences.show.unpublished"
            type={ChipStyle.Grey}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      defaultPageSize={10 as PageSizeType}
      hasKeywordFilter
      keywordFilterLabel={t`web.pages.portal.admin.audiences.show.contentTable.contentSearchText`}
      data={contents}
      pagination="client"
    />
  );
};
