import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Loading, S } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChannelsContext } from 'lane-shared/contexts';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { H4 } from 'components/typography';
import styles from './styles.scss';
import { Dropdown } from 'design-system-web/components/Dropdown/Dropdown';
import { useUserAccessDetails } from 'domains/accessControl/hooks/useUserAccessDetails';
import {
  AccessRole,
  AccessUser,
} from 'domains/accessControl/hooks/types/UserAccessDetails';
import { useAccessLocations } from 'domains/accessControl/hooks/useAccessLocations';
import { AccessLocation } from 'domains/accessControl/hooks/types/AccessLocation';
import { ProviderMappingList } from 'domains/accessControl/components/manage-user-access/ProviderMappingList/ProviderMappingList';
import { CredentialMappingTable } from '../../components/manage-user-access/CredentialMappingTable/CredentialMappingTable';
import { CredentialGroup } from 'domains/accessControl/types/CredentialGroup';
import { Credential } from 'domains/accessControl/types/Credential';
import { convertTo62 } from 'uuid-encoding';

export function BuildingAccessPage({ channel }: { channel: Channel }) {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();

  const [selectedChannel, setSelectedChannel] = useState<string>(channel?._id);

  const { channels: contextChannels } = useContext(ChannelsContext);

  const {
    data: user,
    loading: userLoading,
    refetch: refetchUserAccessDetails,
  } = useUserAccessDetails(userId);

  const { data: accessLocations, loading: accessLocationsLoading } =
    useAccessLocations();

  const accessLocationsMap = useMemo(() => {
    const map = new Map();

    if (accessLocations) {
      accessLocations.forEach((location: AccessLocation) => {
        map.set(location.channelId, location);
      });
    }

    return map;
  }, [accessLocations]);

  const [accessUsers, setAccessUsers] = useState<Map<string, AccessUser>>(
    new Map()
  );

  const [credentialGroups, setCredentialGroups] = useState<Map<string, any>>(
    new Map()
  );

  const currentChannelAccessUser: AccessUser | null = useMemo(() => {
    return accessUsers.get(selectedChannel) || null;
  }, [selectedChannel, accessUsers]);

  const currentChannelCredentialGroups: CredentialGroup[] = useMemo(() => {
    return credentialGroups.get(selectedChannel) || [];
  }, [selectedChannel, credentialGroups]);
  const authorizedChannels = useMemo(() => {
    const userChannels: string[] = user?.accessRole?.map(
      (accessRole: AccessRole) => accessRole.channelId
    );

    return userChannels?.filter(channelId => accessLocationsMap.has(channelId));
  }, [accessLocationsMap, user]);

  const channels = contextChannels?.filter(channel =>
    authorizedChannels?.includes(channel._id)
  );

  useEffect(() => {
    if (user) {
      const accessUsersMap = new Map<string, AccessUser>(
        user.accessUsers.map((accessUser: AccessUser) => [
          accessUser.channelId,
          accessUser,
        ])
      );

      const credentialGroupsMap = new Map<string, CredentialGroup[]>();

      user.credentialGroups?.forEach((group: CredentialGroup) => {
        const key = convertTo62(group.vtsOrganizationId);
        const currentGroups = credentialGroupsMap.get(key) || [];

        credentialGroupsMap.set(key, [...currentGroups, group]);
      });

      setAccessUsers(accessUsersMap);
      setCredentialGroups(credentialGroupsMap);
    }
  }, [user]);

  const currentAccessLocation = accessLocationsMap.get(selectedChannel);

  if (userLoading || accessLocationsLoading) {
    return (
      <Flex direction="column" align="flex-start" justify="center">
        <Loading
          testId="building-access-spinner-icon"
          className={styles.loading}
        />
      </Flex>
    );
  }

  if (!user) {
    return (
      <Flex direction="column" align="flex-start" justify="center">
        <S>{t('web.admin.accessControl.manageUserAccess.error')}</S>
      </Flex>
    );
  }

  return (
    <Flex direction="column" className={cx(styles.BuildingAccessPage)}>
      <Dropdown
        className={cx(styles.CustomDropdownStyle)}
        label={t(
          'web.admin.accessControl.manageUserAccess.accessLocationSelector.label'
        )}
        fixedLabel
        doTranslation={false}
        items={channels.map(channel => ({
          label: channel?.name || '',
          value: channel?._id || '',
        }))}
        value={selectedChannel || ''}
        onChange={({ value }: any) => setSelectedChannel(value)}
      />
      <Flex
        direction="column"
        gap={5}
        data-test="manageUserAccessControlGroups"
      >
        {!currentAccessLocation && (
          <Flex direction="column" align="flex-start" justify="center">
            <S>
              {t('web.admin.accessControl.manageUserAccess.noChannelSelected')}
            </S>
          </Flex>
        )}
        {currentAccessLocation && (
          <Flex className={cx(styles.AccessCredentialsContainer)}>
            <H4>
              {t(
                'web.admin.accessControl.manageUserAccess.accessCredentials.title'
              )}
            </H4>
            <ProviderMappingList
              accessControlProviders={
                currentAccessLocation.configuration?.accessProviders || []
              }
              identityProviders={
                currentAccessLocation.configuration?.identityProviders || []
              }
              providerMappings={
                currentChannelAccessUser?.providerMappings || []
              }
            />
            <CredentialMappingTable
              channelId={selectedChannel}
              credentials={
                currentChannelCredentialGroups?.flatMap(group =>
                  group.credentials.map((credential: Credential) => ({
                    ...credential,
                    groupId: group.id,
                    groupType: group.type,
                  }))
                ) ?? []
              }
              locationConfiguration={currentAccessLocation.configuration}
              refetch={refetchUserAccessDetails}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
