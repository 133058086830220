import { ProductsServices } from 'pages/portal/admin/channel/products-services';
import React from 'react';
import routes from 'lane-shared/config/routes';
import { useTranslation } from 'react-i18next';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Props = {
  channel?: Channel;
};

export function ServiceRequestBillingSettings({ channel }: Props) {
  const { t } = useTranslation();

  if (!channel) return null;

  const breadcrumbs = [
    {
      label: t('web.admin.serviceRequest.title'),
      url: routes.channelAdminWorkOrdersServiceRequests.replace(
        ':id',
        channel.slug
      ),
    },
    {
      label: t('web.admin.serviceRequest.settings'),
      url: routes.channelAdminWorkOrdersServiceRequestsSettings.replace(
        ':id',
        channel.slug
      ),
    },
    {
      label: t('web.admin.serviceRequest.settings.billing.title'),
    },
  ];

  const categoryFormRoute =
    routes.channelAdminWorkOrdersServiceRequestsBillingCreateCategory.replace(
      ':id',
      channel.slug
    );

  const productFormRoute =
    routes.channelAdminWorkOrdersServiceRequestsBillingCreateProduct.replace(
      ':id',
      channel.slug
    );

  const chargeCodeFormRoute =
    routes.channelAdminWorkOrdersServiceRequestsBillingCreateChargeCode.replace(
      ':id',
      channel.slug
    );

  return (
    <ProductsServices
      breadcrumbs={breadcrumbs}
      productFormRoute={productFormRoute}
      categoryFormRoute={categoryFormRoute}
      chargeCodeFormRoute={chargeCodeFormRoute}
    />
  );
}
