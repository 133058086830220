import { ApolloError } from '@apollo/client';
import { useOnboardingBuildingsQuery } from 'lane-shared/hooks';
import { AddressType } from '../../types/AddressType';

type OnboardingBuildingsParams = {
  query?: string;
  presumedEmailDomain?: string;
  pageSize?: number;
  channelId?: string | null;
  skip?: boolean;
};

export type Edge = {
  cursor: string;
  node: {
    id: string;
    name: string;
    thumbnailUrl: string | null;
    inviteOnly: boolean;
    address: AddressType;
    matchesEmailDomain: boolean;
  };
};

export type OnboardingBuildingsResult = {
  buildings: Edge[];
  isInitialLoading: boolean;
  isFetchingMore: boolean;
  error: ApolloError | null | undefined;
  fetchMore: () => void;
  hasNextPage: boolean;
  refetch: () => void;
  called: boolean;
  isSignUpBlocked: boolean;
};

export function useOnboardingBuildings({
  query = '',
  presumedEmailDomain,
  pageSize = 10, // Default page size
  channelId,
  skip,
}: OnboardingBuildingsParams): OnboardingBuildingsResult {
  const {
    buildings,
    isInitialLoading,
    isFetchingMore,
    error,
    fetchMore,
    hasNextPage,
    refetch,
    called,
  } = useOnboardingBuildingsQuery({
    query,
    presumedEmailDomain,
    pageSize,
    channelId,
    skip,
  });

  const numPrivateBuildings =
    buildings.filter(building => building.node.inviteOnly).length || 0;

  const isSignUpBlocked = numPrivateBuildings === buildings.length;

  return {
    buildings,
    isInitialLoading,
    isFetchingMore,
    error,
    fetchMore,
    hasNextPage,
    refetch,
    called,
    isSignUpBlocked,
  };
}
