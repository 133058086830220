import { routes } from 'lane-shared/config';
import { ContentTypeEnum } from 'constants-content';

export function breadCrumbsLabel(contentType: string) {
  let label: string = '';

  switch (contentType) {
    case ContentTypeEnum.Static:
      label = 'web.admin.content.viewer.bread.crumbs.label.static';
      break;
    case ContentTypeEnum.Content:
      label = 'web.admin.content.viewer.bread.crumbs.label.content';
      break;
    case ContentTypeEnum.Notice:
      label = 'web.admin.content.viewer.bread.crumbs.label.content';
      break;
    case ContentTypeEnum.ScheduledContent:
      label = 'web.admin.content.viewer.bread.crumbs.label.content';
      break;
    case ContentTypeEnum.ScheduledNotice:
      label = 'web.admin.content.viewer.bread.crumbs.label.content';
      break;
    case ContentTypeEnum.Perk:
      label = 'web.admin.content.viewer.bread.crumbs.label.perk';
      break;
    case ContentTypeEnum.Promotion:
      label = 'web.admin.content.viewer.bread.crumbs.label.promotion';
      break;
    case ContentTypeEnum.VisitorManagement:
      label = 'web.admin.content.viewer.bread.crumbs.label.visitor.management';
      break;
  }

  return label;
}

type BreadCrumbUrlFunctionMap<
  Key extends keyof typeof ContentTypeEnum,
  Value extends (channelSlug: string) => string,
> = {
  [K in Key]: Value;
};

function defaultBreadCrumbsUrl(channelSlug: string) {
  return routes.channelAdminContentCenter.replace(':id', channelSlug);
}

function perkCenterBreadCrumbsUrl(channelSlug: string) {
  return routes.channelAdminPerkCenter.replace(':id', channelSlug);
}

function promotionCenterBreadCrumbsUrl(channelSlug: string) {
  return routes.channelAdminPromotionCenter.replace(':id', channelSlug);
}

function pageCenterBreadCrumbUrl(channelSlug: string) {
  return routes.channelAdminPageCenter.replace(':id', channelSlug);
}

function visitorManagementBreadCrumbsUrl(channelSlug: string) {
  return routes.visitorManagementRegistrationForms.replace(':id', channelSlug);
}

export const breadCrumbsUrlForContentTypeMap: Readonly<
  BreadCrumbUrlFunctionMap<ContentTypeEnum, (channelSlug: string) => string>
> = Object.freeze({
  [ContentTypeEnum.Static]: pageCenterBreadCrumbUrl,
  [ContentTypeEnum.Content]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Notice]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.ScheduledContent]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.ScheduledNotice]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Promotion]: promotionCenterBreadCrumbsUrl,
  [ContentTypeEnum.Perk]: perkCenterBreadCrumbsUrl,
  [ContentTypeEnum.WorkOrder]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.VisitorManagement]: visitorManagementBreadCrumbsUrl,
});
