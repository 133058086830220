import React from 'react';
import { WaitlistTableView as ReservableWaitlistTableView } from 'domains/reservableManagement/components/WaitlistTableView';
import { WaitlistTableView as QuantityWaitlistTableView } from 'domains/quantityManagement/components/WaitlistTableView';
import { FeatureNameEnum } from 'constants-content';

type ContentWaitlistTableViewProps = {
  content: any;
  channel: any;
  timeZone: string;
  contentId: string;
};

function isFeatureActive(content: any, featureName: FeatureNameEnum): boolean {
  return (
    hasContentFeatures(content) &&
    content.features.some((f: any) => f?.type === featureName)
  );
}

function hasContentFeatures(content: any): boolean {
  return content && 'features' in content && Array.isArray(content.features);
}

export function ContentWaitlistTableView({
  content,
  timeZone,
  channel,
  contentId,
}: ContentWaitlistTableViewProps) {
  const quantityFeature = isFeatureActive(content, FeatureNameEnum.Quantity);
  const reservableFeature = isFeatureActive(
    content,
    FeatureNameEnum.Reservable
  );

  if (quantityFeature) {
    return (
      <QuantityWaitlistTableView
        channelId={channel?._id}
        contentId={contentId}
        timeZone={timeZone}
        content={content}
      />
    );
  }

  if (reservableFeature) {
    return (
      <ReservableWaitlistTableView
        channelId={channel?._id}
        contentId={contentId}
        timeZone={timeZone}
      />
    );
  }

  return null;
}
