import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import searchSectionContentQuery from 'lane-shared/hooks/useSectionQuery/searchSectionContentQuery';
import {
  PresentContentFilterTimeEnum,
  PresetContentFilter,
} from 'constants-content';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

import extractRegionAttribute from '../../../../helpers/integrations/ShopifyBurst/extractRegionAttribute';
import useBurstLocationData from './useBurstLocationData';

type Props = {
  search: string;
  shopifyParentChannelId?: string;
  roomBookingSectionId?: string;
  dates: {
    startDate: string | undefined;
    endDate: string | undefined;
    minDate: Date;
  };
  ports: ShopifyChannelType[];
};

export default function useBurstLocationFiltersAndMetatag({
  search,
  roomBookingSectionId,
  dates,
  shopifyParentChannelId,
  ports,
}: Props) {
  const { data } = useQuery(searchSectionContentQuery, {
    variables: {
      searchOptions: {
        search,
        areFiltersApplied: true,
        channelId: shopifyParentChannelId,
        filters: [
          {
            type: PresetContentFilter.FeatureReservableAvailableDays,
            filter: {
              startDate: dates.startDate,
              endDate: dates.endDate,
              useContentTimeZone: true,
              enabled: dates.endDate
                ? PresentContentFilterTimeEnum.Enabled
                : PresentContentFilterTimeEnum.AnyTime,
            },
          },
        ],
        metatagFilters: [],
      },
      id: roomBookingSectionId,
    },
    fetchPolicy: 'no-cache',
  });

  const filterOptions = useBurstLocationData(
    data?.section?.sectionContent,
    ports
  );

  return useMemo(() => {
    const tag = extractRegionAttribute(
      data?.section?.sectionContent[0]?.content
    );

    return {
      filterOptions,
      regionMetatag: {
        _id: tag?._id || '',
        metatagId: tag?.metatag._id || '',
        metatagType: 'Simple',
        valueType: 'Option',
      },
    };
  }, [filterOptions, data]);
}
