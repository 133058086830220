import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import routes from 'lane-shared/config/routes';
import { pause } from 'lane-shared/helpers';
import { convertToUUID } from 'uuid-encoding';
import {
  AttachmentResponse,
  EntityTypeEnum,
} from 'lane-shared/types/attachment';

import { ErrorMessage, Flex } from 'components';
import BreadCrumbs from 'components/lds/BreadCrumbs';
import { H3, H4 } from 'components/typography';

import {
  createEquipmentMutation,
  updateEquipmentMutation,
} from 'graphql-queries';

import { useAttachmentUpload } from 'hooks/useAttachmentUpload';
import EquipmentForm from './EquipmentForm';

import styles from './styles.scss';
import { AmazonS3Buckets } from 'lane-shared/config';

function EquipmentCreate({ channel }: any) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [attachmentFiles, setAttachmentFiles] = useState<AttachmentResponse[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const initialFormValues = {
    name: '',
    category: '',
    location: '',
    status: '',
    notes: '',
    make: '',
    model: '',
    asset: '',
    serial: '',
    floor: '',
    suite: '',
    installDate: undefined,
    warrantyExpirationDate: undefined,
    hyperlinks: [],
  };

  const { uploading: _, filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated: () => null,
    entity: {
      type: EntityTypeEnum.Equipment,
      id: '',
    },
    s3Bucket: AmazonS3Buckets.Activate,
  });

  const saveAttachments = async (equipmentId: string) => {
    let attachmentIds: string[] = [];

    try {
      const attachmentReturn = await filesSelectedHandler(
        attachmentFiles.map(attachment => attachment.file as File),
        equipmentId
      );

      attachmentIds = attachmentReturn.map(({ id }) => convertToUUID(id));
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: any) {
      setLoading(false);
    }

    return attachmentIds;
  };

  const saveEquipment = async (data: any) => {
    try {
      await pause();
      const equipment = await getClient().mutate({
        mutation: createEquipmentMutation,
        variables: {
          createEquipment: {
            name: data.name,
            category: data?.category?.value,
            location: data?.location?.value,
            status: data?.status?.value,
            notes: data?.notes,
            channelId: channel?._id,
            make: data?.make,
            model: data?.model,
            serial: data?.serial,
            asset: data?.asset,
            floor: data?.floor,
            suite: data?.suite,
            warrantyExpirationDate: data?.warrantyExpirationDate,
            installDate: data?.installDate,
            hyperlinks: data?.hyperlinks,
            meterReadingSettings: data?.meterReadingSettings,
          },
        },
      });

      // If there are attachments to be uploaded, upload them with the created Equipments ID and then update the equipment with the Attachment IDs
      if (attachmentFiles.length > 0 && equipment?.data?.createEquipment?.id) {
        const attachmentIds = await saveAttachments(
          equipment?.data?.createEquipment?.id
        );

        await getClient().mutate({
          mutation: updateEquipmentMutation,
          variables: {
            updateEquipment: {
              id: equipment?.data?.createEquipment?.id,
              name: data.name,
              category: data?.category?.value,
              location: data?.location?.value,
              status: data?.status?.value,
              notes: data?.notes,
              make: data?.make,
              model: data?.model,
              serial: data?.serial,
              asset: data?.asset,
              floor: data?.floor,
              suite: data?.suite,
              warrantyExpirationDate: data?.warrantyExpirationDate,
              installDate: data?.installDate,
              hyperlinks: data?.hyperlinks,
              meterReadingSettings: data?.meterReadingSettings,
              attachments: attachmentIds,
            },
          },
        });
      }

      setLoading(false);
      window.Toast.show(t('web.admin.serviceRequest.equipment.create.success'));
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  const addAttachment = async (files: any) => {
    const attachmentsToAdd = files.map((file: any) => ({
      file,
      thumbnailSignedUrl: URL.createObjectURL(file),
    }));
    const newAttachmentFiles = [...attachmentFiles, ...attachmentsToAdd];

    setAttachmentFiles(newAttachmentFiles);
  };

  const deleteAttachment = async (index: string) => {
    const newAttachmentFiles = [...attachmentFiles];

    newAttachmentFiles.splice(Number(index), 1);
    setAttachmentFiles(newAttachmentFiles);
  };

  const equipmentListPath = routes.channelAdminWorkOrdersEquipment.replace(
    ':id',
    channel?.slug
  );
  const afterSave = () => {
    history.push(equipmentListPath);
  };

  return (
    <div className={styles.container}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.equipment.title'),
            url: equipmentListPath,
          },
          {
            label: t('web.admin.serviceRequest.equipment.add'),
          },
        ]}
      />
      {error ? <ErrorMessage error={error} /> : null}

      <H3 mt={5} mb={5}>
        {t('web.admin.serviceRequest.equipment.add')}
      </H3>
      <Flex className={styles.equipmentForm} gap={5} direction="column">
        <H4>{t('web.admin.serviceRequest.equipment.form.title')}</H4>
        <EquipmentForm
          onSave={saveEquipment}
          loading={loading}
          formData={initialFormValues}
          addAttachments={addAttachment}
          deleteAttachment={deleteAttachment}
          attachmentFiles={attachmentFiles}
          channel={channel}
          afterSave={afterSave}
        />
      </Flex>
    </div>
  );
}

export default EquipmentCreate;
