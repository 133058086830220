import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { IconButton, ContentFilterStatus, Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import { SectionTypeEnum } from 'constants-content';

import styles from './styles.scss';

function SectionContentTable({
  headers,
  sectionContent,
  channel,
  selectedSort,
  selectedOrder,
  reverseOrder,
  section,
  loading,
  makeUrl,
  onRemoveContent,
}: any) {
  const { t } = useTranslation();

  return (
    <table>
      <thead>
        <tr>
          {headers.map((header: any) => (
            <th key={header.label}>
              <Flex align="center">
                <span>{header.label}</span>
                {header.sort && (
                  <Link
                    to={makeUrl({
                      sort: header.sort,
                      order: reverseOrder,
                    })}
                  >
                    <IconButton
                      inverted
                      icon={
                        selectedOrder === 'asc'
                          ? 'sort-amount-down-alt'
                          : 'sort-amount-up'
                      }
                      className={cx(styles.sortButton, {
                        [styles.selected]: selectedSort === header.sort,
                      })}
                      selected={selectedSort === header.sort}
                    />
                  </Link>
                )}
              </Flex>
            </th>
          ))}
          {section?.type === SectionTypeEnum.Static && (
            <th>{t`web.admin.content.sections.view.sectionContentTable.remove`}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {sectionContent.map((sectionContent: any) => (
          <tr key={sectionContent._id}>
            <td>{simpleDate(sectionContent.content._created)}</td>
            <td>{fromNow(sectionContent.content._updated)}</td>
            <td>
              <Link
                to={`/l/channel/${channel.slug}/admin/post/${sectionContent.content._id}`}
              >
                {sectionContent.content.name}
              </Link>
            </td>
            <td style={{ width: '20em' }}>
              <span style={{ fontSize: '0.8em' }}>
                {sectionContent.content.description}
              </span>
            </td>
            <td>
              {sectionContent.content.isInteractive && <Icon name="check" />}
            </td>
            <td>{sectionContent.content.tags.join(', ')}</td>
            <td>
              <ContentFilterStatus
                content={sectionContent.content}
                metatags={section.sectionMetatags?.map(
                  ({ metatag }: any) => metatag
                )}
                filters={section.filters}
                sorts={section.sorts}
              />
            </td>
            {section?.type === SectionTypeEnum.Static && (
              <td>
                <IconButton
                  inverted
                  className={styles.removeButton}
                  icon="times"
                  disabled={loading}
                  onClick={() => onRemoveContent(sectionContent)}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SectionContentTable;
