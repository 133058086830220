import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  page: {
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    margin: spacing[5],
    paddingBottom: 65,
  },
  line: {
    marginTop: 20,
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 12,
    fontWeight: 500,
    margin: 8,
    marginBottom: 4,
  },
  subheadings: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 500,
    color: colors.gray,
    marginRight: 8,
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 0,
  },
  sectionHeader: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 500,
    margin: 8,
  },
  dualView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },
  infoText: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 'bold',
    marginRight: 8,
    marginLeft: 8,
    marginTop: 2,
    marginBottom: 2,
  },
  equipmentSection: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    padding: 8,
    margin: 8,
    width: '80%',
  },
  stepSection: {
    flexDirection: 'row',
    width: '85%',
  },
  stepIndex: {
    marginRight: 5,
    width: '8%',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    fontStyle: 'italic',
    bottom: 40,
    left: 0,
    right: 50,
    textAlign: 'right',
    color: 'grey',
  },
  dualViewItem: {
    width: '48%',
    textAlign: 'left',
  },
  stepSubHeader: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 'bold',
    color: colors.gray,
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 0,
    marginTop: 2,
  },
  stepContent: {
    flex: 1,
  },
  notes: {
    width: '85%',
  },
});
