import { StyleSheet } from '@react-pdf/renderer';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';

export const styles = StyleSheet.create({
  ChargeRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 0,
    flexShrink: 0,
    rowGap: spacing[2],
    columnGap: spacing[2],
    marginVertical: spacing[1],
  },
  ChargeCell: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '20%',
    fontSize: 12,
    gap: spacing[2],
  },
  ChargeItemDetails: {
    flexBasis: '36.5%',
  },
  ChargeItemQuantity: {
    flexBasis: '10%',
  },
  ChargeCellAlignRight: {
    alignItems: 'flex-end',
  },
  ChargeCellItem: {
    color: colors.neutral.grey900,
    fontSize: 11,
  },
  ProductCategory: {
    color: colors.subduedText,
  },
  ChargeItemHeading: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: 'heavy',
  },
  Line: {
    marginTop: spacing[2],
    marginBottom: spacing[2],
  },
});
