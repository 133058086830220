import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { getDisplayName } from 'lane-shared/helpers';
import { useChannelsByRelationshipQuery } from 'lane-shared/hooks';

import Input from 'components/form/Input';

import ChannelDirectoryListView from './ChannelDirectoryListView';

import styles from './RetailOnChannel.scss';
import {
  ActiveChannelTypeEnum,
  DeprecatedChannelTypeEnum,
} from 'constants-channel';

const PER_PAGE = 300;

export default function OfficeOnChannel({ channel }: any) {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const { items } = useChannelsByRelationshipQuery({
    perPage: PER_PAGE,
    relatedTo: { _id: channel?._id },
    channel: {
      sortBy: { key: 'name', dir: 'asc' },
      type: {
        any: [
          ActiveChannelTypeEnum.Company,
          DeprecatedChannelTypeEnum.Professional,
        ],
      },
      name: {
        type: 'like',
        value: search,
      },
    },
  });

  const name = getDisplayName(channel);

  return (
    <div className={styles.RetailOnChannel}>
      <h2>{t('{{name}} Directory', { name })}</h2>
      <menu className={styles.search}>
        <Input
          className={styles.searchInput}
          icon="search"
          value={search}
          showClear
          onChange={search => setSearch(search)}
          placeholder={t('Search')}
        />
      </menu>
      <section>
        <ul>
          {items.map(relationship => (
            <Link
              className={styles.channelLink}
              to={routes.channel.replace(':id', relationship.channel.slug)}
              key={relationship.channel._id}
            >
              <ChannelDirectoryListView
                className={styles.channel}
                channel={relationship.channel}
              />
            </Link>
          ))}
        </ul>
      </section>
    </div>
  );
}
