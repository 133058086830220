import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Icon, Modal } from 'design-system-web';
import { isEmpty, isEqual } from 'lodash';

import { routes } from 'lane-shared/config';
import {
  Flex,
  CreatedBy,
  Loading,
  Tooltip,
  SuitesMultiSelectDropdown,
} from 'components';
import { AdminPage, PageHeader } from 'components/layout';
import { ButtonType } from 'components/layout/PageHeader';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { H4, S } from 'components/typography';

import { Alert } from 'components/lds';

import styles from './styles.scss';
import { convertToUUID } from 'uuid-encoding';
import { OFFICE_TYPES, RETAIL_TYPES } from 'constants-channel';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import { UserDataContext } from 'lane-shared/contexts';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { relateSpacesToTenant } from 'lane-shared/graphql/tenant';

import { RelateSpaceToTenantMutation } from 'graphql-query-contracts';
import { useFetchSuitesByTenant } from './hooks/useFetchTenantSuites';
import { useFetchRelationships } from './hooks/useFetchRelationships';
import { useCreateTenant } from './hooks/useCreateTenant';
import { useProcessSuiteList } from './hooks/useProcessSuiteList';
import { useRemoveTenantRelationship } from '../hooks/useRemoveTenantRelationship';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Space = {
  suiteIds: string[];
};

export function TenantDetails() {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { user } = useContext(UserDataContext);
  const [isSuiteEditable, setIsSuiteEditable] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState<Space>({
    suiteIds: [],
  });
  const [isNotFoundModalOpen, setIsOpenNotFoundModal] = useState(false);
  const { tenantId } = useParams<{ tenantId: string }>();
  const [relateSpaceToTenantMutation] =
    useMutation<RelateSpaceToTenantMutation>(relateSpacesToTenant);
  const { data, error } = useFetchRelationships(channel?._id, tenantId);
  const relationship = data?.channelsByRelationship?.items?.[0];
  const tenant = relationship?.channel as Channel;
  const {
    suitesData,
    fetchSuites,
    retryFetchSuites,
    retryFetchSuitesFailed,
    isLoadingSuites,
  } = useFetchSuitesByTenant(tenant?._id, channel?._id);
  const { floorNames, suiteNames, suitesAsLabelValue, selectedSuitesIds } =
    useProcessSuiteList(suitesData);

  const { removeTenant, isLoading: isRemovingTenant } =
    useRemoveTenantRelationship();

  useCreateTenant(tenant?._id, channel?._id);

  useEffect(() => {
    if ((data && !tenant && !error) || (!data && error)) {
      setIsOpenNotFoundModal(true);
    }
  }, [tenant, error, data, t]);

  useEffect(() => {
    setSpaceDetails({
      suiteIds: selectedSuitesIds,
    });
  }, [selectedSuitesIds]);

  if (isRemovingTenant) {
    return <Loading fullscreen />;
  }

  function goToChannel(rel: any) {
    const hasRetailAdmin =
      RETAIL_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
      ]);
    const hasOfficeAdmin =
      OFFICE_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY,
      ]);

    let route: string;

    if (user?.isSuperUser || hasRetailAdmin || hasOfficeAdmin) {
      route = routes.channelAdmin.replace(':id', rel.slug);
    } else {
      route = routes.channel.replace(':id', rel.slug);
    }

    history.push(route);
  }

  function onChannelUpdated(props: Space) {
    setSpaceDetails(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  const handleSaveSpaces = async () => {
    try {
      const newSelectedSuiteIds = spaceDetails.suiteIds || [];
      const suiteIdsToRemove =
        selectedSuitesIds.filter(id => !newSelectedSuiteIds.includes(id)) || [];

      await relateSpaceToTenantMutation({
        variables: {
          tenantId: convertToUUID(tenant?._id),
          propertyId: convertToUUID(channel?._id),
          suitesToAdd: newSelectedSuiteIds,
          suitesToRemove: suiteIdsToRemove,
        },
      });
      setIsSuiteEditable(false);

      if (
        !isEmpty(suiteIdsToRemove) ||
        !isEqual(selectedSuitesIds, newSelectedSuiteIds)
      ) {
        retryFetchSuites(tenant?._id, channel?._id, suitesData, 0);
      } else {
        fetchSuites(tenant?._id, channel?._id);
      }
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      window.Toast.show(
        t(
          'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.errorToast'
        )
      );
    }
  };

  const handleRemoveTenant = async () => {
    if (!relationship?._id || !tenant?.name) {
      return;
    }

    const isRemoved = await removeTenant({
      relationshipId: relationship._id,
      tenantName: tenant.name,
    });

    if (isRemoved && channel?.slug) {
      const tenantListUrl = routes.channelAdminRelationships.replace(
        ':id',
        channel.slug
      );

      history.push(tenantListUrl);
    }
  };

  const actionButtons = [
    {
      label: t(
        'web.admin.channel.settings.tenantManagement.tenant.details.removeTenant'
      ),
      type: 'secondary' as ButtonType,
      onClick: async () => handleRemoveTenant(),
      testId: 'remove-tenant',
    },
    {
      label: `${t(
        'web.admin.channel.settings.tenantManagement.tenant.details.goto'
      )} ${tenant?.name}`,
      type: 'primary' as ButtonType,
      onClick: () => goToChannel(tenant),
      testId: 'goto-tenant',
    },
  ];

  const tenantAddress = (
    <span className="tenant-location">
      <S className="block">{tenant?.address?.street1}</S>
      <S className="block">{tenant?.address?.street2}</S>
      <S>
        {[tenant?.address?.city, tenant?.address?.state, tenant?.address?.code]
          .filter(Boolean)
          .join(', ')}
      </S>
    </span>
  );

  const pageHeader = (
    <PageHeader
      header={tenant?.name}
      headerLevel="h3"
      actionButtons={actionButtons}
      externalPadding={[0, 0]}
      breadcrumbs={[
        {
          label: t('web.admin.channel.settings.tenantManagement.name'),
          url: routes.channelAdminRelationships.replace(
            ':id',
            channel?.slug || ''
          ),
        },
        {
          label: tenant?.name
            ? `${tenant?.name} ${t('web.admin.channel.settings.tenantManagement.tenant.details')}`
            : '',
        },
      ]}
      description={tenantAddress}
    />
  );

  if (!tenant || error) {
    return (
      <AdminPage>
        {pageHeader}
        <Modal
          isOpen={isNotFoundModalOpen}
          onClose={() => {
            setIsOpenNotFoundModal(false);
          }}
          title={t(
            'web.admin.channel.settings.tenantManagement.tenant.details.notFound.title'
          )}
          actions={
            <Button
              onClick={() => {
                setIsOpenNotFoundModal(false);
              }}
            >
              {t(
                'web.admin.channel.settings.tenantManagement.tenant.details.notFound.confirm'
              )}
            </Button>
          }
          testId="tenant-not-found"
        >
          {t(
            'web.admin.channel.settings.tenantManagement.tenant.details.notFound.message'
          )}
        </Modal>
      </AdminPage>
    );
  }

  const createdBy = {
    _createdBy: tenant._createdBy,
    _updatedBy: tenant._updatedBy,
    _created: tenant._created,
    _updated: tenant._updated,
  } as any;

  const showLoadingWhileRetryingFetchingSuites = isLoadingSuites && (
    <Flex direction="row" justify="flex-start" align="center">
      <span className="mr-2">
        {' '}
        {t(
          'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.loading'
        )}{' '}
      </span>
      <Loading style={{ fontSize: '1rem' }} />
    </Flex>
  );

  return (
    <AdminPage>
      {pageHeader}

      <div className="mt-4">
        <div className={styles.section}>
          <Flex align="center" mb={4}>
            <H4>
              {t`web.admin.channel.settings.tenantManagement.tenant.details.overview`}
            </H4>
            <Tooltip
              TooltipComponent={
                <>{t`web.admin.channel.settings.tenantManagement.tenant.details.overview.tooltip`}</>
              }
              placement="right"
            >
              <Icon
                className="cursor-pointer"
                name="info-circle"
                type="far"
                set={ICON_SET_FONTAWESOME}
                size="medium"
              />
            </Tooltip>
          </Flex>
          <Flex direction="column">
            <Flex className={styles.rows}>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.activeMembers'
                  )}{' '}
                </span>
                <span>{tenant?.stats?.subscribers}</span>
              </Flex>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.type'
                  )}
                </span>
                <span>{tenant?.type}</span>
              </Flex>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.squareFootage'
                  )}
                </span>
                <span>
                  {tenant?.stats?.sf}{' '}
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.sqFt'
                  )}
                </span>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>

      <div className="mt-2">
        <div className={styles.section}>
          <Flex direction="row" justify="space-between">
            <H4
              mb={4}
            >{t`web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.title`}</H4>
            {!isSuiteEditable && user?.isSuperUser && (
              <Button
                variant="secondary"
                testId="edit-floors-suites"
                size="large"
                className="!px-2"
                onClick={() => setIsSuiteEditable(true)}
              >
                {t(
                  'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.edit'
                )}
              </Button>
            )}
          </Flex>

          {isSuiteEditable ? (
            <>
              <Flex direction="row" justify="flex-start">
                <Flex direction="column" className="w-1/3" gap={3}>
                  <b>
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.floor'
                    )}{' '}
                  </b>
                  {showLoadingWhileRetryingFetchingSuites || (
                    <span data-test="floor-names">{floorNames}</span>
                  )}
                </Flex>
                <Flex direction="column" className="w-2/4 ml-2" gap={3}>
                  <SuitesMultiSelectDropdown
                    suites={suitesAsLabelValue}
                    direction="column"
                    onSuitesUpdate={({ suiteIds }: Space) =>
                      onChannelUpdated({ suiteIds })
                    }
                  />
                </Flex>
              </Flex>
              <Flex gap={3} className="mt-4">
                <Button
                  variant="primary"
                  size="large"
                  testId="save-floors-suites"
                  onClick={handleSaveSpaces}
                >
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.save'
                  )}
                </Button>
                <Button
                  variant="secondary"
                  size="large"
                  testId="cancel-floors-suites"
                  onClick={() => setIsSuiteEditable(false)}
                >
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.cancel'
                  )}
                </Button>
              </Flex>
            </>
          ) : (
            <>
              {retryFetchSuitesFailed && (
                <Flex direction="row" justify="flex-start" className="mb-3">
                  <Alert type="error">
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.retryError'
                    )}
                  </Alert>
                </Flex>
              )}
              <Flex direction="row" justify="flex-start">
                <Flex direction="column" className="w-1/3" gap={3}>
                  <b>
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.floor'
                    )}{' '}
                  </b>
                  {showLoadingWhileRetryingFetchingSuites || (
                    <span data-test="floor-names">{floorNames}</span>
                  )}
                </Flex>
                <Flex direction="column" className="w-1/3 ml-2" gap={3}>
                  <b>
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.suite'
                    )}
                  </b>
                  {showLoadingWhileRetryingFetchingSuites || (
                    <span data-test="suites-names">{suiteNames}</span>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </div>
      </div>
      <div className={styles.createdByFooter}>
        <CreatedBy object={createdBy} forAdmin />
      </div>
    </AdminPage>
  );
}
