import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { Button, Flex, Loading } from 'design-system-web';

import { Channel } from 'packages/lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';

import WorkflowBuilder from 'components/renderers/WorkflowBuilder';
import { useFlag } from 'lane-shared/hooks';

import {
  WorkflowTypeEnum,
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'constants-workflows';
import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';
import { EVENT_KEYS } from 'constants-events';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';

import styles from './NotificationsTab.scss';
import { constructDefaultWorkflowsForTaskNotifications } from 'lane-shared/domains/workOrder/emails/helpers/constructDefaultWorkflowNotifications';
import { RestoreDefaultWorkflowsParams } from 'lane-shared/hooks/useWorkflows';
import { FeatureFlag } from 'constants-flags';
import { useWorkflows } from '../../../../../hooks/useWorkflows';
import { Workflow } from 'packages/lane-shared/types/ContentWorkflowType';
import { checkOotbConfigurationV2Enabled } from 'lane-shared/helpers/channelModule';

export function NotificationsTab({
  channel,
  user,
}: {
  channel: Channel;
  user: UserType;
}) {
  const isNotificationsCrudStrategyV2Enabled = useFlag(
    FeatureFlag.NotificationsCrudStrategyV2,
    false
  );
  const {
    loading: loadingWorkflows,
    workflows,
    addWorkflow,
    saveWorkflows,
    editWorkflow,
    removeWorkflow,
    cloneWorkflow,
    restoreDefaultWorkflows,
    reorderWorkflows,
  } = useWorkflows(
    channel._id,
    WorkflowTypeEnum.Task,
    isNotificationsCrudStrategyV2Enabled
  );
  const isNotificationsBatchRestoreEnabled = useFlag(
    FeatureFlag.NotificationsBatchRestore,
    false
  );

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onAddNotificationWorkflow = async () => {
    const workflowToAdd = constructWorkflow({
      when: WorkflowWhenEnum.Immediate,
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_KEYS.EVENT_TASK_CREATED,
      type: WorkflowTypeEnum.Task,
      targetType: 'ALL',
      channelId: channel._id,
      order: workflows.length,
      userId: user._id,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    });

    addWorkflow(workflowToAdd);

    return workflowToAdd;
  };

  const handleRestoreDefaultNotifications = async () => {
    if (workflows.length !== 0) {
      try {
        await window.Alert.confirm({
          title: t`web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.modal.header`,
          message: t`web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.modal.message`,
          confirmText: t`web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.modal.confirm`,
          cancelText: t`web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.modal.cancel`,
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
        return;
      }
    }

    if (isNotificationsBatchRestoreEnabled) {
      await handleBatchRestoreDefaultNotifications();

      return;
    }

    setLoading(true);

    for (const workflow of workflows) {
      removeWorkflow(workflow._id);
    }

    const isWoModuleOotbConfigurationV2 = checkOotbConfigurationV2Enabled(
      channel.channelModules
    );
    const defaultTaskNotifications =
      constructDefaultWorkflowsForTaskNotifications({
        channelId: channel._id,
        userId: user._id,
        ootbConfigurationV2Enabled: isWoModuleOotbConfigurationV2,
      });

    await saveWorkflows(defaultTaskNotifications);
    setLoading(false);
  };

  const handleBatchRestoreDefaultNotifications = async () => {
    const onSuccess = (_: Workflow[]) => {
      window.Toast.show(
        t(
          'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.toast.success'
        )
      );
    };

    const onFailure = (error: any) => {
      Sentry.captureException(error, {
        contexts: {
          params: {
            channelId: channel._id,
            type: WorkflowTypeEnum.Task,
          },
        },
      });
      window.Toast.show(
        t(
          'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.toast.failure'
        )
      );
    };

    const params: RestoreDefaultWorkflowsParams = {
      channelId: channel._id,
      type: WorkflowTypeEnum.Task,
      onSuccess,
      onFailure,
    };

    setLoading(true);

    await restoreDefaultWorkflows(params);

    setLoading(false);
  };

  const restoreDefaultsButton = (
    <Button
      variant="tertiary"
      size="large"
      onClick={handleRestoreDefaultNotifications}
      testId="restoreDefaultsButton"
    >
      {t`web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.restoreDefaults.button`}
    </Button>
  );

  if (loading || loadingWorkflows) {
    return <Loading />;
  }

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (channel.address?.geo) {
    const [longitude, latitude] = channel.address.geo;

    timeZone = getTimeZoneByGeoLocation({ longitude, latitude });
  }

  return (
    <Flex direction="column" gap={5} className={styles.NotificationsTab}>
      <WorkflowBuilder
        title={t(
          `web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.title`
        )}
        secondaryButton={restoreDefaultsButton}
        channel={channel}
        onAddWorkflow={onAddNotificationWorkflow}
        onRemoveWorkflow={removeWorkflow}
        onCloneWorkflow={index => cloneWorkflow(index, user._id)}
        onReorderWorkflow={reorderWorkflows}
        onUpdateWorkflow={editWorkflow}
        onSaveWorkflow={saveWorkflows}
        workflows={workflows}
        timeZone={timeZone}
        translationKeys={{
          addWorkflowButton:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.emptyState.add',
          noWorkflowsToDisplayDescription:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.emptyState.description',
          noWorkflowsToDisplayText:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.emptyState.text',
          workflowSaved:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.toast.saved',
          workflowDeleted:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.toast.deleted',
          workflowDuplicated:
            'web.admin.workOrder.preventiveMaintenance.settings.notificationTab.notifications.toast.duplicated',
        }}
      />
    </Flex>
  );
}
