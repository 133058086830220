import { colors } from 'constants-colors';
import React from 'react';

export default function IconDatabase() {
  return (
    <svg
      width={22}
      height={24}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2.25C15.5469 2.25 19.25 3.5625 19.25 5.15625V5.39062C19.25 6.98438 15.5469 8.25 11 8.25C6.40625 8.25 2.75 6.98438 2.75 5.39062V5.15625C2.75 3.5625 6.40625 2.25 11 2.25ZM19.25 8.625V9.84375C19.25 11.4375 15.5469 12.75 11 12.75C6.40625 12.75 2.75 11.4375 2.75 9.84375V8.625C4.48438 9.89062 7.76562 10.5 11 10.5C14.1875 10.5 17.4688 9.89062 19.25 8.625ZM19.25 13.125V14.3438C19.25 15.9375 15.5469 17.25 11 17.25C6.40625 17.25 2.75 15.9375 2.75 14.3438V13.125C4.48438 14.3906 7.76562 15 11 15C14.1875 15 17.4688 14.3906 19.25 13.125ZM19.25 17.625V18.8906C19.25 20.4844 15.5469 21.75 11 21.75C6.40625 21.75 2.75 20.4844 2.75 18.8906V17.625C4.48438 18.8906 7.76562 19.5 11 19.5C14.1875 19.5 17.4688 18.8906 19.25 17.625ZM11 0C6.92188 0 0.5 1.125 0.5 5.15625V18.8906C0.5 22.9219 6.92188 24 11 24C15.0312 24 21.5 22.9219 21.5 18.8906V5.15625C21.5 1.125 15.0312 0 11 0Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={-13}
          y1={-12}
          x2="43.4236"
          y2="1.96448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset={1} stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
