import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getSection } from 'lane-shared/graphql/query';
import {
  PIN_STANDARD,
  PIN_CIRCLE,
  PIN_SQUARE,
  PIN_LIST_VIEW,
  PIN_IMAGE,
} from 'constants-content';
import { useMultiLanguage, useFlag } from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { FeatureFlag } from 'constants-flags';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import Loading from '../../../general/Loading';
import CirclePin from '../../../lane/CirclePin';
import ContentImagePin from '../../../lane/ContentImagePin';
import ContentPin from '../../../lane/ContentPin';
import SectionListView from '../../../lane/SectionListView';
import SquarePin from '../../../lane/SquarePin';
import styleMap from '../styleMap';
import { cloneDeep } from 'lodash';

import styles from './SectionBlock.scss';

type BlockProps = WebBlockProps & {
  pinType: string;
  section: DocumentType;
};

export default function SectionBlock({
  style,
  className,
  pinType,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { translate } = useMultiLanguage();
  const { data } = useQuery(getSection, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      id: otherProps.section?._id,
    },
  });
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  let section = data?.section;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    section = translate({
      model: cloneDeep(section),
      columns: ['name'],
      previewLanguage: props['data-preview-language'],
    });
  } else {
    section = translate({ model: section, columns: ['name'] });
  }

  function renderSection() {
    if (!section) {
      return <Loading />;
    }

    switch (pinType) {
      case PIN_SQUARE:
        return (
          <Link to={routes.section.replace(':sectionId', section._id)}>
            <SquarePin className={styles.contentPin} content={section} />
          </Link>
        );
      case PIN_CIRCLE:
        return (
          <Link to={routes.section.replace(':sectionId', section._id)}>
            <CirclePin content={section} className={styles.contentPin} />
          </Link>
        );
      case PIN_LIST_VIEW:
        return (
          <Link to={routes.section.replace(':sectionId', section._id)}>
            <SectionListView section={section} className={styles.contentPin} />
          </Link>
        );
      case PIN_IMAGE:
        return (
          <Link to={routes.section.replace(':sectionId', section._id)}>
            <ContentImagePin
              className={styles.newContentPin}
              content={section}
            />
          </Link>
        );
      case PIN_STANDARD:
      default:
        return (
          <Link to={routes.section.replace(':sectionId', section._id)}>
            <ContentPin
              className={styles.StaticContentCard}
              content={section}
            />
          </Link>
        );
    }
  }

  return (
    <div
      className={cx(styles.SectionBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      {renderSection()}
      <div className={styles.wrapper} />
    </div>
  );
}
