import React, { Suspense, useContext, useEffect, useMemo } from 'react';

import {
  AlertModal,
  Toast,
  Favicon,
  GoogleRecaptchaProvider,
  ErrorBoundary,
  Loading,
} from 'components';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { datadogRum } from '@datadog/browser-rum';

import LocationProvider from 'lane-shared/components/LocationProvider';
import RendererProvider from 'lane-shared/components/RendererProvider';
import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import {
  AppContext,
  UserDataContext,
  ChannelsContext,
  AnalyticsContext,
} from 'lane-shared/contexts';
import SignUpContextProvider from 'lane-shared/contexts/SignUpContext/SignUpContextProvider';
import { AuthUserContextProvider } from 'lane-shared/contexts/AuthUserContext/AuthUserContextProvider';
import {
  useAppData,
  useUserData,
  useUserChannelsData,
  useChannelFromSlug,
} from 'lane-shared/hooks';
import i18n from 'localization';
import { PlatformEnum } from 'constants-activate';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { useFonts } from './hooks/useFonts';

import BlockingLoad from 'components/general/BlockingLoad';

import MuiTheme from 'static/materialUITheme';

import * as primitives from './components/renderers/v5/primitives';
import locationHelper from './helpers/Location';
import history from './helpers/history';
import { getLinkHandler } from './helpers/linkHandler';
import './helpers/setAppHeight';
import { useAnonymousChannelsData } from './hooks';

import { getWhitelabelFonts } from 'lane-shared/config/whitelabels/whitelabelFonts';

import { ThemeController } from './ThemeController';
import { ThemeBlockingLoad } from 'components/general/ThemeBlockingLoad';

export default function Providers({ children, withRouter = true }: any) {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const jti = urlSearchParams.get('tokenid') ?? undefined;
  const jwt = urlSearchParams.get('token') ?? undefined;

  const {
    user,
    loading,
    error,
    route,
    hasAttemptedLogin,
    isLoggingIn,
    isLoggedIn,
    isInitialized,
    refetch,
    hasAnyPermission,
    sessionId,
  } = useUserData({ jti, jwt });

  const getHost = (url: string) => {
    // https://*.sub.domain.name.com to match sub.domain.name.com
    const pattern = /^(https?:\/\/)?(\*\.)?(?<host>[^/:]+)(:\d+)?(\/.*)?$/;

    return pattern.exec(url)?.groups?.host?.toLowerCase();
  };

  const address = window.location.href;
  const addressHost = getHost(address);
  const analytics = useContext(AnalyticsContext);

  const linkHandler = useMemo(
    () => getLinkHandler(analytics, user?._id),
    [analytics, user?._id]
  );

  function getChannelSlug() {
    const pathNameSplit = window.location.pathname.split('/');

    return pathNameSplit[4] === 'admin' ? pathNameSplit[3] : null;
  }

  const channelSlug = getChannelSlug();
  const { channel: adminFocusChannel } = useChannelFromSlug({
    channelSlug: channelSlug ?? null,
  });

  const {
    whitelabel,
    whitelabels,
    hasWhiteLabelLoaded,
    cards,
    blocks,
    isTransitioning,
    transition,
    clearTransition,
    isBlockingLoad,
    setIsBlockingLoad,
    isThemeBlockingLoad,
    setIsThemeBlockingLoad,
  } = useAppData({
    defaultInstance: WHITELABEL_LANE,
    findWhiteLabel: (whitelabel: WhiteLabelType) => {
      const isHostSame = (wlHost: string) =>
        addressHost && getHost(wlHost)?.toLowerCase() === addressHost;

      return whitelabel.hosts.some(isHostSame);
    },
  });

  const { bodyFont, headerFont } = getWhitelabelFonts(whitelabel?.instance);

  const channelsData = useUserChannelsData({
    user,
    channelId: adminFocusChannel?._id,
  });

  const anonChannelsData = useAnonymousChannelsData({
    user,
    isInitialized,
    isLoggingIn,
  });

  useEffect(() => {
    if (
      !isLoggedIn &&
      whitelabel?.locale &&
      i18n.language !== whitelabel.locale
    ) {
      i18n.changeLanguage(whitelabel.locale);
    }
  }, [whitelabel?.locale, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && user?.profile) {
      datadogRum.setGlobalContext({
        user: {
          _id: user.profile?._id || '',
        },
      });
    }
  }, [user, isLoggedIn]);

  useFonts({ bodyFont, headerFont });

  // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  function setAlertRef(ref: AlertModal) {
    window.Alert = ref;
  }

  // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  function setToastRef(ref: Toast) {
    window.Toast = ref;
  }

  let channelForTheme =
    anonChannelsData?.primaryChannel ?? anonChannelsData?.secondaryChannel;

  if (user) {
    channelForTheme =
      channelsData?.primaryChannel ?? channelsData?.secondaryChannel;
  }

  return (
    <Suspense fallback={<Loading />}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message */}
      <ErrorBoundary user={user} sessionId={sessionId}>
        <I18nextProvider i18n={i18n}>
          <RendererProvider
            platform={PlatformEnum.Web}
            primitives={primitives}
            linkHandler={linkHandler}
            blockDefinitions={blocks}
          >
            <LocationProvider locationHelper={locationHelper}>
              <AppContext.Provider
                value={{
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<WhiteLabelType>' is not assignable t... Remove this comment to see the full error message
                  whitelabel,
                  whitelabels,
                  cards,
                  blocks,
                  transition,
                  hasWhiteLabelLoaded,
                  isTransitioning,
                  isBlockingLoad,
                  setIsBlockingLoad,
                  clearTransition,
                  isThemeBlockingLoad,
                  setIsThemeBlockingLoad,
                }}
              >
                <AuthUserContextProvider>
                  <UserDataContext.Provider
                    value={{
                      user,
                      error,
                      route,
                      hasAttemptedLogin,
                      isLoggingIn,
                      loading,
                      isLoggedIn,
                      isInitialized,
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '(variables?: Partial<OperationVariables> | u... Remove this comment to see the full error message
                      refetch,
                      hasAnyPermission,
                    }}
                  >
                    <GoogleRecaptchaProvider>
                      <MuiThemeProvider theme={MuiTheme}>
                        <ChannelsContext.Provider
                          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelsContextType | { readonly channels: r... Remove this comment to see the full error message
                          value={user ? channelsData : anonChannelsData}
                        >
                          <ThemeController channel={channelForTheme}>
                            <SignUpContextProvider>
                              <Favicon />
                              <BlockingLoad />
                              <ThemeBlockingLoad />
                              {withRouter ? (
                                <Router history={history}>{children}</Router>
                              ) : (
                                <>{children}</>
                              )}
                            </SignUpContextProvider>
                          </ThemeController>
                        </ChannelsContext.Provider>
                      </MuiThemeProvider>
                      <AlertModal ref={setAlertRef} />
                      <Toast ref={setToastRef} />
                    </GoogleRecaptchaProvider>
                  </UserDataContext.Provider>
                </AuthUserContextProvider>
              </AppContext.Provider>
            </LocationProvider>
          </RendererProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </Suspense>
  );
}
